import React, { useState, useEffect, useCallback } from 'react'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import { toast } from 'react-toastify';
import './Profile.css';
import { getCompanyDetails, getUserDetails, storeUserDetails } from '../../Services/Storage';
import { UpdateUserProfile, ViewUserDetails } from '../../Config/api';
import Title from '../../JsonData/Tittle';
import formatDate from '../Component/DateConvert';
// import { getRegisterId } from '../../Config/userId';

const Profile = () => {

  // const register_id = getRegisterId();
  const user_details = getUserDetails();
  const company_details = getCompanyDetails();
  const [FetchDetails, SetFetchDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEditing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address_line1: '',
    address_line2: '',
    password: '',
    city: '',
    state: '',
    pincode: '',
    role: "Company",
    user_mr_ms: "Mr",
    edited_by: user_details.id,
  });

  const FetchDt = useCallback(async () => {
    try {
      const response = await ViewUserDetails(`?register_id=${user_details.id}`);
      console.log(response)

      const updatedetails = response.data.user_details;
      SetFetchDetails(updatedetails);
      delete updatedetails.fcm_token;
    } catch (error) {
    }

  }, [user_details.id]);

  useEffect(() => {
    localStorage.removeItem("salaryFilters");
    FetchDt();
  }, [FetchDt]);

  useEffect(() => {
    if (FetchDetails) {
      setFormData({
        name: FetchDetails.name || '',
        email: FetchDetails.email || '',
        phone: FetchDetails.phone || '',
        address_line1: FetchDetails.address_line1 || '',
        address_line2: FetchDetails.address_line2 || '',
        city: FetchDetails.city || '',
        state: FetchDetails.state || '',
        pincode: FetchDetails.pincode || '',
        role: 'Company'
      });
    }
  }, [FetchDetails
  ]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const openeditprofile = () => {
    setEditing(!isEditing)
  };

  const handleclose = () => {
    setEditing(false)
  };

  const handleSubmit = async () => {
    let newErrors = {};
    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.name)) {
      newErrors.name =
        " Name must contain only alphabetic characters and spaces";
    }
    const phonenumber = String(formData.phone).trim();
    if (phonenumber === "") {
      newErrors.phone = "Phone is required";
    } else if (!/^\d+$/.test(phonenumber)) {
      newErrors.phone = "Phone must contain only digits";
    } else if (phonenumber.length < 10 || phonenumber.length > 13) {
      newErrors.phone = "Phone must be between 10 to 13 digits";
    }
    if (formData.email.trim() === '') {
      newErrors.email = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }
    if (formData.address_line1.trim() === '') {
      newErrors.address_line1 = 'Address Line1 is required';
    } if (formData.address_line2.trim() === '') {
      newErrors.address_line2 = 'Address Line2 is required';
    }
    if (formData.city.trim() === '') {
      newErrors.city = 'City is required';
    }
    if (formData.state.trim() === '') {
      newErrors.state = 'State is required';
    }
    const pin_field = String(formData.pincode).trim()
    if (pin_field === '') {
      newErrors.pincode = 'Pincode is required';
    } else if (!/^\d+$/.test(pin_field)) {
      newErrors.pincode = 'Pincode must contain only digits';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
        const response = await UpdateUserProfile(user_details.id, formData);
        if (response) {
          toast.success('User Details Updated successful!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          });
          storeUserDetails(response.data.updated_registration);
          FetchDt();
          setEditing(false)
          setTimeout(() => {
          }, 1000);
        }
      }
      catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors[key] = errorData[key];
            }
          }
          setErrors(newErrors);
        } else {
          toast.error('Failed.Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,

          });
          const newErrors = {
            response_error: [],

          };
          for (const key in error.response.data) {
            if (error.response.data.hasOwnProperty(key)) {
              newErrors.response_error.push(`${error.response.data[key]}`);
            }
          }
          setErrors(newErrors);
          setFormData((state) => ({
            ...state,
          }));
        }

      }
      finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>
                <div className='pages-subheader-sub-1'>
                  <p> Profile</p>
                  <span className='header-search-add-parent'>
                  </span>
                </div>
                <div className='pages-line'></div>
              </div>
              <div className='pages-maincontent'>
                {!isEditing ?
                  (
                  
                  <div className='manageacc-maincont'>
                    <div>

                  
                    <div className='manageacc-editbutton'>
                    <p className='manageacc-maincont-heading'>Personal Info:</p>

                          <button onClick={openeditprofile}>Edit</button>
                        </div>
                        <br/>

                    <div className='manageacc-personalinfoview'>
                    <div className='manageacc-personalinfoviewsb'>
                      <div>
                        <p>
                          <strong>Name</strong>
                        </p>
                        <p>
                          <strong>Email</strong>
                        </p>
                        <p>
                          <strong>Phone</strong>
                        </p>
                        <p>
                          <strong>Account Created</strong>
                        </p>
                        <p>
                          <strong>Account Status</strong>
                        </p>
                      </div>
                      <div>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                      </div>
                      <div>
                        <p>
                        {user_details.name}
                          </p>
                        <p style={{color:"#133fbf"}}>
                        {user_details.email}
                        </p>
                        <p>
                        {user_details.phone}
                        </p>
                        <p>
                        {formatDate(user_details.date)}
                        </p>
                        <p style={{color:"green",fontWeight:"600"}}>
                             ACTIVE
                        </p>
                      </div>
                      
                     
                      </div>
                      <div className='manageacc-personalinfoviewsb'>
                      <div>
                        <p>
                          <strong>Address</strong>
                        </p>
                        <p>
                          <strong>City</strong>
                        </p>
                        <p>
                          <strong>State</strong>
                        </p>
                        <p>
                          <strong>Pincode</strong>
                        </p>
                        {/* <p>
                          <strong>Account Status</strong>
                        </p> */}
                      </div>
                      <div>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                        
                      </div>
                      <div>
                        <p>
                        {user_details.address_line1},{user_details.address_line2}
                        </p>
                        <p>
                       {user_details.city}
                        </p>
                        <p>
                        {user_details.state}
                        </p>
                        <p>
                        {user_details.pincode}
                        </p>
                        
                      </div>
                      
                     
                      </div>
                    </div>
<br/>
                    <div className='manageacc-editbutton'>
                                          <p className='manageacc-maincont-heading'>Company Info:</p>

                          {/* <button onClick={openeditprofile}>Edit</button> */}
                        </div>
                        <br/>
                    <div className='manageacc-personalinfoview'>
                    <div className='manageacc-personalinfoviewsb'>
                      
                      <div>
                      
                        <p>
                          <strong>Company Name</strong>
                        </p>
                        <p>
                          <strong>Company Email</strong>
                        </p>
                        <p>
                          <strong>Company Phone</strong>
                        </p>
                       
                        <p>
                          <strong>Company Status</strong>
                        </p>
                      </div>
                      <div>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                        
                      </div>
                      <div>
                        <p>
                        {company_details.company_name}
                          </p>
                        <p style={{color:"#133fbf"}}>
                        {company_details.company_email}
                        </p>
                        <p>
                        {company_details.company_phone_num}
                        </p>
                        
                        <p style={{color:"green",fontWeight:"600"}}>
                             ACTIVE
                        </p>
                      </div>
                      
                     
                      </div>
                      <div className='manageacc-personalinfoviewsb'>
                      <div>
                        <p>
                          <strong>Address</strong>
                        </p>
                        <p>
                          <strong>City</strong>
                        </p>
                        <p>
                          <strong>State</strong>
                        </p>
                        <p>
                          <strong>Pincode</strong>
                        </p>
                        {/* <p>
                          <strong>Account Status</strong>
                        </p> */}
                      </div>
                      <div>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                        <p>
                          :
                        </p>
                        
                      </div>
                      <div>
                        <p>
                        {company_details.company_address_line1},{company_details.company_address_line1}
                        </p>
                        <p>
                       {company_details.company_city}
                        </p>
                        <p>
                        {company_details.company_state}
                        </p>
                        <p>
                        {company_details.company_pincode}
                        </p>
                        
                      </div>
                      
                     
                      </div>
                    </div>

                    </div>
                    {/* <div className='manageacc-main'>
                      <div className='manageacc-main-sub'>
                        <div className='manageacc-editbutton' onClick={openeditprofile}>
                          <CiEdit style={{ color: "cornflowerblue", fontSize: "20px", cursor: "pointer" }} />
                        </div>
                        <div className='first-input-manageacc'>
                          <div style={{ width: "45px", height: "45px", borderRadius: "50%", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "green", color: "#fff", fontSize: "20px", fontWeight: "bold" }}>

                            {`${user_details.name?.split(" ")[0]?.charAt(0) || ""}${user_details.name?.split(" ")[1]?.charAt(0) || ""}`}

                          </div>
                          <div className='manageacc_h_p'>
                            <p>{user_details.name.charAt(0).toUpperCase() + user_details.name.slice(1)}</p>
                          </div>

                        </div>
                        <div className='manageacc-container'>
                          <p>Personal:</p>
                          <div >

                            <div><p className='manageacc-para'><label className='manageacc-content-view'><FcCellPhone size={20}/></label> {user_details.phone}</p></div>
                            <div><p className='manageacc-para'><label className='manageacc-content-view'><FcAddressBook size={20}/></label> {user_details.email}</p></div>

                          </div>
                          <p>Address:</p>
                          <div className='second-input-manageacc'>

                            <div><p className='manageacc-para'><label className='manageacc-content-view'>Address Line1 :</label> {user_details.address_line1}</p></div>
                        
                            <div><p className='manageacc-para'><label className='manageacc-content-view'>Address Line2 :</label>  {user_details.address_line2}</p></div>
                       
                            <div><p className='manageacc-para'><label className='manageacc-content-view'>State & City :</label>  {user_details.city} &{user_details.state}</p></div>
                        
                            <div><p className='manageacc-para'><label className='manageacc-content-view'>Pincode :</label>{user_details.pincode}</p></div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    
                  </div>
                  
                )
                  :
                  (
                    <div className='registeration-main'>
                      <div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>

                            <label htmlFor='first-input'> Name <span className='registeration-mandatory'>*</span></label>
                            <span className='select-input'>
                              <select className='select_mr_mrs' value={formData.user_mr_ms} onChange={handleInputChange} name='user_mr_ms'>
                                {Title.title.map((tittle, index) => (
                                  <option key={index}>
                                    {tittle}

                                  </option>
                                )
                                )
                                };
                              </select>
                              <input id='first-input' type='text' onChange={handleInputChange} name='name' className='registeration-input-field' value={formData.name} />
                            </span>

                            {errors.name && (
                              <div className="registeration-error-view">{errors.name}</div>
                            )}
                          </div>
                        </div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='third-input'>Phone <span className='registeration-mandatory'>*</span></label>
                            <input id='third-input' type='text' onChange={handleInputChange} name='phone' className='registeration-input-field' value={formData.phone} />
                            {errors.phone && (
                              <div className="registeration-error-view">{errors.phone}</div>
                            )}
                          </div>

                        </div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='fifth-input'>Email <span className='registeration-mandatory'>*</span></label>
                            <input id='fifth-input' type='text' onKeyDown={(e) => {
                              const regex = /^[a-zA-Z0-9@._\b]+$/;
                              if (!regex.test(e.key)) {
                                e.preventDefault();
                              }
                            }} onChange={handleInputChange} name='email' value={formData.email} className='registeration-input-field' />
                            {errors.email && (
                              <div className="registeration-error-view">{errors.email}</div>
                            )}
                          </div>

                        </div>
                        <div className='registeration-input-row'>
                        </div>
                        <div className='registeration-input-row'>

                          <div className='registeration-input-group'>
                            <label htmlFor='second-input'>Address Line 1 <span className='registeration-mandatory'>*</span></label>
                            <input id='second-input' type='text' onChange={handleInputChange} value={formData.address_line1} name='address_line1' className='registeration-input-field input-cls-full' />
                            {errors.address_line1 && (
                              <div className="registeration-error-view">{errors.address_line1}</div>
                            )}
                          </div>
                          <div className='registeration-input-group'>
                            <label htmlFor='fourth-input'>Address Line 2 <span className='registeration-mandatory'>*</span></label>
                            <input id='fourth-input' type='text' onChange={handleInputChange} value={formData.address_line2} name='address_line2' className='registeration-input-field' />
                            {errors.address_line2 && (
                              <div className="registeration-error-view">{errors.address_line2}</div>
                            )}
                          </div>

                        </div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='sixth-input'>City <span className='registeration-mandatory'>*</span></label>
                            <input id='sixth-input' type='text' value={formData.city} onChange={handleInputChange} name='city' className='registeration-input-field' />
                            {errors.city && (
                              <div className="registeration-error-view">{errors.city}</div>
                            )}
                          </div>
                          <div className='registeration-input-group'>
                            <label htmlFor='eighth-input'>State <span className='registeration-mandatory'>*</span></label>
                            <input id='eighth-input' type='text' onChange={handleInputChange} name='state' className='registeration-input-field' value={formData.state} />
                            {errors.state && (
                              <div className="registeration-error-view">{errors.state}</div>
                            )}
                          </div>
                          <div className='registeration-input-group'>
                            <label htmlFor='tenth-input'>Pincode <span className='registeration-mandatory'>*</span></label>
                            <input id='tenth-input' type='text' onChange={handleInputChange} name='pincode' className='registeration-input-field' value={formData.pincode} />
                            {errors.pincode && (
                              <div className="registeration-error-view">{errors.pincode}</div>
                            )}
                          </div>
                        </div>
                        <div >
                          {errors.response_error && (
                            <div className="registeration-error-view">{errors.response_error}</div>
                          )}
                          <div className='manageacc-button-div'>
                            <button className='manageacc-button1' onClick={handleclose}>Close</button>
                            <button className='manageacc-button' onClick={handleSubmit} disabled={loading}>
                              {loading ? <span className="spinner"></span> : 'Update'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile