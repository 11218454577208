import React, { useState, useEffect, useCallback } from 'react'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import { CiEdit, CiSearch } from 'react-icons/ci'
import pageloader from '../../../Loader/pageloading/Pageloader'
import nodatafound from '../../../Images/Noimagefound/Noimgfound'
import ReactPaginate from "react-paginate";
import { UpdateEmployeeStatus, View_User, ViewRolesselect } from '../../../Config/api'
import { getUserDetails } from '../../../Services/Storage'
import EditUser from './EditUser'
import { useNavigate } from 'react-router-dom'
import Switch from "react-switch";
import Select from "react-select";
import { Link } from 'react-router-dom';
import Confirmation from '../../Component/Confirmation'
import { getRegisterId } from '../../../Config/userId'

const ViewUser = () => {

  const register_id = getRegisterId();
  const [employeeStatus, setEmployeeStatus] = useState("all");
  const [search, setSearchword] = useState("");
  const [totaldedcount, setdedcount] = useState(0)
  const [userfilter, setuserfilter] = useState({ value: "all", label: "All" });
  const [loading, setIsloading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [rowId, setRowId] = useState([])
  const [currentpage, setCurrentPage] = useState(1);
  const [updatedatas, setupdatedatas] = useState()
  const [edituserandroles, setedituserandroles] = useState(false);
  const [statusToUpdate, setStatustoupdate] = useState(null)
  const [userandroles, setuserandroles] = useState()
  const [activeStates, setActiveStates] = useState({});
  const [rolefilview, setrolefilview] = useState('')
  const [userfilterrol, setuserfilterrol] = useState({ value: "", label: "All" });
  const [role, setrole] = useState('')
  const user_details = getUserDetails();
  const navigate = useNavigate();
  const per_page = 10;

  useEffect(() => {
    localStorage.removeItem("salaryFilters");

    const fetchRoleData = async () => {
      try {
        const data = await ViewRolesselect(`?user_id=${register_id}`);

        const roleData = [
          { value: "", label: "ALL" },
          ...data.data.get_role.map(role => ({
            value: role.id,
            label: role.role,
          })),
        ]
        setrolefilview(roleData);
        console.log(roleData, "sdsdsds")
      } catch (error) {
      }
    };
    fetchRoleData();
  }, [register_id]);

  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const listStatus = [
    { value: "all", label: "All" },
    { value: "0", label: "Active" },
    { value: "1", label: "Deactive" },
  ];

  const handleSearch = (event) => {
    setSearchword(event.target.value);
  };

  const openeditpopup = (id) => {
    const dtalist = userandroles.filter((data) => data.register_details[0].id === id);
    setupdatedatas(dtalist[0]);
    setedituserandroles(true)
  }
  const closeeditpopup = () => {
    setedituserandroles(false)

  }
  const openaddpopup = () => {

    navigate('/adduser')

  }

  const updateddatas = (data) => {
    if (data) {
      Fetchuserandroledetail();
    }

  }

  const handleToggle = (datas) => {
    const currentStatus = datas.register_details[0].user_status;
    const newStatus = currentStatus === "0" ? "1" : "0"; // Toggle status
    console.log(datas.register_details[0].user_status)
    setStatustoupdate(newStatus)
    setRowId(datas.register_details[0].id);
    setShowConfirmModal(true);

  }

  const handleConfirm = async () => {
    setShowConfirmModal(false);
    // const newStatus = !activeStates[rowId];
    // setActiveStates((prevState) => ({
    //   ...prevState,
    //   [rowId]: newStatus,
    // }));
    try {
      const data = {
        ids: [rowId],
        status: statusToUpdate, // Use the updated status
        edited_by: register_id
      }

      const response = await UpdateEmployeeStatus(data);
      if (response) {
        // setActiveStates((prevState) => ({
        //   ...prevState,
        //   [rowId]: statusToUpdate === "0", // Reflect the new status
        // }));
        Fetchuserandroledetail();
      }
    } catch (error) {
      console.error('Failed to update status:', error);
    }

  }

  const handleChangeList = (selecteOption) => {
    setEmployeeStatus(selecteOption.value);
    setuserfilter(selecteOption);
    setCurrentPage(1);

  };
  const EmployeeStatusText = (status) => {
    switch (status) {
      case '0':
        return 'Active'
      case '1':
        return 'Deactive';
      default:
        return 'None';

    }
  }
  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };
  const handleChangeRole = (selecteOption) => {
    setrole(selecteOption.value);
    setuserfilterrol(selecteOption);
    setCurrentPage(1);
  };

  const Fetchuserandroledetail = useCallback(async () => {
    try {
      setIsloading(true);

      const response = await View_User(`?user_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}&role=${role}&user_status=${employeeStatus}`);
      if (response) {
        setuserandroles(response.data.get_invite_users_details);
        setdedcount(response.data.page_context.total);
        const initialStates = response.data.get_invite_users_details.reduce((acc, user) => {
          const { id, user_status } = user.register_details[0];
          acc[id] = user_status === "0";
          return acc;
        }, {});
        setActiveStates(initialStates);
      }
    } catch (error) {

    } finally {
      setIsloading(false);

    }
  }, [user_details.id, role, currentpage, employeeStatus, search]);

  useEffect(() => {
    Fetchuserandroledetail();
  }, [Fetchuserandroledetail])

  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>


                <div className='pages-subheader-sub-1'>
                  <p>Users</p>
                  <span className='header-search-add-parent'>
                    <div className='pages-serach-view'>
                      <CiSearch className='page-search-icon' />
                      <input className='pages-serach' onChange={handleSearch} placeholder='Search...' type='search' />
                    </div>
                    <button className='addusrndrl-button-top' onClick={openaddpopup} >Add User</button>

                  </span>
                </div>



                <div className='pages-line'></div>
              </div>
              <div className="pages-filter-options">
                <div className="pages-filter-sub">
                <Link to="/viewroles" className='earnings-redirect-link'>
                    <div className='userndrole-step '>
                      Roles
                    </div>
                  </Link>
                  <Link to="/viewuser" className='earnings-redirect-link2'>
                    <div className='userndrole-step userndrole-selected '>
                      User
                    </div>
                  </Link >           
                  <Select
                    className="pages-dropdown1"
                    options={rolefilview}
                    onChange={handleChangeRole}
                    value={userfilterrol}
                    placeholder="Select"
                  />

                  <Select
                    className="pages-dropdown"
                    onChange={handleChangeList}
                    options={listStatus}
                    value={userfilter}
                    placeholder="Active List"
                  />

                </div>
              </div>
              <div className='pages-maincontent'>
                {loading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                )
                  : (
                    <div className="pages-table-container">
                      <table className="pages-table">
                        {userandroles && userandroles.length > 0 && (
                          <thead>
                            <tr>
                              <th></th>
                              <th>S NO</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Role</th>
                              <th>##</th>
                              <th>Active/Deactive</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {userandroles && userandroles.length > 0 ? (
                            userandroles.map((userroleview, index) => (
                              <tr key={userroleview.id}>
                                <td></td>
                                <td>{(currentpage - 1) * per_page + index + 1}</td>
                                <td>{userroleview.register_details[0].name}</td>
                                <td>{userroleview.register_details[0].email}</td>
                                <td>{userroleview.role_details[0].role}</td>
                                <td>
                                  {(userroleview.register_details[0].user_status !== "1")
                                    ? (
                                      <>
                                        <span>
                                          <CiEdit onClick={() => openeditpopup(userroleview.register_details[0].id)} style={{ color: "#2a7ac7", cursor: "pointer", fontSize: "16px" }} />
                                        </span></>
                                    )
                                    : (<></>)

                                  }


                                </td>

                                <td>
                                  <Switch
                                    onChange={() => handleToggle(userroleview)}
                                    checked={activeStates[userroleview.register_details[0].id] || false}
                                    onColor="#4caf50"
                                    offColor="#ccc"
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6} className="no-data-table">
                                <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                  )}
                <div style={{ float: "right" }} id="pagination_link"></div>
                <div >
                  {(!loading) && (totaldedcount > per_page) ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totaldedcount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        forcePage={currentpage - 1}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditUser
        show={edituserandroles}
        close={closeeditpopup}
        datas={updatedatas}
        updated={updateddatas}
      />

      <Confirmation
        isOpen={showConfirmModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        message={`Are you sure you want to ${EmployeeStatusText(String(statusToUpdate))} ?`}
      />


    </div>
  )
}

export default ViewUser