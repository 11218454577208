import React, { useState, useEffect, useCallback } from 'react'
import Select from 'react-select';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import ReactPaginate from "react-paginate";
import formatDate from '../../Component/DateConvert';
import leaveStatusText from '../LeaveStatusText';
import { getStaffDetails, getUserDetails } from "../../../Services/Storage";
import { DeleteLeavestaffApi, ViewStaffLeaveApi } from '../../../Config/api';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import getMonthYearData from '../../Component/MonthYearList';
import pageloader from '../../../Loader/pageloading/Pageloader';
import AddStaffLeave from '../Addleave/AddStaffLeave';
import { CiTrash } from 'react-icons/ci';
import DeleteAlert from '../../popup/DeleteAlert';
import LeaveReason from '../../Component/LeaveReason';
import { toast } from 'react-toastify';

const StaffLeave = () => {

  const [isaddleaveopen, setaddleaveopen] = useState(false);
  const [currentpage, setCurrentPage] = useState(1);
  const [totalLeaveCount, setTotalLeaveCount] = useState(0);
  const [leaves, setLeaves] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const user_details = getUserDetails();
  const staff_details = getStaffDetails();
  const [deleteComponentId, setDeleteComponentId] = useState(null);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [openReason, setOpenReason] = useState(false);
  const [reasonView, setReaseonView] = useState(false);
  
  const per_page = 10;

  const handleChangeYear = (selectedOption) => {
    setSelectedYear(selectedOption.value);
    setCurrentPage(1)
  };

  const handleChangeMonth = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
    setCurrentPage(1)
  };

  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const showConfirmationModalDelete = (delid) => {
    setDeleteComponentId(delid);
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setDeleteComponentId(null);
    setConfirmationModalOpen(false);
  };

  const confirmDelete = () => {
    if (deleteComponentId) {
      deletesalaryComponent(deleteComponentId);
      closeConfirmationModal();
    }
  };

  const handleDataFromChild = (data) => {
    if (data) {
      fetchleaveList();
    }
  }

  const addStaffleave = () => {
    setaddleaveopen(true);
  };

  const closeaddleave = () => {
    setaddleaveopen(false);
  };

  const openLeaveResponPop = (name,data) => {
    const datas ={
      "name":name,
      "leave_reason":data,
    }
    setReaseonView(datas)
    setOpenReason(true);
  };

  const closeLeaveReasonPop = () => {
    setOpenReason(false);
  };

  const { months, years } = getMonthYearData();
  const monthData = Object.keys(months).sort((a, b) => a - b).map(key => (
    {
      value: key,
      label: months[key],
    }));

  const yearData = Object.keys(years).map(key => ({
    value: years[key],
    label: years[key],
  }));

  const deletesalaryComponent = async (componentId) => {
    try {
      const response = await DeleteLeavestaffApi(componentId);
      if (response) {
        const updatedList = leaves.filter((component) => component.id !== componentId);
        setLeaves(updatedList);
        toast.success('Deleted Sucessfully!', {
                                position: "top-center",
                                autoClose: 2000,
                                closeButton: false,
                                className: 'custom_toast'
                              });
      }
    }
    catch (error) {
    }
  }

  const fetchleaveList = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await ViewStaffLeaveApi(
        `?user_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&year=${selectedYear}&month=${selectedMonth}&admin_id=${staff_details.register_details.user_id}`
      );
      setLeaves(response.data.emp_leave_view);
      setTotalLeaveCount(response.data.page_context.total);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [currentpage, selectedMonth, selectedYear, user_details.id, staff_details.register_details.user_id]);
  useEffect(() => {
    fetchleaveList();
  },
    [fetchleaveList]);

  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>
                <div className='pages-subheader-sub-1'>
                  <p>Leave</p>
                  <span className='header-search-add-parent'>

                    <button className='pages-addempbutton' onClick={addStaffleave}>Add Leave</button>
                  </span>
                </div>

                <div className='pages-line'></div>
              </div>
              <div className='pages-filter-options'>
                <div className='pages-filter-sub'>
                  <p>Filter:</p>

                  <Select className='pages-dropdown' options={yearData} value={yearData.find(option => option.value === selectedYear)} onChange={handleChangeYear} placeholder="Select Year" />
                  <Select className='pages-dropdown' options={monthData} onChange={handleChangeMonth} value={monthData.find(option => option.value === selectedMonth)} placeholder="Select Month" />

                </div>
              </div>
              <div className='pages-maincontent'>
                {isLoading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                ) : (
                  <table className='pages-table'>
                    {leaves.length > 0 && (
                      <thead>
                        <tr>
                          <th></th>
                          <th> S.No</th>
                          <th>From Date</th>
                          <th>To Date</th>
                          <th>No OF Days</th>
                          <th>Leave Type</th>
                          <th>Leave Reason</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {leaves.length > 0 ? (
                        leaves.map((leave, index) => (
                          <tr key={leave.id}>
                            <td></td>
                            <td>{index + 1}</td>
                            <td>{formatDate(leave.from_date)}</td>
                            <td>{formatDate(leave.to_date)}</td>
                            <td>{leave.total_days}</td>
                            <td>{leave.leave_type} ({leave.leave_deduct})</td>
                            <td>  <button onClick={()=>openLeaveResponPop(leave.name,leave.reason)} className='show-leavereason'>view</button>
                            </td>
                            <td style={{ color: leaveStatusText(leave.status).color }}>
                              {leaveStatusText(leave.status).text}
                            </td>
                            <td>
                              {leave.status === "0" ? (
                              <CiTrash onClick={() => showConfirmationModalDelete(leave.id)} style={{ color: "red", cursor: "pointer", fontSize: "14px" }} />
                            ) : (
                              <></>

                            )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8} className="no-data-table">

                            <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
                <div style={{ float: "right" }} id="pagination_link"></div>
                <div >
                  {(!isLoading) && (totalLeaveCount > per_page) ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totalLeaveCount / per_page)}
                        pageRangeDisplayed={3}
                        forcePage={currentpage - 1}
                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <AddStaffLeave
        show={isaddleaveopen}
        onclose={closeaddleave}
        sendDataToParent={handleDataFromChild}
      />

      <DeleteAlert
        isOpen={isConfirmationModalOpen}
        message="Really, would you like to remove this Leave?"
        onCancel={closeConfirmationModal}
        onConfirm={confirmDelete}
      />

 <LeaveReason
        show={openReason}
        close={closeLeaveReasonPop}
        data={reasonView}
      />
    </div>
  );
}
export default StaffLeave