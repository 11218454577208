import React from 'react'
import './Unauth.css'
import nodatafound from '../Images/Noimagefound/Noimgfound.js'
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  const login =()=>{
    navigate('/login');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('company');
    localStorage.removeItem('notification');
    localStorage.removeItem('Staff');

  }
  return (
    <div className='index-container'>

    <div className='unauth-main'>
        <div className='unauth-404image'>
            <img src={nodatafound.unauth} alt='img'/>
        </div>
        <div className='unauth-button'>
            <button onClick={login}>Go Back</button>
        </div>
        </div>
        </div>
  )
}

export default UnauthorizedPage