import React, { useState, useEffect, useCallback } from 'react';
import { CiEdit, CiSearch, CiTrash } from 'react-icons/ci';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import Addleavecomponent from './Addleavecomponent';
import { DeleteLeavecompApi, FetchLeaveComponent } from '../../Config/api';
import Editleavecomp from './Editleavecomponent';
import DeleteAlert from '../popup/DeleteAlert';
import nodatafound from '../../Images/Noimagefound/Noimgfound';
import ReactPaginate from "react-paginate";
import pageloader from '../../Loader/pageloading/Pageloader';
import { getRegisterId } from '../../Config/userId';
import { leavecompAddAcess, leavecompDeleteAcess, leavecompEditAcess } from '../../Services/AccessCondition';
import { getUserDetails } from '../../Services/Storage';
import { toast } from 'react-toastify';

const Leavecompo = () => {

  const user_details = getUserDetails();
  const register_id = getRegisterId();
  const AddAccess = leavecompAddAcess();
  const EditAccess = leavecompEditAcess();
  const DeleteAccess = leavecompDeleteAcess();
  const [editData, setEditData] = useState([]);
  const [search, setSearchword] = useState("");
  const [loading, setIsloading] = useState(false);
  const [totaldedcount, setdedcount] = useState(0)
  const per_page = 10;
  const [currentpage, setCurrentPage] = useState(1);
  const [leavecompon, setLeavecompon] = useState([]);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteComponentId, setDeleteComponentId] = useState(null);
  const [isopenaddleavecomp, setopenleavecomp] = useState(false);
  const [editleavecomp, seteditleavecomp] = useState(false);

  const showConfirmationModalDelete = (id) => {
    setDeleteComponentId(id);
    setConfirmationModalOpen(true);
  };

  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const openaddleavecomp = () => {
    setopenleavecomp(true);
  };

  const closeeditleavecomp = () => {
    seteditleavecomp(false);
  };

  const closeaddleavecomp = () => {
    setopenleavecomp(false);
  };

  const closeConfirmationModal = () => {
    setDeleteComponentId(null);
    setConfirmationModalOpen(false);
  };

  const confirmDelete = () => {
    if (deleteComponentId) {
      deletesalaryComponent(deleteComponentId);
      closeConfirmationModal();
    }
  };

  const handleUpdateData = (data) => {
    // const updatedList = leavecompon.map((comp) =>
    //   comp.id === data.id ? data : comp
    // );
    // setLeavecompon(updatedList);
    if (data) {
      fetchleavecomponent();
    }
  };

  const editleaveButton = (componentId) => {
    const updatedList = leavecompon.filter((component) =>
      component.id === componentId
    );
    if (updatedList) {
      setEditData(updatedList[0]);
      seteditleavecomp(true);
    }
  }

  const handleDataFromChild = (data) => {
    if (data) {
      fetchleavecomponent();
    }
    // setLeavecompon((prev) => [
    //   data,
    //   ...prev,

    // ]);

  };

  const handleSearch = (event) => {
    setSearchword(event.target.value);
  }

  const deletesalaryComponent = async (componentId) => {
    try {
      const response = DeleteLeavecompApi(`${componentId}/?edited_by=${user_details.id}`);
      if (response) {
        const updatedList = leavecompon.filter((component) => component.id !== componentId);
        setLeavecompon(updatedList);
        toast.success('Deleted Sucessfully!', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
          className: 'custom_toast'
        });
      }
    }
    catch (error) {

    }
  }

  const fetchleavecomponent = useCallback(async () => {
    try {

      setIsloading(true);

      const response = await FetchLeaveComponent(`?register_id=${register_id}&page=${currentpage}&page_count=${per_page}&search=${search}`);

      if (response) {

        setLeavecompon(response.data.get_leave_component || [])
        setdedcount(response.data.page_context.total)

      }
    } catch (error) {
    } finally {
      setIsloading(false)

    }
  }, [currentpage, search, register_id]);

  useEffect(() => {
    localStorage.removeItem("salaryFilters");
    fetchleavecomponent();
  }, [fetchleavecomponent]);

  return (

    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>
                <div className='pages-subheader-sub-1'>
                  <p>Leave Component</p>
                  <span className='header-search-add-parent'>
                    <div className='pages-serach-view'>
                      <CiSearch className='page-search-icon' />
                      <input className='pages-serach' onChange={handleSearch} placeholder='Search Leave' type='search' />
                    </div>
                    {AddAccess
                      ? (<button className='pages-addempbutton-leave' onClick={openaddleavecomp} >Add Leave Component</button>
                      )
                      : (<></>)
                    }
                  </span>
                </div>
                <div className='pages-line'></div>
              </div>

              <div className='pages-maincontent'>
                {loading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                )
                  : (
                    <div className="pages-table-container">
                      <table className='pages-table'>
                        {leavecompon && leavecompon.length > 0 && (
                          <thead>
                            <tr>
                              <th></th>
                              <th>S NO</th>
                              <th>Leave Type</th>
                              <th>Leave Name</th>
                              <th>Leave Deduction</th>
                              <th>Deduction Type</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {
                            leavecompon && leavecompon.length > 0 ? (

                              leavecompon.map((leaveview, index) => (
                                <tr key={leaveview.id}>
                                  <td></td>
                                  <td>{(currentpage - 1) * per_page + index + 1}</td> {/* Updated index logic */}
                                  <td>{leaveview.leave_type}</td>
                                  <td>{leaveview.leave_name}</td>
                                  <td>{leaveview.leave_deduct}</td>
                                  <td>{leaveview.earning_deduction.charAt(0).toUpperCase() + leaveview.earning_deduction.slice(1)}</td>
                                  <td>{leaveview.user !== null ? (
                                    <span style={{ display: "flex", flexDirection: "row", gap: "20px", fontSize: "14px", alignItems: "center" }}>
                                      {EditAccess
                                        ? (<CiEdit onClick={() => editleaveButton(leaveview.id)} style={{ color: "#2a7ac7", cursor: "pointer" }} />
                                        ) : (<></>)
                                      }
                                      {DeleteAccess ? (<CiTrash onClick={() => showConfirmationModalDelete(leaveview.id)} style={{ color: "red", cursor: "pointer" }} />
                                      ) : (<></>)
                                      }
                                    </span>
                                  ) : null}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={8} className="no-data-table">

                                  <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  )}
                <div style={{ float: "right" }} id="pagination_link"></div>
                <div >
                  {(!loading) && (totaldedcount > per_page) ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totaldedcount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        forcePage={currentpage - 1}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Editleavecomp
        show={editleavecomp}
        close={closeeditleavecomp}
        editData={editData}
        updateData={handleUpdateData}
      />

      <Addleavecomponent
        show={isopenaddleavecomp}
        close={closeaddleavecomp}
        sendDataToParent={handleDataFromChild}
      />

      <DeleteAlert
        isOpen={isConfirmationModalOpen}
        message="Really, would you like to remove this Component?"
        onCancel={closeConfirmationModal}
        onConfirm={confirmDelete}
      />

    </div>
  )
}

export default Leavecompo;