import React, { useState, useEffect, useCallback } from 'react'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import Select from 'react-select';
import Calendar from '../Viewattncalender/Viewattncal';
import './Viewattendance.css';
import getMonthYearData from '../../Component/MonthYearList';
import { viewSingleAttendanceApi, viewStaffAttendanceApi } from '../../../Config/api';
import { getStaffDetails, getUserDetails } from '../../../Services/Storage';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import pageloader from '../../../Loader/pageloading/Pageloader';
import ReactPaginate from "react-paginate";
// import monthNames from '../../Component/Monthview';

const ViewStaffAttendance = () => {

  const staff_details = getStaffDetails();
  const [currentpage, setCurrentPage] = useState(1);
  const [iscalander, setopencalander] = useState(false);
  const [attendance, setAttendance] = useState([]);
  const [singleAttendance, setSingleAttendance] = useState([]);
  const [loading, setIsloading] = useState(false);
  const [totaldedcount, setdedcount] = useState(0)
  const [selectedStaffId, setSelectedStaffId] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const user_details = getUserDetails();
  const [rowLoader, setRowLoader] = useState({});
  const per_page = 10;
  const { months, years } = getMonthYearData();

  const monthData = Object.keys(months).sort((a, b) => a - b).map(key => (
    {
      value: key,
      label: months[key],
    }));

  const yearData = Object.keys(years).map(key => ({
    value: years[key],
    label: years[key],
  }));

  const handleChangeYear = (selecteOption) => {
    setYear(selecteOption.value);
    setCurrentPage(1)
  }

  const handleChangeMonth = (selecteOption) => {
    setMonth(selecteOption.value);
    setCurrentPage(1)
  }

  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const closepopupcalander = () => {
    setMonth('');
    setYear('');
    setopencalander(false)
  }

  const openpopupclander = async (id, month, year) => {
    setMonth(month);
    setYear(year);
    setSelectedStaffId(id);
    try {
      setRowLoader((prev) => ({ ...prev, [id]: true }));

      const response = await viewSingleAttendanceApi(`?register_id=${id}&month=${month}&year=${year}&admin_id=${staff_details.register_details.user_id}`)
      if (response) {
        setSingleAttendance(response);
        setopencalander(true);
      }
    }
    catch (error) {

    } finally {
      setRowLoader((prev) => ({ ...prev, [id]: false }));

    }
  }



  const fetchAttendanceList = useCallback(async () => {
    try {
      setIsloading(true);
      const response = await viewStaffAttendanceApi(`?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&month=${month}&year=${year}&admin_id=${staff_details.register_details.user_id}`);
      if (response) {
        setAttendance(response.data.get_attendance);
        setdedcount(response.data.page_context.total)

      }
    }
    catch (error) {

    } finally {
      setIsloading(false);

    }
  }, [currentpage, per_page, month, year, user_details.id, staff_details.register_details.user_id]);

  useEffect(() => {
    fetchAttendanceList();
  }, [fetchAttendanceList])


  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>
                <div className='pages-subheader-sub-1'>

                  <p>Attendance Report</p>
                  <span className='header-search-add-parent'>
                 
                  </span>

                </div>
                <div className='viewlpageseave-line'></div>
              </div>
              <div className='pages-filter-options'>
                <div className='pages-filter-sub'>
                  <p>Filter:</p>
                  <Select className='pages-dropdown' options={yearData}
                    onChange={handleChangeYear}
                    placeholder="Select Year" />
                  <Select className='pages-dropdown' options={monthData} onChange={handleChangeMonth}
                    placeholder="Select Month" />

                </div>
              </div>
              <div className='pages-maincontent'>
                {loading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                )
                  : (
                    <div className="pages-table-container">

<table className="pages-table">
  {attendance.length > 0 && (
    <thead>
      <tr>
        <th></th>
        <th>S.No</th>
        <th>Month / Year</th>
        <th>Punched</th>
        <th>Leave</th>
        <th>Holiday</th>
        <th>View</th>
      </tr>
    </thead>
  )}
  <tbody>
    {attendance.length > 0 ? (
      attendance.map((atndnce, index) => {
        // Check if at least one of the three arrays contains data
        const hasData =
          atndnce.attendance_count.length > 0 ||
          atndnce.leave_count.length > 0 ||
          atndnce.holiday_count.length > 0;

        if (!hasData) {
          return (
            <tr key={`${atndnce.registration_id}-no-data`}>
              <td colSpan={7} className="no-data-table">
                <img
                  src={nodatafound.no_data_img}
                  alt="No Data Found"
                  className="no-data-found"
                />
              </td>
            </tr>
          );
        }

        // Define month names
        const monthNames = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        // Merge all available month-year pairs from attendance, leave, and holiday
        const uniqueMonths = new Set([
          ...atndnce.attendance_count.map((item) => `${item.month}-${item.year}`),
          ...atndnce.leave_count.map((item) => `${item.month}-${item.year}`),
          ...atndnce.holiday_count.map((item) => `${item.month}-${item.year}`)
        ]);

        return Array.from(uniqueMonths).map((monthYear, idx) => {
          const [monthStr, year] = monthYear.split("-");
          const monthIndex = parseInt(monthStr, 10) - 1; // Convert month to 0-based index

          const punched =
            atndnce.attendance_count.find(
              (att) => att.month === monthStr && att.year === year
            )?.count || 0;
          const leave =
            atndnce.leave_count.find(
              (l) => l.month === monthStr && l.year === year
            )?.count || 0;
          const holiday =
            atndnce.holiday_count.find(
              (h) => h.month === monthStr && h.year === year
            )?.count || 0;

          return (
            <tr key={`${atndnce.registration_id}-${idx}`}>
              <td></td>
              <td>{(currentpage - 1) * per_page + idx + 1}</td>
              <td>{monthNames[monthIndex]} / {year}</td>
              <td>{punched}</td>
              <td>{leave}</td>
              <td>{holiday}</td>
              <td onClick={() => openpopupclander(atndnce.registration_id, monthStr, year)}>
                {rowLoader[atndnce.registration_id] ? (
                  <span className="loader-pages-tablefield"></span>
                ) : (
                  <div className="viewattn-view-button">View</div>
                )}
              </td>
            </tr>
          );
        });
      })
    ) : (
      <tr>
        <td colSpan={7} className="no-data-table">
          <img
            src={nodatafound.no_data_img}
            alt="No Data Found"
            className="no-data-found"
          />
        </td>
      </tr>
    )}
  </tbody>
</table>

                    </div>
                  )}
                <div style={{ float: "right" }} id="pagination_link"></div>
                <div>
                  {(!loading) && (totaldedcount > per_page) ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totaldedcount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        forcePage={currentpage - 1} // Adjust index (0-based)

                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {singleAttendance && (
        <Calendar
          show={iscalander}
          close={closepopupcalander}
          responseData={singleAttendance}
          selMonth={month}
          selYear={year}
          staffId={selectedStaffId}
        />
      )}

    </div>
  )
}

export default ViewStaffAttendance