import React, { useState, useEffect, useCallback } from 'react';
import '../Earnings/Earnings.css';
import { Link } from 'react-router-dom';
import { CiEdit, CiSearch, CiTrash } from 'react-icons/ci';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Adddeduction from './Adddeduction';
import { deleteSalaryComponentApi, viewSalaryComponentDeductionApi } from '../../../Config/api';
import DeleteAlert from '../../popup/DeleteAlert';
import Editdeduction from './Editdeduction';
import pageloader from '../../../Loader/pageloading/Pageloader';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import ReactPaginate from "react-paginate";
import { getRegisterId } from '../../../Config/userId';
import { salarycompAddAcess, salarycompDeleteAcess, salarycompEditAcess } from '../../../Services/AccessCondition';
import Deleteconfirmation from '../../Component/Deleteconfirmation';
import { getUserDetails } from '../../../Services/Storage';
import { toast } from 'react-toastify';

const Deductions = () => {

  const user_details = getUserDetails();
  const register_id = getRegisterId();
  const AddAccess = salarycompAddAcess();
  const EditAccess = salarycompEditAcess();
  const DeleteAccess = salarycompDeleteAcess();
  const [deductioncomp, setDeductionComp] = useState([]);
  const [loading, setIsloading] = useState(false);
  const [totaldedcount, setdedcount] = useState(0)
  const per_page = 10;
  const [currentpage, setCurrentPage] = useState(1);
  const [search, setSearchword] = useState("");
  const [isopendeduction, setopendeduction] = useState(false);
  const [editDeduction, setEditDeduction] = useState(false);
  const [editData, setEditData] = useState([]);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteComponentId, setDeleteComponentId] = useState(null);
  const [deleteComponentData, setDeleteComponentData] = useState([]);
  const [opendeleteconf, setdeleteconf] = useState(false)

  const handleDataFromChild = (data) => {
    if(data){
      fetchSalaryComponentDeduction();

    }
    // setDeductionComp((prevcompArray) => [
    //   data,
    //   ...prevcompArray,
    // ]);
  };


  const opendeduction = () => {
    setopendeduction(true);
  };
  const closededuction = () => {
    setopendeduction(false);
  };

  const editDeductionButton = (componentId) => {
    const updatedList = deductioncomp.filter((component) => component.id === componentId);
    if (updatedList) {
      setEditData(updatedList[0]);
      setEditDeduction(true);
    }
  };

  const closeEditDeduction = () => {
    setEditDeduction(false);
  };

  const handleUpdateData = (data) => {
    // const updatedList = deductioncomp.map((comp) =>
    //   comp.id === data.id ? data : comp
    // );
    if(data){
      fetchSalaryComponentDeduction();
    }
    // setDeductionComp(updatedList);
  };

  const showConfirmationModalDelete = (InvoiceId) => {
    setDeleteComponentId(InvoiceId);
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setDeleteComponentId(null);
    setConfirmationModalOpen(false);
  };
  const handleSearch = (event) => {
    setSearchword(event.target.value);
  }
  const confirmDelete = () => {
    if (deleteComponentId) {
      deletesalaryComponent(deleteComponentId);
      closeConfirmationModal();
    }
  };

  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const closedeleteconfirm = () => {
    setdeleteconf(false)
    setDeleteComponentData([])
  };

  const deletesalaryComponent = async (componentId) => {
    try {
      const response = await deleteSalaryComponentApi(`${componentId}/?edited_by=${user_details.id}`);
      if (response) {
        // const updatedList = deductioncomp.filter((component) => component.id !== componentId);
        // setDeductionComp(updatedList);
           toast.success('Deleted Sucessfully!', {
                                        position: "top-center",
                                        autoClose: 2000,
                                        closeButton: false,
                                        className: 'custom_toast'
                                      });
        fetchSalaryComponentDeduction();

      }
    }
    catch (error) {
      if (error.response && error.response.status === 422) {
        setDeleteComponentData(error.response.data.data);
        setdeleteconf(true)
      }
    }
  };

  const fetchSalaryComponentDeduction = useCallback(async () => {
    try {
      setIsloading(true);
      const response = await viewSalaryComponentDeductionApi(`?register_id=${register_id}&page=${currentpage}&page_count=${per_page}&search=${search}`);
      if (response) {
        setDeductionComp(response.data.get_salary_component_deduction || []); // Ensure response data is an array
        setdedcount(response.data.page_context.total)
      }
    } catch (error) {
    } finally {
      setIsloading(false);
    }
  }, [currentpage, search, register_id]);

  useEffect(() => {
    fetchSalaryComponentDeduction();
  }, [fetchSalaryComponentDeduction]);

  return (

    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>


                <div className='pages-subheader-sub-1'>
                  <p>Salary Component </p>
                  <span className='header-search-add-parent'>
                    <div className='pages-serach-view'>
                      <CiSearch className='page-search-icon' />
                      <input className='pages-serach' onChange={handleSearch}
                        placeholder='Search Deduction' type='search' />
                    </div>
                    {AddAccess
                      ? (
                        <button className='pages-addempbutton' onClick={opendeduction} >Add Deduction</button>

                      ) : (
                        <></>
                      )
                    }
                  </span>
                </div>

                <div className='pages-line'></div>
              </div>
              <div className='pages-filter-options'>
                <div className='pages-filter-sub'>
                  <div className="earnings-stepper">
                    <div className="earnings-step-indicator">
                      <Link to="/addearningcomp" className='earnings-redirect-link2'>
                        <div className='earnings-step '>
                          Earnings
                        </div>
                      </Link >
                      <Link to="/adddeductioncomp" className='earnings-redirect-link'>
                        <div className='earnings-step earnings-selected'>
                          Deductions
                        </div>
                      </Link>
                      <Link to="/otherComp" className='earnings-redirect-link2'>
                        <div className='earnings-step '>
                          Other
                        </div>
                      </Link>
                    </div>


                  </div>
                </div>
              </div>
              <div className='pages-maincontent'>
                {loading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                )
                  : (
                    <div className="pages-table-container">

                      <table className='pages-table'>
                        {deductioncomp && deductioncomp.length > 0 && (
                          <thead>
                            <tr>
                              <th></th>
                              <th>S No</th>
                              <th>Deduction Name</th>
                              <th>Deduction Type</th>
                              <th>Calculation Type</th>
                              <th>Amount Type</th>
                              <th>Component Access</th>
                              <th>##</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {deductioncomp && deductioncomp.length > 0 ? (
                            deductioncomp.map((deductionview, index) => (
                              <tr key={deductionview.id}>
                                <td></td>
                                <td>{(currentpage - 1) * per_page + index + 1}</td> 
                                <td>{deductionview.component_name}</td>
                                <td>{deductionview.component}</td>
                                <td>{deductionview.calculation_type}</td>
                                <td>
                                  {deductionview.calculation_type ==="Float amount"
                                  ?(<>
                                 ₹ {deductionview.amount_type} 
                                  </>)
                                  :(<>
                                  {deductionview.amount_type} %

                                  </>)

                                  }
                                  </td>                                <td>{deductionview.general_earning}</td>
                                <td>{deductionview.user !== null ? (
                                  <span style={{ display: "flex", flexDirection: "row", gap: "20px", fontSize: "14px", alignItems: "center" }}>
                                    {EditAccess
                                      ? (<CiEdit onClick={() => editDeductionButton(deductionview.id)} style={{ color: "#2a7ac7", cursor: "pointer" }} />
                                      ) :
                                      (<></>)

                                    }
                                    {DeleteAccess
                                      ? (<CiTrash onClick={() => showConfirmationModalDelete(deductionview.id)} style={{ color: "red", cursor: "pointer" }} />
                                      )
                                      : (<></>)

                                    }
                                  </span>
                                ) : null} </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8} className="no-data-table">

                                <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}

                <div style={{ float: "right" }} id="pagination_link"></div>
                <div >
                  {(!loading) && (totaldedcount > per_page) ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totaldedcount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        forcePage={currentpage - 1}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Adddeduction
        show={isopendeduction}
        close={closededuction}
        sendDataToParent={handleDataFromChild}
      />
      <Editdeduction
        show={editDeduction}
        close={closeEditDeduction}
        editData={editData}
        updateData={handleUpdateData}
      />
      <DeleteAlert
        isOpen={isConfirmationModalOpen}
        message="Really, would you like to remove this Component?"
        onCancel={closeConfirmationModal}
        onConfirm={confirmDelete}
      />
      <Deleteconfirmation
        show={opendeleteconf}
        close={closedeleteconfirm}
        data={deleteComponentData}
      />

    </div>

  )
}

export default Deductions;