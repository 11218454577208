import React, { useState,useCallback,useEffect } from 'react'
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Viewslippopup from '../viewslippopup/Viewslippopup';
import { useLocation } from 'react-router-dom';
import { viewSingleStaffSalaryApi, viewStaffSalarySlipApi } from '../../../Config/api';
import monthNames from '../../Component/Monthview';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import { getRegisterId } from '../../../Config/userId';
import pageloader from '../../../Loader/pageloading/Pageloader';
import ReactPaginate from "react-paginate";

const Viewsalaryslip = () => {

    const register_id = getRegisterId();
    const location = useLocation();
    const { id, userName } = location.state || {};
    const [ispopviewslip, showpopupslip] = useState(false);
    const [slipData, setslipData] = useState([]);
    const [rowLoader, setRowLoader] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [salarySlipData ,setSalarySlipData] = useState([]);
    const [totalLeaveCount, setTotalLeaveCount] = useState(0);
    const [currentpage, setCurrentPage] = useState(1);
   
    const per_page = 10;

      const fetchpayrollList = useCallback(async () => {
        try {
          setIsLoading(true);
    
         const response = await viewSingleStaffSalaryApi(`${id}/?admin_id=${register_id}&page=${currentpage}&page_count=${per_page}`);

          if (response) {
            
            setSalarySlipData(response.data.salary_report);
            // setEmployees(response.data.employee_salary_details);
             setTotalLeaveCount(response.data.page_context.total);
          }
        }
        catch (error) {
        } finally {
          setIsLoading(false);
    
        }
      }, [ register_id,id,currentpage]);
    
      useEffect(() => {
        fetchpayrollList();
      }, [fetchpayrollList])

      
  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

    const openpopslip = async (id, month, year) => {
        try {
            setRowLoader((prev) => ({ ...prev, [month]: true }));

            const response = await viewStaffSalarySlipApi(
                `?register=${id}&month=${month}&year=${year}&admin_id=${register_id}`
            );
            if (response.data) {
                setslipData(response.data.salary_report[0])
                showpopupslip(true);
            }
        }
        catch (error) {

        } finally {
            setRowLoader((prev) => ({ ...prev, [month]: false }));

        }
    };

    const closepopupslip = () => {
        showpopupslip(false);
    };

    // const employees = data ? data.salary_report : [];

    // if (!data) {
    //     return <div> No salary slip data available. </div>;
    // }
    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='pages-subheader'>


                                <div className='pages-subheader-sub-1'>
                                    <p>Salary Report</p>

                                </div>

                                <div className='pages-line'></div>
                            </div>
                            <div className='pages-filter-options'>
                                <div className='pages-filter-sub'>
                                    <p className='page-pra-username'>{userName ? userName : ''}</p>
                                </div>
                            </div>
                            <div className='pages-maincontent'>
                            {isLoading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                ) : (
                                <div className="pages-table-container">

                                    <table className='pages-table'>
                                        {salarySlipData.length > 0 && (
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Sl No</th>
                                                    <th>Month/Year</th>
                                                    <th>Actual Salary</th>
                                                    <th>Deduction</th>
                                                    <th>Earning</th>
                                                    <th>Slip</th>

                                                </tr>
                                            </thead>
                                        )}
                                        <tbody>
                                            {salarySlipData.length > 0 ? (

salarySlipData.map((employee, index) => (
                                                    <tr key={employee.id}>
                                                        <td></td>
                                                        <td>{(currentpage - 1) * per_page + index + 1}</td> {/* Updated index logic */}
                                                        <td>{monthNames[employee.month]} / {employee.year}</td>
                                                        <td>{employee.monthly_salary}</td>
                                                        <td>{employee.deduct.toFixed(2)}</td>
                                                        <td>{employee.earning.toFixed(2)}</td>
                                                        <td onClick={() => openpopslip(employee.register_id, employee.month, employee.year)}>
                                                            {rowLoader[employee.month] ? <span className='loader-pages-tablefield'></span> :
                                                                <div className='viewattn-view-button'>Slip</div>

                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={8} className="no-data-table">
                                                        <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                )}
                                <div style={{ float: "right" }} id="pagination_link"></div>
                <div >
                  {(!isLoading) && (totalLeaveCount > per_page) ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totalLeaveCount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        forcePage={currentpage - 1}
                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Viewslippopup
                show={ispopviewslip}
                close={closepopupslip}
                salarydata={slipData}
            />
        </div>
    )
}

export default Viewsalaryslip;

