import React, { useState } from 'react';
import '../addingcomp.css'
import { AddSalaryComponentApi } from '../../../Config/api';
import { Store } from '../../../Store';
import { addSalaryComponent } from '../../../Slice/salaryComponentSlice';
import { toast } from 'react-toastify';
import { getRegisterId } from '../../../Config/userId';
import { getUserDetails } from '../../../Services/Storage';

const AddEarnings = ({ show, close, sendDataToParent }) => {

  const user_details = getUserDetails();
  const register_id = getRegisterId();
  const [errors, setErrors] = useState({});
  const [loader, setloader] = useState(false);

  const [salarycomponent, setSalaryComponent] = useState({
    component_name: '',
    component: '',
    calculation_type: 'Float amount',
    amount_type: '',
    calculation_code: 'basic',
    earning_deduction: 'deduction',
    user: register_id,
    edited_by: user_details.id,
    general_earning:'General Deduction',

  });

  const handlekeynumber = (e) => {
    const restricpattern = /[^0-9]/g;
    if (e.target.name === 'amount_type' && restricpattern.test(e.key)) {
      e.preventDefault();
    }
  };

  const handlekeydecimal = (e) => {
    const restricpattern = /[^0-9.]/g;
    if (e.target.name === 'amount_type' && restricpattern.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'amount_type') {
      const isPercentage = ['Percentage of CTC', 'Percentage of basic'].includes(salarycomponent.calculation_type);
      if (isPercentage) {
        if (!/^\d{1,3}(\.\d{1,2})?$/.test(value) || parseFloat(value) > 100) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            amount_type: 'Please enter a valid percentage',
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            amount_type: '',
          }));
        }
      } else if (salarycomponent.calculation_type === 'Float amount') {
        if (!/^\d+(\.\d{0,2})?$/.test(value) || parseFloat(value) > 1000000) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            amount_type: 'Please enter a valid amount up to 1,000,000',
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            amount_type: '',
          }));
        }
      }
    }
    setSalaryComponent((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const closePopup =()=>{
    close();
    setErrors({})
    setSalaryComponent({
      component_name: '',
      component: '',
      calculation_type: 'Float amount',
      amount_type: '',
      calculation_code: 'basic',
      earning_deduction: 'deduction',
      user: register_id,
      general_earning:'General Deduction',
      edited_by: user_details.id,
  
    })
  }
  const handleSubmit = async () => {
    const fieldData = [
      {
        name: "component_name",
        type: "input",
        err: "Component Name",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
          (value) => /^[a-zA-Z\s]+$/.test(value),
        ],
      },
      {
        name: "component",
        type: "input",
        err: "Component Type",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
          (value) => /^[a-zA-Z\s]+$/.test(value),
        ],
      },
      {
        name: "amount_type",
        type: "input",
        err: "Amount Type",
        conditions: [
          (value) => {
            if (salarycomponent.calculation_type === "Float amount") {
              return !isNaN(value) && parseFloat(value) > 0 && parseFloat(value) <= 1000000;
            }
            return /^[1-9]?[0-9]?(\.\d{1,2})?$|^100$/.test(value); // Validates 1-100%
          }
        ],
      },
    ];
    const fieldOrder = [
      "component_name",
      "component",
      "amount_type"
    ];
    const newErrors = {};
    for (const fieldName of fieldOrder) {
      const fieldInfo = fieldData.find((field) => field.name === fieldName);
      if (!fieldInfo) {
        continue;
      }
      const fieldType = fieldInfo.type;
      const fieldLabel = fieldInfo.err;
      const value = salarycomponent[fieldName];
      const isFieldValid = fieldInfo.conditions.every((condition) =>
        typeof condition === "function" ? condition(value) : condition
      );
      if (!isFieldValid) {
        if (value.trim() === "") {
          newErrors[fieldName] = `${fieldLabel} is required`;
        } else {
          newErrors[fieldName] = `Please Enter a Valid ${fieldLabel}.`;
        }
        const inputField = document.querySelector(
          `${fieldType}[name="${fieldName}"]`
        );
        if (inputField) {
          inputField.focus();
          break;
        }
      }
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setloader(true);

        const response = await AddSalaryComponentApi(salarycomponent);
        if (response && response.data && response.data.salary_component) {
          sendDataToParent(response.data.salary_component);
          Store.dispatch(addSalaryComponent(response.data.salary_component));
          close();
          setSalaryComponent((preValue) => ({
            ...preValue,
            component_name: '',
            component: '',
            calculation_type: 'Float amount',
            amount_type: '',
            calculation_code: 'basic',
            earning_deduction: 'deduction',
            user: register_id,
            edited_by: user_details.id,
            general_earning:'General Deduction',

          }))
          toast.success('Deduction Added Sucessfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          })
        } else {
        }
      }

      catch (error) {
        const errorMessage =
          error.response && error.response.data && error.response.data.data
            ? error.response.data.data
            : "Add Earnings Failed. Please try again.";

        setErrors((prevErrors) => ({
          ...prevErrors,
          response_error: errorMessage,
        }));

        toast.error("Add Deduction Failed. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
      } finally {
        setloader(false);

      }
    }
  }

  if (!show) return null
  return (
    <div className='addsalrcomp-section'>
      <div className='addsalrcomp-container'>
          <span className='uploadexcel-close' onClick={closePopup}>&times;</span>
          <div className='addsalrcomp-container-sub'>
            <div className='add-component-heading'>
              <p>Add Deduction</p>
            </div>
            <div className='addleave-strightline'></div>
            <div className='sal-comp-form-div'>
            <div className='sal-comp-form-divsub'>

              <div className='sal-comp-input-div'>
                <span className='sal-comp-input-div-span-text'>  Deduction Name <span className='sal-comp-input-important'> * </span> </span>
                <input type='text' placeholder="Enter code (e.g.PF, IT)" onChange={handleInputChange} name='component_name' className='sal-comp-input' />
                {
                  errors.component_name && (
                    <div className='error-message'>{errors.component_name}</div>
                  )
                }
              </div>
              <div className='sal-comp-input-div'>
                <span className='sal-comp-input-div-span-text'> Deduction Type  <span className='sal-comp-input-important'> * </span></span>
                <input type='text'  placeholder="Enter type (e.g.Provident Fund, Income Tax...)" onChange={handleInputChange} name='component' className='sal-comp-input' />
                {
                  errors.component && (
                    <div className='error-message'>{errors.component}</div>
                  )
                }
              </div>
              <p className='pages-notes'> Note <span className='emppersonalinfo-mandatory'>*</span> : Only add additional deduction to the salary.</p>

              <div className='sal-comp-input-div'>
                <span className='sal-comp-input-div-span-text' > Component Access <span className='sal-comp-input-important'> *  </span> </span>

                <select className='sal-comp-select-box' name='general_earning' onChange={handleInputChange}>
                  <option value="General Deduction">General Deduction</option>
                  <option value="Additional Deduction">Additional Deduction</option>
                </select>

              </div>
              <p className='pages-notes'>
               
                 Note <span className='emppersonalinfo-mandatory'>*</span> : When you enter a percentage or amount, it will be calculated for the whole year.
                 </p>
              <div className='sal-comp-input-div'>
                <span className='sal-comp-input-div-span-text' > Calculation Type <span className='sal-comp-input-important'> * </span> </span>

                <select className='sal-comp-select-box' name='calculation_type' onChange={handleInputChange}>
                  <option value="Float amount">Float Amount</option>

                  <option value="Percentage of CTC">Percentage of CTC</option>
                  <option value="Percentage of basic">Percentage of Basic</option>
                </select>

              </div>
              {salarycomponent.calculation_type === 'Float amount' ? (
                <div className='sal-comp-input-div'>
                  <span className='sal-comp-input-div-span-text'> Enter Amount <span className='sal-comp-input-important'> * </span> </span>

                  <input type='text' onChange={handleInputChange} placeholder='e.g., 5000' onKeyPress={handlekeynumber} name='amount_type' className='sal-comp-input' />
                  {
                    errors.amount_type && (
                      <div className='error-message'>{errors.amount_type}</div>
                    )
                  }
                </div>
              ) : (
                <div className='sal-comp-input-div'>
                  <span className='sal-comp-input-div-span-text'> Enter {salarycomponent.calculation_type} <span className='sal-comp-input-important'> * </span> </span>
                  <input type='text' onChange={handleInputChange} placeholder='e.g., 50%' onKeyPress={handlekeydecimal} name='amount_type' className='sal-comp-input' />
                  {
                    errors.amount_type && (
                      <div className='error-message'>{errors.amount_type}</div>
                    )
                  }
                </div>
              )}
              {
                errors.response_error && (
                  <div className='error-message-view salry-comp-err'>
                    {errors.response_error.component}
                  </div>
                )
              }
              <div className='sal-comp-button-div'>
                <button onClick={handleSubmit} disabled={loader} className='popup-button-cls'>{loader ? <span className='spinner'></span> : "submit"}</button>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  )
}

export default AddEarnings