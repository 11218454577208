import React, { useState, useEffect, useCallback } from 'react';
import './Earnings.css';
import { Link } from 'react-router-dom';
import { CiEdit, CiSearch, CiTrash } from 'react-icons/ci';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import AddEarnings from './Addearnings/AddEarnings';
import { deleteSalaryComponentApi, FetchEarningsSalary } from '../../../Config/api';
import DeleteAlert from '../../popup/DeleteAlert';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import pageloader from '../../../Loader/pageloading/Pageloader';
import ReactPaginate from "react-paginate";
import Editearning from './Editearnings';
import { getRegisterId } from '../../../Config/userId';
import { salarycompAddAcess, salarycompDeleteAcess, salarycompEditAcess } from '../../../Services/AccessCondition';
import Deleteconfirmation from '../../Component/Deleteconfirmation';
import { getUserDetails } from '../../../Services/Storage';
import { toast } from 'react-toastify';

const Earnings = () => {

  const user_details = getUserDetails();
  const register_id = getRegisterId();
  const AddAccess = salarycompAddAcess();
  const EditAccess = salarycompEditAcess();
  const DeleteAccess = salarycompDeleteAcess();
  const [earingscomp, setearningcomp] = useState([])
  const [editData, setEditData] = useState([]);
  const [editEarnings, setEditEarnings] = useState(false);
  const [loading, setIsloading] = useState(false);
  const [totaldedcount, setdedcount] = useState(0)
  const per_page = 10;
  const [currentpage, setCurrentPage] = useState(1);
  const [search, setSearchword] = useState("");
  const [isopenearnings, setopenearnings] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteComponentId, setDeleteComponentId] = useState(null);
  const [deleteComponentData, setDeleteComponentData] = useState([]);
  const [opendeleteconf, setdeleteconf] = useState(false)

  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleSearch = (event) => {
    setSearchword(event.target.value);
  }

  const handleDataFromChild = (data) => {
    // setearningcomp((prevcompArray) => [
    //   data,
    //   ...prevcompArray,
    // ]);
    if(data){
      fetchsalarycompearnings();

    }
  };

  const closeEditEarnings = () => {
    setEditEarnings(false);
  };

  const openaddearnings = () => {
    setopenearnings(true);
  };

  const closeaddearnings = () => {
    setopenearnings(false);
  };

  const editearningsButton = (componentId) => {
    const updatedList = earingscomp.filter((component) =>
      component.id === componentId
    );
    if (updatedList) {
      setEditData(updatedList[0]);
      setEditEarnings(true);
    }
  };

  const handleUpdateData = (data) => {
    if (data){
      fetchsalarycompearnings();
    }
    // const updatedList = earingscomp.map((comp) =>
    //   comp.id === data.id ? data : comp
    // );
    // setearningcomp(updatedList);
  };

  const showConfirmationModalDelete = (InvoiceId) => {
    setDeleteComponentId(InvoiceId);
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setDeleteComponentId(null);
    setConfirmationModalOpen(false);
  };

  const confirmDelete = () => {
    if (deleteComponentId) {
      deletesalaryComponent(deleteComponentId);
      closeConfirmationModal();
    }
  };
  const deletesalaryComponent = async (componentId) => {
    try {
      const response = await deleteSalaryComponentApi(`${componentId}/?edited_by=${user_details.id}`);
      if (response) {
        // const updatedList = earingscomp.filter((component) => component.id !== componentId);
        // setearningcomp(updatedList);
        fetchsalarycompearnings();
        toast.success('Deleted Sucessfully!', {
                                        position: "top-center",
                                        autoClose: 2000,
                                        closeButton: false,
                                        className: 'custom_toast'
                                      })

      }
    }
    catch (error) {
      if (error.response && error.response.status === 422) {
        setDeleteComponentData(error.response.data.data);
        setdeleteconf(true)
      }
    }
  };

  const closedeleteconfirm = () => {
    setdeleteconf(false)
    setDeleteComponentData([])
  };

  const fetchsalarycompearnings = useCallback(async () => {
    try {
      setIsloading(true);
      const response = await FetchEarningsSalary(`?register_id=${register_id}&page=${currentpage}&page_count=${per_page}&search=${search}`);
      if (response) {
        setearningcomp(response.data.get_salary_component_earning || []);
        setdedcount(response.data.page_context.total)
      }
    } catch (error) {

    } finally {
      setIsloading(false)
    }
  }, [currentpage, search, register_id]);

  useEffect(() => {
    localStorage.removeItem("salaryFilters");
    fetchsalarycompearnings();
  }, [fetchsalarycompearnings]);

  return (

    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>


                <div className='pages-subheader-sub-1'>
                  <p>Salary Component </p>
                  <span className='header-search-add-parent'>
                    <div className='pages-serach-view'>
                      <CiSearch className='page-search-icon' />
                      <input className='pages-serach' onChange={handleSearch} placeholder='Search Earnings' type='search' />
                    </div>
                    {AddAccess ?
                      (<button className='pages-addempbutton' onClick={openaddearnings} >Add Earnings</button>)
                      : (<></>)

                    }

                  </span>
                </div>



                <div className='pages-line'></div>
              </div>
              <div className='pages-filter-options'>
                <div className='pages-filter-sub'>
                  <div className="earnings-stepper">
                    <div className="earnings-step-indicator">
                      <Link to="/addearningcomp" className='earnings-redirect-link'>
                        <div className='earnings-step earnings-selected'>
                          Earnings
                        </div>
                      </Link >
                      <Link to="/adddeductioncomp" className='earnings-redirect-link2'>
                        <div className='earnings-step'>
                          Deductions
                        </div>
                      </Link>
                      <Link to="/otherComp" className='earnings-redirect-link2'>
                        <div className='earnings-step'>
                          Other
                        </div>
                      </Link>
                    </div>


                  </div>
                </div>
              </div>
              <div className='pages-maincontent'>
                {loading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                )
                  : (

                    <div className="pages-table-container">

                      <table className='pages-table'>
                        {earingscomp && earingscomp.length > 0 && (
                          <thead>
                            <tr>
                              <th></th>
                              <th>S No</th>
                              <th>Earning Name</th>
                              <th>Earning Type</th>
                              <th>Calculation Type</th>
                              <th>Amount Type</th>
                              <th>Component Access</th>
                              <th>##</th>

                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {earingscomp && earingscomp.length > 0 ? (
                            earingscomp.map((earningsview, index) => (


                              <tr key={earningsview.id} >
                                <th></th>
                                <td>{(currentpage - 1) * per_page + index + 1}</td> {/* Updated index logic */}
                                <td>{earningsview.component_name}</td>
                                <td>{earningsview.component}</td>
                                <td>{earningsview.calculation_type}</td>
                                
                                <td>
                                  {earningsview.calculation_type ==="Float amount"
                                  ?(<>
                                 ₹ {earningsview.amount_type} 
                                  </>)
                                  :(<>
                                  {earningsview.amount_type} %

                                  </>)

                                  }
                                  </td>
                                <td>{earningsview.general_earning}</td>
                                <td>{earningsview.user !== null ? (
                                  <span style={{ display: "flex", flexDirection: "row", gap: "20px", fontSize: "14px", alignItems: "center" }}>
                                    {EditAccess ? (<CiEdit onClick={() => editearningsButton(earningsview.id)} style={{ color: "#2a7ac7", cursor: "pointer" }} />)
                                      : (<></>)

                                    }{DeleteAccess ? (
                                      <CiTrash onClick={() => showConfirmationModalDelete(earningsview.id)} style={{ color: "red", cursor: "pointer" }} />

                                    ) : (<></>)

                                    }
                                  </span>
                                ) : null} </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8} className="no-data-table">

                                <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                              </td>
                            </tr>
                          )
                          }
                        </tbody>
                      </table>
                    </div>
                  )}

                <div style={{ float: "right" }} id="pagination_link"></div>
                <div >
                  {(!loading) && (totaldedcount > per_page) ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totaldedcount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        forcePage={currentpage - 1} // Adjust index (0-based)

                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddEarnings
        show={isopenearnings}
        close={closeaddearnings}
        sendDataToParent={handleDataFromChild}
      />
      <Editearning
        show={editEarnings}
        close={closeEditEarnings}
        editData={editData}
        updateData={handleUpdateData}
      />
      <DeleteAlert
        isOpen={isConfirmationModalOpen}
        message="Really, would you like to remove this Component?"
        onCancel={closeConfirmationModal}
        onConfirm={confirmDelete}
      />
      <Deleteconfirmation
        show={opendeleteconf}
        close={closedeleteconfirm}
        data={deleteComponentData}
      />
    </div>

  )
}

export default Earnings