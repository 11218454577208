import React, { useState, useEffect, useRef } from 'react';
import './Addleave.css';
import DatepickerComp from '../../Component/DatepickerComp';
import { getCompanyDetails, getStaffDetails } from '../../../Services/Storage';
import { Addstaffleav } from '../../../Config/api';
import { toast } from 'react-toastify';

const AddStaffLeave = ({ show, onclose, sendDataToParent }) => {

  const [total_days, setNumberofDays] = useState(0);
  const [from_date, setFromDate] = useState('');
  const [to_date, setToDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const staff_details = getStaffDetails();
  const [searchOpen, setSearchOpen] = useState(false);
  const [showleavetype, setleavetype] = useState(false);
  const dropdownRef = useRef(null);
  const leaveTypeDropdownRef = useRef(null);
  const [eligibleLeaveDetails, setEligibleLeaveDetails] = useState([]);
  // const [isEmployeeSelected, setIsEmployeeSelected] = useState(false);
  const company_details = getCompanyDetails() || { company_id: '' };

  const [formData, setFormData] = useState({
    empid: staff_details.staff_details.emp_id,
    employeename: staff_details.register_details.name,
    leave_type: '',
    reason: '',
    from_date: '',
    to_date: '',
    total_days: 0,
    apply_date: new Date().toISOString().split('T')[0], 
    user: staff_details.staff_details.user_id,
    register: staff_details.register_details.id,
  });

  useEffect(() => {
    // setIsEmployeeSelected(true);
    const staff_details = getStaffDetails();
    if (staff_details) {
      setEligibleLeaveDetails(staff_details.staff_details.leave_component_details || []);
    }

    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setSearchOpen(false);
      }
      if (
        leaveTypeDropdownRef.current &&
        !leaveTypeDropdownRef.current.contains(event.target)
      ) {
        setleavetype(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const closePopUp =()=>{
    onclose();
    setErrors({});
    setFormData({
      apply_date: new Date().toISOString().split('T')[0], 
      empid: staff_details.staff_details.emp_id,
      employeename: staff_details.register_details.name,
      leave_type: '',
      reason: '',
      from_date: '',
      to_date: '',
      total_days: 0,
      user: staff_details.staff_details.user_id,
      register: staff_details.register_details.id,
    });
    setNumberofDays(0);
    setFromDate('');
    setToDate('');
    setSearchOpen(false);
    setleavetype(false);
    // setIsEmployeeSelected(false);
  }

  const handleinputClick = () => {
    setleavetype(!showleavetype);
  }

  const handleleavetype = (leavetype) => {
    setleavetype(false)
    setFormData((prevData) => ({
      ...prevData,
      leave_type: leavetype.id,
      leave_name: leavetype.leave_type,
      leave_deduct:leavetype.leave_deduct,

    }));
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      from_date,
      to_date,
      total_days: total_days,
    }));
  }, [from_date, to_date, total_days]);

  const handleSubmit = async () => {
    const newErrors = {};
    if (formData.employeename.trim() === "") {
      newErrors.employeename = "Name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.employeename)) {
      newErrors.employeename = "Name must contain only alphabetic characters and spaces";
    }
    if (formData.reason.trim() === '') {
      newErrors.reason = 'Leave Reason is required';
    }
    if (formData.from_date.trim() === '' && formData.to_date.trim() === '') {
      newErrors.from_date = 'Please select a leave date.';
    }
    if (formData.apply_date.trim() === '') {
      newErrors.apply_date = 'Applying Date is required';
    }
    if (formData.empid.trim() === '') {
      newErrors.empid = 'Employee Id is required';
    }
    if (!formData.leave_type) {
      newErrors.leave_type = 'Leave Type is required';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
        const response = await Addstaffleav(formData);
        if (response) {
          sendDataToParent(response.staff_leave_details);

          toast.success('Leave added successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
          setFormData({
            empid: staff_details.staff_details.emp_id,
            employeename: staff_details.register_details.name,
            leave_type: "",
            leave_deduct:'',
            leave_name: '',
            reason: '',
            from_date: '',
            to_date: '',
            total_days: 0,
            apply_date: new Date().toISOString().split('T')[0], 
            user: staff_details.staff_details.user_id,
            register: staff_details.register_details.id,
            company: company_details.company_id || ''
          });
          setFromDate('');
          setToDate('');
          setNumberofDays(0);
          onclose();
          setTimeout(() => {
          }, 1000);
        }
      } catch (error) {
        if (error.response) {
          const newErrors = {};
          newErrors.response_error = error.response.data.data;
          setErrors(newErrors);
        } else {
          toast.error('Failed. Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,

          });
          const newErrors = {
            response_error: [],
          };
          for (const key in error.response.data.errors) {
            if (error.response.data.errors.hasOwnProperty(key)) {
              newErrors.response_error.push(`${error.response.data.errors[key]}`);
            }
          }
          setErrors(newErrors);
          setFormData((state) => ({
            ...state,
          }));
        }
      } finally {
        setLoading(false);
      }
    }
  };

  if (!show) return null;

  return (
    <div className='add-leave-section'>
      <div className='addleave-container'>
        <span className='uploadexcel-close' onClick={closePopUp}>&times;</span>
        <div className='addleave-container-sub'>
          <div className='addleave-heading'>
            <p>Add Leave</p>
          </div>
          <div className='addleave-strightline'></div>
          <div className='addleave-input-cont'>
            <div className='addleave-input'>
              <div className='addleave-input-line1'>
                <div className='addleave-input-group'>
                  <label>Employee ID</label>
                  <div ref={dropdownRef} className={`invoice-grid-div invoice-span-div invoice-sales-div ${searchOpen ? "active" : ""}`}>
                    <div>
                      <input
                        type='text'
                        placeholder='Employee Id'
                        name='empid'
                        value={staff_details.staff_details.emp_id}
                        readOnly
                      />
                    </div>
                    {errors.empid && (
                      <div className="error-message-view">{errors.empid}</div>
                    )}
                  </div>
                </div>
                <div className='addleave-input-group'>
                  <label>Employee Name</label>
                  <input type='text' value={staff_details.register_details.name} name='employeename' />
                  {errors.employeename && (
                    <div className="error-message-view">{errors.employeename}</div>
                  )}
                </div>
                <div className='addleave-input-group'>
                  <label>Applying Date</label>
                  <input type='date' value={formData.apply_date} disabled onChange={handleInputChange} name="apply_date" />
                  {errors.apply_date && (
                    <div className="error-message-view">{errors.apply_date}</div>
                  )}
                </div>
              </div>
            </div>
            <div className='addleave-input2'>
              <div className='addleave-input-line2'>
                <div className='addleave-input-group'>
                  <label>Leave Type</label>
                  <div ref={leaveTypeDropdownRef} className={`invoice-grid-div invoice-span-div invoice-sales-div ${showleavetype ? "active" : ""}`}>
                    <div onClick={handleinputClick}>
                      <input
                        type='text'
                        placeholder='Select Leave Type'
                        onChange={handleInputChange}
                        name='leave_type'
                        value={formData.leave_name ? `${formData.leave_name} (${formData.leave_deduct})` : ''}
                        readOnly
                        // disabled={!isEmployeeSelected} 

                      />
                    </div>
                    {showleavetype && (
                      <div className='invoice-sales-grid-div-dropdown' style={{fontSize:"12px"}}>
                        <ul>
                          {Array.isArray(eligibleLeaveDetails) && eligibleLeaveDetails.length > 0 ? (
                            eligibleLeaveDetails.map(leaveType => (
                              <li
                                key={leaveType.id}
                                className='sal_block'
                                onClick={() => handleleavetype(leaveType)}
                              >
                                {leaveType.leave_name} ({leaveType.leave_deduct})
                                </li>
                            ))
                          ) : (
                            <li className='sal_block'>No Data Found</li>
                          )}
                        </ul>
                      </div>
                    )}
                    {errors.leave_type && (
                      <div className="error-message-view">{errors.leave_type}</div>
                    )}
                  </div>
                </div>
                <div className='addleave-input-group'>
                  <label>Leave Reason</label>
                  <textarea name='reason' value={formData.reason} onChange={handleInputChange}></textarea>
                  {errors.reason && (
                    <div className="error-message-view">{errors.reason}</div>
                  )}
                </div>
              </div>
            </div>
            <div className='addleave-input2'>
              <div className='addleave-input-line2'>
                <div className='addleave-input-group'>
                  <label>Select Leave Dates</label>
                  <DatepickerComp
                    setNumberOfDays={setNumberofDays}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                  />
                  {errors.from_date && (
                    <div className="error-message-view">{errors.from_date}</div>
                  )}
 
                </div>
                <div className='addleave-input-group'>
                  <label>No Of Days</label>
                  <input type='text' name='noofdays' value={total_days} readOnly />
                </div>
              </div>
            </div>
            <div className='response-message-view'>
              {errors.response_error && (
                <div className="error-message-view">{errors.response_error}</div>
              )}
            </div>

            <div className='addleave-buttons'>
              <div className='addleave-submit-button'>
                <button onClick={handleSubmit} disabled={loading}>
                  {loading ? <span className="spinner"></span> : 'Submit'}

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaffLeave