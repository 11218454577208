import React from 'react';
import './Sidebar.css';
import { RiDashboardLine, RiUserLine, RiFileTextFill, RiCalculatorLine, RiMoneyRupeeCircleLine } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import { CiMoneyBill } from 'react-icons/ci';
import { getUserDetails } from '../../Services/Storage';

const Sidebar = () => {

  const user_details = getUserDetails();
  const authUser = user_details ? user_details : null;
  const accessDetails = authUser?.get_access_role?.[0]?.invite_user_details?.[0] || null;
  const userAccess = accessDetails ? accessDetails : null;

  const listAll = { ...userAccess?.access_category, dashboard: ["all"] };
  const actionPage = Object.keys(listAll).filter(
    key => listAll[key].length > 0
  );
  const navLinks = [
    {
      path: "/employee",
      label: "Employees",
      icon: <RiUserLine size={15} />,
      condition: authUser.user_status && authUser.access_users && actionPage.includes("employee") && Array.isArray(listAll["employee"]) && listAll["employee"].length > 0
    },
    {
      path: "/viewleave",
      label: "Leave",
      icon: <RiFileTextFill size={15} />,
      condition: authUser.user_status && authUser.access_users && actionPage.includes("leave") && Array.isArray(listAll["leave"]) && listAll["leave"].length > 0
    },
    {
      path: "/viewattendance",
      label: "Attendance Report",
      icon: <RiCalculatorLine size={15} />,
      condition: authUser.user_status && authUser.access_users && actionPage.includes("attendance") && Array.isArray(listAll["attendance"]) && listAll["attendance"].length > 0
    },
    {
      path: "/viewpayroll",
      label: "Payroll",
      icon: <RiMoneyRupeeCircleLine size={15} />,
      condition: authUser.user_status && authUser.access_users && actionPage.includes("payroll") && Array.isArray(listAll["payroll"]) && listAll["payroll"].length > 0
    },
    {
      path: "/addearningcomp",
      label: "Salary Component",
      icon: <CiMoneyBill size={15} />,
      condition: authUser.user_status && authUser.access_users && actionPage.includes("salary_component") && Array.isArray(listAll["salary_component"]) && listAll["salary_component"].length > 0
    },
    {
      path: "/viewleavecomp",
      label: "Leave Component",
      icon: <RiFileTextFill size={15} />,
      condition: authUser.user_status && authUser.access_users && actionPage.includes("leave_component") && Array.isArray(listAll["leave_component"]) && listAll["leave_component"].length > 0
    }
  ];

  return (
    <div className='sidebar-container'>
      <div className='sidebar-container-sub'>
        <div className='sidebar-body'>
          <div className='sidebar-body-sub'>
            <ul className='sidebar-body-list'>
              {user_details.role === 'Company' ? (
                <NavLink to="/dashboard">
                  <li>
                    <span>
                      <RiDashboardLine size={15} />
                      <span>Dashboard</span>
                    </span>
                  </li>
                </NavLink>
              ) : user_details.role === 'staff' ? (
                <NavLink to="/dashboardstaff">
                  <li>
                    <span>
                      <RiDashboardLine size={15} />
                      <span>Dashboard</span>
                    </span>
                  </li>
                </NavLink>
              ) : (
                <NavLink to="/dashboardother">
                  <li>
                    <span>
                      <RiDashboardLine size={15} />
                      <span>Dashboard</span>
                    </span>
                  </li>
                </NavLink>
              )}


              <>
                {user_details.role === 'Company' ? (
                  <>
                    <NavLink to="/employee">
                      <li>
                        <span>
                          <RiUserLine size={15} />
                          <span>Employees</span>
                        </span>
                      </li>
                    </NavLink>
                    <NavLink to="/viewleave">
                      <li>
                        <span>
                          <RiFileTextFill size={15} />
                          <span>Leave</span>
                        </span>
                      </li>
                    </NavLink>
                    <NavLink to="/viewattendance">
                      <li>
                        <span>
                          <RiCalculatorLine size={15} />
                          <span>Attendance Report</span>
                        </span>
                      </li>
                    </NavLink>
                    <NavLink to="/viewpayroll">
                      <li>
                        <span>
                          <RiMoneyRupeeCircleLine size={15} />
                          <span>Payroll</span>
                        </span>
                      </li>
                    </NavLink>
                    <NavLink to="/addearningcomp">
                      <li>
                        <span>
                          <CiMoneyBill size={15} />
                          <span>Salary Component</span>
                        </span>
                      </li>
                    </NavLink>
                    <NavLink to="/viewleavecomp">
                      <li>
                        <span>
                          <RiFileTextFill size={15} />
                          <span>Leave Component</span>
                        </span>
                      </li>
                    </NavLink>
                  </>
                ) : user_details.role === 'staff' ? (
                  <>
                    <NavLink to="/staffLeave">
                      <li>
                        <span>
                          <RiFileTextFill size={15} />
                          <span>Leave</span>
                        </span>
                      </li>
                    </NavLink>
                    <NavLink to="/viewstaffattendance">
                      <li>
                        <span>
                          <RiCalculatorLine size={15} />
                          <span>Attendance Report</span>
                        </span>
                      </li>
                    </NavLink>
                    <NavLink to="/viewstaffpayroll">
                      <li>
                        <span>
                          <RiMoneyRupeeCircleLine size={15} />
                          <span>Payroll</span>
                        </span>
                      </li>
                    </NavLink>
                  </>
                ) : (

                  <>
                    {navLinks
                      .filter(link => link.condition)
                      .map((link, index) => (
                        <NavLink key={index} to={link.path}>
                          <li>
                            <span>
                              {link.icon}
                              <span>{link.label}</span>
                            </span>
                          </li>
                        </NavLink>
                      ))}
                  </>
                )
                }
              </>


            </ul>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Sidebar;