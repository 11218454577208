const websiteImages={

    about : "./Images/Website_images/about.png",
    appstore : "./Images/Website_images/appstore.png",
    image1 : "./Images/Website_images/img1.png",
    contact : "./Images/Website_images/contact.png",
    image2 : "./Images/Website_images/img2.png",
    playstore : "./Images/Website_images/playstore.png",
    attend     :"./Images/Website_images/attend.png",
    emplo      :"./Images/Website_images/Emplo.png",
    payroll     : "./Images/Website_images/payroll.png",
    About_tt     : "./Images/Website_images/about_att.png",
    Web_app     : "./Images/Website_images/webapp.png",
    websitesal    :"./Images/Website_images/websitesal.png",
    websiteacc    :"./Images/Website_images/websiteacc.png",
    websitesatt    :"./Images/Website_images/websiteatt.png",

    
};

export default websiteImages;