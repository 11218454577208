import React, { useRef, useState, useEffect } from 'react';
import api_backend, { AddsalaryCalculationApi } from '../../../Config/api';
import { toast } from 'react-toastify';
import { CiCircleInfo } from 'react-icons/ci';
// import { getRegisterId } from '../../../Config/userId';
import { getUserDetails } from '../../../Services/Storage';

const Editpayroll = ({ show, close, salarydata, datatoparent }) => {

  const user_details = getUserDetails();
  // const register_id = getRegisterId();
  const payslipRef = useRef();
  const [otherEarnings, setOtherEarnings] = useState();
  const [otherDeductions, setOtherDeductions] = useState();
  const [viewOtherEarnings, setViewOtherEarnings] = useState();
  const [viewOtherDeductions, setViewOtherDeductions] = useState();
  const [addOtherEarnings, setAddOtherEarnings] = useState(0);
  const [addOtherDeductions, setAddOtherDeductions] = useState(0);
  const [loader, setloader] = useState(false)
  const employee = salarydata;
  const totalEarningsview = Math.max(0, employee.earning_salary + (parseFloat(addOtherEarnings || 0) - parseFloat(addOtherDeductions || 0)));
  const totalDeductions = employee.deduct_salary + parseFloat(otherDeductions || 0);
  const totalComponentEarnings = (employee.salary_compo_list || [])
  .flat()
  .filter(item => item.earning_deduction === 'earning' )
  .reduce((acc, item) => acc + (parseFloat(item.monthly) || 0), 0);

// Update totalEarnings calculation
const totalEarnings = totalComponentEarnings + parseFloat(otherEarnings || 0);

  useEffect(() => {
    if (salarydata) {
      setOtherEarnings(salarydata.other_earning || 0);
      setViewOtherEarnings(salarydata.other_earning || 0);
      setViewOtherDeductions(salarydata.other_deduction || 0);
      setOtherDeductions(salarydata.other_deduction || 0);
    }
  }, [salarydata]);

  const salaryCalculation = async () => {
    setloader(true)
    const id = employee.register;
    const idsar = [id];
    try {
      const data = {
        edited_by: user_details.id,
        salary_edit: "salary_edit",
        "register_id": idsar,
        "month": employee.month,
        "year": employee.year,
        "other_deduction": parseFloat(otherDeductions || 0),
        "other_earning": parseFloat(otherEarnings || 0),

      };
      console.log(data)
      const response = await AddsalaryCalculationApi(JSON.stringify(data));
      if (response) {
        datatoparent(response);
        close();
        toast.success('Salary added successfully!', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
      }
    }
    catch (error) {
      toast.error('Failed. Please try again.', {
        position: "top-center",
        autoClose: 2000,
        closeButton: false,

      });
    } finally {
      setloader(false)
    }
  };

  if (!show) return null;
  if (!salarydata) return null;

  return (
    <div className='viewslippopup-section'>
      <div className='viewslippopup-container'>
        <span className='uploadexcel-close' onClick={close}>&times;</span>
        <div className="payslip" id="payslip" ref={payslipRef}>
          <div className="payslip-header">
            <h3>Update Salary Report</h3>
              <div className='payslip-header-company'>
              <div style={{textAlign:"justify"}}>
            <h3>{employee.company_details[0].company_name},</h3>
            <p> {employee.company_details[0].address_line1} ,{employee.company_details[0].address_line2},</p>
            <p>{employee.company_details[0].city} , {employee.company_details[0].state} , {employee.company_details[0].pincode}.</p>
            </div>
            <div className='payslip-header-companylogo'>
              <img 
              src={`${api_backend.BACKEND_API}${employee.company_details[0].company_logo}`}
              alt='img'
              />
            </div>
              </div>
            
<br/>
          <div className='payslip-employee-container'>
            <div className="payslip-employee" style={{borderRight:"none"}}>
              <div style={{textAlign:"justify"}}>
              <p><strong>Employee Id:</strong> </p>
              <p><strong>Employee Name:</strong> </p>
              <p><strong>Department:</strong> </p>
              <p><strong>Designation:</strong> </p>
              <p><strong>Actual Amount:</strong> </p>
              </div>
              <div>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              </div>
              <div style={{textAlign:"justify"}}>
              <p> {employee.emp_id}</p>
              <p>{employee.register_details[0].name}</p>
              <p>{employee.department}</p>
              <p>{employee.designation}</p>
              <p style={{color:"green"}}>₹ {employee?.monthly_salary}</p>

              </div>

            </div>
            <div className="payslip-employee">
              <div style={{textAlign:"justify"}}>
              <p><strong>Bank Name</strong> </p>
              <p><strong>Account Number</strong> </p>
              <p><strong>UAN</strong> </p>
              <p><strong>Month/Year</strong> </p>
              </div>
              <div>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              </div>
              <div style={{textAlign:"justify"}}>
              <p> {employee.payment_details[0].bank_name || ""}</p>
              <p>{employee.payment_details[0].account_num || ""}</p>
              <p>{employee.payment_details[0].UAN || ""}</p>
              <p>{employee.month} / {employee.year}</p>

              </div>

            </div>
            
          </div>
          </div>
          <p style={{marginLeft:"20px"}} className='payslip-edit-alertshow-para'> <CiCircleInfo style={{ fontSize: "16px" }} />Users can update other earnings and other deductions directly within the input fields.</p>
          <div className="payslip-details">
            <div className='payslip-earnings'>
              <table>
                <tbody>
                  <tr>
                    <td colSpan={2}>Total Days: {employee.days_in_month}</td>
                    <td colSpan={1}>Earning Days: {employee.earning_days}</td>
                    <td colSpan={1}>Deduction Days: {employee.deduction_days}</td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Earnings</th>
                    <th colSpan={2}>Deductions</th>
                  </tr>
                  <tr>
                    <td colSpan={2}>Leave Earnings: {employee.leave_earning}</td>
                    <td colSpan={2}>Leave Deductions: {employee.leave_deduction}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Punched Count: {employee.punch_count}</td>
                    <td colSpan={2}>Missed Punch: {employee.missed_punched}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Half Punch Count: {employee.half_punching}</td>
                    <td colSpan={2}>Late Deductions: {employee.late_deduction || 0}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Holiday Count: {employee.holiday_count}</td>
                    <td>Leave  Deductions(₹)</td>
                    <td>{Math.round(employee.leave_deduction * employee.per_day_salary)}</td>
                  </tr>
                  <tr>
                  <td colSpan={2}></td>
                    <td>Missed Punch(₹)</td>
                    <td>{Math.round(employee.missed_punched*employee.per_day_salary)}</td>
                  </tr>
                  <tr>
                  <td colSpan={2}></td>
                    <td>Late Deductions(₹)</td>
                    <td>{Math.round(employee.late_deduction*employee.per_day_salary)}</td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Amount</th>
                  </tr>
                  {(() => {
                    const flattenedList = employee.salary_compo_list.flat();
                    const earnings = flattenedList.filter(item => item.earning_deduction === 'earning');
                    const deductions = flattenedList.filter(item => item.earning_deduction === 'deduction');
                    const maxLength = Math.max(earnings.length, deductions.length);
                    
                    return Array.from({ length: maxLength }).map((_, index) => (
                      <tr key={index}>
                        <td>{earnings[index]?.component_name || ''}</td>
                        <td>{earnings[index]?.monthly || ''}</td>
                        <td>{deductions[index]?.component_name || ''}</td>
                        <td>{deductions[index]?.monthly || ''}</td>
                      </tr>
                    ));
                  })()}
                         <tr>
                    <td>Other Earnings</td>
                    <td className='payslip-input-field'>
                      {/* <input
                        type="text"
                        value={otherEarnings}
                        
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*\.?\d*$/.test(value)) {
                            setOtherEarnings(value);
                            setAddOtherEarnings(value);
                          }
                        }}                        
                        placeholder="Enter amount"
                      /> */}
                      {viewOtherEarnings}
                    </td>
                    <td>Other Deductions</td>
                    <td className='payslip-input-field'>
                      {/* <input
                        type="text"
                        value={otherDeductions}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*\.?\d*$/.test(value)) { // Allow only numbers and decimals
                            setOtherDeductions(value);
                            setAddOtherDeductions(value);
                            
                          }
                        }}                        
                        placeholder="Enter amount"

                      /> */}
                      {viewOtherDeductions}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Other Earnings</strong></td>
                    <td className='payslip-input-field'>
                      <input
                        type="text"
                        // value={otherEarnings}
                        
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*\.?\d*$/.test(value)) {
                            setOtherEarnings(value);
                            setAddOtherEarnings(value);
                          }
                        }}                        
                        placeholder="Enter amount"
                      />
                    </td>
                    <td><strong>Other Deductions</strong></td>
                    <td className='payslip-input-field'>
                      <input
                        type="text"
                        // value={otherDeductions}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*\.?\d*$/.test(value)) { // Allow only numbers and decimals
                            setOtherDeductions(value);
                            setAddOtherDeductions(value);
                            
                          }
                        }}                        
                        placeholder="Enter amount"

                      />
                    </td>
                  </tr>
                  <tr className="total-row">
                    <td><strong>Total Earnings</strong></td>
                    <td><strong>₹ {Math.round(totalEarnings)}</strong></td>
                    <td><strong>Total Deductions</strong></td>
                    <td><strong>₹ {Math.round(totalDeductions)}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="payslip-summary-edit">
            <div>
              <h4>Summary</h4>
              <p><strong>Net Pay:</strong> ₹ {Math.round(totalEarningsview)}</p>
            </div>

            <div className='payslip-summary-editdel'>
              <button onClick={salaryCalculation} disabled={loader}>
                {loader ? <span className='spinner'></span> : "Submit"}

              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editpayroll;
