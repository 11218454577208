import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CiBadgeDollar, CiBank, CiCalendar, CiCreditCard1, CiCreditCard2, CiDroplet, CiEdit, CiLocationOn, CiMail, CiMemoPad, CiPhone, CiUser, CiViewList, CiWallet } from 'react-icons/ci'
import userdemoimg from '../../../Images/Pagesimg/userdemo'
import api_backend, { ViewEditEmployeeApi } from '../../../Config/api'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import { getStaffDetails } from '../../../Services/Storage'
import pageloader from '../../../Loader/pageloading/Pageloader'
import { format } from 'date-fns'

const Staffprofile = () => {

  const navigate = useNavigate();
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [loading, setLoading] = useState(false); // Loader state


  const fetchuserdetails = async () => {
    setLoading(true);
    const staffdetails = getStaffDetails();
    try {
      const response = await ViewEditEmployeeApi(staffdetails.register_details.id)
      setEmployeeDetails(response.data.get_staff_details[0])
    } catch {

    }finally{
      setLoading(false);

    }
  }
  useEffect(() => {
   
    fetchuserdetails();
  }, []);

  const editbasic = () => {
    navigate('/editstaffbasic')
  };

  const editpersonal = () => {
    navigate('/editstaffpersonalinfo')
  };

  const overview_view = () => {
    // navigate('/vieweditdetails', { state: { user_id } });
  };

  const salary_view = () => {
    navigate('/staffsalary');
  };

  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          {loading ? (
            // Show loader while data is being fetched
            <div style={{backgroundColor:"#fff"}} className="loader-container">
            <img src={pageloader.page_loader} alt="Loading..." />
          </div>
          ) : (
            employeeDetails && (
            
              <>
                <div className='index-content-sub'>
                  <div className='index-main'>
                    <div className='addemp-subheader'>
                      <div className='addemp-subheader-sub'>
                        <div className='vieweditdet-subheader'>
                          <p> {employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.name : "N/A"} </p>

                        </div>
                        <div className='addemp-subheader2'>
                          <div className="vieweditdet-stepper">
                            <div className="vieweditdet-step-indicator">
                              <span className='vieweditdet-redirect-link' onClick={overview_view}>
                                <div className='vieweditdet-step vieweditdet-selected'>
                                  Overview
                                </div>
                              </span>
                              <span className='vieweditdet-redirect-link2' onClick={salary_view}>
                                <div className='vieweditdet-step'>
                                  Salary Details
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='addemp-line'></div>
                    <div className='addemp-maincontent-sub'>
                      <div className='addemp-form emp-edit'>
                        <div className="vieweditdet-left-side">
                          <div className='vieweditdet-left-side-sub'>
                            <div className='vieweditdet-card vieweditdet-full-card'>
                              <div className='vieweditdet-edit-icon' onClick={editbasic}>
                                <CiEdit />
                              </div>
                              <div className='vieweditdet-profile-edit'>
                                {employeeDetails.work_details.staff_pic ?
                                  (
                                  <img src={`${api_backend.BACKEND_API}${employeeDetails.work_details.staff_pic}`} alt="Employee" />
                                  )
                                  :
                                  (<img src={userdemoimg.demo_img} alt='img' />)

                                }
                              </div>
                              <div className='vieweditdet-leftside-name'>
                                <h4>{employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.name : "N/A"}</h4>
                              </div>
                              <div className='vieweditdet-line'></div>
                              <div className='vieweditdet-leftcontent-view'>
                                <p className='vieweditdet-heading-p-left'>BASIC DETAILS:</p>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                    <CiViewList />
                                  </div>
                                  <p style={{textOverflow:"ellipsis" ,overflow:"hidden",whiteSpace:"nowrap"}}>{employeeDetails
                                    ? employeeDetails.designation_name : "N/A"}</p>
                                </span>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                    <CiCalendar />
                                  </div>
                                  <p>
                                      DOJ ({employeeDetails && employeeDetails.work_details && employeeDetails.work_details.doj
                                                                          ? format(new Date(employeeDetails.work_details.doj), 'do MMMM yyyy')
                                                                          : 'N/A'}
                                                                        )
                                  </p>
                                </span>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                  <CiMemoPad />                                 
                                   </div>
                                  <p>{employeeDetails
                                    ? employeeDetails.work_details.doj : "N/A"}
                                  </p>
                                </span>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                    <CiMail />
                                  </div>
                                  <p  style={{textOverflow:"ellipsis" ,overflow:"hidden",whiteSpace:"nowrap"}}>{employeeDetails && employeeDetails.work_details
                                    ? employeeDetails.work_details.work_email : "N/A"}</p>
                                </span>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                    <CiPhone />
                                  </div>
                                  <p>{employeeDetails && employeeDetails.work_details
                                    ? employeeDetails.work_details.emergency_contact : "N/A"}</p>
                                </span>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                    <CiUser />
                                  </div>
                                  <p>{employeeDetails && employeeDetails.work_details
                                    ? employeeDetails.work_details.gender : "N/A"}</p>
                                </span>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                    <CiDroplet />
                                  </div>
                                  <p>{employeeDetails && employeeDetails.work_details
                                    ? employeeDetails.work_details.blood_group : "N/A"}</p>
                                </span>
                          
                               

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="vieweditdet-right-side">
                          <div className='vieweditdet-card1'>
                            <div className='vieweditdet-edit-icon' onClick={editpersonal}>
                              <CiEdit />
                            </div>
                            <p className='vieweditdet-heading-p-left'>PERSONAL INFO:</p>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiUser />
                              </div>
                              <p>{employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.name : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiPhone />
                              </div>
                              <p>{employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.phone : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiMail />
                              </div>
                              <p>{employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.email : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiLocationOn />
                              </div>
                              <p>{employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.address_line1 : "N/A"}</p>
                            </span>

                          </div>
                          <div className='vieweditdet-card1'>
                            <p className='vieweditdet-heading-p-left'>PAYMENT:</p>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiBank />
                              </div>
                              <p>Bank :  {employeeDetails && employeeDetails.payment_details ? employeeDetails.payment_details.bank_name : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiWallet />
                              </div>
                              <p>Account Number :  {employeeDetails && employeeDetails.payment_details ? employeeDetails.payment_details.account_num : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiCreditCard1 />
                              </div>
                              <p> Aadhar :  {employeeDetails && employeeDetails.payment_details ? employeeDetails.payment_details.aadhar_num : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiCreditCard2 />
                              </div>
                              <p> Pan : {employeeDetails && employeeDetails.payment_details ? employeeDetails.payment_details.pan_num : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiBadgeDollar />
                              </div>
                              <p>UAN :  {employeeDetails && employeeDetails.payment_details ? employeeDetails.payment_details.UAN : "N/A"}</p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              )
            )}
        </div>
      </div>
    </div>
  )
}

export default Staffprofile;
