import React, { useEffect, useState } from "react";
import Employeesatus from "../../Employees/Employeeview/Employeestatus";
import leaveStatusText from "../../Leave/LeaveStatusText";
import api_backend from "../../../Config/api";

const ViewLogsDetails = ({ show, close, datas }) => {
    const [viewDatas, setViewDatas] = useState();

    useEffect(() => {
        if (datas) {
            setViewDatas(datas);
        }
    }, [datas]);

    const convertTo12HourFormats = (time24) => {
        let [hours, minutes] = time24.split(":");
        hours = parseInt(hours, 10);
        const period = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;
        return `${hours}:${minutes} ${period}`;
    };

    if (!show) return null;
    const renderDetails = (item) => {
        const { action, category, delete_data, table_name, new_data, prev_data, get_register_name } = item;
        switch (category) {
            case "Payroll":
                switch (action) {
                    case "edit":

                        return (
                            <div className="edit-payroll-container">
                                <div className="payroll-record">
                                    <h3 className="record-title">Edited Payroll Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>
                                    <p> Name : {get_register_name}</p>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header"> Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Other Deduction</td>
                                                <td className="table-cell">{JSON.stringify(new_data.other_deduction, null, 2)}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Other Earnings</td>
                                                <td className="table-cell">{JSON.stringify(new_data.other_earning, null, 2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                    case "delete":
                        return (
                            <div className='viewslippopup-section'>
                                <div className='viewpayrolllog-container '>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>
                                    <h3 className="record-title">Deleted Payroll Record</h3>
                                    <p> Name : {get_register_name}</p>

                                    <div className="payslip" id="payslip">

                                        <div className='payslip-employee-container'>


                                        </div>
                                        <div className="payslip-details">
                                            <div className='payslip-earnings'>
                                                <table>
                                                    <tr>
                                                        <td colSpan={2}>Total Days : {delete_data.days_in_month}</td>
                                                        <td colSpan={1}>Earning Days : {delete_data.earning_days}</td>
                                                        <td colSpan={1}>Deductions Days : {delete_data.deduction_days}</td>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={2}>Earnings</th>
                                                        <th colSpan={2}>Deductions</th>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Leave Earnings : {delete_data.leave_earning}</td>
                                                        <td colSpan={2}>Leave Deductions : {delete_data.leave_deduction}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Punched Count : {delete_data.punched_count}</td>
                                                        <td colSpan={2}>Missed Punch : {delete_data.missed_punch}</td>

                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Holiday Count : {delete_data.holiday_count}</td>
                                                        <td colSpan={2}>Late Deductions : {delete_data.late_deduction}</td>

                                                    </tr>

                                                    <tbody>
                                                        <tr>
                                                            <td>Description</td>
                                                            <td>Amount</td>
                                                            <td>Description</td>
                                                            <td>Amount</td>
                                                        </tr>
                                                        {
                                                            (() => {
                                                                const earnings = delete_data.salary_compo_list.filter(item => item.earning_deduction === 'earning');
                                                                const deductions = delete_data.salary_compo_list.filter(item => item.earning_deduction === 'deduction');
                                                                const maxLength = Math.max(earnings.length, deductions.length);


                                                                return Array.from({ length: maxLength }).map((_, index) => (
                                                                    <tr key={index}>
                                                                        <td>{earnings[index]?.component_name || ''}</td>
                                                                        <td>{earnings[index]?.monthly || ''}</td>
                                                                        <td>{deductions[index]?.component_name || ''}</td>
                                                                        <td>{deductions[index]?.monthly || ''}</td>
                                                                    </tr>
                                                                ));
                                                            })()
                                                        }
                                                        <tr>
                                                            <td>Other Earnings</td>
                                                            <td>{delete_data.other_earning}</td>
                                                            <td>Other Deductions</td>
                                                            <td>{delete_data.other_deduction}</td>
                                                        </tr>

                                                        <tr className="total-row">
                                                            <td><strong>Total Earnings</strong></td>
                                                            <td><strong>{delete_data.earning_salary.toFixed(2)}</strong></td>
                                                            <td><strong>Total Deductions</strong></td>
                                                            <td><strong>{delete_data.deduct_salary.toFixed(2)}</strong></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="payslip-summary">
                                            <h4>Summary</h4>
                                            <p><strong>Net Pay:</strong> ₹{delete_data.earning_salary.toFixed(2)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        );




                    default:
                        return (
                            <div className="nodata_found_log">
                            <span className='uploadexcel-close' onClick={close}>&times;</span>

                            <div className="nodata_found_logcont" style={{color:"red"}}>
                                {/* <h3 className="record-title">Deleted  Record</h3> */}

                          
                                    No Data Found
                            

                              
                            </div>
                        </div>
                        );
                }

            case "Department Head":
                switch (action) {
                    case "add":
                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Added Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Department name</td>
                                                <td className="table-cell">{new_data[0].department_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Department Head name</td>
                                                <td className="table-cell">{new_data[0].depart_head_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Company</td>
                                                <td className="table-cell">{new_data[0].company_name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    case "edit":
                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Edit Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>
                                                <th className="table-header">Old Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Department name</td>
                                                <td className="table-cell">{new_data[0].department_name}</td>
                                                <td className="table-cell">{prev_data[0].department_name}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Department Head name</td>
                                                <td className="table-cell">{new_data[0].depart_head_name}</td>
                                                <td className="table-cell">{prev_data[0].depart_head_name}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Company</td>
                                                <td className="table-cell">{new_data[0].company_name}</td>
                                                <td className="table-cell">{prev_data[0].company_name}</td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    case "delete":
                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Deleted Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Department name</td>
                                                <td className="table-cell">{delete_data[0].department_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Department Head name</td>
                                                <td className="table-cell">{delete_data[0].depart_head_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Company</td>
                                                <td className="table-cell">{delete_data[0].company_name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    default:
                        return (
                            <div className="nodata_found_log">
                            <span className='uploadexcel-close' onClick={close}>&times;</span>

                            <div className="nodata_found_logcont" style={{color:"red"}}>
                                {/* <h3 className="record-title">Deleted  Record</h3> */}

                          
                                    No Data Found
                            

                              
                            </div>
                        </div>
                        );

                }
            case "Department TL":
                switch (action) {
                    case "add":
                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Added Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Department name</td>
                                                <td className="table-cell">{new_data[0].department_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Department Tl name</td>
                                                <td className="table-cell">{new_data[0].depart_tl_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Company</td>
                                                <td className="table-cell">{new_data[0].company_name}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    case "edit":
                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Edit Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>
                                                <th className="table-header">Old Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Department name</td>
                                                <td className="table-cell">{new_data[0].department_name}</td>
                                                <td className="table-cell">{prev_data[0].department_name}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Department Tl name</td>
                                                <td className="table-cell">{new_data[0].depart_tl_name}</td>
                                                <td className="table-cell">{prev_data[0].depart_tl_name}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Company</td>
                                                <td className="table-cell">{new_data[0].company_name}</td>
                                                <td className="table-cell">{prev_data[0].company_name}</td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    case "delete":
                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Deleted Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Department name</td>
                                                <td className="table-cell">{delete_data[0].department_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Department Tl name</td>
                                                <td className="table-cell">{delete_data[0].depart_tl_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Company</td>
                                                <td className="table-cell">{delete_data[0].company_name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    default:
                        return (
                            <div key={item.id}>
                                <p>Unhandled leave action: {action}</p>
                            </div>
                        );

                }
            case "Department":
                switch (action) {
                    case "add":
                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Added Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Department name</td>
                                                <td className="table-cell">{new_data[0].depart_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Department name</td>
                                                <td className="table-cell">
                                                     <div className="dataview-dropdown">
                                                    <button className="dropbtn">
                                                        View
                                                        <span className="arrow-down"></span> {/* Arrow added here */}
                                                    </button>
                                                    <div className="dataview-dropdown-content">
                                                        {new_data[0].company_details.map((company) => (
                                                            <p >{company.company_name}</p>
                                                        ))}
                                                    </div>
                                                </div>
                                                </td>
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    case "edit":
                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Edit Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>
                                                <th className="table-header">Old Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Department name</td>
                                                <td className="table-cell">{new_data[0].depart_name}</td>
                                                <td className="table-cell">{prev_data[0].depart_name}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Company</td>
                                                <td className="table-cell">
                                                <div className="dataview-dropdown">
                                                    <button className="dropbtn">
                                                        View
                                                        <span className="arrow-down"></span> {/* Arrow added here */}
                                                    </button>
                                                    <div className="dataview-dropdown-content">
                                                        {new_data[0].company_details.map((company) => (
                                                            <p >{company.company_name}</p>
                                                        ))}
                                                    </div>
                                                </div>                                                    </td>
                                                <td className="table-cell">
                                                <div className="dataview-dropdown">
                                                    <button className="dropbtn">
                                                        View
                                                        <span className="arrow-down"></span> {/* Arrow added here */}
                                                    </button>
                                                    <div className="dataview-dropdown-content">
                                                        {prev_data[0].company_name.map((company) => (
                                                            <p >{company.company_name}</p>
                                                        ))}
                                                    </div>
                                                </div>                                                      </td>

                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    case "delete":
                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Deleted Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header"> Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Department name</td>
                                                <td className="table-cell">{delete_data[0].depart_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Company</td>
                                                <td className="table-cell">
                                                <div className="dataview-dropdown">
                                                    <button className="dropbtn">
                                                        View
                                                        <span className="arrow-down"></span> {/* Arrow added here */}
                                                    </button>
                                                    <div className="dataview-dropdown-content">
                                                        {delete_data[0].company_name.map((company) => (
                                                            <p >{company.company_name}</p>
                                                        ))}
                                                    </div>
                                                </div>                                                    </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    default:
                        return (
                            <div className="nodata_found_log">
                            <span className='uploadexcel-close' onClick={close}>&times;</span>

                            <div className="nodata_found_logcont" style={{color:"red"}}>
                                {/* <h3 className="record-title">Deleted  Record</h3> */}

                          
                                    No Data Found
                            

                              
                            </div>
                        </div>
                        );

                }
            case "Designation":
                switch (action) {
                    case "add":
                        return (
                            <div className="edit-payroll-container">
                                <span className='uploadexcel-close' onClick={close}>&times;</span>

                                <div className="payroll-record">
                                    <h3 className="record-title">Added Record</h3>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Designation name</td>
                                                <td className="table-cell">{new_data[0].designation}</td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    case "edit":
                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Edit Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>
                                                <th className="table-header">Old Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Designation name</td>
                                                <td className="table-cell">{new_data[0].designation}</td>
                                                <td className="table-cell">{prev_data[0].designation}</td>

                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    case "delete":
                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Deleted Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header"> Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Designation name</td>
                                                <td className="table-cell">{delete_data[0].designation}</td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    default:
                        return (
                            <div className="nodata_found_log">
                            <span className='uploadexcel-close' onClick={close}>&times;</span>

                            <div className="nodata_found_logcont" style={{color:"red"}}>
                                {/* <h3 className="record-title">Deleted  Record</h3> */}

                          
                                    No Data Found
                            

                              
                            </div>
                        </div>
                        );

                }
            case "Attendance":

                switch (action) {

                    case "delete":
                        const displayTimefrom = convertTo12HourFormats(delete_data[0].punch_in_time);
                        const displayTimeto = convertTo12HourFormats(delete_data[0].punch_out_time);

                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Deleted Attendance Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>
                                    <p> Name : {get_register_name}</p>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">From</th>
                                                <th className="table-header">To</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Timming</td>
                                                <td className="table-cell">{displayTimefrom}</td>
                                                <td className="table-cell">{displayTimeto}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    case "edit":
                        const displayTimefromeeditprev = convertTo12HourFormats(prev_data[0].punch_in_time);
                        const displayTimetoeditprev = convertTo12HourFormats(prev_data[0].punch_out_time);
                        const displayTimefromeeditnew = convertTo12HourFormats(new_data[0].punch_in_time);
                        const displayTimetoeditnew = convertTo12HourFormats(new_data[0].punch_out_time);

                        return (
                            <div className="edit-payroll-container">

                                <div className="payroll-record">
                                    <h3 className="record-title">Edited Attendance Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>
                                    <p> Name : {get_register_name}</p>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">From</th>
                                                <th className="table-header">To</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Timming (Old Data)</td>
                                                <td className="table-cell">{displayTimefromeeditprev}</td>
                                                <td className="table-cell">{displayTimetoeditprev}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Timming (New Data)</td>
                                                <td className="table-cell">{displayTimefromeeditnew}</td>
                                                <td className="table-cell">{displayTimetoeditnew}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    default:
                        return (
                            <div className="nodata_found_log">
                            <span className='uploadexcel-close' onClick={close}>&times;</span>

                            <div className="nodata_found_logcont" style={{color:"red"}}>
                                {/* <h3 className="record-title">Deleted  Record</h3> */}

                          
                                    No Data Found
                            

                              
                            </div>
                        </div>
                        );
                }

            case "Leave":
                switch (action) {
                    case "add":
                        return (
                            <div className="add-leave-container">
                                <span className='uploadexcel-close' onClick={close}>&times;</span>


                                <div className="payroll-record">

                                    <h3 className="record-title">Added Leave Record</h3>
                                    <p> Name : {get_register_name}</p>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {/* <tr className="table-row">
                                                <td className="table-cell">Reason</td>
                                                <td className="table-cell">{new_data[0].reason}</td>
                                            </tr> */}
                                            <tr className="table-row">
                                                <td className="table-cell">Applying Date</td>
                                                <td className="table-cell">{new_data[0].apply_date}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Total Days</td>
                                                <td className="table-cell">{new_data[0].total_days}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">From Date</td>
                                                <td className="table-cell">{new_data[0].from_date}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">To Date</td>
                                                <td className="table-cell">{new_data[0].to_date}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Leave Type</td>
                                                <td className="table-cell">{new_data[0].leave_type_name}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            //   <div key={item.id}>
                            //     <h3>Added Leave Record</h3>
                            //     <pre>{JSON.stringify(new_data, null, 2)}</pre>
                            //   </div>
                        );
                    case "deactive":
                        return (
                            <div className="add-leave-container">
                                <span className='uploadexcel-close' onClick={close}>&times;</span>


                                <div className="payroll-record">
                                    <h3 className="record-title">Edited Leave Record</h3>
                                    <p> Name : {get_register_name}</p>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>
                                                <th className="table-header">Old Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>
{/* 
                                            <tr className="table-row">
                                                <td className="table-cell">Reason</td>
                                                <td className="table-cell">{new_data[0].reason}</td>
                                                <td className="table-cell">{prev_data[0].reason}</td>

                                            </tr> */}
                                            <tr className="table-row">
                                                <td className="table-cell">Applying Date</td>
                                                <td className="table-cell">{new_data[0].apply_date}</td>
                                                <td className="table-cell">{prev_data[0].apply_date}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Total Days</td>
                                                <td className="table-cell">{new_data[0].total_days}</td>
                                                <td className="table-cell">{prev_data[0].total_days}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">From Date</td>
                                                <td className="table-cell">{new_data[0].from_date}</td>
                                                <td className="table-cell">{prev_data[0].from_date}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">To Date</td>
                                                <td className="table-cell">{new_data[0].to_date}</td>
                                                <td className="table-cell">{prev_data[0].to_date}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Leave Type</td>
                                                <td className="table-cell">{new_data[0].leave_type_name}</td>
                                                <td className="table-cell">{prev_data[0].leave_type_name}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Status</td>
                                                <td className="table-cell" style={{ color: leaveStatusText(new_data[0].status).color }}> {leaveStatusText(new_data[0].status).text}</td>
                                                <td className="table-cell" style={{ color: leaveStatusText(prev_data[0].status).color }}>{leaveStatusText(prev_data[0].status).text}</td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            //   <div key={item.id}>
                            //     <h3>Added Leave Record</h3>
                            //     <pre>{JSON.stringify(new_data, null, 2)}</pre>
                            //   </div>
                        );
                    case "delete":
                        return (
                            <div className="add-leave-container">
                                <span className='uploadexcel-close' onClick={close}>&times;</span>

                                <div className="payroll-record">
                                    <h3 className="record-title">Deleted Leave Record</h3>
                                    <p> Name : {get_register_name}</p>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
{/* 
                                            <tr className="table-row">
                                                <td className="table-cell">Reason</td>
                                                <td className="table-cell">{delete_data[0].reason}</td>
                                            </tr> */}
                                            <tr className="table-row">
                                                <td className="table-cell">Applying Date</td>
                                                <td className="table-cell">{delete_data[0].apply_date}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Total Days</td>
                                                <td className="table-cell">{delete_data[0].total_days}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">From Date</td>
                                                <td className="table-cell">{delete_data[0].from_date}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">To Date</td>
                                                <td className="table-cell">{delete_data[0].to_date}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Leave Type</td>
                                                <td className="table-cell">{delete_data[0].leave_type_name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                    default:
                        return (
                            <div className="nodata_found_log">
                            <span className='uploadexcel-close' onClick={close}>&times;</span>

                            <div className="nodata_found_logcont" style={{color:"red"}}>
                                {/* <h3 className="record-title">Deleted  Record</h3> */}

                          
                                    No Data Found
                            

                              
                            </div>
                        </div>
                        );
                }

            case "Leave Component":
                switch (action) {
                    case "add":
                        return (
                            <div className="view-leavesal-container">
                                <span className='uploadexcel-close' onClick={close}>&times;</span>


                                <div className="payroll-record">

                                    <h3 className="record-title">Leave Component Record</h3>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr className="table-row">
                                                <td className="table-cell">Leave Type</td>
                                                <td className="table-cell">{new_data[0].leave_type}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Leave Name</td>
                                                <td className="table-cell">{new_data[0].leave_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Leave Deduction</td>
                                                <td className="table-cell">{new_data[0].leave_deduct}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Earning/Deduction</td>
                                                <td className="table-cell">{new_data[0].earning_deduction}</td>
                                            </tr>



                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            //   <div key={item.id}>
                            //     <h3>Added Leave Record</h3>
                            //     <pre>{JSON.stringify(new_data, null, 2)}</pre>
                            //   </div>
                        );
                    case "delete":
                        return (
                            <div className="view-leavesal-container">
                                <span className='uploadexcel-close' onClick={close}>&times;</span>

                                <div className="payroll-record">
                                    <h3 className="record-title">Deleted  Record</h3>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr className="table-row">
                                                <td className="table-cell">Leave Type</td>
                                                <td className="table-cell">{delete_data[0].leave_type}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Leave Name</td>
                                                <td className="table-cell">{delete_data[0].leave_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Levae Deduction</td>
                                                <td className="table-cell">{delete_data[0].leave_deduct}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Earning/Deduction</td>
                                                <td className="table-cell">{delete_data[0].earning_deduction}</td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                    case "edit":
                        return (
                            <div className="view-leavesal-container">
                                <span className='uploadexcel-close' onClick={close}>&times;</span>


                                <div className="payroll-record">

                                    <h3 className="record-title">Leave Component Record</h3>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>
                                                <th className="table-header">Old Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr className="table-row">
                                                <td className="table-cell">Leave Type</td>
                                                <td className="table-cell">{new_data[0].leave_type}</td>
                                                <td className="table-cell">{prev_data[0].leave_type}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Leave Name</td>
                                                <td className="table-cell">{new_data[0].leave_name}</td>
                                                <td className="table-cell">{prev_data[0].leave_name}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Leave Deduction</td>
                                                <td className="table-cell">{new_data[0].leave_deduct}</td>
                                                <td className="table-cell">{prev_data[0].leave_deduct}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Earning/Deduction</td>
                                                <td className="table-cell">{new_data[0].earning_deduction}</td>
                                                <td className="table-cell">{prev_data[0].earning_deduction}</td>

                                            </tr>



                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            //   <div key={item.id}>
                            //     <h3>Added Leave Record</h3>
                            //     <pre>{JSON.stringify(new_data, null, 2)}</pre>
                            //   </div>
                        );
                    default:
                        return (
                            <div className="nodata_found_log">
                            <span className='uploadexcel-close' onClick={close}>&times;</span>

                            <div className="nodata_found_logcont" style={{color:"red"}}>
                                {/* <h3 className="record-title">Deleted  Record</h3> */}

                          
                                    No Data Found
                            

                              
                            </div>
                        </div>
                        );
                }
            case "Holiday":
                switch (action) {
                    case "add":
                        return (
                            <div className="view-leavesal-container">
                                <span className='uploadexcel-close' onClick={close}>&times;</span>


                                <div className="payroll-record">

                                    <h3 className="record-title">Holiday Record</h3>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr className="table-row">
                                                <td className="table-cell">From Date</td>
                                                <td className="table-cell">{new_data[0].holiday_from_date}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">To Date</td>
                                                <td className="table-cell">{new_data[0].holiday_end_date}</td>
                                            </tr>
                                            {/* <tr className="table-row">
                                                <td className="table-cell">Reason</td>
                                                <td className="table-cell">{new_data[0].reason}</td>
                                            </tr> */}
                                            <tr className="table-row">
                                                <td className="table-cell">Company</td>
                                                <td className="table-cell">  <div className="dataview-dropdown">
                                                    <button className="dropbtn">
                                                        View
                                                        <span className="arrow-down"></span> {/* Arrow added here */}
                                                    </button>
                                                    <div className="dataview-dropdown-content">
                                                        {new_data[0].company_name.map((company) => (
                                                            <p >{company}</p>
                                                        ))}
                                                    </div>
                                                </div></td>                                                </tr>



                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            //   <div key={item.id}>
                            //     <h3>Added Leave Record</h3>
                            //     <pre>{JSON.stringify(new_data, null, 2)}</pre>
                            //   </div>
                        );
                    case "delete":
                        return (
                            <div className="view-leavesal-container">
                                <span className='uploadexcel-close' onClick={close}>&times;</span>


                                <div className="payroll-record">

                                    <h3 className="record-title">Deleted Holiday Record</h3>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr className="table-row">
                                                <td className="table-cell">From Date</td>
                                                <td className="table-cell">{delete_data[0].holiday_from_date}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">To Date</td>
                                                <td className="table-cell">{delete_data[0].holiday_end_date}</td>
                                            </tr>
                                            {/* <tr className="table-row">
                                                <td className="table-cell">Reason</td>
                                                <td className="table-cell">{delete_data[0].reason}</td>
                                            </tr> */}
                                            <tr className="table-row">
                                                <td className="table-cell">Company</td>
                                                <td className="table-cell"> 
                                                     <div className="dataview-dropdown">
                                                    <button className="dropbtn">
                                                        View
                                                        <span className="arrow-down"></span> {/* Arrow added here */}
                                                    </button>
                                                    <div className="dataview-dropdown-content">
                                                        {delete_data[0].company_name.map((company) => (
                                                            <p >{company}</p>
                                                        ))}
                                                    </div>
                                                </div>
                                                </td>                                                </tr>



                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        );

                    default:
                        return (
                            <div className="nodata_found_log">
                            <span className='uploadexcel-close' onClick={close}>&times;</span>

                            <div className="nodata_found_logcont" style={{color:"red"}}>
                                {/* <h3 className="record-title">Deleted  Record</h3> */}

                          
                                    No Data Found
                            

                              
                            </div>
                        </div>
                        );
                }
            case "Salary Component":
                switch (action) {
                    case "add":
                        return (
                            <div className="view-leavesal-container">
                                <span className='uploadexcel-close' onClick={close}>&times;</span>


                                <div className="payroll-record">

                                    <h3 className="record-title">Salary Component Record</h3>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr className="table-row">
                                                <td className="table-cell">Component Type</td>
                                                <td className="table-cell">{new_data[0].component}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Component Name</td>
                                                <td className="table-cell">{new_data[0].component_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Calculation Code</td>
                                                <td className="table-cell">{new_data[0].calculation_type}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Earning/Deduction</td>
                                                <td className="table-cell">{new_data[0].earning_deduction}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Amount/Percentage</td>
                                                <td className="table-cell">{new_data[0].amount_type}</td>
                                            </tr>



                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            //   <div key={item.id}>
                            //     <h3>Added Leave Record</h3>
                            //     <pre>{JSON.stringify(new_data, null, 2)}</pre>
                            //   </div>
                        );
                    case "delete":
                        return (
                            <div className="view-leavesal-container">
                                <span className='uploadexcel-close' onClick={close}>&times;</span>

                                <div className="payroll-record">
                                    <h3 className="record-title">Deleted  Record</h3>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr className="table-row">
                                                <td className="table-cell">component Type</td>
                                                <td className="table-cell">{delete_data[0].component}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Leave Name</td>
                                                <td className="table-cell">{delete_data[0].component_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Calculation Code</td>
                                                <td className="table-cell">{delete_data[0].calculation_type}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Earning/Deduction</td>
                                                <td className="table-cell">{delete_data[0].earning_deduction}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Amount/Percentage</td>
                                                <td className="table-cell">{delete_data[0].amount_type}</td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                    case "edit":
                        return (
                            <div className="view-leavesal-container">
                                <span className='uploadexcel-close' onClick={close}>&times;</span>


                                <div className="payroll-record">

                                    <h3 className="record-title">Salary Component Record</h3>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">New Data</th>
                                                <th className="table-header">Old Data</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr className="table-row">
                                                <td className="table-cell">Component Type</td>
                                                <td className="table-cell">{new_data[0].component}</td>
                                                <td className="table-cell">{prev_data[0].component}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Component Name</td>
                                                <td className="table-cell">{new_data[0].component_name}</td>
                                                <td className="table-cell">{prev_data[0].component_name}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Calculation Code</td>
                                                <td className="table-cell">{new_data[0].calculation_type}</td>
                                                <td className="table-cell">{prev_data[0].calculation_type}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Earning/Deduction</td>
                                                <td className="table-cell">{new_data[0].earning_deduction}</td>
                                                <td className="table-cell">{prev_data[0].earning_deduction}</td>

                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Amount/Percentage</td>
                                                <td className="table-cell">{new_data[0].amount_type}</td>
                                                <td className="table-cell">{prev_data[0].amount_type}</td>

                                            </tr>



                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            //   <div key={item.id}>
                            //     <h3>Added Leave Record</h3>
                            //     <pre>{JSON.stringify(new_data, null, 2)}</pre>
                            //   </div>
                        );
                    default:
                        return (
                            <div className="nodata_found_log">
                            <span className='uploadexcel-close' onClick={close}>&times;</span>

                            <div className="nodata_found_logcont" style={{color:"red"}}>
                                {/* <h3 className="record-title">Deleted  Record</h3> */}

                          
                                    No Data Found
                            

                              
                            </div>
                        </div>
                        );
                }
            case "Staff Salary Component":
                switch (action) {
                    case "edit":
                        return (
                            <div className="logs-salary-comp-other">
                                <div className="logs-salary-comp-othersb">
                                    <div className="logssalary-compother-close">
                                        <span className='uploadexcel-close' onClick={close}>&times;</span>
                                    </div>
                                    <div className="logssalary-compother-container">
                                        <div className="logssalary-compother-containersb">
                                        <h3 className="record-title">Satff Salary Component Record</h3>

                                        <table className="payroll-table">
                                            <thead>
                                                <tr>
                                                    <th className="table-header">Field</th>
                                                    <th className="table-header">Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr className="table-row">
                                                    <td className="table-cell">Component Type</td>
                                                    <td className="table-cell">{new_data[0].new_salary_component[0].component}</td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-cell">Component Name</td>
                                                    <td className="table-cell">{new_data[0].new_salary_component[0].component_name}</td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-cell">Calculation Code</td>
                                                    <td className="table-cell">{new_data[0].new_salary_component[0].calculation_type}</td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-cell">Earning/Deduction</td>
                                                    <td className="table-cell">{new_data[0].new_salary_component[0].earning_deduction}</td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-cell">Amount/Percentage</td>
                                                    <td className="table-cell">{new_data[0].new_salary_component[0].amount_type}</td>
                                                </tr>



                                            </tbody>
                                        </table>
                                        <h3 className="record-title">Employee's List</h3>
                                        <table className="payroll-table">
                                            <thead>
                                                <tr>
                                                    <th className="table-header">Name</th>
                                                    <th className="table-header">Employee Id</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {new_data[0].employee_details.map((log, index) => (
                                                    <tr className="table-row">
                                                        <td style={{ fontSize: "12px" }} >{log.name}</td>
                                                        <td style={{ fontSize: "12px" }} >{log.emp_id}</td>
                                                    </tr>
                                                ))

                                                }




                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        )
                    case "delete":
                        return (
                            <div className="logs-salary-comp-other">
                                <div className="logs-salary-comp-othersb">
                                    <div className="logssalary-compother-close">
                                        <span className='uploadexcel-close' onClick={close}>&times;</span>
                                    </div>
                                    <div className="logssalary-compother-container">
                                    <div className="logssalary-compother-containersb">

                                        <h3 className="record-title">Satff Salary Component Record</h3>

                                        <table className="payroll-table">
                                            <thead>
                                                <tr>
                                                    <th className="table-header">Field</th>
                                                    <th className="table-header">Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr className="table-row">
                                                    <td className="table-cell">Component Type</td>
                                                    <td className="table-cell">{delete_data[0].new_salary_component[0].component}</td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-cell">Component Name</td>
                                                    <td className="table-cell">{delete_data[0].new_salary_component[0].component_name}</td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-cell">Calculation Code</td>
                                                    <td className="table-cell">{delete_data[0].new_salary_component[0].calculation_type}</td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-cell">Earning/Deduction</td>
                                                    <td className="table-cell">{delete_data[0].new_salary_component[0].earning_deduction}</td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-cell">Amount/Percentage</td>
                                                    <td className="table-cell">{delete_data[0].new_salary_component[0].amount_type}</td>
                                                </tr>



                                            </tbody>
                                        </table>
                                        <h3 className="record-title">Employee's List</h3>
                                        <table className="payroll-table">
                                            <thead>
                                                <tr>
                                                    <th className="table-header">Name</th>
                                                    <th className="table-header">Employee Id</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {delete_data[0].employee_details.map((log, index) => (
                                                    <tr className="table-row">
                                                        <td style={{ fontSize: "12px" }} >{log.name}</td>
                                                        <td style={{ fontSize: "12px" }} >{log.emp_id}</td>
                                                    </tr>
                                                ))

                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                        );
                    default:
                        return (
                            <div className="nodata_found_log">
                            <span className='uploadexcel-close' onClick={close}>&times;</span>

                            <div className="nodata_found_logcont" style={{color:"red"}}>
                                {/* <h3 className="record-title">Deleted  Record</h3> */}

                          
                                    No Data Found
                            

                              
                            </div>
                        </div>
                        );

                }
            case "Employee":
                switch (action) {
                    case "deactive":
                        return (

                            <div className="edit-employeestatus-container">
                                <div className="payroll-record">
                                    <h3 className="record-title"> Personal info Record</h3>
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                                    <table className="payroll-table">
                                        <thead>
                                            <tr>
                                                <th className="table-header">Field</th>
                                                <th className="table-header">Previous Data</th>
                                                <th className="table-header">New Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="table-cell">Name</td>
                                                <td className="table-cell">{prev_data[0].name}</td>
                                                <td className="table-cell">{new_data[0].name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Email</td>
                                                <td className="table-cell">{prev_data[0].email}</td>
                                                <td className="table-cell">{new_data[0].email}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Phone</td>
                                                <td className="table-cell">{prev_data[0].phone}</td>
                                                <td className="table-cell">{new_data[0].phone}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Address Line 1</td>
                                                <td className="table-cell">{prev_data[0].address_line1}</td>
                                                <td className="table-cell">{new_data[0].address_line1}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Address Line 2</td>
                                                <td className="table-cell">{prev_data[0].address_line2}</td>
                                                <td className="table-cell">{new_data[0].address_line2}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">City</td>
                                                <td className="table-cell">{prev_data[0].city}</td>
                                                <td className="table-cell">{new_data[0].city}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">State</td>
                                                <td className="table-cell">{prev_data[0].city}</td>
                                                <td className="table-cell">{new_data[0].city}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Pincode</td>
                                                <td className="table-cell">{prev_data[0].pincode}</td>
                                                <td className="table-cell">{new_data[0].pincode}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="table-cell">Status</td>
                                                <td className="table-cell" style={{ color: Employeesatus(prev_data[0].user_status).color }}>
                                                    {Employeesatus(prev_data[0].user_status).text}

                                                </td>
                                                <td className="table-cell" style={{ color: Employeesatus(new_data[0].user_status).color }} >
                                                    {Employeesatus(new_data[0].user_status).text}

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );

                    case "edit":
                        switch (table_name) {
                            case "staffinclnt_staff_details":
                                return (
                                    <div className="edit-employeestatus-container">
                                        <span className='uploadexcel-close' onClick={close}>&times;</span>
                                        <div className="logs-overflow">

                                            <div className="payroll-record">
                                                <h3 className="record-title">Edited Basic Details Record</h3>
                                                <p> Name : {get_register_name}</p>

                                                <table className="payroll-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="table-header">Field</th>
                                                            <th className="table-header">New Data</th>
                                                            <th className="table-header">Old Data</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Profile</td>
                                                            <td className="table-cell">{
                                                                prev_data[0].staff_pic
                                                                    ? (
                                                                        <div className="image-view-tablecd">
                                                                            <img src={`${api_backend.BACKEND_API}${prev_data[0].staff_pic}`} alt="img" />

                                                                        </div>
                                                                    ) : (
                                                                        <p className="no-image-found-table">No Image found</p>
                                                                    )
                                                            }

                                                            </td>
                                                            <td className="table-cell">
                                                                {
                                                                    new_data[0].staff_pic
                                                                        ? (
                                                                            <div className="image-view-tablecd">
                                                                                <img src={`${api_backend.BACKEND_API}${new_data[0].staff_pic}`} alt="img" />

                                                                            </div>
                                                                        ) : (
                                                                            <p className="no-image-found-table">No Image found</p>
                                                                        )
                                                                }                                                        </td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Employee Id</td>
                                                            <td className="table-cell"> {prev_data[0].emp_id}</td>
                                                            <td className="table-cell"> {new_data[0].emp_id}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">DOB</td>
                                                            <td className="table-cell"> {prev_data[0].dob}</td>
                                                            <td className="table-cell"> {new_data[0].dob}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">DOJ</td>
                                                            <td className="table-cell"> {prev_data[0].doj}</td>
                                                            <td className="table-cell"> {new_data[0].doj}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Emergency contact</td>
                                                            <td className="table-cell"> {prev_data[0].emergency_contact}</td>
                                                            <td className="table-cell"> {new_data[0].emergency_contact}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Father name</td>
                                                            <td className="table-cell"> {prev_data[0].father_name}</td>
                                                            <td className="table-cell"> {new_data[0].father_name}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Blood Group</td>
                                                            <td className="table-cell"> {prev_data[0].blood_group}</td>
                                                            <td className="table-cell"> {new_data[0].blood_group}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Email</td>
                                                            <td className="table-cell"> {prev_data[0].work_email}</td>
                                                            <td className="table-cell"> {new_data[0].work_email}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Gender</td>
                                                            <td className="table-cell"> {prev_data[0].gender}</td>
                                                            <td className="table-cell"> {new_data[0].gender}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Eligible leave</td>
                                                            <td className="table-cell">  <div className="dataview-dropdown">
                                                                <button className="dropbtn">
                                                                    View
                                                                    <span className="arrow-down"></span> {/* Arrow added here */}
                                                                </button>
                                                                <div className="dataview-dropdown-content">
                                                                    {prev_data[0].eligible_leave_name.map((company) => (
                                                                        <p >{company}</p>
                                                                    ))}
                                                                </div>
                                                            </div></td>
                                                            <td className="table-cell"> <div className="dataview-dropdown">
                                                                <button className="dropbtn">
                                                                    View
                                                                    <span className="arrow-down"></span> {/* Arrow added here */}
                                                                </button>
                                                                <div className="dataview-dropdown-content">
                                                                    {prev_data[0].eligible_leave_name.map((company) => (
                                                                        <p >{company}</p>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            </td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Work From</td>
                                                            <td className="table-cell"> {convertTo12HourFormats(prev_data[0].work_from)}</td>
                                                            <td className="table-cell"> {convertTo12HourFormats(new_data[0].work_from)}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Work TO</td>
                                                            <td className="table-cell"> {convertTo12HourFormats(prev_data[0].work_to)}</td>
                                                            <td className="table-cell"> {convertTo12HourFormats(new_data[0].work_to)}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Permission Hour</td>
                                                            <td className="table-cell"> {convertTo12HourFormats(prev_data[0].permission_hr)}</td>
                                                            <td className="table-cell"> {convertTo12HourFormats(new_data[0].permission_hr)}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Company</td>
                                                            <td className="table-cell"> {prev_data[0].company_name}</td>
                                                            <td className="table-cell"> {new_data[0].company_name}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Designation</td>
                                                            <td className="table-cell"> {prev_data[0].designation_name}</td>
                                                            <td className="table-cell"> {new_data[0].designation_name}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Department</td>
                                                            <td className="table-cell"> {prev_data[0].department_name}</td>
                                                            <td className="table-cell"> {new_data[0].department_name}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Department Head</td>
                                                            <td className="table-cell"> {prev_data[0].depart_head_name}</td>
                                                            <td className="table-cell"> {new_data[0].depart_head_name}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Department Tl</td>
                                                            <td className="table-cell"> {prev_data[0].depart_tl_name}</td>
                                                            <td className="table-cell"> {new_data[0].depart_tl_name}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                )

                            case "staffinclnt_registration":

                                return (
                                    <div className="edit-employeestatus-container">
                                        <div className="payroll-record">
                                            <h3 className="record-title">Edited Personal Details Record</h3>
                                            <span className='uploadexcel-close' onClick={close}>&times;</span>
                                            <table className="payroll-table">
                                                <thead>
                                                    <tr>
                                                        <th className="table-header">Field</th>
                                                        <th className="table-header">New Data</th>
                                                        <th className="table-header">Old Data</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr className="table-row">
                                                        <td className="table-cell">Name</td>
                                                        <td className="table-cell"> {prev_data[0].name}</td>
                                                        <td className="table-cell"> {new_data[0].name}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">User Mr & Ms</td>
                                                        <td className="table-cell"> {prev_data[0].user_mr_ms}</td>
                                                        <td className="table-cell"> {new_data[0].user_mr_ms}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Email</td>
                                                        <td className="table-cell"> {prev_data[0].email}</td>
                                                        <td className="table-cell"> {new_data[0].email}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Phone</td>
                                                        <td className="table-cell"> {prev_data[0].phone}</td>
                                                        <td className="table-cell"> {new_data[0].phone}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Address Line 1</td>
                                                        <td className="table-cell"> {prev_data[0].address_line1}</td>
                                                        <td className="table-cell"> {new_data[0].address_line1}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Address Line 2</td>
                                                        <td className="table-cell"> {prev_data[0].address_line2}</td>
                                                        <td className="table-cell"> {new_data[0].address_line2}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">City</td>
                                                        <td className="table-cell"> {prev_data[0].city}</td>
                                                        <td className="table-cell"> {new_data[0].city}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">State</td>
                                                        <td className="table-cell"> {prev_data[0].state}</td>
                                                        <td className="table-cell"> {new_data[0].state}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Pincode</td>
                                                        <td className="table-cell"> {prev_data[0].pincode}</td>
                                                        <td className="table-cell"> {new_data[0].pincode}</td>

                                                    </tr>

                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                )

                            case "staffinclnt_payment":

                                return (
                                    <div className="edit-payment-container">
                                        <div className="payroll-record">
                                            <h3 className="record-title">Edited Payment Info Record</h3>
                                            <span className='uploadexcel-close' onClick={close}>&times;</span>
                                            <p> Name : {get_register_name}</p>

                                            <table className="payroll-table">
                                                <thead>
                                                    <tr>
                                                        <th className="table-header">Field</th>
                                                        <th className="table-header">New Data</th>
                                                        <th className="table-header">Old Data</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr className="table-row">
                                                        <td className="table-cell">Bank Name</td>
                                                        <td className="table-cell"> {prev_data[0].bank_name}</td>
                                                        <td className="table-cell"> {new_data[0].bank_name}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Account number</td>
                                                        <td className="table-cell"> {prev_data[0].account_num}</td>
                                                        <td className="table-cell"> {new_data[0].account_num}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Pan Number</td>
                                                        <td className="table-cell"> {prev_data[0].pan_num}</td>
                                                        <td className="table-cell"> {new_data[0].pan_num}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Aadhar number</td>
                                                        <td className="table-cell"> {prev_data[0].aadhar_num}</td>
                                                        <td className="table-cell"> {new_data[0].aadhar_num}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">UAN</td>
                                                        <td className="table-cell"> {prev_data[0].UAN}</td>
                                                        <td className="table-cell"> {new_data[0].UAN}</td>

                                                    </tr>



                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                )

                            case "staffinclnt_salary_details":

                                return (
                                    <div className="edit-employeestatus-container">
                                        <span className='uploadexcel-close' onClick={close}>&times;</span>
                                        <div className="logs-overflow">
                                            <div className="payroll-record">
                                                <h3 className="record-title">Edited Salary Record</h3>
                                                <p> Name : {get_register_name}</p>

                                                <table className="payroll-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="table-header">Field</th>
                                                            <th className="table-header">New Data</th>
                                                            <th className="table-header">Old Data</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr className="table-row">
                                                            <td className="table-cell">Annual CTC</td>
                                                            <td className="table-cell"> {prev_data[0].annual_ctc}</td>
                                                            <td className="table-cell"> {new_data[0].annual_ctc}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Monthly salary</td>
                                                            <td className="table-cell"> {prev_data[0].monthly_salary}</td>
                                                            <td className="table-cell"> {new_data[0].monthly_salary}</td>

                                                        </tr>




                                                    </tbody>
                                                </table>
                                                <p>Previous Data (Salary Component):</p>
                                                <table className="payroll-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="table-header">Earnings</th>
                                                            <th className="table-header"></th>
                                                            <th className="table-header">Deduction</th>
                                                            <th className="table-header"></th>

                                                        </tr>
                                                    </thead>    <tbody>

                                                        {
                                                            (() => {
                                                                const selectedSlacomp = prev_data[0].eligible_salary || [];

                                                                const earnings = prev_data[0].salary_list.filter(item => item.earning_deduction === 'earning' && selectedSlacomp.includes(item.id));
                                                                const deductions = prev_data[0].salary_list.filter(item => item.earning_deduction === 'deduction' && selectedSlacomp.includes(item.id));
                                                                const maxLength = Math.max(earnings.length, deductions.length);

                                                                return Array.from({ length: maxLength }).map((_, index) => (
                                                                    <tr className="table-row" key={index}>
                                                                        <td className="td-view-logs">{earnings[index]?.component_name || ''}</td>
                                                                        <td className="td-view-logs" >{earnings[index]?.monthly || ''}</td>
                                                                        <td className="td-view-logs" >{deductions[index]?.component_name || ''}</td>
                                                                        <td className="td-view-logs" >{deductions[index]?.monthly || ''}</td>
                                                                    </tr>
                                                                ));
                                                            })()
                                                        }

                                                    </tbody>
                                                </table>
                                                <p>New Data (Salary Component):</p>
                                                <table className="payroll-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="table-header">Earnings</th>
                                                            <th className="table-header"></th>
                                                            <th className="table-header">Deduction</th>
                                                            <th className="table-header"></th>

                                                        </tr>
                                                    </thead>    <tbody>

                                                        {
                                                            (() => {
                                                                const selectedSlacomp = new_data[0].eligible_salary || [];

                                                                const earnings = new_data[0].salary_list.filter(item => item.earning_deduction === 'earning' && selectedSlacomp.includes(item.id));
                                                                const deductions = new_data[0].salary_list.filter(item => item.earning_deduction === 'deduction' && selectedSlacomp.includes(item.id));
                                                                const maxLength = Math.max(earnings.length, deductions.length);

                                                                return Array.from({ length: maxLength }).map((_, index) => (
                                                                    <tr className="table-row" key={index}>
                                                                        <td className="td-view-logs" >{earnings[index]?.component_name || ''}</td>
                                                                        <td className="td-view-logs" >{earnings[index]?.monthly || ''}</td>
                                                                        <td className="td-view-logs" >{deductions[index]?.component_name || ''}</td>
                                                                        <td className="td-view-logs" >{deductions[index]?.monthly || ''}</td>
                                                                    </tr>
                                                                ));
                                                            })()
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                )
                            default:
                                return (
                                    <div className="nodata_found_log">
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>
        
                                    <div className="nodata_found_logcont" style={{color:"red"}}>
                                        {/* <h3 className="record-title">Deleted  Record</h3> */}
        
                                  
                                            No Data Found
                                    
        
                                      
                                    </div>
                                </div>
                                );

                        }
                    case "add":
                        switch (table_name) {
                            case "staffinclnt_staff_details":
                                return (
                                    <div className="edit-employeestatus-container">
                                        <span className='uploadexcel-close' onClick={close}>&times;</span>
                                        <div className="logs-overflow">
                                            <div className="payroll-record">
                                                <h3 className="record-title"> Basic Details Record</h3>
                                                <p> Name : {get_register_name}</p>

                                                <table className="payroll-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="table-header">Field</th>
                                                            <th className="table-header"> Datas</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Profile</td>

                                                            <td className="table-cell">

                                                                {
                                                                    new_data[0].staff_pic
                                                                        ? (
                                                                            <div className="image-view-tablecd">
                                                                                <img src={`${api_backend.BACKEND_API}${new_data[0].staff_pic}`} alt="img" />

                                                                            </div>
                                                                        ) : (
                                                                            <p className="no-image-found-table">No Image found</p>
                                                                        )
                                                                }
                                                            </td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Employee Id</td>
                                                            <td className="table-cell"> {new_data[0].emp_id}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">DOB</td>
                                                            <td className="table-cell"> {new_data[0].dob}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">DOJ</td>
                                                            <td className="table-cell"> {new_data[0].doj}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Emergency contact</td>
                                                            <td className="table-cell"> {new_data[0].emergency_contact}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Father name</td>
                                                            <td className="table-cell"> {new_data[0].father_name}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Blood Group</td>
                                                            <td className="table-cell"> {new_data[0].blood_group}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Email</td>
                                                            <td className="table-cell"> {new_data[0].work_email}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Gender</td>
                                                            <td className="table-cell"> {new_data[0].gender}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Eligible leave</td>

                                                            <td className="table-cell"> <div className="dataview-dropdown">
                                                                <button className="dropbtn">
                                                                    View
                                                                    <span className="arrow-down"></span> {/* Arrow added here */}
                                                                </button>
                                                                <div className="dataview-dropdown-content">
                                                                    {new_data[0].eligible_leave_name.map((company) => (
                                                                        <p >{company}</p>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            </td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Work From</td>
                                                            <td className="table-cell"> {convertTo12HourFormats(new_data[0].work_from)}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Work TO</td>
                                                            <td className="table-cell"> {convertTo12HourFormats(new_data[0].work_to)}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Permission Hour</td>
                                                            <td className="table-cell"> {convertTo12HourFormats(new_data[0].permission_hr)}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Company</td>
                                                            <td className="table-cell"> {new_data[0].company_name}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Designation</td>
                                                            <td className="table-cell"> {new_data[0].designation_name}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Department</td>
                                                            <td className="table-cell"> {new_data[0].department_name}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Department Head</td>
                                                            <td className="table-cell"> {new_data[0].depart_head_name}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Department Tl</td>
                                                            <td className="table-cell"> {new_data[0].depart_tl_name}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                )

                            case "staffinclnt_registration":

                                return (
                                    <div className="edit-employeestatus-container">
                                        <div className="payroll-record">
                                            <h3 className="record-title"> Personal Details Record</h3>
                                            <span className='uploadexcel-close' onClick={close}>&times;</span>
                                            <table className="payroll-table">
                                                <thead>
                                                    <tr>
                                                        <th className="table-header">Field</th>
                                                        <th className="table-header">Datas</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr className="table-row">
                                                        <td className="table-cell">Name</td>
                                                        <td className="table-cell"> {new_data[0].name}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">User Mr & Ms</td>
                                                        <td className="table-cell"> {new_data[0].user_mr_ms}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Email</td>
                                                        <td className="table-cell"> {new_data[0].personal_email}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Phone</td>
                                                        <td className="table-cell"> {new_data[0].phone}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Address Line 1</td>
                                                        <td className="table-cell"> {new_data[0].address_line1}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Address Line 2</td>
                                                        <td className="table-cell"> {new_data[0].address_line2}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">City</td>
                                                        <td className="table-cell"> {new_data[0].city}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">State</td>
                                                        <td className="table-cell"> {new_data[0].state}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Pincode</td>
                                                        <td className="table-cell"> {new_data[0].pincode}</td>

                                                    </tr>

                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                )

                            case "staffinclnt_payment":

                                return (
                                    <div className="edit-payment-container">
                                        <div className="payroll-record">
                                            <h3 className="record-title"> Payment Info Record</h3>
                                            <span className='uploadexcel-close' onClick={close}>&times;</span>
                                            <p> Name : {get_register_name}</p>

                                            <table className="payroll-table">
                                                <thead>
                                                    <tr>
                                                        <th className="table-header">Field</th>
                                                        <th className="table-header">Datas</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr className="table-row">
                                                        <td className="table-cell">Bank Name</td>
                                                        <td className="table-cell"> {new_data[0].bank_name}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Account number</td>
                                                        <td className="table-cell"> {new_data[0].account_num}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Pan Number</td>
                                                        <td className="table-cell"> {new_data[0].pan_num}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">Aadhar number</td>
                                                        <td className="table-cell"> {new_data[0].aadhar_num}</td>

                                                    </tr>
                                                    <tr className="table-row">
                                                        <td className="table-cell">UAN</td>
                                                        <td className="table-cell"> {new_data[0].UAN}</td>

                                                    </tr>



                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                )

                            case "staffinclnt_salary_details":

                                return (
                                    <div className="edit-employeestatus-container">
                                        <span className='uploadexcel-close' onClick={close}>&times;</span>
                                        <div className="logs-overflow">
                                            <div className="payroll-record">
                                                <h3 className="record-title"> Salary Record</h3>
                                                <p> Name : {get_register_name}</p>

                                                <table className="payroll-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="table-header">Field</th>
                                                            <th className="table-header">Datas</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr className="table-row">
                                                            <td className="table-cell">Annual CTC</td>
                                                            <td className="table-cell"> {new_data[0].annual_ctc}</td>

                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell">Monthly salary</td>
                                                            <td className="table-cell"> {new_data[0].monthly_salary}</td>

                                                        </tr>




                                                    </tbody>
                                                </table>
                                                <p> Data (Salary Component):</p>
                                                <table className="payroll-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="table-header">Earnings</th>
                                                            <th className="table-header"></th>
                                                            <th className="table-header">Deduction</th>
                                                            <th className="table-header"></th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            (() => {
                                                                const selectedSlacomp = new_data[0].eligible_salary || [];

                                                                const earnings = new_data[0].salary_list.filter(item => item.earning_deduction === 'earning' && selectedSlacomp.includes(item.id));
                                                                const deductions = new_data[0].salary_list.filter(item => item.earning_deduction === 'deduction' && selectedSlacomp.includes(item.id));
                                                                const maxLength = Math.max(earnings.length, deductions.length);

                                                                return Array.from({ length: maxLength }).map((_, index) => (
                                                                    <tr className="table-row" key={index}>
                                                                        <td className="td-view-logs">{earnings[index]?.component_name || ''}</td>
                                                                        <td className="td-view-logs" >{earnings[index]?.monthly || ''}</td>
                                                                        <td className="td-view-logs" >{deductions[index]?.component_name || ''}</td>
                                                                        <td className="td-view-logs" >{deductions[index]?.monthly || ''}</td>
                                                                    </tr>
                                                                ));
                                                            })()
                                                        }

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                )
                            default:
                                return (
                                    <div className="nodata_found_log">
                                    <span className='uploadexcel-close' onClick={close}>&times;</span>
        
                                    <div className="nodata_found_logcont" style={{color:"red"}}>
                                        {/* <h3 className="record-title">Deleted  Record</h3> */}
        
                                  
                                            No Data Found
                                    
        
                                      
                                    </div>
                                </div>
                                );

                        }


                    default:
                        return (
                            <div className="nodata_found_log">
                            <span className='uploadexcel-close' onClick={close}>&times;</span>

                            <div className="nodata_found_logcont" style={{color:"red"}}>
                                {/* <h3 className="record-title">Deleted  Record</h3> */}

                          
                                    No Data Found
                            

                              
                            </div>
                        </div>
                        );
                }

            default:
                return (
                    <div className="nodata_found_log">
                    <span className='uploadexcel-close' onClick={close}>&times;</span>

                    <div className="nodata_found_logcont" style={{color:"red"}}>
                        {/* <h3 className="record-title">Deleted  Record</h3> */}

                  
                            No Data Found
                    

                      
                    </div>
                </div>
                );
        }
    };

    return (
        <div className="add-leave-section">
            {viewDatas && viewDatas.map((item) => renderDetails(item))}
        </div>

    );
};

export default ViewLogsDetails;
