import React, { useState, useEffect } from 'react';
import DatePicker from 'react-multi-date-picker';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import './DatePicker.css';

const DateselectComp = ({ setSelectedDates }) => {
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const formattedDates = dates.map(date => new Date(date).toISOString().split('T')[0]);
    setSelectedDates(formattedDates);
  }, [dates, setSelectedDates]);

  const handleDateChange = (selectedDates) => {
    setDates(selectedDates);
  };

  return (
    <DatePicker
      multiple
      value={dates}
      onChange={handleDateChange}
      format="MMMM D, YYYY"
      editable={false}
    />
  );
};

export default DateselectComp;
