import React, { useState,useEffect,useRef  } from 'react';
import { useTimer } from 'react-timer-hook';
import './Registeration.css';
import { toast } from 'react-toastify';
import { Otpverifyregister, Registration } from '../../Config/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../Services/Authentication';
import Register_img from '../../Images/Register/Registerpage';
import Title from '../../JsonData/Tittle';
import { storeUserDetails, storeUserToken } from '../../Services/Storage';
import { Link } from 'react-router-dom';
import { decryptOTP } from '../Decript';
import AuthCode from "react-auth-code-input";

const Registeration = () => {

  const navigate = useNavigate();
  const authCodeRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [split, setsplit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [otpsend, setotpsend] = useState(false);
  const [otp, setOtp] = useState('');
  // const [timer, setTimer] = useState(180);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [resdloader, resdsetloader] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [enterotp, setenterotp] = useState("");
  const [loderstp1, setloaderstp1] = useState(false)

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address_line1: '',
    address_line2: '',
    password: '',
    city: '',
    state: '',
    pincode: '',
    role: "Company",
    user_mr_ms: "Mr",
  });

  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 180); // 3 minutes

  const { seconds, minutes, restart } = useTimer({
      expiryTimestamp,
      onExpire: () => {
          setIsResendDisabled(false);
          setenterotp("");
          setOtpError("OTP expired. Please request a new one.");
      }
  });

  useEffect(() => {
    if (otpsend) {
        restart(new Date(Date.now() + 180 * 1000)); // Restart timer on OTP send
    }
}, [otpsend ,restart]);

  // const startTimer = () => {
  //   setTimer(180);
  //   setIsResendDisabled(true);
  //   const countdown = setInterval(() => {
  //     setTimer((prev) => {
  //       if (prev === 1) {
  //         clearInterval(countdown);
  //         setIsResendDisabled(false);
  //         return 0;
  //       }
  //       return prev - 1;
  //     });
  //   }, 1000);
  // };

//   useEffect(() => {
//     if (otpsend) {
//         const countdown = setInterval(() => {
//             setTimer((prev) => {
//                 if (prev === 1) {
//                     clearInterval(countdown);
//                     setIsResendDisabled(false);
//                     setenterotp("");
//                     setOtpError("OTP expired. Please request a new one.");
//                     return 0;
//                 }
//                 return prev - 1;
//             });
//         }, 1000);

//         return () => clearInterval(countdown);
//     }
// }, [otpsend]);

  // const formatTime = (time) => {
  //   const minutes = Math.floor(time / 60);
  //   const seconds = time % 60;
  //   return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  // }

  const handleInputChange = (e) => {
    setErrors({});
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  };

  const handleOtpChange = (data) => {
    setOtpError("");
    setOtp(data);
    // const { value } = e.target;
    // if (/^[0-9]$/.test(value) || value === "") {
    //   const newOtp = [...otp];
    //   newOtp[index] = value;
    //   setOtp(newOtp);

    //   if (value && index < otp.length - 1) {
    //     document.getElementById(`otp-${index + 1}`).focus();
    //   } else if (value === "" && index > 0 && newOtp[index] === "") {
    //     document.getElementById(`otp-${index - 1}`).focus();
    //   }
    // }
  };

  const handleSubmit = async () => {
    const newErrors = {};
    if (formData.address_line1.trim() === '') {
      newErrors.address_line1 = 'Address Line1 is required';
    } if (formData.address_line2.trim() === '') {
      newErrors.address_line2 = 'Address Line2 is required';
    } if (formData.city.trim() === '') {
      newErrors.city = 'City is required';
    } if (formData.state.trim() === '') {
      newErrors.state = 'State is required';
    }
    if (formData.pincode.trim() === '') {
      newErrors.pincode = 'Pincode is required';
    } else if (!/^\d+$/.test(formData.pincode)) {
      newErrors.pincode = 'Pincode must contain only digits';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
        const response = await Registration(formData);
        storeUserToken(response.token);
        storeUserDetails(response.user_details);
        if (response) {
          toast.success('Signup successful!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          });
          setTimeout(() => {
            navigate('/compdetails');
          }, 1000);
        }
      }
      catch (error) {
        const newErrors = {
          response_error: [],
        };
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors.response_error.push(errorData[key]);
            }
          }
        } else {
          newErrors.response_error.push('An unexpected error occurred.');
        }
        setErrors(newErrors);
        toast.error('Signup failed. Please try again.', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
      }

      finally {
        setLoading(false);
      }
    }
  }

  if (isAuthenticated()) {
    return <Navigate to="/dashboard" />
  }

  const handleprev = () => {
    const newErrors = { ...errors };

    delete newErrors.address_line1;
    delete newErrors.address_line2;
    delete newErrors.city;
    delete newErrors.state;
    delete newErrors.pincode;
    setErrors(newErrors);
    setenterotp("");
    setotpsend(false);
    setsplit(true);
    restart(new Date(Date.now() + 180 * 1000))

  };

  const handleprevotp = () => {
    setenterotp("");
    setotpsend(false);
    setOtp('');

    setOtpError('')

  };

  const handleOtpSubmit = () => {
    if (!otp || otp.length !== 5) {  // Ensure OTP exists and is 5 digits
        setOtpError("Please enter a valid 5-digit OTP.");
        return;
    }

    const decryptedOtp = decryptOTP(enterotp);  // Ensure it's properly formatted

    if (otp === decryptedOtp) {
        setsplit(false);
        setOtp("");  // Clear OTP input
        setOtpError("");
    } else {
        setOtpError("Entered OTP is incorrect.");
    }
}

  // const handleOtpSubmit = () => {
  //   const enteredOtp = otp.trim();  
  //   const decript = decryptOTP(enterotp);
  //   if (enteredOtp.length < 5) {
  //     setOtpError("Please enter a valid 5-digit OTP.");
  //   } else if (enteredOtp === decript) {
  //     setsplit(false);
  //     setOtp('');
  //     setOtpError('')


  //   } else {
  //     setOtpError("Entered OTP is incorrect.");
  //   }
  // };

  const handlekeypress = (e) => {
    const restrictedpattern = /[0-9!@#$%^&*(),.?":{}|<>+_]/;
    if ((e.target.name === 'name' || e.target.name === 'state' || e.target.name === 'city') && restrictedpattern.test(e.key)) {
      e.preventDefault();
    }
  };

  const handlekeynumber = (e) => {
    const restricpattern = /[^0-9]/g;
    if ((e.target.name === 'phone' || e.target.name === 'pincode') && restricpattern.test(e.key)) {
      e.preventDefault();

    }
  };

  const handlenext = async () => {
    const newErrors = { ...errors };

    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.name)) {
      newErrors.name = "Name must contain only alphabetic characters and spaces";
    } else {
      delete newErrors.name;
    }

    if (formData.phone.trim() === "") {
      newErrors.phone = "Phone is required";
    } else if (!/^[6-9]\d{9}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone number";
    } else {
      delete newErrors.phone;
    }
    

    if (formData.email.trim() === "") {
      newErrors.email = "Email is required";
    } else if (!/^[a-zA-Z][\w.-]*@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    } else {
      delete newErrors.email;
    }
    

    if (formData.password.trim() === "") {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      newErrors.password = "The password must contain 8 Characters.";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/.test(formData.password)
    ) {
      newErrors.password =
        "The password must contain digits, uppercase letters, and lowercase letters.";
    } else {
      delete newErrors.password;
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const data = {
        'name': formData.name,
        'email': formData.email,
      }
      try {
        setloaderstp1(true)
        const response = await Otpverifyregister(data)
        if (response) {
          setenterotp(response.register_otp_data.register_otp_code)
          setotpsend(true);
          setOtpError('');

          // startTimer();
          setIsResendDisabled(true);
          restart(new Date(Date.now() + 180 * 1000))
        }
      } catch (error) {
        const errorMessage = error.response?.data?.data?.error || "An unexpected error occurred.";
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
      } finally {
        setloaderstp1(false)
      }
    }
  };


  const handleresendotp = async () => {
    setIsResendDisabled(true);
    setOtpError('');
    restart(new Date(Date.now() + 180 * 1000)); // Restart timer for 3 mins


    const data = {
      'name': formData.name,
      'email': formData.email,
    }
    try {
      setOtp('');
      if (authCodeRef.current) {
        authCodeRef.current.clear();
      }
      resdsetloader(true)
      const response = await Otpverifyregister(data)
      if (response) {

        // startTimer();

        setenterotp(response.register_otp_data.register_otp_code)
      }
    } catch {

    } finally {
      resdsetloader(false)
    }
  };
  return (
    <div className='registeration-container'>
      <div className='registeration-container-sub'>
        <div className='registeration-content'>
          <div className='register-split'>
            <div className='register-img-view'>
              <div className='register-img-view-sub'>
                <img src={Register_img.registerimg} alt='img' />
              </div>
            </div>
          </div>
          <div className='register-split-1'>
            <div className='registeration-content-sub'>
              <div className='registeration-main'>
                <div className='registration-sub'>
                  <div className='registeration-form-fields'>
                    <p>Start Your Journey :)</p>
                    <h2 className='reg-heading'>Sign Up to Staffin</h2>
                    {!otpsend ? (
                      <>
                          <div class="regstepper-container">
        <div class="regstepper">
            <div class="regstep-box">
                <div class="regstep active">1</div>
                <span class="regstep-label">Personal</span>
            </div>
            <div class="regstep-line"></div>
            <div class="regstep-box">
                <div class="regstep">2</div>
                <span class="regstep-label">OTP</span>
            </div>
            <div class="regstep-line"></div>
            <div class="regstep-box">
                <div class="regstep">3</div>
                <span class="regstep-label">Address</span>
            </div>
        </div>
        </div>

                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='first-input'> Name <span className='registeration-mandatory'>*</span></label>
                            <span className='select-input'>
                              <select className='select_mr_mrs' value={formData.user_mr_ms} onChange={handleInputChange} name='user_mr_ms'>
                                {Title.title.map((tittle, index) => (
                                  <option key={index}>
                                    {tittle}

                                  </option>
                                )
                                )
                                };
                              </select>
                              <input id='first-input' type='text' onChange={handleInputChange} onKeyPress={handlekeypress} name='name' value={formData.name} className='registeration-input-field' />
                            </span>
                            {errors.name && (
                              <div className="registeration-error-view">{errors.name}</div>
                            )}
                          </div>
                        </div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='third-input'>Phone <span className='registeration-mandatory'>*</span></label>
                            <input id='third-input' type='text' onChange={handleInputChange} name='phone' value={formData.phone} onKeyPress={handlekeynumber} className='registeration-input-field' />
                            {errors.phone && (
                              <div className="registeration-error-view">{errors.phone}</div>
                            )}
                          </div>
                        </div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='fifth-input'>Email <span className='registeration-mandatory'>*</span></label>
                            <input id='fifth-input' type='text' onChange={handleInputChange} onKeyDown={(e) => {
                              const regex = /^[a-zA-Z0-9@._\b]+$/;
                              if (!regex.test(e.key)) {
                                e.preventDefault();
                              }
                            }} name='email' value={formData.email} className='registeration-input-field' />
                            {errors.email && (
                              <div className="registeration-error-view">{errors.email}</div>
                            )}
                          </div>
                        </div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='seventh-input'>Password <span className='registeration-mandatory'>*</span></label>
                            <div className='registeration-password-container'>
                              <input id='seventh-input' type={showPassword ? "text" : "password"} onChange={handleInputChange} name='password' value={formData.password} className='registeration-input-field' />
                              <FontAwesomeIcon className="registeration-password-icon" icon={showPassword ? faEye : faEyeSlash} onClick={togglePasswordVisibility} />
                            </div>
                            {errors.password && (
                              <div className="registeration-error-view">{errors.password}</div>
                            )}
                          </div>
                        </div>
                        <div className='registeration-nextsplit'>
                          <button className='registeration-button' disabled={loderstp1} onClick={handlenext}>
                            {loderstp1 ? <span className="spinner"></span> : 'Continue'}

                          </button>
                        </div>
                      </>
                    ) :
                      split ? (
                        <>
                         <div class="regstepper-container">
        <div class="regstepper">
            <div class="regstep-box">
                <div class="regstep active">1</div>
                <span class="regstep-label">Personal</span>
            </div>
            <div class="regstep-line active"></div>
            <div class="regstep-box">
                <div class="regstep active">2</div>
                <span class="regstep-label">OTP</span>
            </div>
            <div class="regstep-line"></div>
            <div class="regstep-box">
                <div class="regstep">3</div>
                <span class="regstep-label">Address</span>
            </div>
        </div>
        </div>
                          <div className='registeration-otp-content'>
                            <p>We've sent an OTP to your email. Please enter it below.</p>
                          </div>
                          <div className='registeration-otpinput-view'>
                            <div className='auth-input-fieldview otp-input-box'>
                              {/* {otp.map((digit, index) => (
                                <input
                                  key={index}
                                  type="text"
                                  maxLength="1"
                                  id={`otp-${index}`}
                                  value={digit}
                                  onChange={(e) => handleOtpChange(e, index)}
                                  className="otp-box"
                                />
                              ))} */}

                              <AuthCode
                              onChange={handleOtpChange}
                              allowedCharacters="numeric"
                              length={5}  // Change as needed
                              ref={authCodeRef}
          containerClassName="otp-box"
                inputClassName="auth-input"

                              />
                            </div>
                          </div>
                          <div className='resend-otp-cont'>
                            {isResendDisabled ?
                              // <p style={{ color: 'cornflowerblue' }}>
                              //   Resend ({formatTime(timer)})
                              // </p>
                              <p style={{ color: 'darkorange' ,fontSize:"14px"}}> Resend OTP in {minutes}:{String(seconds).padStart(2, '0')}</p>

                              : <p style={{
                                color: 'cornflowerblue',
                                cursor: resdloader ? "default" : "pointer",
                                pointerEvents: resdloader ? "none" : "auto"
                              }}
                                onClick={handleresendotp}
                              >
                                {resdloader ? (
                                  <span className="spinner"></span>
                                ) : (
                                  'Resend'
                                )}
                              </p>}
                          </div>
                          {otpError && (
                            <div className="registeration-error-view" style={{ textAlign: "center" }}>{otpError}</div>
                          )}
                          <div className='registeration-otp-buttons'>
                            <button onClick={handleprevotp}>
                              Back
                            </button>
                            <button onClick={handleOtpSubmit}>
                              Verify
                            </button>
                          </div>
                        </>)
                        : (
                          <div>
                            <div style={{marginBottom:"8px"}} class="regstepper-container">
        <div class="regstepper">
            <div class="regstep-box">
                <div class="regstep active">1</div>
                <span class="regstep-label">Personal</span>
            </div>
            <div class="regstep-line active"></div>
            <div class="regstep-boxcenter">
                <div class="regstep active">2</div>
                <span class="regstep-label">OTP</span>
            </div>
            <div class="regstep-line active"></div>
            <div class="regstep-box">
                <div class="regstep active">3</div>
                <span class="regstep-label">Address</span>
            </div>
        </div>
        </div>
  
        
                            <div className='registeration-input-row'>
                              <div className='registeration-input-group'>
                                <label htmlFor='second-input'>Address Line 1 <span className='registeration-mandatory'>*</span></label>
                                <input id='second-input' type='text' onChange={handleInputChange} name='address_line1' value={formData.address_line1} className='registeration-input-field input-cls-full' />
                                {errors.address_line1 && (
                                  <div className="registeration-error-view">{errors.address_line1}</div>
                                )}
                              </div>
                            </div>
                            <div className='registeration-input-row'>
                              <div className='registeration-input-group'>
                                <label htmlFor='fourth-input'>Address Line 2 <span className='registeration-mandatory'>*</span></label>
                                <input id='fourth-input' type='text' onChange={handleInputChange} name='address_line2' value={formData.address_line2} className='registeration-input-field' />
                                {errors.address_line2 && (
                                  <div className="registeration-error-view">{errors.address_line2}</div>
                                )}
                              </div>
                            </div>
                            <div className='registeration-input-row'>
                              <div className='registeration-input-group'>
                                <label htmlFor='sixth-input'>City <span className='registeration-mandatory'>*</span></label>
                                <input id='sixth-input' type='text' onKeyPress={handlekeypress} onChange={handleInputChange} name='city' value={formData.city} className='registeration-input-field' />
                                {errors.city && (
                                  <div className="registeration-error-view">{errors.city}</div>
                                )}
                              </div>
                            </div>
                            <div className='registeration-input-row'>
                              <div className='registeration-input-group'>
                                <label htmlFor='eighth-input'>State <span className='registeration-mandatory'>*</span></label>
                                <input id='eighth-input' type='text' onKeyPress={handlekeypress} onChange={handleInputChange} name='state' value={formData.state} className='registeration-input-field' />
                                {errors.state && (
                                  <div className="registeration-error-view">{errors.state}</div>
                                )}
                              </div>
                              <div className='registeration-input-group'>
                                <label htmlFor='tenth-input'>Pincode <span className='registeration-mandatory'>*</span></label>
                                <input id='tenth-input' onKeyPress={handlekeynumber} type='text' onChange={handleInputChange} name='pincode' value={formData.pincode} className='registeration-input-field' />
                                {errors.pincode && (
                                  <div className="registeration-error-view">{errors.pincode}</div>
                                )}
                              </div>

                            </div>
                            <div >
                              {errors.response_error && (
                                <div className="registeration-error-view">{errors.response_error}</div>
                              )}
                              <div className='registeration-button-div'>

                                <button onClick={handleprev} className='registeration-button'>
                                  Prev
                                </button>

                                <button className='registeration-button' onClick={handleSubmit} disabled={loading}>
                                  {loading ? <span className="spinner"></span> : 'Register'}
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                    }
                    <div className=''>
                      <p className='reg-signup'>Have a Staffin account?
                        <Link to="/login">
                       Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registeration;
