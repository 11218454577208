import React, { useState, useEffect, useRef } from 'react'
import './UserandRoles.css'
import { adduser, ViewCompanyList, ViewRolesselect } from '../../../Config/api';
import { getUserDetails } from '../../../Services/Storage';
import { CiSearch } from 'react-icons/ci';
import Header from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';
import { toast } from 'react-toastify';
import { getRegisterId } from '../../../Config/userId';

const Adduser = () => {

  const register_id = getRegisterId();
  const user_details = getUserDetails();
  const [companyOpen, setcompnayOpen] = useState(false);
  const [RoleOpen, setRoleOpen] = useState(false);
  const [search, setSearchword] = useState("");
  const [rolefilter, setrolefilter] = useState("");
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const dropdownRef = useRef(null);
  const leaveTypeDropdownRef = useRef(null);
  const [loader, setloader] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [Roledata, setRoledata] = useState([]);
  const [errors, setErrors] = useState({});
  const [addusr, setaddusr] = useState({
    name: "",
    email: "",
    role: "",
    user: register_id,
  });

  const [roles, setroles] = useState({
    employee: { all: false, view: false, add: false, edit: false, active_deactive: false },
    leave: { all: false, view: false, add: false, leave_status: false, delete: false },
    payroll: { all: false, view: false, calculate_salary: false, approvel: false, delete: false },
    holiday: { all: false, view: false, add: false, delete: false },
    leave_component: { all: false, view: false, add: false, edit: false, delete: false },
    salary_component: { all: false, view: false, add: false, edit: false, delete: false },
    attendance: { all: false, view: false, add: false, edit: false, delete: false },
    department: { all: false, view: false, add: false, edit: false, delete: false },
    designation: { all: false, view: false, add: false, edit: false, delete: false },
    department_tl: { all: false, view: false, add: false, edit: false, delete: false },
    department_head: { all: false, view: false, add: false, edit: false, delete: false },
  });


  useEffect(() => {
    const fetchCompanyData = async () => {
      const data = await ViewCompanyList(`/?register_id=${register_id}`);
      setCompanyData(data.data.get_company);
    };
    const fetchRoleData = async () => {
      try {
        const data = await ViewRolesselect(`?user_id=${user_details.id}`);
        setRoledata(data.data.get_role);

      } catch (error) {

      }
    };
    fetchCompanyData();
    fetchRoleData();
  }, [user_details.id, register_id]);



  const handlecompanyClick = () => {
    setcompnayOpen((prevState) => !prevState);
    if (!companyOpen) {
      setRoleOpen(false);
    }
  };

  const handleRoleClick = () => {
    setRoleOpen((prevState) => !prevState);
    if (!RoleOpen) {
      setcompnayOpen(false);
    }
  };

  const formatFieldName = (fieldName) => {
    return fieldName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };


  const handleCheckboxChange = (category, field) => {
    setroles((prev) => {
      const updatedCategory = { ...prev[category], [field]: !prev[category][field] };

      if (field === "all") {
        const allFields = Object.keys(updatedCategory).reduce((acc, key) => {
          acc[key] = !prev[category][field];
          return acc;
        }, {});
        return { ...prev, [category]: allFields };
      } else {
        let updatedFields = { ...updatedCategory };
        if (field === "view" && !updatedCategory.view) {
          const otherPermissionsChecked = Object.keys(updatedCategory)
            .filter((key) => key !== "view" && key !== "all")
            .some((key) => updatedCategory[key]);

          if (otherPermissionsChecked) {
            return prev;
          }
        }

        if (field !== "view" && updatedCategory[field]) {
          updatedFields.view = true;
        }

        updatedFields.all = Object.keys(updatedFields)
          .filter((key) => key !== "all")
          .every((key) => updatedFields[key]);

        return { ...prev, [category]: updatedFields };
      }
    });
  };

  const inputRefs = {
    name: useRef(null),
    email: useRef(null),
    role: useRef(null),
    company: useRef(null),
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check for company dropdown
      if (
        companyOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !inputRefs.company.current.contains(event.target)
      ) {
        setTimeout(() => setcompnayOpen(false), 100);
      }

      // Check for role dropdown
      if (
        RoleOpen &&
        leaveTypeDropdownRef.current &&
        !leaveTypeDropdownRef.current.contains(event.target)
      ) {
        setTimeout(() => setRoleOpen(false), 100);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [companyOpen, RoleOpen, inputRefs.company]); // Add dependencies to avoid stale closures

  const handleSearch = (event) => {
    setSearchword(event.target.value);
  };



  const handleselectedcompany = (comp) => {
    setErrors({});
    setaddusr((prev) => ({
      ...prev,
      company: comp.id
    }))
    setSelectedCompanies((prevSelected) => {
      if (prevSelected.some((item) => item.id === comp.id)) {
        return prevSelected.filter((item) => item.id !== comp.id);
      }
      return [...prevSelected, comp];
    });
  };

  const handleroleselected = (comp) => {
    setrolefilter(comp.role);
    setaddusr((prev) => ({
      ...prev,
      role: comp.id,
    }));
    setRoleOpen(false);
  };

  const handleinputchange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setaddusr((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isSelected = (company) =>
    selectedCompanies.some((item) => item.id === company.id);

  const handleSubmit = async () => {
    const selectedvalue = selectedCompanies.map((selected) => selected.id);
    let newErrors = {};

    const selectedFields = Object.keys(roles).reduce((acc, category) => {
      if (roles[category].all) {
        acc[category] = ["all"];
      } else {
        acc[category] = Object.keys(roles[category])
          .filter((field) => roles[category][field] && field !== "all");
      }
      return acc;
    }, {});
    const data = {
      ...addusr,
      access_company: selectedvalue,
      access_category: selectedFields,
    }

    const isAnyCheckboxSelected = Object.keys(roles).some((category) =>
      Object.values(roles[category]).some((value) => value)
    );
    if (!isAnyCheckboxSelected) {
      newErrors.access = "At least one access checkbox must be selected.";
    }

    if (addusr.name.trim() === '') {
      newErrors.name = "Name is Required";
      inputRefs.name.current.focus();

    }
    if (addusr.email.trim() === '') {
      newErrors.email = "Email is Required";
      inputRefs.email.current.focus();

    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(addusr.email.trim())) {
      newErrors.email = "Invalid email format";
      inputRefs.email.current.focus();
    }
    if (addusr.role === "") {
      newErrors.role = "Roles is Required";
      inputRefs.role.current.focus();

    }
    if (selectedvalue.length === 0) {
      newErrors.company = "Select Company"
      inputRefs.company.current.focus();

    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {

        setloader(true);

        const response = await adduser(data);
        if (response) {
          toast.success('Added successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
          setroles({
            employee: { all: false, view: false, add: false, edit: false, active_deactive: false },
            leave: { all: false, view: false, add: false, leave_status: false, delete: false },
            attendance: { all: false, view: false, add: false, edit: false, delete: false },
            payroll: { all: false, view: false, calculate_salary: false, approvel: false, delete: false },
            salary_component: { all: false, view: false, add: false, edit: false, delete: false },
            leave_component: { all: false, view: false, add: false, edit: false, delete: false },
            holiday: { all: false, view: false, add: false, delete: false },
            department: { all: false, view: false, add: false, edit: false, delete: false },
            designation: { all: false, view: false, add: false, edit: false, delete: false },
            department_tl: { all: false, view: false, add: false, edit: false, delete: false },
            department_head: { all: false, view: false, add: false, edit: false, delete: false },
          });
          setaddusr({ name: "", email: "", role: "", user: register_id });
          setSelectedCompanies([]);
          setrolefilter("")
        }
      } catch (error) {
        const newErrors = {
          response_error: [],
        };

        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors.response_error.push(errorData[key]);
            }
          }
        } else {
          newErrors.response_error.push('An unexpected error occurred.');
        }

        setErrors(newErrors);

      } finally {
        setloader(false);

      }
    }

  }
  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>

            <div className='index-main'>
              <div className='addemp-subheader'>
                <div className='addemp-subheader-sub'>
                  <div className='addemp-subheader1'>
                    <p>Add User</p>
                  </div>
                </div>
              </div>
              <div className='addemp-line'></div>
              <br />

              <div className='addusrndrl_input_cont'>
                <p className='pages-notes'><span className='emppersonalinfo-mandatory'>*</span> Note : A password will be sent to your registered email ID upon entering it.</p>

                <div className='addusr_input_field'>
                  <div className='addusr_input_field-sub'>
                    <label >Name: </label>
                    <input placeholder='Enter Name....' onChange={(e) => {
                      const value = e.target.value;
                      if (/^[a-zA-Z\s]*$/.test(value)) {
                        handleinputchange(e);
                      }
                    }} value={addusr.name} ref={inputRefs.name} name='name' />
                    {errors.name && (<div className='error-message-view'>{errors.name}</div>)}

                  </div>


                </div>
                <div className='addusr_input_field'>
                  <div className='addusr_input_field-sub'>
                    <label >Email: </label>
                    <input placeholder='Enter Email....'
                      onKeyDown={(e) => {
                        const regex = /^[a-zA-Z0-9@._\b]+$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      ref={inputRefs.email} value={addusr.email} onChange={handleinputchange} name='email' />
                    {errors.email && (<div className='error-message-view'>{errors.email}</div>)}

                  </div>


                </div>


                <div>
                  <div className='select_input_field'>

                    <div className='select_input_field_sub'>


                      <label>Role</label>
                      <div ref={leaveTypeDropdownRef} className={`invoice-grid-div invoice-span-div invoice-sales-div `}>
                        <div onClick={handleRoleClick}>
                          <input
                            ref={inputRefs.role}
                            type='text'
                            placeholder='Select Role'
                            onChange={handleinputchange}
                            name='company'

                            className='addusrndrl-input-field3'
                            value={rolefilter}

                            readOnly
                          />
                        </div>
                        {RoleOpen && (
                          <div className='invoice-sales-grid-div-dropdown'>
                            <div className='invoice-dropdown-search'>
                              <CiSearch className="las la-search" />
                              <input
                                type="text"
                                placeholder='Search Role'
                                name="search"
                                onChange={handleSearch}
                              />
                            </div>
                            <ul>
                              {Roledata
                                .filter(roles =>
                                  roles.role && roles.role.toLowerCase().includes(search.toLowerCase())
                                )
                                .map(roles => (
                                  <li
                                    key={roles.id}
                                    className='sal_block'
                                    onClick={() => handleroleselected(roles)}
                                  >
                                    {roles.role}
                                  </li>
                                ))}
                              {Roledata.length === 0 && (
                                <li className='no-data'>No data found</li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    {errors.role && (<div className='error-message-view'>{errors.role}</div>)}

                    <p className='pages-notes'><span className='emppersonalinfo-mandatory'>*</span> Note : The selected company will have access to the role you choose.</p>


                  </div>

                  <div className='select_input_field'>

                    <div className="select_input_field_sub">
                      <label>Company </label>
                      <div
                        className={`invoice-grid-div invoice-span-div invoice-sales-div`}
                      >
                        <div onClick={handlecompanyClick}>
                          <input
                            type="text"
                            placeholder="Select Company"
                            className="addusrndrl-input-field3"
                            value={selectedCompanies.map((c) => c.company_name).join(", ")}
                            readOnly
                            ref={inputRefs.company}
                          />
                        </div>
                        {companyOpen && (
                          <div className="invoice-sales-grid-div-dropdown" ref={dropdownRef}>
                            <div className="invoice-dropdown-search">
                              <CiSearch className="las la-search" />
                              <input
                                type="text"
                                placeholder="Search Company"
                                name="search"
                                onChange={handleSearch}
                              />
                            </div>
                            <ul>
                              {companyData
                                .filter((company) =>
                                  company.company_name.toLowerCase().includes(search.toLowerCase())
                                )
                                .map((company) => (
                                  <li
                                    key={company.id}
                                    className={`sal_block ${isSelected(company) ? "selected" : ""
                                      }`}
                                    onClick={() => handleselectedcompany(company)}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={isSelected(company)}
                                      readOnly
                                    />
                                    {company.company_name}
                                  </li>
                                ))}
                              {companyData.length === 0 && (
                                <li className="no-data">No data found</li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    {errors.company && (<div className='error-message-view'>{errors.company}</div>)}



                  </div>
                </div>



                <p>Access Category:</p>
                <table className='addusrndrl_input_table'>



                  {Object.keys(roles).map((category) => (
                    <tr key={category}>
                      <td>
                        <h5>{formatFieldName(category)}:</h5>
                      </td>
                      {Object.keys(roles[category]).map((field) => (
                        <td key={field}>
                          <p>{formatFieldName(field)}</p>
                          <input
                            type="checkbox"
                            checked={roles[category][field]}
                            onChange={() => handleCheckboxChange(category, field)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}

                </table>

                <br />
                {errors.access && <span className="error-message-view">{errors.access}</span>}
                <div className='userndrole-errorview'>
                  {errors.response_error && (<div className='error-message-view'>{errors.response_error}</div>)}
                </div>
                <div className='addusrndrl-button'>
                  <button className='addusrndrl_open_button' onClick={handleSubmit} disabled={loader}>

                    {loader ? <span className='spinner'></span> : 'Submit'}
                  </button>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Adduser