import React, { useState, useEffect, useRef } from 'react'
import { AddDepartTlApi, viewSelectedEmpdept } from '../../../Config/api';
import {  getUserDetails } from '../../../Services/Storage';
import { CiSearch } from 'react-icons/ci';
import { toast } from 'react-toastify';
import { Store } from '../../../Store';
import { addDepartmentTl } from '../../../Slice/DepartmentTlSlice';
import { getRegisterId } from '../../../Config/userId';

const AddDepTLBasic = ({ show, close, sendDataToParent,data }) => {
  const register_id = getRegisterId();
  const userDetails = getUserDetails();
  const [searchOpen, setSearchOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const dropdownRef = useRef(null);
  const leaveTypeDropdownRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [employeename, setEmployeeName] = useState('');
  const [loader, setloader] = useState(false)
  const [search, setSearchword] = useState("");
  const [departmentName ,setDepartmentName] = useState('');
  const [companyName ,setCompanyName] = useState("");
  const [isLoadingEmployee, setIsLoadingEmployee] = useState(false);
  
  const [adddeptl, setdeptl] = useState({
    depart_name: '',
    depart_tl: '',
    user: register_id,
    edited_by:userDetails.id,
    company: '',
  });

  const closepopup =()=>{
    close();
    setErrors({});
    setdeptl({
      depart_name: '',
      depart_tl: '',
      user: register_id,
      edited_by:userDetails.id,
      company: '',
    });
    setEmployeeName("")
  }
  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
  };

    useEffect(() => {
    if(show &&data){
     console.log(data) 
     setdeptl((prevData) => ({
      ...prevData,
     depart_name:data.department.id,
     company:data.company.company_id,
    }));
    
  
     const fetchDepartmentComp = async () => {
        setIsLoadingEmployee(true);

      try {
        const response = await viewSelectedEmpdept(`?register_id=${register_id}&company_id=${data.company.id}&depart_id=${data.department.id}&search=${search}`)
        if (response) {
          setEmployees(response.data.emp_name_id)
          // setdephdfetch(response.data.get_depart_head)
        }
      } catch {
  
      }finally{
        setIsLoadingEmployee(false);

      }
    };
    fetchDepartmentComp();
    setDepartmentName(data.company.company_name);
    setCompanyName(data.department.depart_name)
    }
    
    }, [data,register_id,search,show]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setSearchOpen(false);
      }
      if (
        leaveTypeDropdownRef.current &&
        !leaveTypeDropdownRef.current.contains(event.target)
      ) {
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearch = (event) => {
    setSearchword(event.target.value);
  };

  const handleSelectEmployee = (employee) => {
    setSearchword('');
    setSearchOpen(false);
    setdeptl((prev) => ({
      ...prev,
      depart_tl: employee.register_id,

    }));
    setEmployeeName(employee.name);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!adddeptl.company) {
      formErrors.company = 'Department is required';
    }
    if (!adddeptl.depart_name) {
      formErrors.depart_name = 'Department is required';
    }
    if (!adddeptl.depart_tl) {
      formErrors.depart_tl = 'Employee is required';
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  
  const handleInputChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setdeptl((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  



  // const fetchEmployeeList = useCallback(async () => {
  //   try {
  //     const response = await FetchEmployee(`?register_id=${register_id}&search=${search}&access_id=${accessid}`);
  //     setEmployees(response.data.emp_name_id);

  //   } catch (error) {
  //   }
  // }, [search ,register_id,accessid]);

  // useEffect(() => {
  //   fetchEmployeeList();
  // }, [fetchEmployeeList]);



  const handlesubmit = async () => {
    if (validateForm()) {
      try {
        setloader(true)
        const response = await (AddDepartTlApi(adddeptl));
        if (response) {
          sendDataToParent(response.data.get_depart_tl[0]);
          Store.dispatch(addDepartmentTl(response.data.get_depart_tl[0]));
          close();
          toast.success('Department Tl Added Sucessfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          })
          setdeptl({
            depart_name: '',
            depart_tl: '',
            user: register_id,
            edited_by:userDetails.id,
            company: '',
          })
          setEmployeeName('');
        }
      } catch (error) {
        const newErrors = {
          response_error: [],
        };
        for (const key in error.response.data.data) {
          if (error.response.data.data.hasOwnProperty(key)) {
            newErrors.response_error.push(`${error.response.data.data[key]}`);
          }
        }
        toast.error("Add Department Failed. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
        setErrors(newErrors);
      } finally {
        setloader(false)
      }
    }
  };


  if (!show) return null

  return (
    <div className='popup-section'>
      <div className='popup-container-adddepdes'>
        <span className='uploadexcel-close' onClick={closepopup}>&times;</span>

        <div className='popup-container-sub'>
          <div className='addleave-heading'>
            <p>Add Department TL</p>

          </div>

          <div className='addleave-strightline'></div>
          <div className='popup-content-viewing-adddepdes'>
          <div className='popup-inputfield' style={{width:"94%"}}>

<div className='popup-inputfield-sub'>
              <label> Company<span className='registeration-mandatory'> *</span> </label>
              <input
                className=''
                type='text'
                value={departmentName}
                disabled
              //   value={editdepcomp.depart_name}
              />
                  </div>
          <div className='popup-inputfield-sub'>
            <label>Department<span className='registeration-mandatory'> *</span> </label>
            <input
                className=''
                type='text'
                value={companyName}
                disabled
              //   value={editdepcomp.depart_name}
              />
            {errors.depart_name && <span className='error-message-view'>{errors.depart_name}</span>}
          </div>
          </div>
            
            <div className='popup-input-hdtl'>
              <label >Employee<span className='registeration-mandatory'> *</span> </label>
              {/* <Select  options={employeeData} className='popup-input-hdtl2'placeholder="Select Employee" /> */}
              {/* 
        <select className='dephdtl-select'>
        <option value="">Select Employee</option>

          {employeeData.map((emplist)=>(
            <option value={emplist.value} key={emplist.value}>{emplist.label}</option>
          ))

          }

        </select> */}
              <div ref={dropdownRef} className={`invoice-grid-div invoice-span-div invoice-sales-div ${searchOpen ? "active" : ""}`}>
                <div style={{marginTop:"10px"}} onClick={handleSearchClick} className='popup-input-hdt-select'>
                  <input
                    type='text'
                    placeholder='Select Employee'
                    onChange={handleInputChange}
                    // name='empid'
                    value={employeename}
                    readOnly
                    disabled={!adddeptl.depart_name}  // Disable until department is selected

                  />
                </div>
                {searchOpen && (
                  <div className='invoice-sales-grid-div-dropdown'>
                    <div className='invoice-dropdown-search'>
                      <CiSearch className="las la-search" />
                      <input
                        type="text"
                        placeholder='Search Employees'
                        name="search"
                        onChange={handleSearch}
                      />
                    </div>
                    {isLoadingEmployee ?(
                                          <ul style={{textAlign:"center",display:"flex",justifyContent:"center" ,height:"30px"}}>
                                          <span className='loader-pages-tablefield'></span>

                                          </ul>
                                        ):(
                    <ul>
                      {
                      employees
                        .filter(employee =>
                          employee.name && employee.name.toLowerCase().includes(search.toLowerCase())
                        )
                        .map(employee => (
                          <li
                            key={employee.id}
                            className='sal_block'
                            onClick={() => handleSelectEmployee(employee)}
                          >
                            {employee.name}
                          </li>
                        ))}
                      {employees.length === 0 && (
                        <li className='no-data'>No data found</li>
                      )}
                    </ul>
                                        )}
                  </div>
                )}
                {errors.depart_tl && <span className='error-message-view'>{errors.depart_tl}</span>}

              </div>
              {errors.response_error && (
                <div className="registeration-error-view">{errors.response_error}</div>
              )}
            </div>


            <div className='popup-button'>
              <button onClick={handlesubmit} disabled={loader}>{loader ? <span className='spinner'></span> : "Submit"}</button>

            </div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default AddDepTLBasic