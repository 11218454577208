import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import './CalendarDashboard.css';

const CalendarDashboard = () => {

    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const getDaysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
    const getFirstDayOfMonth = (month, year) => new Date(year, month, 1).getDay();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const today = new Date();
    const daysInMonth = getDaysInMonth(month, year);
    const firstDay = getFirstDayOfMonth(month, year);

    const handlePrevMonth = () => {
        setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
    };

    const handleDateClick = (day) => {
        setSelectedDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), day));
    };

    const calendarDays = [];
    for (let i = 0; i < firstDay; i++) {
        calendarDays.push(null);
    }
    for (let day = 1; day <= daysInMonth; day++) {
        calendarDays.push(day);
    }
    while (calendarDays.length < 42) {
        calendarDays.push(null);
    }

    return (
        <div className="custom-calendar">
            <div className="header">
                <button onClick={handlePrevMonth}>&lt;</button>
                <div>
                    {currentDate.toLocaleString('default', { month: 'long' })} {year}
                </div>
                <button onClick={handleNextMonth}>&gt;</button>
            </div>
            <div className="days-of-week">
                {daysOfWeek.map((day) => (
                    <div key={day} className="day-label">
                        {day}
                    </div>
                ))}
            </div>
            <div className="calendar-grid">
                {calendarDays.map((day, index) => {
                    const isSunday = index % 7 === 0;
                    const isSaturday = (index + 1) % 7 === 0;

                    return (
                        <div
                            key={index}
                            className={`day ${day ? '' : 'empty'} ${selectedDate?.getDate() === day &&
                                    selectedDate?.getMonth() === month &&
                                    selectedDate?.getFullYear() === year
                                    ? 'selected'
                                    : ''
                                } ${day === today.getDate() &&
                                    month === today.getMonth() &&
                                    year === today.getFullYear()
                                    ? 'highlight'
                                    : ''
                                } ${isSunday || isSaturday ? 'weekend' : ''}`}
                            onClick={day ? () => handleDateClick(day) : null}
                        >
                            {day}
                        </div>
                    );
                })}
            </div>

        </div>
    );
};

export default CalendarDashboard;
