import React, { useState, useEffect } from 'react'
import { getUserDetails } from '../../../Services/Storage';
import { UpdateDepartcomp } from '../../../Config/api';
import { toast } from 'react-toastify';
import { updatedepartcomp } from '../../../Slice/departmentSlice'
import { Store } from '../../../Store';
import { getRegisterId } from '../../../Config/userId';
import { CompanyApi } from '../../../Config/CommonApi';
import { MultiSelect } from 'react-multi-select-component';
import Deleteconfirmation from '../../Component/Deleteconfirmation';

const Editdep = ({ show, close, editData, updateData }) => {

    const register_id = getRegisterId();
    const userDetails = getUserDetails();
    const [errors, setErrors] = useState({});
    const [loader, setloader] = useState(false);
    const [companyData, setCompanyData] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [opendeleteconf, setdeleteconf] = useState(false)
    const [deleteComponentDataUser, setDeleteComponentDataUser] = useState([]); 

    const [editdepcomp, setdepcom] = useState({
        depart_name: '',
        user: register_id,
        edited_by: userDetails.id,
        company: '',
    });

    const handleInputchange = (event) => {
        const { name, value } = event.target;
        setdepcom((prev) => ({
            ...prev,
            [name]: value,
            ...(name === "company" && { company: value })

        }))
    };

    const options = companyData
    .filter((company) => company.value !== 'all')
    .map((company) => ({
      label: company.label,
      value: company.value,
    }));
    
    const handleChange = (selected) => {
        setdepcom((prev) => ({
          ...prev,
          company: selected.map((company) => company.value), // Store only values
        }));
        setSelectedCompanies(selected);
      };

    const closepop=()=>{
        close();
        setErrors({});
    }

    const closeDeleteConfirmUser =()=>{
        setdeleteconf(false);
    }

    const openDeleteConfirmUser =()=>{
        setdeleteconf(true);
    }

        useEffect(() => {
            if(show){

          const fetchCompanyData = async () => {
            const data = await CompanyApi();
    
            const filteredOption = data.filter(data =>data.value !== "all");
            setCompanyData(filteredOption);
            // setCompanyFilter(data[0]);
          };
        
          fetchCompanyData();
        }
        }, [register_id ,show]);

    const handlesubmit = async () => {
      
    const newErrors = {};

    if (!editdepcomp.depart_name.trim()) {
      newErrors.depart_name = "Department Name is required";
    }
  
    if (editdepcomp.company.length === 0) {
      newErrors.company = "At least one company must be selected";
    }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {


            try {
                setloader(true);
                const response = await (UpdateDepartcomp(editData.id, editdepcomp));
                if (response) {
                    updateData(response.updated_department);
                    Store.dispatch(updatedepartcomp(response.updated_department));
                    close();
                    toast.success('Department Updated Sucessfully!', {
                        position: "top-center",
                        autoClose: 2000,
                        closeButton: false,
                        className: 'custom_toast'
                    })
                } else {

                }
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    openDeleteConfirmUser();
                    setDeleteComponentDataUser(error.response.data);
                }else{
                    const errorMessage = error.response && error.response.data && error.response.data.data
                    ? error.response.data.data.depart_name
                    : "Update Department Failed. Please try again.";

                setErrors((prevErrors) => ({
                    ...prevErrors,
                    depart_name: errorMessage,
                }));

                toast.error("Update Department Failed. Please try again.", {
                    position: "top-center",
                    autoClose: 2000,
                    closeButton: false,
                });
                }

          
            }
            finally {
                setloader(false);
            }
        }
    }

    useEffect(() => {

        if (editData) {
            console.log(editData.company_details)
            setdepcom({
                depart_name: editData.depart_name,
                user: register_id,
                edited_by: userDetails.id,
                company:editData.company,
            });
            const companyData = editData?.company_details?.map(company => ({
                value: company.id,
                label: company.company_name,
            })) || [];
            
                
            console.log(companyData,"gshdghsgdh")
             setSelectedCompanies(companyData);
        }
    }, [editData, userDetails.id, register_id])

    if (!show) return null;

    return (
        <div className='popup-section'>
            <div className='popup-container'>
                <span className='uploadexcel-close' onClick={closepop}>&times;</span>

                <div className='popup-container-sub'>
                    <div className='addleave-heading'>
                        <p>Edit Department</p>

                    </div>

                    <div className='addleave-strightline'></div>
                    <div className='popup-content-viewing'>
                        <div className='popup-inputfield'>
                        <div className='popup-inputfield-sub'>
                <label> Company<span className='registeration-mandatory'> *</span> </label>
               
                <MultiSelect
                  options={options}
                  value={selectedCompanies}
                  onChange={handleChange}
                  overrideStrings={{
                    "allItemsAreSelected": "All company selected",
                    "selectSomeItems": "Select Company",
                  }}                 />
                {errors.company && (<div className='error-message-view'>{errors.company}</div>)}        
                    </div>
                            <div className='popup-inputfield-sub'>
                                <label> Department<span className='registeration-mandatory'> *</span> </label>
                                <input
                                    className=''
                                    type='text'
                                    name='depart_name'
                                    onChange={handleInputchange}
                                    value={editdepcomp.depart_name}
                                />
                                {errors.depart_name && (<div className='error-message-view'>{errors.depart_name}</div>)}
                            </div>
                        </div>
                        {
                            errors.response_error && (
                                <div className='error-message-view salry-comp-err'>
                                    {errors.response_error.component}
                                </div>
                            )
                        }
                        <div className='popup-button'>
                            <button onClick={handlesubmit} disabled={loader}>{loader ? <span className='spinner'></span> : "Submit"}</button>

                        </div>
                    </div>
                </div>

            </div>
            <Deleteconfirmation
                show={opendeleteconf}
                close={closeDeleteConfirmUser}
                data={deleteComponentDataUser}
            />
        </div>

    )
}

export default Editdep