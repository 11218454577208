import React from 'react'
import Action from '../../Images/Action/action';

const SalaryComponetAlert = ({ data, show, close,updated }) => {
    
    const handleYes =()=>{
        updated("yes");
        close();
    };

    const handleNo =()=>{
        updated("no");
        close();
    };

    if (!show) return null;
    return (
        <div className="modal-overlay">
            <div className="modal-content">

                <div className='modal-content-container' >
                <span className='uploadexcel-close' onClick={close}>&times;</span>
                    <div className='modal-content-imgview'>
                        <img src={Action.error_img} alt='img' />
                    </div>
                </div>
                <p style={{ fontSize: "14px" }}>{data}</p>
                <p className='pages-notes' style={{textAlign:"left"}}>Note :</p>
                <p className='pages-notes'>
                <ul style={{textAlign:"left"}}>
                    <li>Selecting <span style={{color:'black'}}>"Yes"</span> will update this component for all employees.</li>
                    <li>Selecting <span style={{color:'black'}}>"No"</span> will update only this specific component.</li>
                    <li>Selecting <span style={{color:'black'}}>"Cancel"</span> will leave it unchanged.</li>
                </ul>
                 </p>
                <div className="modal-buttons">
                    <button onClick={handleYes} className="confirm-button">Yes</button>
                    <button onClick={handleNo} className="cancel-button">No</button>
                    <button onClick={close} className="closecomp-button">cancel</button>

                </div>
            </div>
        </div>
    )
}

export default SalaryComponetAlert