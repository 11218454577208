import React, { useState ,useEffect } from 'react'
import {getUserDetails } from '../../../Services/Storage';
import { AddDeparmentcomp } from '../../../Config/api';
import { toast } from 'react-toastify';
import { addDepartment } from '../../../Slice/departmentSlice';
import { Store } from '../../../Store';
import { getRegisterId } from '../../../Config/userId';

const AddDepBasic = ({ show, close, sendDataToParent,data }) => {

  const user_details = getUserDetails();  const register_id = getRegisterId();
  const [errors, setErrors] = useState({});
  const [loader, setloader] = useState(false);
  const [companyName ,setCompanyName] = useState('');

  const [editdepcomp, setdepcom] = useState({
    depart_name: '',
    user: register_id,
    edited_by: user_details.id,
    company: [],  // Reset to empty array

  });

  useEffect(() => {
  if(data){
    
    setdepcom((prev) => ({
        ...prev,
        company:[data.company_id],
      }))
    setCompanyName(data.company_name)
  }
  
  }, [data])
  
  const handleInputchange = (event) => {
    setErrors({});
    const { name, value } = event.target;
    setdepcom((prev) => ({
      ...prev,
      [name]: value,

    }))
  };



  const closepop =()=>{
    close();
    setErrors({});
    setdepcom({
      depart_name: '',
      user: register_id,
      edited_by: user_details.id,
      company: [],  // Reset to empty array
      
    });

  };
 

  const handlesubmit = async () => {
console.log(editdepcomp)
    const newErrors = {};

    if (!editdepcomp.depart_name.trim()) {
      newErrors.depart_name = "Department Name is required";
    }
  
  
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      
      try {
        setloader(true);

        const response = await AddDeparmentcomp(editdepcomp);
        if (response) {
          sendDataToParent(response.data.department);
          Store.dispatch(addDepartment(response.data.department))
          close();
          toast.success('Department Added Sucessfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          })
          setdepcom((prev) => ({
            ...prev,
            depart_name: '',
            company: [],  // Reset to empty array
          }));

        }
      } catch (error) {
        const errorMessage = error.response && error.response.data && error.response.data.data
          ? error.response.data.data.depart_name
          : "Update Department Failed. Please try again.";

        setErrors((prevErrors) => ({
          ...prevErrors,
          depart_name: errorMessage,  // Set the error message for the department name
        }));

        toast.error("Update Department Failed. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
      }
      finally {
        setloader(false);
      }
    }
  }

  if (!show) return null

  return (
    <div className='popup-section'>
      <div className='popup-container'>
        <span className='uploadexcel-close' onClick={closepop}>&times;</span>

        <div className='popup-container-sub'>
          <div className='addleave-heading'>
            <p>Add Department</p>

          </div>

          <div className='addleave-strightline'></div>
          <div className='popup-content-viewing'>
            <div className='popup-inputfield'>
            <div className='popup-inputfield-sub'>
                <label> Company<span className='registeration-mandatory'> *</span> </label>
              
                               {/* <MultiSelect
                  options={options}
                  value={selectedCompanies}
                  onChange={handleChange}
                  overrideStrings={{
                    "allItemsAreSelected": "All company selected",
                    "selectSomeItems": "Select Company",
                  }}                 /> */}
                    <input
                  className=''
                  type='text'
                  value={companyName}
                  disabled
                //   value={editdepcomp.depart_name}
                />
                    </div>
              <div className='popup-inputfield-sub'>
                <label> Department<span className='registeration-mandatory'> *</span> </label>
                <input
                  className=''
                  type='text'
                  name='depart_name'
                  onChange={handleInputchange}
                  value={editdepcomp.depart_name}
                />
                {errors.depart_name && (<div className='error-message-view'>{errors.depart_name}</div>)}        
                    </div>
                
            </div>
            {
              errors.response_error && (
                <div className='error-message-view salry-comp-err'>
                  {errors.response_error.component}
                </div>
              )
            }
            <div className='popup-button'>
              <button onClick={handlesubmit} disabled={loader}>{loader ? <span className='spinner'></span> : "Submit"}</button>

            </div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default AddDepBasic