import React, { useState, useEffect } from 'react'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import Select from 'react-select';
import './Viewpayroll.css'
import getMonthYearData from '../../Component/MonthYearList';
import { viewSingleStaffSalaryApi, viewStaffSalarySlipApi } from '../../../Config/api';
import { getStaffDetails, getUserDetails } from '../../../Services/Storage';
import pageloader from '../../../Loader/pageloading/Pageloader';
import ReactPaginate from "react-paginate";
import Viewslippopup from '../viewslippopup/Viewslippopup';
import monthNames from '../../Component/Monthview';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';

const ViewStaffPayroll = () => {

  const staff_details = getStaffDetails();
  const [currentpage, setCurrentPage] = useState(1);
  const per_page = 10;
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [employees, setEmployees] = useState([]);
  const user_details = getUserDetails();
  const [isLoading, setIsLoading] = useState(true);
  const [totalLeaveCount, setTotalLeaveCount] = useState(0);
  const [ispopviewslip, showpopupslip] = useState(false);
  const [slipData, setslipData] = useState([]);
  const [rowLoader, setRowLoader] = useState({});

  const handleChangeYear = (selecteOption) => {
    setYear(selecteOption.value);
    setCurrentPage(1)
  };

  const handleChangeMonth = (selecteOption) => {
    setMonth(selecteOption.value);
    setCurrentPage(1)
  };

  const { months, years } = getMonthYearData();
  const monthData = Object.keys(months).sort((a, b) => a - b).map(key => (
    {
      value: key,
      label: months[key],
    }));

  const yearData = Object.keys(years).map(key => ({
    value: years[key],
    label: years[key],
  }));

  const closepopupslip = () => {
    showpopupslip(false);
  };

  useEffect(() => {
    const opensalaryslip = async () => {
      try {
        setIsLoading(true);
        const response = await viewSingleStaffSalaryApi(`${user_details.id}/?admin_id=${staff_details.register_details.user_id}&month=${month}&year=${year}`);
        if (response) {
          setEmployees(response.data.salary_report);
          setTotalLeaveCount(response.data.page_context.total);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    opensalaryslip();
  }, [currentpage, per_page, month, year, user_details.id, staff_details.register_details.user_id]);

  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const openpopslip = async (id, month, year) => {
    try {
      setRowLoader((prev) => ({ ...prev, [month]: true }));

      const response = await viewStaffSalarySlipApi(
        `?register=${user_details.id}&month=${month}&year=${year}&admin_id=${staff_details.register_details.user_id}`
      );
      if (response.data) {
        setslipData(response.data.salary_report[0])
        showpopupslip(true);
      }
    }
    catch (error) {

    } finally {
      setRowLoader((prev) => ({ ...prev, [month]: false }));

    }
  }

  return (
    <>
      <div className='index-container'>
        <Header />
        <div className='index-content-parent'>
          <div className='index-content'>
            <Sidebar />
            <div className='index-content-sub'>
              <div className='index-main'>
                <div className='pages-subheader'>

                  <div className='pages-subheader-sub-1'>
                    <p>Payroll</p>
                    <span className='header-search-add-parent'>
                    </span>
                  </div>
                </div>
                <div className='pages-line'></div>
                <div className='pages-filter-options'>
                  <div className='pages-filter-sub'>
                    <p>Filter:</p>
                    <Select className='pages-dropdown' options={yearData}
                      onChange={handleChangeYear}
                      placeholder="Select Year" />
                    <Select className='pages-dropdown' options={monthData} onChange={handleChangeMonth} placeholder="Select Month" />
                  </div>
                </div>
                <div className='pages-maincontent'>
                  {isLoading ? (
                    <div className="loader-container">
                      <img src={pageloader.page_loader} alt="Loading..." />
                    </div>
                  ) : (
                    <div className="pages-table-container">

                      <table className='pages-table'>
                        {employees.length > 0 && (
                          <thead>
                            <tr>
                              <th></th>
                              <th>Sl No</th>
                              <th>Month/Year</th>
                              <th>Actual Salary</th>
                              <th>Deduction</th>
                              <th>Earning</th>
                              <th>Salary Status</th>
                              <th>Slip</th>

                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {employees.length > 0 ? (

                            employees.map((employee, index) => (

                              <tr key={employee.id}>
                                <td></td>
                                <td>{(currentpage - 1) * per_page + index + 1}</td>
                                <td>{monthNames[employee.month]} / {employee.year}</td>
                                <td>{employee.monthly_salary}</td>
                                <td>{employee.deduct.toFixed(2)}</td>
                                <td>{employee.earning.toFixed(2)}</td>

                                <td
                                  style={{
                                    color: employee
                                      ? employee.status === "1"
                                        ? "green"
                                        : employee.status === "2"
                                          ? "red"
                                          : employee.status === "3"
                                            ? "orange"
                                            : "black"
                                      : "black",
                                  }}
                                >
                                  {employee ? (
                                    employee.status === "0"
                                      ? "Pending"
                                      : employee.status === "1"
                                        ? "Approve"
                                        : employee.status === "2"
                                          ? "Reject"
                                          : employee.status === "3"
                                            ? "Hold"
                                            : "N/A"
                                  ) : "N/A"}
                                </td>
                                {employee.status && (employee.status === '0' || employee.status === '1') ? (
                                  <td onClick={() => openpopslip(employee.register_id, employee.month, employee.year)}>
                                    {rowLoader[employee.month] ?

                                      <span className='loader-pages-tablefield'></span>
                                      : <div className='viewattn-view-button'>Slip</div>

                                    }
                                  </td>
                                ) : (
                                  <td></td>
                                )
                                }

                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8} className="no-data-table">
                                <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <div style={{ float: "right" }} id="pagination_link"></div>
                  <div style={{ padding: "20px" }}>
                    {(!isLoading) && (totalLeaveCount > per_page) ? (
                      <>
                        <ReactPaginate
                          pageCount={Math.ceil(totalLeaveCount / per_page)}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          onPageChange={currentPageNo}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                          forcePage={currentpage - 1}

                        />
                      </>
                    ) : (
                      <> </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Viewslippopup
        show={ispopviewslip}
        close={closepopupslip}
        salarydata={slipData}
      />

    </>
  )
}

export default ViewStaffPayroll