import React, {  useState } from 'react'
import { Navigate } from 'react-router-dom';
import { getCompanyDetails, getUserDetails } from './Services/Storage';

const PrivateRoute = ({ element, roles, action, subaction }) => {
    const userDetails = getUserDetails();
    const companyDetails = getCompanyDetails();
    const authUser = userDetails ? userDetails : null;
    const companyUser = companyDetails ? companyDetails : null;
    const [usersrole] = useState(authUser ? authUser.role : null);
    const accessDetails = authUser?.get_access_role?.[0]?.invite_user_details?.[0] || null;
    const userAccess = accessDetails ? accessDetails : null;
    if (authUser !== null) {
        if (usersrole === 'Company') {
            if (companyUser) {
                if (roles && roles.length > 0 && !roles.includes(usersrole)) {
                    return <Navigate to="/unauthorized" />;
                }
                return element;
            }
            else {
                return <Navigate to="/compdetails" />;
            }
        }
        else if (usersrole === 'staff')
        {
            if (roles && roles.length > 0 && !roles.includes(usersrole)) {
                return <Navigate to="/unauthorized" />;
            }
            else {
                return companyUser ? element : <Navigate to="/login" />;
            }  
        }
        else if (usersrole === 'other_user') {
            if(authUser.access_users)
            {
                const listAll = { ...userAccess.access_category, dashboard : ["all"] }
                const actionPage = Object.keys(listAll).filter(
                key => listAll[key].length > 0
                );
                if(authUser.user_status && authUser.access_users && actionPage.includes(action) &&
                (listAll[action]?.includes(subaction) || listAll[action]?.includes("all")))
                {
                return element;
                }
                else
                {
                    return <Navigate to="/unauthorized" />;
                // if (roles && roles.length > 0 && !roles.includes(usersrole)) {
                // return <Navigate to="/unauthorized" />;
                // }
                // return element;
                }
            }
        }
        else {
            if (roles && roles.length > 0 && !roles.includes(usersrole)) {
                return <Navigate to="/unauthorized" />;
            }
            else {
                return companyUser ? element : <Navigate to="/login" />;
            }
        }
    }
    else {
        return <Navigate to="/login" />;
    }
}

export default PrivateRoute