import React, { useState, useEffect, useCallback } from "react";
import Header from "../../Header/Header";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import "../../Dashboard/Index.css";
import Sidebar from "../../Sidebar/Sidebar";
import "../../../Assets/Pages.css";
import { CiSearch } from "react-icons/ci";
// import formatDate from "../../Component/DateConvert";
import { Fetchallemployeeprint, UpdateEmployeeStatus, ViewEditEmployeeApi, ViewEmployeeApi, viewSalaryComponentApi, ViewSelectedPrint } from "../../../Config/api";
import ReactPaginate from "react-paginate";
import "../../../Assets/Pagination.css";
import {
  CompanyApi,
  DepartmentApi,
  DepartmentHeadApi,
  DepartmentTLApi,
  DesignationApi,
} from "../../../Config/CommonApi";
import { FcPrint } from "react-icons/fc";
import nodatafound from "../../../Images/Noimagefound/Noimgfound";
import pageloader from "../../../Loader/pageloading/Pageloader";
import Confirmation from "../../Component/Confirmation";
import { setEmployees as setEmployeesRedux } from "../../../Slice/employeeSlice";
import { Store } from "../../../Store";
import Employeesatus from "./Employeestatus";
import Deleteconfirmation from "../../Component/Deleteconfirmation";
import EmployeeDetails from "./Printallemp";
import { setSalaryComponent as setSalaryComponentRedux } from '../../../Slice/salaryComponentSlice';
import perPageOptions from "../../../JsonData/Perpagelist";
import { getUserDetails } from "../../../Services/Storage";
import { getRegisterId } from "../../../Config/userId";
import { employeeAccess, employeeAddAccess, employeeEditAccess } from "../../../Services/AccessCondition";

const ViewEmployee = () => {

  const userDetails = getUserDetails();
  const accessid = userDetails?.get_access_role?.[0]?.invite_user_details?.[0]?.register || "";
  const register_id = getRegisterId();
  const AccessRole = employeeAccess();
  const addAcess = employeeAddAccess();
  const editAcess = employeeEditAccess();
  const [currentpage, setCurrentPage] = useState(1);
  const [search, setSearchword] = useState("");
  const [companyFilter, setCompanyFilter] = useState([]);
  const [company, setCompany] = useState('');
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [loading, setloading] = useState(null);
  const [statusToUpdate, setStatustoupdate] = useState(null)
  const [isloading, setIsLoading] = useState(true);
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
  const [companyData, setCompanyData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [openalert, setopenalert] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [departmentfilter, setdepartmentfilter] = useState([]);
  const [designationfilter, setdesignationfilter] = useState([]);
  const [userfilter, setuserfilter] = useState({ value: "all", label: "All Users" });
  const [printalldata, setprintalldatas] = useState([]);
  const [printpopup, setprintpop] = useState(false);
  const [rowLoader, setRowLoader] = useState({});
  const [perpage, setperpage] = useState(10);
  const [perpagefilter, setperpagefilter] = useState({ value: 10, label: "10" })
  const [dataalert, setalartdata] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const { employeeView = "all" } = location.state || {};
  const [employeeStatus, setEmployeeStatus] = useState(employeeView);

  useEffect(() => {
    localStorage.removeItem("salaryFilters");
    if (employeeView === "active") {
      setEmployeeStatus("active");
      setuserfilter({ value: "active", label: "Active Users" })
    }else if(employeeView === "deactive"){
      setEmployeeStatus("deactive");
      
      setuserfilter({ value: "deactive", label: "Deactive Users" })
    }else{
      setEmployeeStatus("all")
    }
  }, [employeeView]);

  const handleChangeCompany = (selecteOption) => {
    setCompany(selecteOption.value);
    setCompanyFilter(selecteOption);
    setCurrentPage(1);
  };

  const handleChangeDepartment = (selecteOption) => {
    setDepartment(selecteOption.value);
    setdepartmentfilter(selecteOption);
    setCurrentPage(1);
  };

  const handleChangeDesignation = (selecteOption) => {
    setDesignation(selecteOption.value);
    setdesignationfilter(selecteOption);
    setCurrentPage(1);
  };

  const handleChangeList = (selecteOption) => {
    setEmployeeStatus(selecteOption.value);
    setuserfilter(selecteOption);
    setCurrentPage(1);
  };

  const listStatus = [
    { value: "all", label: "All Users" },
    { value: "active", label: "Active Users" },
    { value: "deactive", label: "Deactive Users" },
  ];

  const handlePerPageChange = (selectedOption) => {
    setperpage(selectedOption.value);
    setperpagefilter(selectedOption)
    setCurrentPage(1);
  };

  useEffect(() => {
    if (selectedEmployees.length === employees.length) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [selectedEmployees, employees.length]);

  const handeactionbutton = (status) => {
    setStatustoupdate(status);
    setShowConfirmModal(true);
  }

  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      setSelectedEmployees([]);
    } else {
      setSelectedEmployees(employees.map((employee) => employee.register_id));
    }
    setIsAllChecked(!isAllChecked);
  };
  const handleCheckboxChange = (id) => {
    setSelectedEmployees((prevState) =>
      prevState.includes(id)
        ? prevState.filter((empId) => empId !== id)
        : [...prevState, id]
    );
  };

  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };


  const handleSearch = (event) => {
    setSearchword(event.target.value);
  }

  const handleaddbutton = () => {
    navigate("/addemployee");
  };

  const handleincompbutton = () => {
    navigate('/incompemployee')
  };

  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const EmployeeStatusText = (status) => {
    switch (status) {
      case '1':
        return 'Deactivate'
      case '0':
        return 'Activate';
      default:
        return 'None';
    }
  }

  const closealert = () => {
    setopenalert(false)
    setprintalldatas("")
  };

  const handleprintalldatacle = () => {
    setprintpop(false);
  };

  const handleprintalldata = async () => {
    setprintpop(true);
    try {
      const response = await Fetchallemployeeprint(`?register_id=${register_id}`)
      setprintalldatas(response.data.print_all_emp_details);
    } catch {

    }
  };

  const handleselectedemp = async () => {
    const data = {
      register_id: selectedEmployees,
    };
    setprintpop(true);

    try {
      const response = await ViewSelectedPrint(JSON.stringify(data));
      if (response) {
        setprintalldatas(response.print_single_emp_details);
      } else {
      }
    } catch (error) {
    }
  };

  const editPage = async (user_id) => {
    try {
      setRowLoader((prev) => ({ ...prev, [user_id]: true }));
      const response = await ViewEditEmployeeApi(user_id);
      if (response.data.status === 200) {
        if (response.data.get_staff_details.length > 0) {
          Store.dispatch(setEmployeesRedux(response.data.get_staff_details[0]));
          navigate('/vieweditdetails');
        } else {

        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        setopenalert(true)
        setalartdata(error.response.data);
      }
    } finally {
      setRowLoader((prev) => ({ ...prev, [user_id]: false }));

    }
  };

  const handleConfirm = async () => {
    setShowConfirmModal(false);
    const payload = {
      status: statusToUpdate,
      ids: selectedEmployees,
      edited_by: userDetails.id,
    }
    try {
      setloading(statusToUpdate);
      const response = await UpdateEmployeeStatus(JSON.stringify(payload));
      if (response) {
        fetchEmployeeList();
        setSelectedEmployees([]);
      }
    } catch {

    } finally {
      setloading(null);
    }
  };

  const fetchEmployeeList = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await ViewEmployeeApi(
        `?register_id=${register_id}&page=${currentpage}&page_count=${perpage}&search=${search}&company=${company}&department=${department}&user_status=${employeeStatus}&designation=${designation}&access_id=${accessid}`
      );
      setEmployees(response.data.get_staff_details);
      setTotalEmployeeCount(response.data.page_context.total);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [currentpage, register_id, company, department, designation, perpage, employeeStatus, search, accessid]);

  useEffect(() => {
    fetchEmployeeList();
  }, [fetchEmployeeList]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      const data = await CompanyApi();
      setCompanyData(data);
      setCompanyFilter(data[0]);
    };
    const fetchDepartmentData = async () => {
      const data = await DepartmentApi();
      setDepartmentData(data);
      setdepartmentfilter(data[0]);
    };
    const fetchDesignationData = async () => {
      const data = await DesignationApi();
      setDesignationData(data);
      setdesignationfilter(data[0])
    };
    const fetchDepartmentHeadData = async () => {
      await DepartmentHeadApi();
    };
    const fetchDepartmentTlData = async () => {
      await DepartmentTLApi();
    };
    const fetchsalaryComponent = async () => {
      try {
        const response = await viewSalaryComponentApi(`?register_id=${register_id}`);
        Store.dispatch(setSalaryComponentRedux(response.data.get_salary_component_earning));
      } catch (error) {
      }
    }
    fetchCompanyData();
    fetchDepartmentData();
    fetchDesignationData();
    fetchDepartmentHeadData();
    fetchDepartmentTlData();
    fetchsalaryComponent();
  }, [register_id]);

  return (
    <>
      <div className="index-container">
        <Header />
        <div className="index-content-parent">
          <div className="index-content">
            <Sidebar />
            <div className="index-content-sub">
              <div className="index-main">
                <div className="pages-subheader">
                  {selectedEmployees.length === 0 ? (
                    <>
                      <div className="pages-subheader-sub-1">
                        <p>Employees</p>
                        <span className="header-search-add-parent">

                          <div className="pages-serach-view">
                            <CiSearch className="page-search-icon" />
                            <input
                              onChange={handleSearch}
                              className="pages-serach"
                              placeholder="Search Employee"
                              type="search"
                            />
                          </div>
                          <button className="pages-addempbutton" onClick={handleprintalldata}>

                            Print All
                          </button>
                          {addAcess
                            ? (<><button
                              className="pages-addempbutton"
                              onClick={handleincompbutton}
                            >
                              Incomplete
                            </button>
                              <button
                                className="pages-addempbutton"
                                onClick={handleaddbutton}
                              >
                                Add Employee
                              </button></>) :
                            (<></>)

                          }

                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pages-subheader-sub-2">

                        <button className="pages-action-button normal" onClick={handleselectedemp}>
                          <FcPrint size={15} /> Print
                        </button>
                        {
                          AccessRole ? (
                            <>
                              <button className='pages-action-button approve' onClick={() => handeactionbutton(0)} disabled={loading !== null}> {loading === 0 ? <span className='loader-pages-tablefield'></span> : 'Active'}</button>

                              <button className='pages-action-button reject' onClick={() => handeactionbutton(1)} disabled={loading !== null} > {loading === 1 ? <span className='loader-pages-tablefield'></span> : 'Deactive'} </button>
                            </>
                          )
                            :
                            <></>
                        }
                      </div>
                    </>
                  )}
                  <div className="pages-line"></div>
                </div>
                <div className="pages-filter-options">
                  <div className="pages-filter-sub">
                    <p>Filter:</p>
                    <Select
                      className="pages-dropdown1"
                      options={companyData}
                      onChange={handleChangeCompany}
                      value={companyFilter}
                      placeholder="Select Work Location"
                    />
                    <Select
                      className="pages-dropdown"
                      options={departmentData}
                      value={departmentfilter}
                      onChange={handleChangeDepartment}
                      placeholder="Select Department"
                    />
                    <Select
                      className="pages-dropdown"
                      options={designationData}
                      value={designationfilter}
                      onChange={handleChangeDesignation}
                      placeholder="Select Designation"
                    />
                    <Select
                      className="pages-dropdown"
                      onChange={handleChangeList}
                      options={listStatus}
                      value={userfilter}
                      placeholder="Active List"
                    />
                  </div>
                </div>
                <div className="pages-maincontent">
                  {isloading ? (
                    <div className="loader-container">
                      <img src={pageloader.page_loader} alt="Loading..." />
                    </div>
                  ) : (
                    <div className="pages-table-container">
                      <table className="pages-table">
                        {employees.length > 0 && (
                          <thead>
                            <tr>
                              {AccessRole
                              ?( <th>
                                <input
                                  type="checkbox"
                                  checked={isAllChecked}
                                  onChange={handleAllCheckboxChange}
                                />
                              </th>)
                              :(<th></th>)

                              }
                             
                              <th>Name</th>
                              <th>Employee Id</th>
                              <th>Work Email</th>
                              <th>Company</th>
                              <th>Department</th>
                              <th>Designation</th>
                              <th>Salary</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                        )}

                        <tbody>
                          {employees.length > 0 ? (
                            employees.map((employee, index) => (
                              <tr key={employee.register_id}>
                                {AccessRole
                                ?(  <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedEmployees.includes(employee.register_id)}
                                    onChange={() => handleCheckboxChange(employee.register_id)}
                                  />
                                </td>)
                                :(<td></td>)
                                }                   
                                <td>
                                  {editAcess
                                  ?
                                  (<>                 {rowLoader[employee.register_details.id] ?
                                    <span className='loader-pages-tablefield'></span> :
                                    <span onClick={() => editPage(employee.register_details.id)} className="link-span">{employee.register_details.name}</span>

                                  }</>):(
                                    <>{employee.register_details.name}</>
                                  )

                                  }
                 
                                </td>
                                <td>{employee.work_details.emp_id}</td>
                                <td>{employee.work_details.work_email}</td>
                                <td>{employee.work_details.company_name}</td>
                                <td>{employee.work_details.department_name}</td>
                                <td>{employee.work_details.designation_name}</td>
                                <td>
                                  {employee.salary_details
                                    ? employee.salary_details.monthly_salary
                                    : "N/A"}
                                </td>
                                {/* <td>
                                  {employee.work_details.doj
                                    ? formatDate(employee.work_details.doj)
                                    : "N/A"}
                                </td > */}
                                <td style={{ color: Employeesatus(employee.register_details.user_status).color }}>{Employeesatus(employee.register_details.user_status).text}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8} className="no-data-table">
                                <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                  )
                  }
                  <div style={{ float: "right" }} id="pagination_link"></div>
                  <div className="pagenation-perpage-cont">
                    <div >
                      {(!isloading) && (totalEmployeeCount > perpage) ? (
                        <>

                          <div>
                            <ReactPaginate
                              pageCount={Math.ceil(totalEmployeeCount / perpage)}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={2}
                              onPageChange={currentPageNo}
                              forcePage={currentpage - 1}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                        </>
                      )}
                    </div>
                    {(!isloading) && (totalEmployeeCount >= 11) ? (
                      <div className="select-per-page-container">
                        <Select
                          menuPlacement="top"
                          className="per-page-container"
                          options={perPageOptions}
                          value={perpagefilter}
                          onChange={handlePerPageChange}
                          placeholder="page"
                        />
                      </div>) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Confirmation
          isOpen={showConfirmModal}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
          message={`Are you sure you want to ${EmployeeStatusText(String(statusToUpdate))} the selected Employee?`}
        />

        <Deleteconfirmation
          show={openalert}
          close={closealert}
          data={dataalert}
        />

        <EmployeeDetails
          employeeData={printalldata}
          show={printpopup}
          close={handleprintalldatacle}
        />
        
      </div>
    </>
  );
};

export default ViewEmployee;
