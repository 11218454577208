import React, { useState, useRef, useEffect, useCallback } from 'react';
import "../../Style/Employeeform.css";
import Sidebar from '../../../Sidebar/Sidebar';
import Header from '../../../Header/Header';
import '../../Style/Addemp.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { convertTo12HourFormat } from '../../../Component/TimeFormat';
import { EmployeeBasicApi, ViewDepHeadwdDepid, ViewDepTlwdDepid, viewSelectedDept, ViewSelectLeaveComp } from '../../../../Config/api';
import { useSelector } from 'react-redux';
import { getUserDetails } from '../../../../Services/Storage';
import userdemoimg from '../../../../Images/Pagesimg/userdemo';
import { toast } from 'react-toastify';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import Addorgprofile from '../../../Settings/Organisation_profile/Addorgprofile';
import Adddesignation from '../../../Settings/Designation/Adddesignation';
import Addleavecomponent from '../../../Leavecomponent/Addleavecomponent';
import { getRegisterId } from '../../../../Config/userId';
import { MultiSelect } from "react-multi-select-component";
import AddDepBasic from '../../../Settings/Department/AddDepBasic';
import AddDepHeadBasic from '../../../Settings/Departmenthead/AddDepHeadBasic';
import AddDepTLBasic from '../../../Settings/Departmenttl/AddDepTLBasic';


const Basicdetails = () => {

  const register_id = getRegisterId();
  const userDetails = getUserDetails();
  const accessid = userDetails?.get_access_role?.[0]?.invite_user_details?.[0]?.register || "";
  const location = useLocation();
  const { name, user_id } = location.state || {};
  const navigate = useNavigate();
  const companies = useSelector((state) => state.companies);
  const designationRedux = useSelector((state) => state.designations);
  const [selecteddep, setselecteddep] = useState(true);
  const [fromvalue, Setfromtime] = useState(null);
  const [tovalue, Settotime] = useState(null);
  const [errors, setErrors] = useState({});
  const [selectedimg, setselectedimg] = useState(null);
  const [dephdfetch, setdephdfetch] = useState([])
  const [deptlfetch, setdeptlfetch] = useState([])
  const [maxDate, setMaxDate] = useState('');
  const [fetchLeavecomp, setLeavecomp] = useState([])
  const fileinput = useRef(null);
  const dropdownRef = useRef(null);
  const [loading, setloading] = useState(false);
  const [showcompanylist, setcompanylist] = useState(false)
  const [companyname, setcompanyname] = useState("Select Company")
  const [addnewcompany, setnewcompany] = useState(false)
  const [showdesignationlist, setdesignationlist] = useState(false);
  const [showleave, setshowleave] = useState(false);
  const [designationname, setdesignationname] = useState("Select Designation")
  const [addnewdesignation, setnewdesignation] = useState(false)
  const [showdeptllist, setdeptllist] = useState(false)
  const [deptlname, setdeptlname] = useState("Select Department Tl")
  const [addnewdeptl, setnewdeptl] = useState(false)
  const [showdephdlist, setdephdlist] = useState(false)
  const [dephdname, setdephdname] = useState("Select Department Head")
  const [addnewdephd, setnewdephd] = useState(false)
  const [showdepartmentlist, setShowDepartmentList] = useState(false);
  const [showaddnewdepartment, setAddNewdepartment] = useState(false);
  const [departname, setDepartmentName] = useState("Select Department");
  const [selectedLeaves, setSelectedLeaves] = useState([]);
  const [isopenaddleavecomp, setopenleavecomp] = useState(false);
  const [time, setTime] = useState(null);
  const [departmentData,setDepartmentData] = useState([]);
  const [companyId,seCompanyId] = useState('');
  const [viewDepData ,setViewDepData] = useState([]);
  const [viewDephdtl,setViewDephdtl] = useState([]);
  const [isLoadingDepartment, setIsLoadingDepartment] = useState(false);
  const [isLoadingDepartmenthd, setIsLoadingDepartmenthd] = useState(false);
  const [isLoadingDepartmenttl, setIsLoadingDepartmenttl] = useState(false);

  const handleuploadimg = () => {
    fileinput.current.click();
  }
  useEffect(() => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    setMaxDate(today.toISOString().split('T')[0]);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      const isTrigger = event.target.closest('.adddept-div-span');
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !isTrigger) {
        setdeptllist(false);
        setdephdlist(false);
        setShowDepartmentList(false);
        setdesignationlist(false);
        setcompanylist(false);
        setshowleave(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [basicInfo, setBasicInfo] = useState({
    emp_name: name,
    emp_id: "",
    work_email: "",
    father_name: "",
    dob: "",
    gender: "",
    blood_group: "",
    emergency_contact: "",
    doj: "",
    permission_hr: "",
    work_from: "",
    work_to: "",
    work_location: "",
    designation: "",
    department: "",
    depart_head: "",
    depart_tl: "",
    register: user_id,
    user: register_id,
    staff_pic: "",
    edited_by: userDetails.id,
    company: "",
    eligible_leave: [],
    lat_dedu_days: "",
    lat_dedu_hrs: "",
  });

  const fetchleavecomp = useCallback(async () => {
    try {
      const response = await ViewSelectLeaveComp(`?register_id=${register_id}`)
      if (response) {

        setLeavecomp(response.data.get_leave_component)
      }
    } catch {

    }
  },[register_id])
  useEffect(() => {
  
    fetchleavecomp();
  }, [fetchleavecomp])

  const handlefromtimechange = (time) => {
    Setfromtime(time);
    setBasicInfo((state) => ({
      ...state,
      work_from: time + ':00',

    }));
  };

  const handletotimechange = (time) => {
    Settotime(time);
    setBasicInfo((state) => ({
      ...state,
      work_to: time + ':00',
    })
    )
  };

  const companyaddlist = () => {
    setcompanylist((prevValue) => !prevValue);
    setdesignationlist(false);
    setdeptllist(false);
    setdephdlist(false);
    setShowDepartmentList(false);
  }

  const showaddnewcompany = () => {
    setcompanylist(false)
    setnewcompany((prevValue) => !prevValue);
  }

  const closeaddnewcompany = () => {
    setnewcompany(false)
  }

  const handleDataFromCompany = (data) => {
    setBasicInfo((state) => ({
      ...state,
      work_location: String(data.company_id),
      company: String(data.company_id),
      depart_head: "",
      depart_tl: "",
      department:"",

    }));
    fetchdepartment(data.company_id);
    setselecteddep(true);
    seCompanyId(data.company_id);
    setcompanylist(false);
    setcompanyname(data.company_name);
    setDepartmentName('Select Department');
    setdeptlname("Select Department Tl");
    setdephdname("Select Department Head");
  };
  useEffect(() => {
    if (!user_id) {

      navigate('/employee');
    }
  }, [user_id, navigate]);

  const designationaddlist = () => {
    setdesignationlist((prevValue) => !prevValue);
    setcompanylist(false);
    setdeptllist(false);
    setdephdlist(false);
    setShowDepartmentList(false);
  }

  const showaddnewdesignation = () => {
    setdesignationlist(false)
    setnewdesignation((prevValue) => !prevValue);

  }
  const closeaddnewdesignation = () => {
    setnewdesignation(false)
  }
  const handleDataFromdesignation = (data) => {
    setBasicInfo((state) => ({
      ...state,
      designation: String(data.id),

    }));
    setdesignationlist(false);

    setdesignationname(data.designation);

  };

  const deptladdlist = () => {
    if(!selecteddep){
    setdeptllist((prevValue) => !prevValue);
    setcompanylist(false);
    setdesignationlist(false);
    setdephdlist(false);
    setShowDepartmentList(false);
    }
  }

  const showaddnewdeptl = () => {
    setdeptllist(false)
    setnewdeptl((prevValue) => !prevValue);
    const companyId = parseInt(basicInfo.company, 10); // Converts to integer
    const companyDetails =  companies.find((comp) => comp.company_id === companyId);
    const depId = parseInt(basicInfo.department, 10); // Converts to integer
    const depDetails =  departmentData.find((dep) => dep.id === depId);
    const dataview = {
      company:companyDetails,
      department:depDetails,
    };
    setViewDephdtl(dataview);
  }

  const closeaddnewdeptl = () => {
    setnewdeptl(false)
  }

  const handleDataFromdeptl = (data) => {
    setBasicInfo((state) => ({
      ...state,
      depart_tl: String(data.row_id),
    }));
    setdeptllist(false);
    setdeptlname(data.depart_tl_name);
  };

  const dephdaddlist = () => {
    if(!selecteddep){
      setdephdlist((prevValue) => !prevValue);
      setcompanylist(false);
      setdesignationlist(false);
      setdeptllist(false);
      setShowDepartmentList(false);
      setshowleave(false)
    }
  }

  const showaddnewdephd = () => {
    setdeptllist(false)
    setnewdephd((prevValue) => !prevValue);
    const companyId = parseInt(basicInfo.company, 10); // Converts to integer
    const companyDetails =  companies.find((comp) => comp.company_id === companyId);
    const depId = parseInt(basicInfo.department, 10); // Converts to integer
    const depDetails =  departmentData.find((dep) => dep.id === depId);
    const dataview = {
      company:companyDetails,
      department:depDetails,
    };
    setdephdlist(false);
    setViewDephdtl(dataview)
  }

  const closeaddnewdephd = () => {
    setnewdephd(false)
  }

  const handleDataFromdephd = (data) => {
    setBasicInfo((state) => ({
      ...state,
      depart_head: String(data.row_id),

    }));
    setdephdlist(false);
    setdephdname(data.depart_head_name);
  };

  const departmentlist = () => {
    if(companyId){
      setShowDepartmentList((prevValue) => !prevValue);
      setcompanylist(false);
      setdesignationlist(false);
      setdeptllist(false);
      setdephdlist(false);
      setshowleave(false)
    }
  }

  const addnewdepartment = () => {
    setShowDepartmentList(false);
    const companyId = parseInt(basicInfo.company, 10); // Converts to integer
    const companyDetails =  companies.find((comp) => comp.company_id === companyId);
    setViewDepData(companyDetails);
    setAddNewdepartment((prevValue) => !prevValue);
  }

  const closeaddleave = () => {
    setAddNewdepartment(false);
  }

  // const handleDataFromChild = (data) => {
  //   console.log(data.company_details,companyId);
  //   fetchdepartment(companyId);
  //   if(data.company.includes(companyId)){
  //     const companyInfo = data.company_details.find(company => company.id === companyId);
  //     if(companyInfo){
  //       setBasicInfo((state) => ({
  //         ...state,
      
  //         department: String(data.id),

  //       }));
  //       setShowDepartmentList(false);
  //       setselecteddep(true);
  //     setDepartmentName(data.depart_name);
  //     }else{
  //       setBasicInfo((state) => ({
  //         ...state,
  //         department: '',
  //       }));
  //     }
      
  //   }else{
   
  //   }
  //   // setBasicInfo((state) => ({
  //   //   ...state,
  //   //   department: String(data.id),

  //   // }));
   

  // };
 const handleDataFromChild = (data) => {
    setBasicInfo((state) => ({
      ...state,
      department: String(data.id),

    }));
    setShowDepartmentList(false);
    setDepartmentName(data.depart_name);
    setselecteddep(false);
    fetchdepartmenthead(data.id,companyId);
    fetchdepartmenttl(data.id,companyId);
    fetchdepartment(basicInfo.company);


  };

  const handleInputChangeDep = (e) => {
    const { name, value } = e.target;
    setBasicInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setBasicInfo((prevState) => ({
      ...prevState,
      depart_head: "",
      depart_tl: "",
    }));
    setselecteddep(false);

    fetchdepartmenthead(value,companyId);
    fetchdepartmenttl(value,companyId);
  }

  const handleKeyPress = (e) => {
    if (e.target.name === 'emp_id') {
      const specialCharPattern = /[!@#$%^&*(),.?":'=;{}|<>+_]/;
      if (specialCharPattern.test(e.key)) {
        e.preventDefault();
      }
    }
  }

  const handlephonepress = (e) => {
    if (e.target.name === 'emergency_contact') {
      const specialCharPattern = /[^0-9]/g;
      if (specialCharPattern.test(e.key)) {
        e.preventDefault();
      }
    }
  }

  const inputRefs = {
    emp_id: useRef(null),
    work_email: useRef(null),
    father_name: useRef(null),
    dob: useRef(null),
    gender: useRef(null),
    blood_group: useRef(null),
    emergency_contact: useRef(null),
    doj: useRef(null),
 

  };

  const handleChange = (newTime) => {
    setTime(newTime);
    setBasicInfo((state) => ({
      ...state,
      permission_hr: newTime + ':00',
    })
    )
  };

  const leavetypeselect = () => {
    setshowleave((prevValue) => !prevValue);
    setcompanylist(false);
    setdesignationlist(false);
    setdeptllist(false);
    setShowDepartmentList(false);
  }
  const toggleLeaveSelection = (leaveId) => {
    setSelectedLeaves((prevSelected) => {
      if (prevSelected.includes(leaveId)) {
        return prevSelected.filter((id) => id !== leaveId);
      } else {
        return [...prevSelected, leaveId];
      }
    });
  };

  const closeaddleavecomp = () => {
    setopenleavecomp(false)
  }
  const openleavecomp = () => {
    setopenleavecomp(true)
    setshowleave(false)
  }

  const handleDataFromChildlve = () => {

    // if (data && data.id) {
    //   setSelectedLeaves((prevSelected) => {
    //     if (prevSelected.includes(data.id)) {
    //       return prevSelected.filter((id) => id !== data.id);
    //     } else {
    //       return [...prevSelected, data.id];
    //     }
    //   });
    // }
    fetchleavecomp();
  }


  const handleEligibleLeaveChange = (selected) => {
    const leaveValues = selected.map((option) => option.value);
    setSelectedLeaves(leaveValues)
    setBasicInfo((state) => ({
      ...state,
      eligible_leave: selected.map((option) => option.value),
    }));
  };

  const handleDeduDays = (e) => {
    if (e.target.name === 'lat_dedu_days') {
      const currentValue = e.target.value;
      const newValue = currentValue + e.key;
      const validPattern = /^(3[01]|[12]?[0-9]|0)(\.5)?$/;
      if (e.key === "." && !currentValue.includes(".")) {
        return;
      }
      if (!validPattern.test(newValue)) {
        e.preventDefault();
      }
    }
  };

  const handleHourPress = (e) => {
    if (e.target.name === 'lat_dedu_hrs') {
      const currentValue = e.target.value;
      const newValue = currentValue + e.key;
      const validPattern = /^(2[0-4]|1?[0-9]|0)(\.5)?$/;
      if (e.key === "." && !currentValue.includes(".")) {
        return;
      }
      if (!validPattern.test(newValue)) {
        e.preventDefault();
      }
    }
  };

  const handleInputChangeWorkLoc = (event) => {
    setErrors({})
    const { name, value } = event.target;
    seCompanyId(value);
    fetchdepartment(value);
    setselecteddep(true);
    if (name === "work_location") {
      setBasicInfo(prevState => ({
        ...prevState,
        work_location: value,
        company: value, 
        depart_head: "",
        depart_tl: "",
        department:"",
      }));
    } else {
      setBasicInfo(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  

  const handleInputChange = (event) => {
    setErrors({})
    const { name, value, type } = event.target;
    if (type === 'time') {
      setBasicInfo((state) => ({
        ...state, [name]: convertTo12HourFormat(value),
      }));
    }
    else if (event.target.type === "file") {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        setBasicInfo((state) => ({
          ...state,
          [name]: file,
        }));
        setselectedimg(URL.createObjectURL(file)); // Set the selected image for preview
      } else {
        setselectedimg("");
      }
    }

    else {
      setBasicInfo((state) => ({
        ...state, [name]: value,
      }));
    }
  }
  const fetchdepartment = async (id) => {
    setIsLoadingDepartment(true);  // Start loader

          try {
            const response = await viewSelectedDept(`?register_id=${register_id}&company_id=${id}`)
            if (response) {
              setDepartmentData(response.data.get_department)
              // setDepartmentView(response.data.get_department)
              // setdephdfetch(response.data.get_depart_head)
            }
          } catch {
      
          }
          finally{
            setIsLoadingDepartment(false); // Stop loader

          }
        }

        const handleInput = (e) => {
          const target = e.target;
          const start = target.selectionStart;
          const end = target.selectionEnd;
          const originalValue = target.value;
        
          // Update the value
          target.value = originalValue.toUpperCase().replace(/[^A-Z0-9]/g, '');
        
          // Restore the cursor position
          if (target.value !== originalValue) {
            const newLength = target.value.length;
            const newStart = Math.max(0, start - (originalValue.length - newLength));
            const newEnd = Math.max(0, end - (originalValue.length - newLength));
            target.setSelectionRange(newStart, newEnd);
          }
        };
        
  const fetchdepartmenthead = async (depid,companyId) => {
    setIsLoadingDepartmenthd(true);
    try {
      const response = await ViewDepHeadwdDepid(`?register_id=${register_id}&depart_id=${depid}&access_id=${accessid}&company_id=${companyId}`)
      if (response) {
        setdephdfetch(response.data.get_depart_head);
        
      }
    } catch {

    }finally{
      setIsLoadingDepartmenthd(false);

    }
  };

  const fetchdepartmenttl = async (depid,companyId) => {
    setIsLoadingDepartmenttl(true);
    try {
      const response = await ViewDepTlwdDepid(`?register_id=${register_id}&depart_id=${depid}&access_id=${accessid}&company_id=${companyId}`)
      if (response) {
        setdeptlfetch(response.data.get_depart_tl)
      }
    } catch {

    }finally{
      setIsLoadingDepartmenttl(false);

    }
  }

  const handleSubmit = async () => {
    let newErrors = {};

    if (basicInfo.emp_id.trim() === "") {
      newErrors.emp_id = "Employee Id is required";
      inputRefs.emp_id.current.focus();
    }else if (!/(?=.*[a-zA-Z])(?=.*\d)^.{2,}$/.test(basicInfo.emp_id.trim())) {
      newErrors.emp_id = "Employee ID must contain both letters and numbers";
      inputRefs.emp_id.current.focus();
    }
    
    if (basicInfo.work_email.trim() === "") {
      newErrors.work_email = "Employee Email is required";
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(basicInfo.work_email.trim())) {
      newErrors.work_email = "Invalid email format";
      inputRefs.work_email.current.focus();
    }
    if (basicInfo.father_name.trim() === "") {
      newErrors.father_name = "Father name is required";
      inputRefs.father_name.current.focus();
    }
    if (basicInfo.dob.trim() === "") {
      newErrors.dob = "Date of Birth is required";
      inputRefs.dob.current.focus();
    }
    if (basicInfo.gender.trim() === "") {
      newErrors.gender = "Please Select Gender";
      inputRefs.gender.current.focus();
    }
    if (basicInfo.blood_group.trim() === "") {
      newErrors.blood_group = "Blood Group is required";
      inputRefs.blood_group.current.focus();
    }
    if (basicInfo.emergency_contact.trim() === "") {
      newErrors.emergency_contact = "Emergency Contact No is required";
    } else if (!/^\d{10}$/.test(basicInfo.emergency_contact)) {
      newErrors.emergency_contact = "Phone Number must be exactly 10 digits";
      inputRefs.emergency_contact.current.focus();
    }
    if (basicInfo.doj.trim() === "") {
      newErrors.doj = "Date of Joining is required";
      inputRefs.doj.current.focus();
    }
 
    if (basicInfo.work_from.trim() === "") {
      newErrors.work_from = "Work From is required";
    }
    if (basicInfo.work_to.trim() === "") {
      newErrors.work_to = "Work To is required";
    }
    if (basicInfo.work_location.trim() === "") {
      newErrors.work_location = "Select Work Location";
    }
    if (basicInfo.designation.trim() === "") {
      newErrors.designation = "Select Designation";
    }
    if (basicInfo.department.trim() === "") {
      newErrors.department = "Select Department";
    }
    if (basicInfo.permission_hr.trim() === "") {
      newErrors.permission_hr = "Permission Hour is required";
    }
    if (selectedLeaves.length === 0) {
      newErrors.eligible_leave = "Select Eligible Leave";
    }
    setErrors(newErrors);
    const errorFields = Object.keys(newErrors);
    if (errorFields.length > 0) {
      const firstErrorField = errorFields[0];
      if (inputRefs[firstErrorField]?.current) {
        inputRefs[firstErrorField].current.focus();
      }
      return;
    }
    if (Object.keys(newErrors).length === 0) {
      try {
        setloading(true);
        const data = {
          ...basicInfo,
          eligible_leave: JSON.stringify(selectedLeaves),
        };
        if (!data.depart_head.trim()) delete data.depart_head;
        if (!data.depart_tl.trim()) delete data.depart_tl;
        const response = await EmployeeBasicApi(data);
        if (response) {
          toast.success('Basic Details added successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });

          navigate('/salarydetails', { state: { name: name, user_id: user_id ,company_id:basicInfo.company} });
        }
      }
      catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors[key] = errorData[key];
            }
          }
          setErrors(newErrors);
          const errorFields = Object.keys(newErrors);
          if (errorFields.length > 0) {
            const firstErrorField = errorFields[0];
            inputRefs[firstErrorField]?.current?.focus();
          }
        } else {
          toast.error('Failed.Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,

          });
          const newErrors = {
            response_error: [],

          };
          for (const key in error.response.data) {
            if (error.response.data.hasOwnProperty(key)) {
              newErrors.response_error.push(`${error.response.data[key]}`);

            }
          }
          setErrors(newErrors);
          setBasicInfo((state) => ({
            ...state,
          }));
        }
      }
      finally {
        setloading(false)
      }
    }
  }



  return (
    <>
      <div className='index-container'>
        <Header />
        <div className='index-content-parent'>
          <div className='index-content'>
            <Sidebar />
            <div className='index-content-sub'>
              <div className='index-main'>
                <div className='addemp-subheader'>
                  <div className='addemp-subheader-sub'>
                    <div className='addemp-subheader1'>
                      <p>Add Basic Details</p>
                    </div>
                    <div className='addemp-subheader2'>
                      <div className='addemp-stepper'>
                        <div className='addemp-step-indicator'>
                          <div className='addemp-step'>
                            Personal Info
                          </div>
                          <div className='addemp-step addemp-selected' >Basic Details</div>
                          <div className='addemp-step'>Salary Details</div>
                          <div className='addemp-step'>Payment</div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className='addemp-line'></div>
                <div className='addemp-maincontent-sub'>
                  <div className='addemp-form form-padding'>
                    <div className='addemp-form-sub'>
                      <div className='addemp-form-sub-main'>
                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-imgemp'>

                            <div className='emppersonalinfo-input-imgemp-sub'>
                              <div className='emppersonalinfo-input-imgemp-main' onClick={handleuploadimg}>


                                {selectedimg ?
                                  <img src={selectedimg} alt='not_ft' />
                                  :
                                  <img src={userdemoimg.demo_img} alt='not_ft' />
                                }
                                <input type='file' style={{ display: 'none' }}
                                  name='staff_pic' ref={fileinput} onChange={handleInputChange} />

                              </div>

                            </div>
                            <label className='label-fields'>Upload Image <span className='emppersonalinfo-mandatory'>*</span></label>
                          </div>

                        </div>
                        {/* <p className='pages-notes'><span className='emppersonalinfo-mandatory'>*</span>Note :When an Excel sheet is uploaded, the system extracts the employee ID, identifies the employee, and updates their attendance.</p> */}
                        <div className='emppersonalinfo-input-row'>

                          <div className='emppersonalinfo-input-group'>
                            <label>Employee Name <span className='emppersonalinfo-mandatory'>*</span></label>
                            <input className='emppersonalinfo-input-field' readOnly name='emp_name' onChange={handleInputChange} placeholder='Enter employee name' value={name} type='text' />
                          </div>
                          <div className='emppersonalinfo-input-group'>
                            <label>Employee ID  <span className='emppersonalinfo-mandatory'>*</span></label>

                            <input className='emppersonalinfo-input-field' onKeyDown={(e) => {
                              if (e.key === ' ') {
                                e.preventDefault();
                              }
                            }} ref={inputRefs.emp_id} onKeyPress={handleKeyPress} onInput={handleInput} name='emp_id' onChange={handleInputChange} value={basicInfo.emp_id} placeholder='Enter employee id' type='text' />
                            {errors.emp_id && (<div className='error-message-view'>{errors.emp_id}</div>)}
                          </div>

                        </div>
                        <p className='pages-notes'><span className='emppersonalinfo-mandatory'>*</span>
                         Note:
                         <ul>
  <li>The company must first create an official email address for the employee (e.g., <code>name.company@gmail.com</code>).</li>
  <li>Once the email is created, it should be entered during the registration process.</li>
  <li>After successful registration, the login credentials will be sent to the company-provided email.</li>
</ul>

                         </p>

                        <div className='basicdetails-input-col'>
                          <div className='basicdetails-col-view'>
                            <label className='label-fields'>Work Email <span className='emppersonalinfo-mandatory'>*</span></label>
                            <input className='emppersonalinfo-inputfield-col' ref={inputRefs.work_email} onKeyDown={(e) => {
                              const regex = /^[a-zA-Z0-9@._\b]+$/;
                              if (!regex.test(e.key)) {
                                e.preventDefault();
                              }
                            }} placeholder='abc@xyz.com' type='text' value={basicInfo.work_email} name="work_email" onChange={handleInputChange} />
                          </div>
                          {errors.work_email && (<div className='error-message-view'>{errors.work_email}</div>)}
                        </div>

                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Father Name <span className='emppersonalinfo-mandatory'>*</span></label>
                            <input name='father_name'
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^[a-zA-Z\s]*$/.test(value)) {
                                  handleInputChange(e);
                                }
                              }}
                              value={basicInfo.father_name} ref={inputRefs.father_name} className='emppersonalinfo-input-field' type='text' />
                            {errors.father_name && (<div className='error-message-view'>{errors.father_name}</div>)}
                          </div>
                          <div className='emppersonalinfo-input-group'>
                            <label>Date Of Birth  <span className='emppersonalinfo-mandatory'>*</span></label>

                            <input name='dob' value={basicInfo.dob} max={maxDate} ref={inputRefs.dob} onChange={handleInputChange} className='emppersonalinfo-input-field' type='date' />
                            {errors.dob && (<div className='error-message-view'>{errors.dob}</div>)}
                          </div>

                        </div>
                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Gender <span className='emppersonalinfo-mandatory'>*</span></label>
                            <select name="gender" onChange={handleInputChange} value={basicInfo.gender} ref={inputRefs.gender} className='emppersonalinfo-input-field3'>
                              <option value=''>Select Gender</option>
                              <option value='male'>Male</option>
                              <option value='female'>Female</option>
                              <option value='other'>Other</option>
                            </select>
                            {errors.gender && (<div className='error-message-view'>{errors.gender}</div>)}
                          </div>
                          <div className='emppersonalinfo-input-group'>
                            <label>Blood Group  <span className='emppersonalinfo-mandatory'>*</span></label>

                            <input name='blood_group' onChange={handleInputChange} ref={inputRefs.blood_group} value={basicInfo.blood_group}
                              className='emppersonalinfo-input-field' type='text' />
                            {errors.blood_group && (<div className='error-message-view'>{errors.blood_group}</div>)}
                          </div>

                        </div>

                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Emergency Contact No <span className='emppersonalinfo-mandatory'>*</span></label>
                            <input name='emergency_contact' onChange={handleInputChange} onKeyPress={handlephonepress} ref={inputRefs.emergency_contact} value={basicInfo.emergency_contact} className='emppersonalinfo-input-field' type='text' />
                            {errors.emergency_contact && (<div className='error-message-view'>{errors.emergency_contact}</div>)}
                          </div>
                          <div className='emppersonalinfo-input-group'>
                            <label>Date Of Joining  <span className='emppersonalinfo-mandatory'>*</span></label>
                            <input name='doj' ref={inputRefs.doj} value={basicInfo.doj} onChange={handleInputChange} className='emppersonalinfo-input-field' type='date' />
                            {errors.doj && (<div className='error-message-view'>{errors.doj}</div>)}
                          </div>
                        </div>



                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Permission Hour <span className='emppersonalinfo-mandatory'>*</span></label>
                            <TimePicker
                              onChange={handleChange}
                              value={time}
                              className="time-picker"

                              format="HH:mm"
                              clockIcon={null}
                            />
                            {errors.permission_hr && (<div className='error-message-view'>{errors.permission_hr}</div>)}
                          </div>

                          <div className='emppersonalinfo-input-group' style={{width:"180px"}}>
                            <label>Leave Eligible <span className='emppersonalinfo-mandatory'>*</span></label>
                            {userDetails.role === "Company"
                              ? (
                                <div className="adddept-div">
                                  <span className="adddept-div-span" onClick={leavetypeselect}>
                                    {selectedLeaves.length > 0
                                      ? `Selected ${selectedLeaves.length} Leave Types`
                                      : 'Select Leave Types'}
                                  </span>
                                  {showleave && (
                                    <div className="add-dept-ul-div" ref={dropdownRef}>
                                      {fetchLeavecomp.length > 0 ? (
                                        <ul>
                                          {fetchLeavecomp.map((leave, index) => (
                                            <li
                                              key={index}
                                              value={leave.id}
                                              className={selectedLeaves.includes(leave.id) ? 'selected' : ''}
                                              onClick={() => toggleLeaveSelection(leave.id)}
                                            >
                                              <span style={{ color: 'green',fontSize:'14px' }}>
  {selectedLeaves.includes(leave.id) ? '✓ ' : ''}
</span> {leave.leave_type} ({leave.leave_deduct})
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        <p style={{color:"red" ,marginLeft:"5px"}}>No Leave found</p>
                                      )}
                                      <div className="add-dept-footer">

                                        <div onClick={openleavecomp} className="add-dept-new-option">
                                          <span>Add New Leave</span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>) : (<MultiSelect
                                  options={
                                    fetchLeavecomp.map(leavecomponent => ({
                                      label: `${leavecomponent.leave_type} (${leavecomponent.leave_deduct})`,
                                      value: leavecomponent.id
                                    }))
                                  }
                                  // value={basicInfo.eligible_leave.map(id => ({
                                  //   label: fetchLeavecomp.find(leave => leave.id === id)?.leave_type,
                                  //   value: id
                                  // }))}
                                  value={basicInfo.eligible_leave.map(id => {
                                    const selectedLeave = fetchLeavecomp.find(leave => leave.id === id);
                                    return {
                                      label: selectedLeave ? `${selectedLeave.leave_type} (${selectedLeave.leave_deduct})` : "",
                                      value: id
                                    };
                                  })}
                                  onChange={handleEligibleLeaveChange}
                                  overrideStrings={{
                                    "allItemsAreSelected": "All leave type selected",
                                    "selectSomeItems": "Select Leave Type",
                                  }}                      
                                />)

                            }
                            {errors.eligible_leave && (<div className='error-message-view'>{errors.eligible_leave}</div>)}
                          </div>
                        </div>
                            <p className='pages-notes'><span className='emppersonalinfo-mandatory'>*</span> Note : Excess permission hours beyond the limit will be considered late hours, with deductions automatically applied upon entry.</p>

                        <div className="emppersonalinfo-input-row">

                          <div className="emppersonalinfo-input-group">

                            <label>
                            <p className='pages-notes'><span className='emppersonalinfo-mandatory'>*</span> Note : Late deduction days can be either 0.5 (half-day) or 1 (full day). If no value is provided, no deduction will be applied.</p>

                              Late Deduction Days
                            </label>
                            <input
                              className="emppersonalinfo-input-field"
                              type="text"
                              ref={inputRefs.lat_dedu_days}
                              name="lat_dedu_days"
                              onKeyPress={handleDeduDays}
                              value={basicInfo.lat_dedu_days}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="emppersonalinfo-input-group">
                            <label>
                            <p className='pages-notes'><span className='emppersonalinfo-mandatory'>*</span> Note : Late deduction hours can be either 0.5 (30 minutes) or 1 (1 hour). If no value is provided, no deduction will be applied.</p>

                              Late Deduction Hours
                            </label>
                            <input
                              className="emppersonalinfo-input-field"
                              type="text"
                              ref={inputRefs.lat_dedu_hrs}
                              name="lat_dedu_hrs"
                              onKeyPress={handleHourPress}
                              value={basicInfo.lat_dedu_hrs}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <p className='emp-work-p'>Work Timing:</p>
                        <div className='emppersonalinfo-input-row'>

                          <div className='emppersonalinfo-input-group'>
                            <label>From Time <span className='emppersonalinfo-mandatory'>*</span></label>
                            <TimePicker
                              onChange={handlefromtimechange}
                              value={fromvalue}
                              className="time-picker"

                            />
                            {errors.work_from && (<div className='error-message-view'>{errors.work_from}</div>)}
                          </div>
                          <div className='emppersonalinfo-input-group'>
                            <label>To Time <span className='emppersonalinfo-mandatory'>*</span></label>
                            <TimePicker
                              onChange={handletotimechange}
                              value={tovalue}
                              className="time-picker"

                            />
                            {errors.work_to && (<div className='error-message-view'>{errors.work_to}</div>)}
                          </div>
                        </div>
                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Work Location <span className='emppersonalinfo-mandatory'>*</span></label>
                            {
                              userDetails.role === "Company"
                                ? (<div className='adddept-div'>
                                  <span className='adddept-div-span' onClick={companyaddlist}>
                                    {companyname}
                                  </span>
                                  {
                                    showcompanylist ?
                                      (
                                        <div className='add-dept-ul-div' ref={dropdownRef}>
                                          {companies.length > 0 ? (

                                            <ul>

                                              {companies.map((company, index) => (
                                                <li key={index} value={company.company_id}
                                                  onClick={() => {
                                                    setBasicInfo((state) => ({
                                                      ...state,
                                                      work_location: String(company.company_id),
                                                      company: String(company.company_id)
                                                    }));
                                                    setcompanyname(company.company_name)
                                                    setcompanylist((prevValue) => !prevValue);
                                                    fetchdepartment(company.company_id);
                                                    seCompanyId(company.company_id);
                                                    setBasicInfo((prevState) => ({
                                                    ...prevState,
                                                    depart_head: "",
                                                    depart_tl: "",
                                                    department:"",
                                                  }));
                                                  setselecteddep(true);
                                                  setDepartmentName('Select Department');
                                                  setdeptlname("Select Department Tl");
                                                  setdephdname("Select Department Head");

                                                  }}
                                                >
                                                  {company.company_name}
                                                </li>
                                              ))}


                                            </ul>
                                          ) : (
                                            <p style={{color:"red" ,marginLeft:"5px"}}>No company found</p>
                                          )}
                                          <div onClick={showaddnewcompany} className='add-dept-new-option'>
                                            <span>Add New Company</span>
                                          </div>
                                        </div>
                                      )
                                      :
                                      null
                                  }
                                </div>)
                                : (<select onChange={handleInputChangeWorkLoc} className="emppersonalinfo-input-field3" name="work_location" value={basicInfo.work_location}>
                                    <option value="" disabled>Select Work Location</option>

                                  {companies.map((company, index) => (
                                    <option key={index} value={company.company_id}>
                                      {company.company_name}
                                    </option>
                                  ))}
                                </select>)
                            }

                            {errors.work_location && (<div className='error-message-view'>{errors.work_location}</div>)}
                          </div>
                          <div className='emppersonalinfo-input-group'>
                            <label>Designation
                              <span className='emppersonalinfo-mandatory'>*</span></label>

                            {userDetails.role === "Company"
                              ? (<div className='adddept-div'>
                                <span className='adddept-div-span' onClick={designationaddlist}>
                                  {designationname}
                                </span>
                                {
                                  showdesignationlist ?
                                    (
                                      <div className='add-dept-ul-div' ref={dropdownRef}>
                                        {designationRedux.length > 0 ? (

                                          <ul>

                                            {designationRedux.map((designation, index) => (
                                              <li key={index} value={designation.id}
                                                onClick={() => {
                                                  setBasicInfo((state) => ({
                                                    ...state,
                                                    designation: String(designation.id),
                                                  }));
                                                  setdesignationname(designation.designation)
                                                  setdesignationlist((prevValue) => !prevValue);
                                                }}
                                              >
                                                {designation.designation}
                                              </li>
                                            ))}


                                          </ul>
                                        ) : (
                                          <p style={{color:"red" ,marginLeft:"5px"}}>No designation found</p>
                                        )}
                                        <div onClick={showaddnewdesignation} className='add-dept-new-option'>
                                          <span>Add New Designation</span>
                                        </div>
                                      </div>
                                    )
                                    :
                                    null
                                }
                              </div>) : (
                                <select onChange={handleInputChange} className="emppersonalinfo-input-field3" name="designation" value={basicInfo.designation}>
                            <option value="" disabled>Designation</option>
                                  {designationRedux.map((designation, index) => (
                                    <option key={index} value={designation.id}>
                                      {designation.designation}
                                    </option>
                                  ))}
                                </select>
                              )

                            }

                            {errors.designation && (<div className='error-message-view'>{errors.designation}</div>)}
                          </div>

                        </div>
                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Department <span className='emppersonalinfo-mandatory'>*</span></label>
                            {userDetails.role === "Company"
                              ? (<div className='adddept-div'>
                                <span className='adddept-div-span'
                                    onClick={departmentlist} 
                                    style={{ cursor: !basicInfo.work_location ? "not-allowed" : "pointer", opacity: !basicInfo.work_location ? 0.5 : 1 }}
                                 >
                                  {departname}
                                </span>
                                {
                                  showdepartmentlist ?
                                    (
                                      <div className='add-dept-ul-div' ref={dropdownRef}>
                                        {isLoadingDepartment ?(
                                          <ul style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
                                          <span className='loader-pages-tablefield'></span>

                                          </ul>
                                        ):
                                        
                                        departmentData.length > 0 ? (

                                          <ul>

                                            {departmentData.map((department, index) => (
                                              <li key={index} value={department.id}
                                                onClick={() => {
                                                  setBasicInfo((state) => ({
                                                    ...state,
                                                    department: String(department.id),
                                                  }));
                                                  setDepartmentName(department.depart_name);
                                                  setShowDepartmentList((prevValue) => !prevValue);
                                                  setselecteddep(false);
                                                  fetchdepartmenthead(department.id,companyId);
                                                  fetchdepartmenttl(department.id,companyId);
                                                  setBasicInfo((prevState) => ({
                                                    ...prevState,
                                                    depart_head: "",
                                                    depart_tl: "",
                                                  }));
                                                  setdeptlname("Select Department Tl");
                                                  setdephdname("Select Department Head");
                                                }}
                                              >
                                                {department.depart_name}
                                              </li>
                                            ))}
                                          </ul>
                                        ) : (
                                          <p style={{color:"red" ,marginLeft:"5px"}}>No departments found</p>
                                        )}
                                        <div onClick={addnewdepartment} className='add-dept-new-option'>
                                          <span>Add New Department</span>
                                        </div>
                                      </div>
                                    )
                                    :
                                    null
                                }

                              </div>)
                              : (isLoadingDepartment ? (
                                <div style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
                                <span className='loader-pages-tablefield'></span>

                                </div>
                              ) :
                              
                              <select name='department' disabled={!basicInfo.work_location} value={basicInfo.department}  onChange={handleInputChangeDep} className={`emppersonalinfo-input-field3 ${!basicInfo.work_location ? 'addemp-sel-disabled' : ''}`}>
                                    <option value="">Select Department</option>
                                    {
                                    
                                    departmentData.length > 0
                                    ?(

                                    departmentData.map((department, index) => (
                                  <option key={index} value={department.id}>
                                    {department.depart_name}
                                  </option>
                                ))
                                    ):(
                                      <option disabled>No Data Found</option>

                                    )
                              }
                              </select>
                              )

                            }

                            {errors.department && (<div className='error-message-view'>{errors.department}</div>)}
                          </div>
                          <div className={`emppersonalinfo-input-group ${selecteddep ? 'addemp-sel-disabled' : ''}`}>


                            <label>Department Head </label>

                            {userDetails.role === "Company"
                              ? (<div className='adddept-div'>
                                <span className='adddept-div-span' onClick={dephdaddlist}>
                                  {dephdname}
                                </span>
                                {
                                  showdephdlist ?
                                    (
                                      <div className='add-dept-ul-div' ref={dropdownRef}>
                                        {isLoadingDepartmenthd ?(
                                          <ul style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
                                          <span className='loader-pages-tablefield'></span>

                                          </ul>
                                        ):
                                        
                                        dephdfetch.length > 0 ? (

                                          <ul>

                                            {dephdfetch.map((department_head, index) => (
                                              <li key={index} value={department_head.row_id}
                                                onClick={() => {
                                                  setBasicInfo((state) => ({
                                                    ...state,
                                                    depart_head: String(department_head.row_id),
                                                  }));
                                                  setdephdname(department_head.depart_head_name)
                                                  setdephdlist((prevValue) => !prevValue);
                                                }}
                                              >
                                                {department_head.depart_head_name}
                                              </li>
                                            ))}


                                          </ul>
                                        ) : (
                                          <p style={{color:"red" ,marginLeft:"5px"}}>No department Head found</p>
                                        )}
                                        <div onClick={showaddnewdephd} className='add-dept-new-option'>
                                          <span>Add New Department Head</span>
                                        </div>
                                      </div>
                                    )
                                    :
                                    null
                                }
                              </div>)
                              : (isLoadingDepartmenthd ? (
                                <div style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
                                <span className='loader-pages-tablefield'></span>

                                </div>
                              ) :
                              <select name='depart_head' value={basicInfo.depart_head} disabled={selecteddep} onChange={handleInputChange} className={`emppersonalinfo-input-field3 ${selecteddep ? 'addemp-sel-disabled' : ''}`}>
                                <option value=''>Select Department Head</option>
                                {dephdfetch.length >0?(

                                dephdfetch.map((department_head, index) => (
                                  <option key={index} value={department_head.row_id}>
                                    {department_head.depart_head_name}
                                  </option>
                                ))
                                ):(
                                  <option disabled>No Data Found</option>

                                )
                              }
                              </select>)

                            }

                            {errors.depart_head && (<div className='error-message-view'>{errors.depart_head}</div>)}
                          </div>
                          <div className={`emppersonalinfo-input-group ${selecteddep ? 'addemp-sel-disabled' : ''} `} >
                            <label>Department TL
                              </label>

                            {userDetails.role === "Company"
                              ? (<div className='adddept-div'>
                                <span className='adddept-div-span' onClick={deptladdlist}>
                                  {deptlname}
                                </span>
                                {
                                  showdeptllist ?
                                    (
                                      <div className='add-dept-ul-div' ref={dropdownRef}>
                                        {isLoadingDepartmenttl ?(
                                          <ul style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
                                          <span className='loader-pages-tablefield'></span>

                                          </ul>
                                        ):
                                        
                                        deptlfetch.length > 0 ? (

                                          <ul>

                                            {deptlfetch.map((department_tl, index) => (
                                              <li key={index} value={department_tl.row_id}
                                                onClick={() => {
                                                  setBasicInfo((state) => ({
                                                    ...state,
                                                    depart_tl: String(department_tl.row_id),
                                                  }));
                                                  setdeptlname(department_tl.depart_tl_name)
                                                  setdeptllist((prevValue) => !prevValue);
                                                }}
                                              >
                                                {department_tl.depart_tl_name}
                                              </li>
                                            ))}


                                          </ul>
                                        ) : (
                                          <p style={{color:"red" ,marginLeft:"5px"}} >No department tl found</p>
                                        )}
                                        <div onClick={showaddnewdeptl} className='add-dept-new-option'>
                                          <span>Add New Department Tl</span>
                                        </div>
                                      </div>
                                    )
                                    :
                                    null
                                }
                              </div>)
                              : (isLoadingDepartmenttl ? (
                                <div style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
                                <span className='loader-pages-tablefield'></span>

                                </div>
                              ) :
                              <select name='depart_tl' value={basicInfo.depart_tl} onChange={handleInputChange} className={`emppersonalinfo-input-field3 ${selecteddep ? 'addemp-sel-disabled' : ''}`}>
                                <option value=''>Select Department TL</option>
                                {deptlfetch.length > 0?(
                                deptlfetch.map((department_tl, index) => (
                                  <option key={index} value={department_tl.row_id}>
                                    {department_tl.depart_tl_name}
                                  </option>
                                ))
                              ):(
                                <option disabled>No Data Found</option>
                              )
                                }
                              </select>)

                            }

                            {errors.depart_tl && (<div className='error-message-view'>{errors.depart_tl}</div>)}

                          </div>
                        </div>
                        {errors.response_error && (<div className='error-message-view'>{errors.response_error}</div>)}
                        <div className='emppersonalinfo-submitbutton'>


                          <button onClick={handleSubmit} disabled={loading}>{loading ? <span className='spinner'></span> : 'Submit'}</button>
                        </div>
                        <div></div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddDepBasic
        data = {viewDepData}
        show={showaddnewdepartment}
        close={closeaddleave}
        sendDataToParent={handleDataFromChild}
      />
      <Addorgprofile
        show={addnewcompany}
        close={closeaddnewcompany}
        sendDataToParent={handleDataFromCompany}
      />
      <Adddesignation
        show={addnewdesignation}
        sendDataToParent={handleDataFromdesignation}
        close={closeaddnewdesignation}
      />
      <AddDepTLBasic
        show={addnewdeptl}
        data = {viewDephdtl}
        close={closeaddnewdeptl}
        sendDataToParent={handleDataFromdeptl}
      />
      <AddDepHeadBasic
        data = {viewDephdtl}
        show={addnewdephd}
        close={closeaddnewdephd}
        sendDataToParent={handleDataFromdephd}
      />
      <Addleavecomponent
        show={isopenaddleavecomp}
        close={closeaddleavecomp}
        sendDataToParent={handleDataFromChildlve}
      />
    </>


  )
}

export default Basicdetails

