import React, { useState } from 'react';
import './UserAccount.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { CiPower } from 'react-icons/ci';
import { FaCircleUser, FaLock } from 'react-icons/fa6';
import { getCompanyDetails, getStaffDetails, getUserDetails } from '../../Services/Storage';
import api_backend, { Logout_all } from '../../Config/api';
import userdemoimg from '../../Images/Pagesimg/userdemo';
import DeleteAlert from '../popup/DeleteAlert';
import { FiRefreshCcw } from 'react-icons/fi';
import pageloader from '../../Loader/pageloading/Pageloader';
import { getRegisterId } from '../../Config/userId';

const UserAccount = ({ user_status, onclose }) => {

  const register_id = getRegisterId();
  const company_details = getCompanyDetails();
  const user_details = getUserDetails();
  const staff_details = getStaffDetails();
  const [showalertpopup, setshowalertpopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('company');
    localStorage.removeItem('notification');
    navigate('/login');
    if (user_details.role === 'staff') {
      localStorage.removeItem('Staff');
    }
  };

  const closeconfirmmodel = () => {
    setshowalertpopup(false);
  };



  const openconfirmation = (e) => {
    e.stopPropagation(); // Prevent event bubbling to parent elements
    setshowalertpopup(true);
  };
  const Deleteapi = async () => {
    closeconfirmmodel();
    setLoading(true);
    try {
      const response = await Logout_all(register_id);
      if (response) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('company');
        navigate('/login');
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        closeconfirmmodel();
      }
    } finally {
      setLoading(false);
    }
  }

  if (!user_status) return null;
  return (
    <>
      <div id="pop-menu" className="account-menu">
        <div className="account-menu-div1">
          <div className="account-menu-header">
            {
              user_details.role === 'Company'
                ? (
                  <div className="account-menu-hedaer-div1">
                    <span
                      className="close-span"
                      style={{
                        right: "0",
                        position: "absolute",
                        cursor: "pointer",
                        fontSize: "30px",
                        zIndex: "888",
                        color: "#fff",
                        padding: "-200px"
                      }}
                      onClick={onclose}
                    >
                      &times;
                    </span>
                    <div className="user-account-pop">
                      <span className="user-pop-img">
                        <div style={{ width: "50px", height: "100%" }}>
                          {company_details?.company_logo ?
                            (
                              <img
                                src={`${api_backend.BACKEND_API}${company_details.company_logo}`}
                                alt="UserIcon"
                                className='user-pop-img-t'
                              />
                            ) : (
                              (<img src={userdemoimg.demo_img} alt='img' className='user-pop-img-t' />)

                            )

                          }

                        </div>
                      </span>
                      <span className="user-pop-comp">
                        <span> {company_details?.company_name} </span>
                        <span> {company_details?.company_email} </span>
                      </span>
                    </div>
                  </div>

                ) : user_details.role === 'other_user' ? (
                  <div className="account-menu-hedaer-div1">
                    <span
                      className="close-span"
                      style={{
                        right: "0",
                        position: "absolute",
                        cursor: "pointer",
                        fontSize: "30px",
                        zIndex: "888",
                        color: "#fff",
                        padding: "-200px"
                      }}
                      onClick={onclose}
                    >
                      &times;
                    </span>
                    <div className="user-account-pop">
                      <span className="user-pop-img">
                        <div style={{ width: "45px", height: "45px", borderRadius: "50%", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "green", color: "#fff", fontSize: "20px", fontWeight: "bold" }}>

                          {`${user_details.name?.split(" ")[0]?.charAt(0) || ""}${user_details.name?.split(" ")[1]?.charAt(0) || ""}`}

                        </div>
                      </span>
                      <span className="user-pop-comp">
                        <span> {user_details.name} </span>
                        <span> {user_details.email} </span>
                      </span>
                    </div>
                  </div>

                ) : (

                  <div className="account-menu-hedaer-div1">
                    <span
                      className="close-span"
                      style={{
                        right: "0",
                        position: "absolute",
                        cursor: "pointer",
                        fontSize: "30px",
                        zIndex: "888",
                        color: "#fff",
                        padding: "-200px"
                      }}
                      onClick={onclose}
                    >
                      &times;
                    </span>
                    <div className="user-account-pop">
                      <span className="user-pop-img">
                        <div style={{ width: "50px", height: "100%" }}>

                          {staff_details.staff_details?.staff_pic ?
                            (
                              <img
                                src={`${api_backend.BACKEND_API}${staff_details.staff_details?.staff_pic}`}
                                alt="UserIcon"
                                className='user-pop-img-t'
                              />
                            ) : (
                              (<img src={userdemoimg.demo_img} alt='img' className='user-pop-img-t' />)

                            )

                          }

                        </div>
                      </span>
                      <span className="user-pop-comp">
                        <span> {staff_details.register_details?.name} </span>
                        <span> {staff_details.register_details?.email} </span>
                      </span>
                    </div>
                  </div>
                )
            }
          </div>

          <div className="user-account-settings">
            <ul className="user-account-settings-ul">
              {
                user_details.role === 'Company'
                  ? (
                    <li>
                      <NavLink to="../viewprofile">
                        <FaCircleUser size={15} /> Manage Your Account
                      </NavLink>

                    </li>

                  ) : user_details.role === 'other_user' ? (
                    <li>
 {/* <NavLink to="../viewprofile">
                        <FaCircleUser size={15} /> Manage Your Account
                      </NavLink> */}
                      <NavLink to="../changepass">
                        <FaLock size={15} /> Change Password
                      </NavLink>
                    </li>
             

                  ) : (
                    <li>
                      <NavLink to="../staffprofile">
                        <FaCircleUser size={15} /> Manage Your Account
                      </NavLink>
                      <NavLink to="../changepass">
                        <FaLock size={15} /> Change Password
                      </NavLink>
                    </li>
                  )}
              <li onClick={logout}>
              
                <NavLink  >
                  <CiPower size={15} /> Sign Out
                </NavLink>
              </li>
              {user_details.role === 'Company' ?
                (<li onClick={openconfirmation}>
             
                  <NavLink style={{ color: 'green' }} >
                    <FiRefreshCcw size={15} /> Log Out of All Accounts
                  </NavLink>
                </li>)
                :
                (<></>)

              }

            </ul>
          </div>
        </div>
        {loading && <div className="pages-maincontent">
          <div className="loader-container">
            <img src={pageloader.page_loader} alt="Loading..." />
          </div>
        </div>}

        <DeleteAlert
          isOpen={showalertpopup}
          message={"Really, would you like to Log Out of All Signed-In Devices"}
          onCancel={closeconfirmmodel}
          onConfirm={Deleteapi}
        />
      </div>
    </>
  )
}

export default UserAccount