import React, { useRef, useState } from 'react';
import html3pdf from 'html3pdf';
import './Viewslippopup.css';
import api_backend from '../../../Config/api';

const Viewslippopup = ({ show, close, salarydata }) => {

  const payslipRef = useRef();
  const [isDownloading, setIsDownloading] = useState(false);
  const employee = salarydata;
const totalComponentEarnings = ((employee?.salary_slip?.salary_compo_list || []) ?? [])
  .flat()
  .filter(item => item?.earning_deduction === 'earning')
  .reduce((acc, item) => acc + (parseFloat(item?.monthly) || 0), 0);

  const downloadPDF = () => {
    setIsDownloading(true);
    const element = payslipRef.current;

    const images = element.getElementsByTagName("img");
    const promises = Array.from(images).map((img) => {
      return new Promise((resolve) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = () => resolve();
          img.onerror = () => resolve();
        }
      });
    });

    Promise.all(promises).then(() => {
      const options = {
        margin: 0.5,
        filename: `payslip_${employee.work_details.emp_id}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };

      html3pdf()
        .set(options)
        .from(element)
        .save()
        .then(() => setIsDownloading(false));
    });
  };


  if (!show) return null;
  if (!salarydata) return null;

  return (
    <div className='viewslippopup-section'>
      <div className='viewslippopup-container'>
        <span className='uploadexcel-close' onClick={close}>&times;</span>
        <div className="payslip" id="payslip" ref={payslipRef}>
          <div className="payslip-header" >
          <div className='payslip-header-company'>
          <div style={{textAlign:"justify"}}>

            <h3>{employee.company_details.company_name}</h3>
            <p> {employee.company_details.address_line1} ,{employee.company_details.address_line2}  </p>
            <p>{employee.company_details.city} , {employee.company_details.state} , {employee.company_details.pincode}</p>
          </div>
          <div className='payslip-header-companylogo'>
              <img 
              src={`${api_backend.BACKEND_API}${employee.company_details.company_logo}`}
              alt='img'
              crossOrigin="anonymous"

              />
            </div>
          </div>
          <br/>
          <div className='payslip-employee-container'>
            {/* <div className="payslip-employee" style={{borderRight:"none"}}>
              <p><strong>Employee Id:</strong> {employee.work_details.emp_id}</p>
              <p><strong>Employee Name:</strong> {employee.register_details.name}</p>
              <p><strong>Department:</strong> {employee.department_name}</p>
              <p><strong>Designation:</strong> {employee.designation_name}</p>
            </div> */}

            <div className="payslip-employee" style={{borderRight:"none"}}>
              <div style={{textAlign:"justify"}}>
              <p><strong>Employee Id:</strong> </p>
              <p><strong>Employee Name:</strong> </p>
              <p><strong>Department:</strong> </p>
              <p><strong>Designation:</strong> </p>
              <p><strong>Actual Amount:</strong> </p>
              </div>
              <div>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              </div>
              <div style={{textAlign:"justify"}}>
              <p> {employee.work_details.emp_id}</p>
              <p>{employee.register_details.name}</p>
              <p>{employee.department_name}</p>
              <p>{employee.designation_name}</p>
              <p style={{color:"green"}}>₹ {employee?.salary_details?.monthly_salary}</p>
              </div>

            </div>
        
            <div className="payslip-employee">
              <div style={{textAlign:"justify"}}>
              <p><strong>Bank Name</strong> </p>
              <p><strong>Account Number</strong> </p>
              <p><strong>Month & Year</strong> </p>
              <p><strong>UAN</strong> </p>
              </div>
              <div>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              <p><strong>:</strong> </p>
              </div>
              <div style={{textAlign:"justify"}}>
              <p> {employee.payment_details.bank_name || ""}</p>
              <p>{employee.payment_details.account_num || ""}</p>
              <p>{employee.salary_slip.month} / {employee.salary_slip.year}</p>
              <p>{employee.payment_details.UAN || ""}</p>
              </div>
              </div>

            </div>
          </div>
          <div className="payslip-details">
            <div className='payslip-earnings'>
              <table>
                <tr>
                  <td colSpan={2}>Total Days : {employee.salary_slip.days_in_month}</td>
                  <td colSpan={1}>Earning Days : {employee.salary_slip.earning_days}</td>
                  <td colSpan={1}>Deductions Days : {employee.salary_slip.deduction_days}</td>
                </tr>
                <tr>
                  <th colSpan={2}>Earnings</th>
                  <th colSpan={2}>Deductions</th>
                </tr>
                <tr>
                  <td colSpan={2}>Leave Earnings : {employee.salary_slip.leave_earning}</td>
                  <td colSpan={2}>Leave Deductions : {employee.salary_slip.leave_deduction}</td>
                </tr>
                <tr>
                  <td colSpan={2}>Punched Count : {employee.salary_slip.punched_count}</td>
                  <td colSpan={2}>Missed Punch : {employee.salary_slip.missed_punch}</td>

                </tr>
                <tr>
                  <td colSpan={2}>Half Punch Count : {employee.salary_slip.half_punch}</td>
                  <td colSpan={2}>Late Deductions : {employee.salary_slip.late_deduction}</td>

                </tr>
                <tr>
                  <td colSpan={2}>Holiday Count : {employee.salary_slip.holiday_count}</td>
                  <td>Leave  Deductions(₹)</td>
                    <td>{Math.round(employee.salary_slip.leave_deduction * employee.salary_slip.perday_salary)}</td>
                </tr>
                  <tr>
                  <td colSpan={2}></td>
                    <td>Missed Punch(₹)</td>
                    <td>{Math.round(employee.salary_slip.missed_punch*employee.salary_slip.perday_salary)}</td>
                  </tr>
                  <tr>
                  <td colSpan={2}></td>
                    <td>Late Deductions(₹)</td>
                    <td>{Math.round(employee.salary_slip.late_deduction*employee.salary_slip.perday_salary)}</td>
                  </tr>
                <tbody>
                  <tr>
                    <th>Description</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Amount</th>
                  </tr>
                 
                  {
                    (() => {
                      const selectedSlacomp = employee.salary_details.eligible_salary || [];
                      const earnings = employee.salary_slip.salary_compo_list.filter(item => item.earning_deduction === 'earning' && selectedSlacomp.includes(item.id));
                      const deductions = employee.salary_slip.salary_compo_list.filter(item => item.earning_deduction === 'deduction' && selectedSlacomp.includes(item.id));

                      const maxLength = Math.max(earnings.length, deductions.length);
                      return Array.from({ length: maxLength }).map((_, index) => (
                        <tr key={index}>
                          <td>{earnings[index]?.component_name || ''}</td>
                          <td>{earnings[index]?.monthly || ''}</td>
                          <td>{deductions[index]?.component_name || ''}</td>
                          <td>{deductions[index]?.monthly || ''}</td>
                        </tr>
                      ));
                    })()
                  }
                  
                  <tr>
                    <td>Other Earnings</td>
                    <td>{employee.salary_slip.other_earning}</td>
                    <td>Other Deductions</td>
                    <td>{employee.salary_slip.other_deduction}</td>
                  </tr>
              
                  <tr className="total-row">
                    <td><strong>Total Earnings</strong></td>
                    <td><strong>₹{Math.round(totalComponentEarnings)+employee.salary_slip.other_earning|| 0}</strong></td>
                    <td><strong>Total Deductions</strong></td>
                    <td><strong>₹{Math.round(employee.salary_slip.deduct_salary)+employee.salary_slip.other_deduction || 0}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="payslip-summary" style={{padding:"20px"}}>
            <h4>Summary</h4>
            <p><strong>Net Pay:</strong> ₹{Math.round(employee.salary_slip.earning_salary)}</p>
          </div>
          <div className="payslip-footer">
            {!isDownloading && (
              <button className='payslip-download-button' onClick={downloadPDF}>Download</button>
            )}          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewslippopup;