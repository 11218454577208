import React, { useState, useEffect } from 'react';
import TimePicker from 'react-time-picker';
import './Addattendance.css';
import { editattendance } from '../../../Config/api';
import { toast } from 'react-toastify';
// import { getRegisterId } from '../../../Config/userId';
import { getUserDetails } from '../../../Services/Storage';


const Editattendance = ({ show, close, sendDataToParent, datas }) => {
  // const register_id = getRegisterId();
  const [fromvalue, Setfromtime] = useState(null);
  const [tovalue, Settotime] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(false);
  const user_details = getUserDetails();

  const [formData, setFormData] = useState({
    punch_in_time: "",
    punch_out_time: "",
  });

  useEffect(() => {
    if (datas) {
      setFormData({
        date: datas.date || "",
        punch_in_time: datas.punch_in_time || "",
        punch_out_time: datas.punch_out_time || "",
      })
      Setfromtime(datas.punch_in_time)
      Settotime(datas.punch_out_time)
    }
  }, [datas]);

  const closePopup =()=>{
    close();
    setErrors({})
  }
  const handlefromtimechange = (time) => {
    Setfromtime(time);
    setFormData((state) => ({
      ...state,
      punch_in_time: time + ':00',

    }));
  }

  const handletotimechange = (time) => {
    Settotime(time);
    setFormData((state) => ({
      ...state,
      punch_out_time: time + ':00',
    })
    )
  }

  const handleSubmit = async () => {
    const newErrors = {};
    if (formData.punch_in_time.trim() === '') {
      newErrors.punch_in_time = 'Punch In is required';
    }
    if (formData.punch_out_time.trim() === '') {
      newErrors.punch_out_time = 'Punch Out is required';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setloading(true)
        const data = {
          ...formData,
          edited_by: user_details.id,
        };
        const response = await editattendance(datas.id, data);
        if (response) {
          sendDataToParent(response);
          close();
          toast.success('Attendance Updated successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });

          
        }

      } catch (error) {
        if (error.response) {
          const newErrors = {};
          newErrors.response_error = error.response.data.data;
          setErrors(newErrors);
        } else {
          toast.error('Failed. Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,

          });
          const newErrors = {
            response_error: [],
          };
          for (const key in error.response.data.errors) {
            if (error.response.data.errors.hasOwnProperty(key)) {
              newErrors.response_error.push(`${error.response.data.errors[key]}`);
            }
          }
          setErrors(newErrors);
          setFormData((state) => ({
            ...state,
          }));
        }
      } finally {
        setloading(false);
      }
    }

  }
  if (!show) return null;

  return (
    <div className='addatten-main'>
      <div className='addatten-main-sub'>
        <div className='addatten-container'>
          <span className='uploadexcel-close' onClick={closePopup}>&times;</span>

          <div className='addatten-heading'>
            <p>Edit Attendance</p>
          </div>
          <div className='addatten-strightline'></div>
          <div className='addatten-inputfield'>
            <div className='addatten-sub'>
              <div className='addatten-input'>
                <div className='addatten-input-sub'>
                  <div className='addatten-inputfield'>
                    <label>Employee Name</label>
                    <div>
                      <input type='text' value={datas.emp_name || ''} className='addatten-input-style' disabled />

                    </div>

                  </div>
                  <div className='addatten-inputfield'>
                    <label>Date</label>
                    <div>
                      <input type='date' value={datas.date || ''} name='date' className='addatten-input-style' disabled />
                    </div>

                  </div>

                </div>
              </div>
              <div className='addatten-checkbox'>


              </div>
              <div className='addatten-input-sub'>
                <div className='addatten-inputfield'>
                  <label>Punch In</label>
                  <div>
                    <TimePicker
                      className="time-picker"
                      onChange={handlefromtimechange}
                      value={fromvalue}
                    />
                  </div>
                  {errors.punch_in_time && (
                    <div className="error-message-view">{errors.punch_in_time}</div>
                  )}
                </div>
                <div className='addatten-inputfield'>
                  <label>Punch Out</label>
                  <div>
                    <TimePicker
                      className="time-picker"
                      onChange={handletotimechange}
                      value={tovalue}
                    />
                  </div>
                  {errors.punch_out_time && (
                    <div className="error-message-view">{errors.punch_out_time}</div>
                  )}
                </div>
              </div>
              <div className='response-message-view'>
                {errors.response_error && (
                  <div className="error-message-view">{errors.response_error}</div>
                )}
              </div>


              <div className='button-addattend'>
                <button onClick={handleSubmit} disabled={loading}>
                  {loading ? <span className="spinner"></span> : 'Submit'}

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editattendance;