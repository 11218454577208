import React, { useState, useEffect } from 'react';
import DatePicker from 'react-multi-date-picker';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import './DatePicker.css';

const DatepickerComp = ({ setNumberOfDays, setFromDate, setToDate }) => {
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (dates && dates.length > 0) {
      if (dates.length > 1) {
        const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));
        const firstDate = new Date(sortedDates[0]);
        const lastDate = new Date(sortedDates[sortedDates.length - 1]);
        const timeDiff = Math.abs(lastDate - firstDate);
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

        setNumberOfDays(diffDays);
        setFromDate(firstDate.toISOString().split('T')[0]);
        setToDate(lastDate.toISOString().split('T')[0]);
      } else if (dates.length === 1) {
        setNumberOfDays(1);
        setFromDate(new Date(dates[0]).toISOString().split('T')[0]);
        setToDate(new Date(dates[0]).toISOString().split('T')[0]);
      }
    } else {
      setNumberOfDays(0);
      setFromDate('');
      setToDate('');
    }
  }, [dates, setNumberOfDays, setFromDate, setToDate]);

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length <= 2) {
      setDates(selectedDates);
    } else {
      setDates(selectedDates.slice(0, 2));
    }
  };

  return (
    <DatePicker
      multiple
      value={dates}
      onChange={handleDateChange}
      placeholder="Select From & To date"
      format="MMMM D, YYYY"
      editable={false}
      mapDays={({ date }) => {
        if (dates.length < 2) return;

        const startDate = new Date(dates[0]).toISOString().split('T')[0];
        const endDate = new Date(dates[dates.length - 1]).toISOString().split('T')[0];
        const currentDate = date.format('YYYY-MM-DD');

        if (currentDate === startDate) {
          return { className: 'start-date' };
        }
        if (currentDate === endDate) {
          return { className: 'end-date' };
        }
        if (currentDate > startDate && currentDate < endDate) {
          return { className: 'between-date' };
        }
      }}
    />
  );
};

export default DatepickerComp;
