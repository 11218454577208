import React, { useEffect, useState } from 'react'
import './Notification.css'
import { Viewnotification } from '../../../Config/api'
import { getCompanyDetails, getUserDetails, storeNotification } from '../../../Services/Storage';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import pageloader from '../../../Loader/pageloading/Pageloader';
import notificationImg from '../../../Images/Notification/NotificationImg';

const Notification = ({ show, close }) => {

  const user_details = getUserDetails();
  const [notification, setnotification] = useState([]);
  const [isloading, setloading] = useState(false);
  // const [timeView ,settimeView] =useState("");

  const getRelativeTime = (dateString) => {
    const date = new Date(dateString); // Convert to Date object
    const now = new Date();

    // Set both dates to midnight to ensure accurate day calculations
    date.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24)); // Difference in days

    if (diffInDays === 0) return "Today";
    if (diffInDays === 1) return "Yesterday";
    if (diffInDays < 7) return `${diffInDays} days ago`;
    if (diffInDays < 30) return `${Math.floor(diffInDays / 7)} week(s) ago`;
    if (diffInDays < 365) return `${Math.floor(diffInDays / 30)} month(s) ago`;

    return `${Math.floor(diffInDays / 365)} year(s) ago`;
  };

  useEffect(() => {
    if (show) {
      const fetchnotification = async () => {
        if (user_details.role === 'Company') {
          const company = getCompanyDetails();
          try {
            setloading(true)
            const response = await Viewnotification(`?register_id=${user_details.id}&company_id=${company.company_id}`);
            // setnotification(response.data.get_notification);
            const notifications = response.data.get_notification.notification_details.map((noti) => ({
              ...noti,
              timeAgo: getRelativeTime(noti.notifi_from_dt),
            }));
            setnotification(notifications);
            storeNotification(response.data.get_notification.notification_count)

            // settimeView(notifications);

          } catch {
          } finally {
            setloading(false)
          }
        }
      }
      fetchnotification();
    }
  }, [user_details.id, user_details.role, show]);

  // useEffect(() => {
  //   if (show ) {
  //     updateNotificationView();
  //   }
  // }, [show]); // Runs only when `show` changes

  // const updateNotificationView =()=>{
  // }




  const renderNotificationIcon = (notificationType) => {
    switch (notificationType) {
      case "announcement":
        return <img src={notificationImg.announcement} alt="announcement Notification" />;
      case "alert":
        return <img src={notificationImg.warning} alt="warning Notification" />;
      case "payment":
        return <img src={notificationImg.income} alt="payment Notification" />;
      case "info":
        return <img src={notificationImg.information} alt="information Notification" />;
      case "warning":
        return <img src={notificationImg.warningsign} alt="warning Notification" />;
      case "update":
        return <img src={notificationImg.loadingarrow} alt="Update Notification" />;
      case "reminder":
        return <img src={notificationImg.reminder} alt="reminder Notification" />;
      case "other":
      default:
        return <img src={notificationImg.demo_img} alt="Other Notification" />;
    }
  };

  if (!show) return null;
  return (
    <div className='notification_main'>
      <div className='notification_mainsub'>
        <div className='notification_container'>
          <div className='notification_header'>
            <div>
              Notifications
            </div>
            <div className='close_bt_popup' onClick={close}>
              <p>x</p>
            </div>
          </div>
          <div className='stright_line'></div>
          <div className='notification_contentview'>
            <div className='notification_contentviewsub'>
              {isloading ? (
                <div className="loader-container">
                  <img src={pageloader.page_loader} alt="Loading..." />
                </div>
              ) : (
                <>
                  {notification.length === 0 ? (
                    <div className="no-data-table">
                      <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />

                    </div>
                  ) : (


                    notification.map((notificlist, index) => (


                      <div className='notification_content' key={index}
                      //  style={{
                      //   backgroundColor: colors[index % colors.length], 

                      // }}
                      >
                        <div className='notification_view'>
                          <div className='notification_view1'>
                            <div className='notification_viewimg'>

                              {renderNotificationIcon(notificlist.notification_type)}                        </div>
                          </div>

                          <div className='notification_view2'>
                            <span className='notification_heading'>
                              <div> <p style={{ fontSize: "14px" }}>{notificlist.content_title}</p>
                              </div>
                              <div> <p style={{ fontSize: "11px", color: "gray" }}>{notificlist.timeAgo}</p>
                              </div>
                            </span>
                            <p style={{ fontSize: "12px", color: "gray" }} className='notification_content_text '>{notificlist.content}</p>

                          </div>

                        </div>
                        {/* <div className='stright_line'></div> */}
                      </div>

                    ))

                  )}

                </>
              )
              }
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Notification
