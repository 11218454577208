import React, { useState, useRef } from 'react'
import './UserandRoles.css'
import { addroles } from '../../../Config/api';
import { toast } from 'react-toastify';
import { getRegisterId } from '../../../Config/userId';

const AddRoles = ({ show, close, updated }) => {
  const register_id = getRegisterId();
  const [loader, setloader] = useState(false);
  const [errors, setErrors] = useState({});

  const [addrole, setaddrole] = useState({
    role: "",
    user: register_id,
  });

  const inputRefs = {
    role: useRef(null),
  }

  const handleinputchange = (e) => {
    const { name, value } = e.target;
    setaddrole((state) => ({
      ...state, [name]: value,
    }));
  }

  const closepop = () => {
    close();
    setaddrole({
      role: "",
      user: register_id,
    })
    setErrors({});
  }

  const handleSubmit = async () => {
    let newErrors = {};
    const data = {
      ...addrole,
    }

    if (addrole.role === "") {
      newErrors.role = "Role is required";
      inputRefs.role.current.focus();
    }

    setErrors(newErrors);
    const errorFields = Object.keys(newErrors);
    if (errorFields.length > 0) {
      const firstErrorField = errorFields[0];
      if (inputRefs[firstErrorField]?.current) {
        inputRefs[firstErrorField].current.focus();
      }
      return;
    }
    if (Object.keys(newErrors).length === 0) {
      try {
        setloader(true);
        const response = await addroles(data);
        if (response) {
          updated(response)
          toast.success('Added successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
          closepop();
          setaddrole({
            role: "",
            user: register_id,

          })
        }
      } catch (error) {
        const newErrors = {
          response_error: [],
        };
        for (const key in error.response.data.data) {
          if (error.response.data.data.hasOwnProperty(key)) {
            newErrors.response_error.push(`${error.response.data.data[key]}`);
          }
        }
        setErrors(newErrors);

      } finally {
        setloader(false);

      }
    }

  }

  if (!show) return null;
  return (
    <div className='popup-section'>
      <div className='popup-container'>
        <span className='uploadexcel-close' onClick={closepop}>&times;</span>

        <div className='popup-container-sub'>
          <div className='addleave-heading'>
            <p>Add Role</p>

          </div>

          <div className='addleave-strightline'></div>


          <div className='usrrolepopup-content-viewing'>
            <p className='pages-notes'><span className='emppersonalinfo-mandatory'>*</span> Note : Enter your role (e.g., Hr ,Manager, GM)</p>

            <div className='popup-inputfield'>
              <div className='popup-inputfield-sub'>
                <label> Role<span className='registeration-mandatory'> *</span> </label>
                <input
                  className=''
                  type='text'
                  value={addrole.role}
                  name='role'
                  ref={inputRefs.role}
                  onChange={handleinputchange}
                />
              </div>

            </div>
            {errors.role && (<div className='error-message-view'>{errors.role}</div>)}

            {
              errors.response_error && (
                <div className='error-message-view salry-comp-err'>
                  {errors.response_error}
                </div>
              )
            }
            <br />
            <div className='popup-button'>
              <button onClick={handleSubmit} disabled={loader}>{loader ? <span className='spinner'></span> : "Submit"}</button>

            </div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default AddRoles