import React, { useState, useEffect, useCallback } from 'react'
import Select from 'react-select';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Addleave from '../Addleave/Addleave';
import { CiSearch } from 'react-icons/ci';
import ReactPaginate from "react-paginate";
import { useLocation } from 'react-router-dom'
import formatDate from '../../Component/DateConvert';
import leaveStatusText from '../LeaveStatusText';
import { CompanyApi, DesignationApi, } from "../../../Config/CommonApi";
import { DeleteLeavestaffApi, UpdateStatusLeave, ViewLeaveApi } from '../../../Config/api';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import getMonthYearData from '../../Component/MonthYearList';
import pageloader from '../../../Loader/pageloading/Pageloader';
import { CiTrash } from 'react-icons/ci';
import { updateLeave } from '../../../Slice/leaveSlice';
import { Store } from '../../../Store';
import { toast } from 'react-toastify';
import Confirmation from '../../Component/Confirmation';
import leaveStatusalert from '../Leavestatusalertview';
import DeleteAlert from '../../popup/DeleteAlert';
import perPageOptions from '../../../JsonData/Perpagelist';
import { getUserDetails } from '../../../Services/Storage';
import { getRegisterId } from '../../../Config/userId';
import { leaveAddAccess, leaveDeleteAccess, leaveStatusAccess } from '../../../Services/AccessCondition';
import LeaveReason from '../../Component/LeaveReason';

const Viewleave = () => {

  const userDetails = getUserDetails();
  const accessid = userDetails?.get_access_role?.[0]?.invite_user_details?.[0]?.register || "";
  const register_id = getRegisterId();
  const AddLeaveAccess = leaveAddAccess();
  const LeaveAccessStatus = leaveStatusAccess();
  const LeaveDeleteAccess = leaveDeleteAccess();
  const [isaddleaveopen, setaddleaveopen] = useState(false);
  const [currentpage, setCurrentPage] = useState(1);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [perpage, setperpage] = useState(10);
  const [perpagefilter, setperpagefilter] = useState({ value: 10, label: "10" })
  const [search, setSearchword] = useState("");
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [totalLeaveCount, setTotalLeaveCount] = useState(0);
  const [companyData, setCompanyData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState([]);
  const [reloadKey, setReloadKey] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, '0')); // Default to current month, formatted as two digits
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setloading] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [statusToUpdate, setStatusToUpdate] = useState(null);
  const [designationfliter, setdesignationfilter] = useState([]);
  const [userfilter, setuserfilter] = useState({ value: "all", label: "All Status" });
  const [deleteComponentId, setDeleteComponentId] = useState(null);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const location = useLocation();
  const { pending = "all" } = location.state || {};
  const [leaveStatus, setleaveStatus] = useState(pending);
  const [openReason, setOpenReason] = useState(false);
  const [reasonView, setReaseonView] = useState(false);

  useEffect(() => {
    if (pending === '0') {
      setuserfilter({ value: 0, label: "Pending" })
    }
  }, [pending]);

  useEffect(() => {
    localStorage.removeItem("salaryFilters");
    const fetchCompanyData = async () => {
      const data = await CompanyApi();
      setCompanyData(data);
      setCompanyFilter(data[0]);

    };

    const fetchDesignationData = async () => {
      const data = await DesignationApi();
      setDesignationData(data);
      setdesignationfilter(data[0]);
    };
    fetchCompanyData();
    fetchDesignationData();
  }, [reloadKey]);

  const handleChangeCompany = (selecteOption) => {
    setCompany(selecteOption.value);
    setCompanyFilter(selecteOption)
    setCurrentPage(1)
  };

  const openLeaveResponPop = (name,data) => {
    const datas ={
      "name":name,
      "leave_reason":data,
    }
    setReaseonView(datas)
    setOpenReason(true);
  };

  const closeLeaveReasonPop = () => {
    setOpenReason(false);
  };

  const handleChangeDesignation = (selecteOption) => {
    setDesignation(selecteOption.value);
    setdesignationfilter(selecteOption);
    setCurrentPage(1)
  };

  const handleChangeYear = (selectedOption) => {
    setSelectedYear(selectedOption.value);
    setCurrentPage(1)
  };

  const handleChangeMonth = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
    setCurrentPage(1)
  };

  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };

  const handleupdatestatus = async (status) => {
    setStatusToUpdate(status);
    setShowConfirmModal(true);
  };

  const handlePerPageChange = (selectedOption) => {
    console.log(selectedOption)
    setperpage(selectedOption.value);
    setperpagefilter(selectedOption)
    setCurrentPage(1);
  };

  const addleave = () => {
    if (AddLeaveAccess) {
      setaddleaveopen(true);
    }
  };

  const closeaddleave = () => {
    setaddleaveopen(false);
    setReloadKey(prevKey => prevKey + 1);
  };

  useEffect(() => {
    if (selectedLeave.length === leaves.length) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [selectedLeave, leaves.length]);

  const handleSearch = (event) => {
    setSearchword(event.target.value);
  };

  useEffect(() => {
    if (selectedLeave.length === leaves.length) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [selectedLeave, leaves.length]);

  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      setSelectedLeave([]);
    } else {
      setSelectedLeave(leaves.map(leave => leave.id));
    }
    setIsAllChecked(!isAllChecked);
  };

  const handleCheckboxChange = (id) => {
    setSelectedLeave(prevState =>
      prevState.includes(id) ? prevState.filter(leaveId => leaveId !== id) : [...prevState, id]
    );
  };

  const { months, years } = getMonthYearData();
  const monthData = Object.keys(months).sort((a, b) => a - b).map(key => (
    {
      value: key,
      label: months[key],
    }));

  const yearData = Object.keys(years).map(key => ({
    value: years[key],
    label: years[key],
  }));

  const handleDataFromChild = (data) => {
    if (data) {
      fetchleaveList();
    }
  };

  const listStatus = [
    { value: "all", label: "All Status" },
    { value: "1", label: "Approved" },
    { value: "0", label: "Pending" },
    { value: "2", label: "Rejected" },
    { value: "3", label: "Hold" },
  ];

  const handleChangeList = (selecteOption) => {
    setleaveStatus(selecteOption.value);
    setuserfilter(selecteOption);
    setCurrentPage(1);
  };

  const showConfirmationModalDelete = (delid) => {
    setDeleteComponentId(delid);
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setDeleteComponentId(null);
    setConfirmationModalOpen(false);
  };

  const confirmDelete = () => {
    if (deleteComponentId) {
      deletesalaryComponent(deleteComponentId);
      closeConfirmationModal();
    }
  };

  const fetchleaveList = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await ViewLeaveApi(
        `?register_id=${register_id}&page=${currentpage}&page_count=${perpage}&search=${search}&designation=${designation}&company=${company}&year=${selectedYear}&month=${selectedMonth}&status=${leaveStatus}&admin_id=${register_id}&access_id=${accessid}`
      );
      setLeaves(response.data.emp_leave_view);
      setTotalLeaveCount(response.data.page_context.total);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [currentpage, search, leaveStatus, company, designation, selectedYear, selectedMonth, perpage, accessid, register_id]);

  useEffect(() => {
    fetchleaveList();
  }, [fetchleaveList]);

  const deletesalaryComponent = async (componentId) => {
    try {
      const response = await DeleteLeavestaffApi(`${componentId}/?edited_by=${userDetails.id}&admin_user=${"admin_user"}`);
      if (response) {
        fetchleaveList();
                toast.success('Deleted Sucessfully!', {
                                        position: "top-center",
                                        autoClose: 2000,
                                        closeButton: false,
                                        className: 'custom_toast'
                                      });
      }
    }
    catch (error) {

    }
  };

  const handleConfirm = async () => {
    setShowConfirmModal(false);
    const payload = {
      status: statusToUpdate,
      ids: selectedLeave,
      edited_by:userDetails.id
    }
    try {
      setloading(statusToUpdate);
      const response = await UpdateStatusLeave(`${JSON.stringify(payload)}`);
      if (response) {
        Store.dispatch(updateLeave(response.data.updated_records));
        fetchleaveList();
        setSelectedLeave([]);
        toast.success('Leave Status Updated successfully!', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
      }
      setloading(null);

    } catch {
      toast.error('Failed. Please try again.', {
        position: "top-center",
        autoClose: 2000,
        closeButton: false,
      });
    }
    setloading(null);
  };

  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>
                {selectedLeave.length === 0 ? (
                  <div className='pages-subheader-sub-1'>
                    <p>Leave</p>
                    <span className='header-search-add-parent'>
                      <div className='pages-serach-view'>
                        <CiSearch className='page-search-icon' />
                        <input className='pages-serach' onChange={handleSearch} placeholder='Search Leave ' type='search' />
                      </div>
                      {AddLeaveAccess
                      ?( <button className='pages-addempbutton' onClick={addleave}>Add Leave</button>
                      ):(<></>)

                      }
                    </span>
                  </div>
                ) :
                  (
                    LeaveAccessStatus ? (
                      <div className='pages-subheader-sub-2'>
                        <button className='pages-action-button pending' onClick={() => handleupdatestatus(0)} disabled={loading !== null}> {loading === 0 ? <span className='loader-pages-tablefield'></span> : 'Pending'} </button>
                        <button className='pages-action-button approve' onClick={() => handleupdatestatus(1)} disabled={loading !== null}> {loading === 1 ? <span className='loader-pages-tablefield'></span> : 'Approve'}</button>
                        <button className='pages-action-button hold' onClick={() => handleupdatestatus(3)} disabled={loading !== null}> {loading === 3 ? <span className='loader-pages-tablefield'></span> : 'Hold'}</button>
                        <button className='pages-action-button reject' onClick={() => handleupdatestatus(2)} disabled={loading !== null}> {loading === 2 ? <span className='loader-pages-tablefield'></span> : 'Reject'} </button>
                      </div>
                    )
                      :
                      <></>
                  )
                }
                <div className='pages-line'></div>
              </div>
              <div className='pages-filter-options'>
                <div className='pages-filter-sub'>
                  <p>Filter:</p>
                  <Select className='pages-dropdown1' options={companyData} value={companyFilter} onChange={handleChangeCompany} placeholder="Select Work Location" />
                  <Select className='pages-dropdown' options={designationData} value={designationfliter} onChange={handleChangeDesignation} placeholder="Select Designation" />
                  <Select className='pages-dropdown' options={yearData} value={yearData.find(option => option.value === selectedYear)} // Pre-select the current year
                    onChange={handleChangeYear} placeholder="Select Year" />
                  <Select className='pages-dropdown' options={monthData} onChange={handleChangeMonth} value={monthData.find(option => option.value === selectedMonth)} // Pre-select the current month
                    placeholder="Select Month" />
                  <Select
                    className='pages-dropdown-last'
                    onChange={handleChangeList}
                    options={listStatus}
                    value={userfilter}
                    placeholder="Active List"
                  />
                </div>
              </div>
              <div className='pages-maincontent'>
                {isLoading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                ) : (
                  <div className="pages-table-container">
                    <table className='pages-table'>
                      {leaves.length > 0 && (
                        <thead>
                          <tr>
                          {LeaveAccessStatus
                          ?( <th>
                            <input
                              type="checkbox" checked={isAllChecked} onChange={handleAllCheckboxChange}
                            />
                          </th>)
                          :(<th></th>)

                          }
                           
                            <th>Name</th>
                            <th>Emp Id</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>No OF Days</th>
                            <th>Leave Type</th>
                            <th>Leave Reason</th>
                            <th>Status</th>
                            <th>##</th>
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {leaves.length > 0 ? (
                          leaves.map((leave, index) => (
                            <tr key={leave.id}>
                              {LeaveAccessStatus
                              ?(<td><input
                                type="checkbox"
                                checked={selectedLeave.includes(leave.id)}
                                onChange={() => handleCheckboxChange(leave.id)}
                              /></td>)
                              :(<td></td>)

                              }
                              
                              <td>{leave.name}</td>
                              <td>{leave.emp_id}</td>
                              <td>{formatDate(leave.from_date)}</td>
                              <td>{formatDate(leave.to_date)}</td>
                              <td>{leave.total_days}</td>
                              <td>{leave.leave_type} ({leave.leave_deduct})</td>

                              <td>
                                <button onClick={()=>openLeaveResponPop(leave.name,leave.reason)} className='show-leavereason'>view</button>
                              {/* <FiEye style={{color:"#0070ff", cursor:"pointer"}} onClick={()=>openLeaveResponPop(leave.name,leave.reason)}/> */}
                                </td>
                              <td style={{ color: leaveStatusText(leave.status).color }}>
                                {leaveStatusText(leave.status).text}
                              </td>
                              <td>

                                {
                                  LeaveDeleteAccess ? (
                                    <CiTrash onClick={() => showConfirmationModalDelete(leave.id)} style={{ color: "red", cursor: "pointer", fontSize: "14px" }} />
                                  )
                                    :
                                    <></>
                                }
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="no-data-table">

                              <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                )}
                <div style={{ float: "right" }} id="pagination_link"></div>
                <div className="pagenation-perpage-cont">

                  <div>
                    {(!loading) && (totalLeaveCount > perpage) ? (
                      <>
                        <ReactPaginate
                          pageCount={Math.ceil(totalLeaveCount / perpage)}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          forcePage={currentpage - 1}
                          onPageChange={currentPageNo}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </>
                    ) : (
                      <> </>
                    )}
                  </div>
                  {(!loading) && (totalLeaveCount >= 11) ? (
                    <div className="select-per-page-container">

                      <Select
                        menuPlacement="top"
                        className="per-page-container"
                        options={perPageOptions}
                        value={perpagefilter}
                        onChange={handlePerPageChange}
                        placeholder="page"
                      />

                    </div>) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Confirmation
        isOpen={showConfirmModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        message={`Are you sure you want to ${leaveStatusalert(String(statusToUpdate))} the selected leave?`}
      />

      <Addleave
        show={isaddleaveopen}
        onclose={closeaddleave}
        sendDataToParent={handleDataFromChild}
      />

      <DeleteAlert
        isOpen={isConfirmationModalOpen}
        message="Really, would you like to remove this Leave?"
        onCancel={closeConfirmationModal}
        onConfirm={confirmDelete}
      />

      <LeaveReason
        show={openReason}
        close={closeLeaveReasonPop}
        data={reasonView}
      />
    </div>
  );
}

export default Viewleave