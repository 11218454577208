import React, { useState, useEffect } from 'react';
import Sidebar from '../../../Sidebar/Sidebar';
import Header from '../../../Header/Header';
// import { FaCircleUser } from 'react-icons/fa6';
import '../../Style/Addemp.css';
import { UpdateEmployeePersonalApi } from '../../../../Config/api';
import { Store } from '../../../../Store';
import { setUpdatePersonalInfo as UpdateEmployeeRedux } from '../../../../Slice/employeeSlice';
import Title from '../../../../JsonData/Tittle';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getRegisterId } from '../../../../Config/userId';
import { getUserDetails } from '../../../../Services/Storage';

const Editpersonalinfo = () => {
  
  const user_details = getUserDetails();
  const register_id = getRegisterId();
  const personalInfo1 = useSelector((state) => state.employees);
  const [personalInfo, setPersonalInfo] = useState(null);
  useEffect(() => {
    if (personalInfo1 && personalInfo1.employees.length > 0) {
      setPersonalInfo(personalInfo1.employees[0]);
    }
  }, [personalInfo1]);

  const [errors, setErrors] = useState({});
  const [loader, setloader] = useState(false);
  const [updatePersonalInfo, setUpdatePersonalInfo] = useState({
    name: "",
    personal_email: "",
    phone: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    pincode: "",
    user_mr_ms: 'Mr',
    edited_by: user_details.id,
    emp_edit: "emp_edit",


  });
  useEffect(() => {
    if (personalInfo) {
      console.log(personalInfo)
      setUpdatePersonalInfo({
        edited_by: user_details.id,
        emp_edit: "emp_edit",
        name: personalInfo.register_details.name  || "",
        personal_email: personalInfo.register_details.personal_email || "",
        phone: personalInfo.register_details.phone  || "",
        address_line1: personalInfo.register_details.address_line1  || "",
        address_line2: personalInfo.register_details.address_line2,
        city: personalInfo.register_details.city  || "",
        state: personalInfo.register_details.state  || "",
        pincode: personalInfo.register_details.pincode  || "",
        user_mr_ms: personalInfo.register_details.user_mr_ms  || ""
      })
    }
  }, [personalInfo,register_id,user_details.id])

  const handleInputChange = (event) => {
    setErrors({});
    const { name, value } = event.target;
    setUpdatePersonalInfo((state) => ({
      ...state, [name]: value,
    }));
  }
  const handlekeypress = (e) => {
    const restrictedpattern = /[0-9!@#$%^&*(),.?":{}|<>+_]/;
    if ((e.target.name === 'name' || e.target.name === 'state' || e.target.name === 'city') && restrictedpattern.test(e.key)) {
      e.preventDefault();
    }
  }
  const handlekeynumber = (e) => {
    const restricpattern = /[^0-9]/g;
    if ((e.target.name === 'phone' || e.target.name === 'pincode') && restricpattern.test(e.key)) {
      e.preventDefault();

    }
  }
  const handleSUbmit = async () => {
    let newErrors = {};
    if (updatePersonalInfo.name.trim() === "") {
      newErrors.name = "Name is required"
    }
    if (updatePersonalInfo.personal_email.trim() === "") {
      newErrors.personal_email = "Email is required"
    } else if (!/^\S+@\S+\.\S+$/.test(updatePersonalInfo.personal_email)) {
      newErrors.personal_email = 'Invalid email format';
    }
    const phones = String(updatePersonalInfo.phone)
    if (phones.trim() === "") {
      newErrors.phone = "Phone Number is required"
    } else if (!/^\d{10}$/.test(updatePersonalInfo.phone)) {
      newErrors.phone = "Phone Number must be exactly 10 digits";
    }
    if (updatePersonalInfo.address_line1.trim() === "") {
      newErrors.address_line1 = "Address is required"
    }
    if (updatePersonalInfo.address_line2.trim() === "") {
      newErrors.address_line2 = "Address is required"
    }
    if (updatePersonalInfo.city.trim() === "") {
      newErrors.city = "City is required"
    }
    if (updatePersonalInfo.state.trim() === "") {
      newErrors.state = "State is required"
    }
    const pin = String(updatePersonalInfo.pincode)
    if (pin.trim() === "") {
      newErrors.pincode = "Pincode is required"
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setloader(true)
        const response = await UpdateEmployeePersonalApi(personalInfo.register_details.id, updatePersonalInfo);
        if (response) {
          toast.success('Personal Details Updated successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          }
          );
          Store.dispatch(UpdateEmployeeRedux({ updatedData: response.data.updated_registration }));
        }
      }
      catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};
          // Handle specific field errors
          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors[key] = errorData[key]; // Assign the specific error message to the respective field
            }
          }

          setErrors(newErrors);
        } else {
          toast.error('Employee Registration failed. Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });

          // Handle generic errors
          setErrors({
            response_error: 'An unexpected error occurred. Please try again later.',
          });
        }
      }
      finally {
        setloader(false)
      }
    }
  }

  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='addemp-subheader'>
                <div className='addemp-subheader-sub'>
                  {/* <div className='addemp-subheader1'>
<p>Edit Personal Information</p>
</div> */}
                  <div className='addemp-subheader2'>
                    <div className='editbasic-stepper'>
                      {/* < FaCircleUser className="editbasic-icon-large" /> */}
                      <p>{updatePersonalInfo.name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='addemp-line'></div>
              <div className='addemp-maincontent-sub'>
                <div className='addemp-form form-padding'>
                  <div className='addemp-form-sub'>
                    <div className='addemp-form-sub-main'>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label> Name <span className='emppersonalinfo-mandatory'>*</span></label>
                          <div className='tittle-view-in'>

                            <select className='tittle-view-field' value={updatePersonalInfo.user_mr_ms} name='user_mr_ms' onChange={handleInputChange}>

                              {Title.title.map((tittle, index) => (
                                <option key={index}>
                                  {tittle}

                                </option>
                              )

                              )

                              };

                            </select>
                            <input className='emppersonalinfo-input-field tittle-view'
                              onChange={(e) => {
                                const value = e.target.value;
                                // Allow only letters and spaces, disallow special characters
                                if (/^[a-zA-Z\s]*$/.test(value)) {
                                  handleInputChange(e); // Call your existing handler if valid
                                }
                              }}
                              placeholder='Enter name' name='name' value={updatePersonalInfo.name} onKeyPress={handlekeypress} type='text' />
                          </div>
                          {errors.name && (<div className='error-message-view'>{errors.name}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label> Phone  <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input className='emppersonalinfo-input-field' placeholder='Enter mobile no' onKeyPress={handlekeynumber} name='phone' value={updatePersonalInfo.phone} onChange={handleInputChange} type='text' />
                          {errors.phone && (<div className='error-message-view'>{errors.phone}</div>)}

                        </div>
                      </div>
                      <div className='emppersonalinfo-input-col'>
                        <div className='emppersonalinfo-col-view'>
                          <label>Email <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-inputfield-col' onKeyDown={(e) => {
                            const regex = /^[a-zA-Z0-9@._\b]+$/; // Allowed characters: alphanumeric, '@', '.', and '_'
                            if (!regex.test(e.key)) {
                              e.preventDefault(); // Prevents the key press if it's not allowed
                            }
                          }} placeholder='abc@xyz.com' name='personal_email' value={updatePersonalInfo.personal_email} onChange={handleInputChange} type='text' />
                        </div>
                        {errors.personal_email && (<div className='error-message-view'>{errors.personal_email}</div>)}
                      </div>
                      <div className='emppersonalinfo-input-col'>
                        <div className='emppersonalinfo-col-view'>
                          <label>Address Line1 <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-inputfield-col' placeholder='Enter address line 1' name='address_line1' value={updatePersonalInfo.address_line1} onChange={handleInputChange} type='text' />
                        </div>
                        {errors.address_line1 && (<div className='error-message-view'>{errors.address_line1}</div>)}
                      </div>
                      <div className='emppersonalinfo-input-col'>
                        <div className='emppersonalinfo-col-view'>
                          <label>Address Line2 <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-inputfield-col' placeholder='Enter address line 2' name='address_line2' value={updatePersonalInfo.address_line2} onChange={handleInputChange} type='text' />
                        </div>
                        {errors.address_line2 && (<div className='error-message-view'>{errors.address_line2}</div>)}
                      </div>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label> City <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-input-field3' placeholder='Enter city' name='city' onKeyPress={handlekeypress} value={updatePersonalInfo.city} onChange={handleInputChange} type='text' />
                          {errors.city && (<div className='error-message-view'>{errors.city}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label> State <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input className='emppersonalinfo-input-field3' placeholder='Enter State' name='state' onKeyPress={handlekeypress} value={updatePersonalInfo.state} onChange={handleInputChange} type='text' />
                          {errors.state && (<div className='error-message-view'>{errors.state}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label> Pincode <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input className='emppersonalinfo-input-field3' placeholder='Enter pincode' name='pincode' onKeyPress={handlekeynumber} value={updatePersonalInfo.pincode} onChange={handleInputChange} type='text' />
                          {errors.pincode && (<div className='error-message-view'>{errors.pincode}</div>)}
                        </div>
                      </div>
                      {errors.response_error && (<div className='error-message-view'>{errors.response_error}</div>)}

                      <div className='emppersonalinfo-submitbutton'>
                        <button onClick={handleSUbmit}>
                          {loader ? <span className='spinner'></span> : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editpersonalinfo