import React, { useState, useEffect, useCallback } from "react";
import Header from "../../Header/Header";
import Select from "react-select";
import "../../Dashboard/Index.css";
import Sidebar from "../../Sidebar/Sidebar";
import "../../../Assets/Pages.css";
import { CiSearch } from "react-icons/ci";
import { addselectedsalarycomp, ViewOtherUser, viewselectedslary } from "../../../Config/api";
import ReactPaginate from "react-paginate";
import "../../../Assets/Pagination.css";
import { Link } from 'react-router-dom';
import nodatafound from "../../../Images/Noimagefound/Noimgfound";
import pageloader from "../../../Loader/pageloading/Pageloader";
import perPageOptions from "../../../JsonData/Perpagelist";
import { getUserDetails } from "../../../Services/Storage";
import { getRegisterId } from "../../../Config/userId";
import { formatNumberDecimal } from "../../Component/NumberFormatDecimal";
import OtherComponetAlert from "./OtherComponetAlert";
import { toast } from 'react-toastify';
import { CompanyApi } from "../../../Config/CommonApi";

const OtherComponent = () => {

    const userDetails = getUserDetails();
    const accessid = userDetails?.get_access_role?.[0]?.invite_user_details?.[0]?.register || "";
    const register_id = getRegisterId();
    const [currentpage, setCurrentPage] = useState(1);
    const [search, setSearchword] = useState("");
    const [addLoading, setAddLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [isloading, setIsLoading] = useState(true);
    const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
    const [employees, setEmployees] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [perpage, setperpage] = useState(10);
    const [perpagefilter, setperpagefilter] = useState({ value: 10, label: "10" });
    const [datasSalaryComp, setDatasSalaryComp] = useState('');
    const [viewCompTypeSel, viewCompTypesel] = useState({ value: "earning", label: "Earnings" });
    const [selectedComptype, setselectedCompType] = useState("earning");
    const [salaryCompFilter, setsalaryCompFilter] = useState("");
    const [openAlertPop, setOPenAlertPop] = useState(false);
    const [updatedList, setUpdatedList] = useState("");
    const [compId, setCompId] = useState("")
    const [alertValue, setAlerValue] = useState('')
    const [companyData, setCompanyData] = useState([]);
    const [companyFilter, setCompanyFilter] = useState([]);
    const [company, setCompany] = useState('');

    const handlePerPageChange = (selectedOption) => {
        setperpage(selectedOption.value);
        setperpagefilter(selectedOption)
        setCurrentPage(1);
    };

    useEffect(() => {
        if (selectedEmployees.length === employees.length) {
            setIsAllChecked(true);
        } else {
            setIsAllChecked(false);
        }
    }, [selectedEmployees, employees.length]);

    const handleAllCheckboxChange = () => {
        if (isAllChecked) {
            setSelectedEmployees([]);
        } else {
            setSelectedEmployees(employees.map((employee) => employee.register_id));
        }
        setIsAllChecked(!isAllChecked);
    };

    const handleCheckboxChange = (id) => {
        setSelectedEmployees((prevState) =>
            prevState.includes(id)
                ? prevState.filter((empId) => empId !== id)
                : [...prevState, id]
        );
    };

    const handleSearch = (event) => {
        setSearchword(event.target.value);
    }

    const listSalaryComp = [
        { value: "earning", label: "Earnings" },
        { value: "deduction", label: "Deductions" },
    ];

    const currentPageNo = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };

    const fetchEmployeeList = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await ViewOtherUser(
                `?register_id=${register_id}&page=${currentpage}&page_count=${perpage}&company=${company}&search=${search}&access_id=${accessid}`
            );
            setEmployees(response.data.get_salary_component_staff);
            setTotalEmployeeCount(response.data.page_context.total);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }, [currentpage, register_id, perpage, search, accessid,company]);

    useEffect(() => {
        fetchEmployeeList();
    }, [fetchEmployeeList]);

    useEffect(() => {
        const fetchCompanyData = async () => {
          const data = await CompanyApi();
          setCompanyData(data);
          setCompanyFilter(data[0]);
        };

        fetchCompanyData();
      }, [register_id]);
    
    useEffect(() => {
        const fetchsalaryComponent = async () => {
            try {
                const response = await viewselectedslary(`?register_id=${register_id}&filter_component=${selectedComptype}`);
                if (response.data.status === 200) {
                    const selectSalaryComp = response.data.get_all_salary_component.map(comp => ({
                        value: comp,
                        label: comp.component
                    }));
                    setDatasSalaryComp(selectSalaryComp);

                }
                // Store.dispatch(setSalaryComponentRedux(response.data.get_salary_component_earning));
            } catch (error) {
            }
        }

        fetchsalaryComponent();
    }, [register_id, selectedComptype]);

    const handleChangeList = (data) => {
        setselectedCompType(data.value);
        viewCompTypesel(data);
        setsalaryCompFilter("")

    };

    const handleChangeSalaryComp = (data) => {
        setsalaryCompFilter(data);
        setCompId(data.value.id);
        handleInputChange([data.value]);
    };

    const handleInputChange = (data) => {
        const seletedCompId = data[0].id;
        if (!Array.isArray(data)) {
            return;
        }

        const new_list = selectedEmployees.map((empId) => {
            const employee = employees.find(emp => emp.register_id === empId);
            const annualCTC = employee ? parseFloat(employee.annual_ctc || 0) : 0;
            const salaryList = employee?.salary_list || {};

            let updatedSalaryComponent = {}; // Change from array to object

            data.forEach((component) => {
                let monthlyAmount = 0;
                let yearlyAmount = 0;

                const basicComponent = data.find(comp => comp.calculation_code.toLowerCase() === 'basic');
                console.log("basic component",basicComponent)
                const basicAmount = basicComponent ? (annualCTC*basicComponent.amount_type)/100 : 0;
                if (component.calculation_type === 'Percentage of basic') {
                    yearlyAmount = (basicAmount * component.amount_type) / 100;
                } else if (component.calculation_type === 'Float amount') {
                    yearlyAmount = parseFloat(component.amount_type);
                } else if (component.calculation_type === 'Percentage of CTC') {
                    yearlyAmount = (annualCTC * component.amount_type) / 100;
                };

                monthlyAmount = yearlyAmount / 12;

                updatedSalaryComponent = {
                    ...component,
                    amount_type: component.amount_type,
                    monthly: formatNumberDecimal(monthlyAmount),
                    yearly: formatNumberDecimal(yearlyAmount),
                };
            });
            // console.log(updatedSalaryComponent)
            return {
                salary_list: salaryList,
                new_salary_component: updatedSalaryComponent, // Now it's an object instead of an array
                register_id: empId,
            };
        });

        const updatelist = {
            "component_status": "add",
            "component_id": seletedCompId,
            "edited_by": userDetails.id,
            "user_id": register_id,
            "selected_employee_id": selectedEmployees,
            new_list,
        };

        setUpdatedList(updatelist);
                 console.log(updatelist);

        return updatelist;
    };


    const closeAlertPop = () => {
        setOPenAlertPop(false);
    };

    const openAlertPopField = (value) => {
        setAlerValue(value);
        setOPenAlertPop(true);
    };

    const handleChangeCompany = (selecteOption) => {
        setCompany(selecteOption.value);
        setCompanyFilter(selecteOption);
        setCurrentPage(1);
      };

    const handleConfirm = async (data) => {
        setOPenAlertPop(false);
        if (data === "Add") {
            setAddLoading(true)

            try {
                const response = await addselectedsalarycomp(updatedList);
                if (response) {
                    toast.success('Added Sucessfully!', {
                        position: "top-center",
                        autoClose: 2000,
                        closeButton: false,
                        className: 'custom_toast'
                    });
                    fetchEmployeeList();
                    setSelectedEmployees([]);

                    setsalaryCompFilter("");

                }
            } catch {
                   toast.error('Failed. Please try again.', {
                          position: "top-center",
                          autoClose: 2000,
                          closeButton: false,
                
                        });
            }finally{

                setAddLoading(false)
            }
        } else {
            setRemoveLoading(true)
            try {
                const data = {
                    component_status: "remove",
                    component_id: compId,
                    selected_employee_id: selectedEmployees,
                    edited_by: userDetails.id,
                    user_id: register_id,
                }
                const response = await addselectedsalarycomp(data);
                if (response) {
                    toast.success('Removed Sucessfully!', {
                        position: "top-center",
                        autoClose: 2000,
                        closeButton: false,
                        className: 'custom_toast'
                    });
                    fetchEmployeeList();
                    setsalaryCompFilter("");
                    setSelectedEmployees([]);
                    setCompId("");
                }
            } catch {
   toast.error('Failed. Please try again.', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,

        });
            }finally{
                setRemoveLoading(false)
            }
        }
    }

    return (
        <>
            <div className="index-container">
                <Header />
                <div className="index-content-parent">
                    <div className="index-content">
                        <Sidebar />
                        <div className="index-content-sub">
                            <div className="index-main">
                                <div className="pages-subheader">
                                    {selectedEmployees.length === 0 ? (
                                        <>
                                            <div className="pages-subheader-sub-1">
                                                <p>Other</p>
                                                <span className="header-search-add-parent">

                                                    <div className="pages-serach-view">
                                                        <CiSearch className="page-search-icon" />
                                                        <input
                                                            onChange={handleSearch}
                                                            className="pages-serach"
                                                            placeholder="Search Employee"
                                                            type="search"
                                                        />
                                                    </div>

                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="pages-subheader-sub-2">
                                                <p>Select here:</p>
                                                <Select
                                                    className="pages-dropdown"
                                                    onChange={handleChangeList}
                                                    options={listSalaryComp}
                                                    value={viewCompTypeSel}
                                                    placeholder="Select Component"
                                                />

                                                <Select
                                                    className="pages-dropdown"
                                                    onChange={handleChangeSalaryComp}
                                                    options={datasSalaryComp}
                                                    value={salaryCompFilter}
                                                    placeholder="Select Component"
                                                />
                                                {salaryCompFilter
                                                    ? (<> <div className="other-comp-topbuttons">
                                                        <button className="other-comp-add" onClick={() => openAlertPopField("Add")}>
                                                            {addLoading ?

                                                                <span className='loader-pages-tablefield'></span>
                                                                :'Add'
                                                            }
                                                            </button>
                                                        <button className="other-compon-delete" onClick={() => openAlertPopField("Remove")}>
                                                            {removeLoading
                                                            ?<span className='loader-pages-tablefield'></span>
                                                            :'Remove'

                                                            }
                                                            </button>
                                                    </div>
                                                    </>)
                                                    : (<></>)
                                                }
                                                {/* <div className="other-comp-topbuttons">
                                                    <button className="other-comp-topbuttons1" onClick={() => openAlertPopField("Add")}>Add</button>
                                                    <button className="other-comp-topbuttons2"  onClick={() => openAlertPopField("Remove")}>Remove</button>
                                                </div> */}


                                            </div>
                                        </>
                                    )}
                                    <div className="pages-line"></div>
                                </div>
                                <div className="pages-filter-options">
                                    <div className="pages-filter-sub">
                                        <div className="earnings-stepper">
                                            <div className="earnings-step-indicator">
                                                <Link to="/addearningcomp" className='earnings-redirect-link2'>
                                                    <div className='earnings-step '>
                                                        Earnings
                                                    </div>
                                                </Link >
                                                <Link to="/adddeductioncomp" className='earnings-redirect-link2'>
                                                    <div className='earnings-step '>
                                                        Deductions
                                                    </div>
                                                </Link>
                                                <Link to="/otherComp" className='earnings-redirect-link'>
                                                    <div className='earnings-step earnings-selected'>
                                                        Other
                                                    </div>
                                                </Link>
                                                <Select
                      className="pages-dropdown1"
                      options={companyData}
                      onChange={handleChangeCompany}
                      value={companyFilter}
                      placeholder="Select Work Location"
                    />
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="pages-maincontent">
                                    {isloading ? (
                                        <div className="loader-container">
                                            <img src={pageloader.page_loader} alt="Loading..." />
                                        </div>
                                    ) : (
                                        <div className="pages-table-container">
                                            <table className="pages-table">
                                                {employees.length > 0 && (
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isAllChecked}
                                                                    onChange={handleAllCheckboxChange}
                                                                />
                                                            </th>
                                                            <th>Name</th>
                                                            <th>Employee Id</th>
                                                            <th>Annual CTC</th>
                                                            <th>Monthly CTC</th>
                                                            <th>Salary Component</th>
                                                        </tr>
                                                    </thead>
                                                )}

                                                <tbody>
                                                    {employees.length > 0 ? (
                                                        employees.map((employee, index) => (
                                                            <tr key={employee.register_id}>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedEmployees.includes(employee.register_id)}
                                                                        onChange={() => handleCheckboxChange(employee.register_id)}
                                                                    />
                                                                </td>
                                                                <td>

                                                                    {employee.name}




                                                                </td>
                                                                <td>{employee.emp_id}</td>
                                                                <td>{employee.annual_ctc}</td>
                                                                <td>{employee.monthly_salary}</td>
                                                                <td>
                                                                    <div className="dataview-dropdown1">
                                                                        <button className="dropbtn">
                                                                            View
                                                                            <span className="arrow-down"></span>
                                                                        </button>
                                                                        <div className="dataview-dropdown-content1">
                                                                            {employee.salary_list.map((salary) => (
                                                                                <p key={salary.id}>{salary.component}</p>
                                                                            ))}
                                                                        </div>
                                                                    </div>

                                                                </td>


                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={8} className="no-data-table">
                                                                <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                    )
                                    }
                                    <div style={{ float: "right" }} id="pagination_link"></div>
                                    <div className="pagenation-perpage-cont">
                                        <div >
                                            {(!isloading) && (totalEmployeeCount > perpage) ? (
                                                <>

                                                    <div>
                                                        <ReactPaginate
                                                            pageCount={Math.ceil(totalEmployeeCount / perpage)}
                                                            pageRangeDisplayed={3}
                                                            marginPagesDisplayed={2}
                                                            onPageChange={currentPageNo}
                                                            forcePage={currentpage - 1}
                                                            containerClassName={"pagination"}
                                                            pageClassName={"page-item"}
                                                            pageLinkClassName={"page-link"}
                                                            previousClassName={"page-item"}
                                                            previousLinkClassName={"page-link"}
                                                            nextClassName={"page-item"}
                                                            nextLinkClassName={"page-link"}
                                                            breakClassName={"page-item"}
                                                            breakLinkClassName={"page-link"}
                                                            activeClassName={"active"}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                </>
                                            )}
                                        </div>
                                        {(!isloading) && (totalEmployeeCount >= 11) ? (
                                            <div className="select-per-page-container">
                                                <Select
                                                    menuPlacement="top"
                                                    className="per-page-container"
                                                    options={perPageOptions}
                                                    value={perpagefilter}
                                                    onChange={handlePerPageChange}
                                                    placeholder="page"
                                                />
                                            </div>) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OtherComponetAlert
                    show={openAlertPop}
                    close={closeAlertPop}
                    onConfirm={handleConfirm}
                    data={alertValue}
                />
            </div>
        </>
    );
};

export default OtherComponent;