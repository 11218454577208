import React from 'react'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import { CiImport } from 'react-icons/ci';

const UploadExcelNote = () => {

    const downloadExcel = () => {
        const fileUrl = "SampleFile/Sample attendance excel sheet.csv"; // Path relative to the public folder
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "sample.csv"; // Filename for the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='pages-subheader'>
                                <div className='pages-subheader-sub-1'>
                                    <p>Attendance Excel Sheet Format Explanation</p>
                                    <span className='header-search-add-parent'>
                                    </span>
                                </div>
                                <div className='pages-line'></div>
                            </div>
                            <div className='pages2-maincontent'>
                                <div className='uploadexcelnote-main'>
                                <h3>Download Sample Excel Sheet</h3>
    <div className='uploadexcel-download-div'>
        <p>Click Here to Download Sample File </p>
        <button className='uploadexcel-download' onClick={downloadExcel}>Click <CiImport /></button>
    </div>
                                <h3>Columns and Their Meaning</h3>
    <ol>
        <li>Attendance (Column A) -  Contains the **date** of attendance (e.g., "19-03-2025").</li>
        <li>Employee ID (Column B) - Unique identification number assigned to the employee (e.g., "AA1006").</li>
        <li>Employee Name (Column C) - Name or designation of the employee (e.g., "User").</li>
        <li>Department (Column D) - Department to which the employee belongs (e.g., "ADMINIST GS").</li>
        <li>Shift Code (Column E) - Code representing the assigned shift schedule.</li>
        <li>Begin Time (Column F) - Scheduled start time of the shift (e.g., "09:30").</li>
        <li>End Time (Column G) - Scheduled end time of the shift (e.g., "18:30").</li>
        <li>In Time (Column H) - Actual check-in time of the employee (e.g., "08:14:15").</li>
        <li>Out Time (Column I) - Actual check-out time of the employee (e.g., "19:10:21").</li>
        <li>Duration (Column J) - Total expected shift duration (e.g., "09:00" indicating 9 hours).</li>
        <li>Late By (Column K) - Time the employee was late to work (e.g., "01:09" means 1 hour and 9 minutes late).</li>
        <li>Early By (Column L) - Time the employee left early (typically "00:00" if they completed their shift).</li>
        <li>Over Time (Column M) - Extra hours worked beyond the scheduled shift (e.g., "01:56" means 1 hour and 56 minutes overtime).</li>
        <li>Attendance Status (Column N) - Indicates whether the employee was <em>Present</em> or <em>Absent</em>.</li>
        <li>Status Code (Column O) - A short code for attendance status (e.g., "P" for Present, "A" for Absent).</li>

    </ol>

    <h3>Key Observations</h3>
    <ul>
        <li>The sheet records employee attendance details, including <strong>shift timing, actual working hours, late arrivals, early departures, and overtime.</strong></li>
        <li>If an employee is <strong>Absent</strong>, the "Attendance Status" will display <strong>Absent</strong>, and late/early/overtime values may not be applicable.</li>
        <li><strong>Employee ID and Employee UID appear to be the same</strong>, likely for verification purposes.</li>
    </ul>
   
                                </div>
                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadExcelNote;