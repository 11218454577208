import React, { useState, useEffect } from 'react'
import Sidebar from '../../../Sidebar/Sidebar';
import Header from '../../../Header/Header';
import '../../AddEmployees/Payment/Payment.css'
import '../../Style/Addemp.css'
import { UpdateEmployeePaymentApi } from '../../../../Config/api';
import { Store } from '../../../../Store';
import { setUpdatePaymentInfo as updatePaymentRedux } from '../../../../Slice/employeeSlice';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getRegisterId } from '../../../../Config/userId';
import { getUserDetails } from '../../../../Services/Storage';

const Editpaymentinfo = () => {

  const user_details = getUserDetails();
  const register_id = getRegisterId();
  const paymentInfo1 = useSelector((state) => state.employees);
  const [Empname, setEmpName] = useState("");
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(false);

  const [updatePaymentInfo, setUpdatePaymentInfo] = useState({
    bank_name: "",
    account_num: "",
    pan_num: "",
    aadhar_num: "",
    UAN: "",
    edited_by: user_details.id,
    user: register_id,


  })

  useEffect(() => {
    if (paymentInfo && paymentInfo.payment_details) {
      setUpdatePaymentInfo({
        bank_name: paymentInfo.payment_details.bank_name || '',
        account_num: paymentInfo.payment_details.account_num || '',
        pan_num: paymentInfo.payment_details.pan_num || '',
        aadhar_num: paymentInfo.payment_details.aadhar_num || '',
        UAN: paymentInfo.payment_details.UAN || '',
        edited_by: user_details.id,
        user: register_id,

      });
    }
  }, [paymentInfo, register_id,user_details.id]);

  useEffect(() => {
    if (paymentInfo1 && paymentInfo1.employees.length > 0) {
      setPaymentInfo(paymentInfo1.employees[0]);
      setEmpName(paymentInfo1.employees[0].register_details.name);
    }
  }, [paymentInfo1]);

  const handleInputChange = (event) => {
    setErrors({});
    const { name, value } = event.target;
    setUpdatePaymentInfo((state) => ({
      ...state, [name]: value,
    }))
  }

  const handlekeypress = (e) => {
    const restrictedpattern = /[0-9!@#$%^&*(),.?":{}|<>+_]/;
    if ((e.target.name === 'bank_name') && restrictedpattern.test(e.key)) {
      e.preventDefault();
    }
  }
  const handlekeynumber = (e) => {
    const restricpattern = /[^0-9]/g;
    if ((e.target.name === 'aadhar_num' || e.target.name === 'account_num') && restricpattern.test(e.key)) {
      e.preventDefault();

    }
  }
  
  const handleKeynumstr = (e) => {
    const restricpattern = /[!@#$%^&*(),.?":'=;{}|<>+_]/;
    if ((e.target.name === 'pan_num' || e.target.name === 'UAN') && restricpattern.test(e.key)) {
      e.preventDefault();
    }
    // if (e.target.name === 'pan_num' && e.key >= 'a' && e.key <= 'z') {
    //   e.preventDefault();
    //   e.target.value += e.key.toUpperCase();
    // }
  };
  
  const handleInput = (e) => {
    const target = e.target;
    const start = target.selectionStart;
    const end = target.selectionEnd;
    const originalValue = target.value;
  
    // Update the value
    target.value = originalValue.toUpperCase().replace(/[^A-Z0-9]/g, '');
  
    // Restore the cursor position
    if (target.value !== originalValue) {
      const newLength = target.value.length;
      const newStart = Math.max(0, start - (originalValue.length - newLength));
      const newEnd = Math.max(0, end - (originalValue.length - newLength));
      target.setSelectionRange(newStart, newEnd);
    }
  };
  
  const handleSubmit = async () => {
    let newErrors = {};
    const panPattern = /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/;
    const twelveDigitPattern = /^\d{12}$/;
    const accountPattern = /^\d{9,18}$/;


    setErrors(newErrors);
    if (updatePaymentInfo.bank_name.trim() === "") {
      newErrors.bank_name = "Bank Name is required"
    }
    const aadhar = String(updatePaymentInfo.aadhar_num);

    if (aadhar.trim() === "") {
      newErrors.aadhar_num = "Aadhar Number is required"
    } else if (!twelveDigitPattern.test(aadhar)) {
      newErrors.aadhar_num = "Aadhar Number must be 12 digits.";
    }
    if (updatePaymentInfo.pan_num.trim() === "") {
      newErrors.pan_num = "Pan Number is required"
    } else if (!panPattern.test(updatePaymentInfo.pan_num)) {
      newErrors.pan_num = "Invalid PAN format. Example: ABCDE1234F";
    }
    // if (updatePaymentInfo.UAN.trim() === "") {
    //   newErrors.UAN = "UAN is required"
    // } else if (!twelveDigitPattern.test(updatePaymentInfo.UAN)) {
    //   newErrors.UAN = "UAN must be 12 digits.";
    // }
    if (updatePaymentInfo.UAN.trim() !== "" && !/^\d{12}$/.test(updatePaymentInfo.UAN.trim())) {
      newErrors.UAN = "UAN must be 12 digits.";
    }
    
    const accnumber = String(updatePaymentInfo.account_num);

    if (accnumber.trim() === "") {
      newErrors.account_num = "Account Number is required"
    } else if (!accountPattern.test(accnumber)) {
      newErrors.account_num = "Account Number must be 9-18 digits.";
    }
    if (Object.keys(newErrors).length === 0) {
      try {
        setloading(true)
        const response = await UpdateEmployeePaymentApi(paymentInfo.payment_details.id, updatePaymentInfo);
        toast.success('Payment Details Updated Sucessfully!', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
          className: 'custom_toast',
        })
        Store.dispatch(updatePaymentRedux({ updatedData: response.data.updated_payment }))
      }
      catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};
          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors[key] = errorData[key];
            }
          }
          setErrors(newErrors);

        } else {
          toast.error('Payment Details Updated Failed!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false
          })

        }
      }
      finally {
        setloading(false);
      }
    }
  }

  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='addemp-subheader'>
                <div className='addemp-subheader-sub'>
                  <div className='addemp-subheader2'>
                    <div className='editbasic-stepper'>
                      <p>{Empname}</p>
                    </div>
                  </div>
                </div>

              </div>
              <div className='addemp-line'></div>
              <div className='addemp-maincontent-sub'>
                <div className='addemp-form form-padding'>
                  <div className='addemp-form-sub '>
                    <div className='addemp-form-sub-main'>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label>Bank Name <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-input-field' onKeyPress={handlekeypress} placeholder='Enter bank name' name='bank_name' value={updatePaymentInfo.bank_name}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^[a-zA-Z\s]*$/.test(value)) {
                                handleInputChange(e);
                              }
                            }}
                            type='text' />
                          {errors.bank_name && (<div className='error-message-view'>{errors.bank_name}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label> Account Number  <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input className='emppersonalinfo-input-field' onKeyPress={handlekeynumber} placeholder='Enter account no' name='account_num' value={updatePaymentInfo.account_num} onChange={handleInputChange} type='text' />
                          {errors.account_num && (<div className='error-message-view'>{errors.account_num}</div>)}
                        </div>

                      </div>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label>Pan Number <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-input-field' onKeyPress={handleKeynumstr}    onInput={handleInput}
 placeholder='Enter pan number' name='pan_num' value={updatePaymentInfo.pan_num} onChange={handleInputChange} type='text' />
                          {errors.pan_num && (<div className='error-message-view'>{errors.pan_num}</div>)}
                        </div>


                      </div>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label> Aadhar Number  <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input className='emppersonalinfo-input-field' onKeyPress={handlekeynumber} placeholder='Enter aadhar number' name='aadhar_num' value={updatePaymentInfo.aadhar_num} onChange={handleInputChange} type='text' />
                          {errors.aadhar_num && (<div className='error-message-view'>{errors.aadhar_num}</div>)}
                        </div>
                      </div>
                      <div className='emppersonalinfo-input-row'>

                        <div className='emppersonalinfo-input-group'>
                          <label>UAN</label>

                          <input className='emppersonalinfo-input-field' placeholder='Enter UAN number' onKeyPress={handleKeynumstr} name='UAN' value={updatePaymentInfo.UAN} onChange={handleInputChange} type='text' />
                          {errors.UAN && (<div className='error-message-view'>{errors.UAN}</div>)}
                        </div>

                      </div>
                      {errors.response_error && (<div className='error-message-view'>{errors.response_error}</div>)}

                      <div className='emppersonalinfo-submitbutton'>
                        <button onClick={handleSubmit} disabled={loading}>
                          {loading ? <span className='spinner'></span> : "Submit"}

                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editpaymentinfo