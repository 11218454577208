import React, { useState, useEffect, useCallback } from 'react'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import { CiEdit, CiSearch, CiTrash } from 'react-icons/ci'
import pageloader from '../../../Loader/pageloading/Pageloader'
import nodatafound from '../../../Images/Noimagefound/Noimgfound'
import ReactPaginate from "react-paginate";
import { deleteroles, ViewRolespage } from '../../../Config/api'
import { getUserDetails } from '../../../Services/Storage'
import { Link } from 'react-router-dom';
import AddRoles from './AddRoles'
import DeleteAlert from '../../popup/DeleteAlert'
import EditRoles from './EditRoles'
import Deleteconfirmation from '../../Component/Deleteconfirmation'
import { toast } from 'react-toastify';

const ViewRoles = () => {
  const [search, setSearchword] = useState("");
  const [totaldedcount, setdedcount] = useState(0)
  const [loading, setIsloading] = useState(false);
  const [openaddrole, setopenaddrole] = useState(false)
  const [currentpage, setCurrentPage] = useState(1);
  const [updatedatas, setupdatedatas] = useState()
  const [edituserandroles, setedituserandroles] = useState(false);
  const [userandroles, setuserandroles] = useState()
  const [fieldid, setfieldid] = useState(null);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const user_details = getUserDetails();
  const [opendeleteconf, setdeleteconf] = useState(false);
  const [deleteComponentData, setDeleteComponentData] = useState([]); // New state to hold data
  const per_page = 10;

  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleSearch = (event) => {
    setSearchword(event.target.value);
  };

  const Fetchuserandroledetail = useCallback(async () => {
    try {
      setIsloading(true);

      const response = await ViewRolespage(`?user_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}`);
      if (response) {
        setuserandroles(response.data.get_role_with_pagination);
        setdedcount(response.data.page_context.total);
      }
    } catch (error) {
  
    } finally {
      setIsloading(false);
    }
  }, [user_details.id, currentpage, search]);

  useEffect(() => {
    localStorage.removeItem("salaryFilters");
    Fetchuserandroledetail();
  }, [Fetchuserandroledetail])

  const openeditpopup = (id) => {
    const dtalist = userandroles.filter((data) => data.id === id);
    setupdatedatas(dtalist[0]);
    setedituserandroles(true)
  };

  const closeeditpopup = () => {
    setedituserandroles(false)
  };

  const updateddatas = (data) => {
    if (data) {
      Fetchuserandroledetail();
    }
  };

  const closedeleteconfirm = () => {
    setdeleteconf(false)
    setDeleteComponentData([])
};

  const updatedadd = (data) => {
    if (data) {
      Fetchuserandroledetail();
    }
  };

  const closeaddrole = () => {
    setopenaddrole(false)
  };

  const openassrole = () => {
    setopenaddrole(true)
  };

  const showConfirmationModalDelete = (id) => {
    setfieldid(id);
    setConfirmationModalOpen(true)
  };

  const closeConfirmationModal = () => {
    setfieldid(null);
    setConfirmationModalOpen(false);
  };

  const confirmDelete = () => {
    if (fieldid) {
      deletesalaryComponent(fieldid);
      closeConfirmationModal();
    }
  };
  const deletesalaryComponent = async (componentId) => {
    try {
      const response = await deleteroles(componentId);
      if (response) {
        Fetchuserandroledetail();
         toast.success('Deleted Sucessfully!', {
                  position: "top-center",
                  autoClose: 2000,
                  closeButton: false,
                  className: 'custom_toast'
                });
      }
    }
    catch (error) {
      if (error.response && error.response.status === 422) {
         setDeleteComponentData(error.response.data.data);
         setdeleteconf(true);
        
              }
    }
  }
  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>


                <div className='pages-subheader-sub-1'>
                  <p>Roles</p>
                  <span className='header-search-add-parent'>
                    <div className='pages-serach-view'>
                      <CiSearch className='page-search-icon' />
                      <input className='pages-serach' onChange={handleSearch} placeholder='Search...' type='search' />
                    </div>
                    <button className='addusrndrl-button-top' onClick={openassrole} >Add Roles</button>

                  </span>
                </div>



                <div className='pages-line'></div>
              </div>
              <div className="pages-filter-options">
                <div className="pages-filter-sub">
                <Link to="/viewroles" className='earnings-redirect-link'>
                    <div className='userndrole-step userndrole-selected'>
                      Roles
                    </div>
                  </Link>
                  <Link to="/viewuser" className='earnings-redirect-link2'>
                    <div className='userndrole-step  '>
                      User
                    </div>
                  </Link >
                  
                </div>
              </div>
              <div className='pages-maincontent'>
                {loading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                )
                  : (
                    <div className="pages-table-container">

                      <table className='pages-table'>
                        {userandroles && userandroles.length > 0 && (
                          <thead>
                            <tr>
                              <th></th>
                              <th>S NO</th>
                              <th>Role</th>
                              <th>##</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {
                            userandroles && userandroles.length > 0 ? (

                              userandroles.map((userroleview, index) => (
                                <tr key={userroleview.id}>
                                  <td></td>
                                  <td>{(currentpage - 1) * per_page + index + 1}</td>
                                  <td>{userroleview.role}</td>
                                  <td>
                                    <span style={{ display: "flex", flexDirection: "row", gap: "40px", fontSize: "14px", alignItems: "center" }}>

                                      <CiEdit onClick={() => openeditpopup(userroleview.id)} style={{ color: "#2a7ac7", cursor: "pointer", fontSize: "16px" }} />
                                      <CiTrash onClick={() => showConfirmationModalDelete(userroleview.id)} style={{ color: "red", cursor: "pointer", fontSize: "16px" }} />

                                    </span>

                                  </td>

                                </tr>

                              ))
                            ) : (
                              <tr>
                                <td colSpan={8} className="no-data-table">

                                  <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />

                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  )}
                <div style={{ float: "right" }} id="pagination_link"></div>
                <div >
                  {(!loading) && (totaldedcount > per_page) ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totaldedcount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        forcePage={currentpage - 1}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddRoles
        show={openaddrole}
        close={closeaddrole}
        updated={updatedadd}
      />

      <EditRoles
        show={edituserandroles}
        close={closeeditpopup}
        datas={updatedatas}
        updated={updateddatas}
      />

      <DeleteAlert
        isOpen={isConfirmationModalOpen}
        message="Really, would you like to remove this Role?"
        onCancel={closeConfirmationModal}
        onConfirm={confirmDelete}
      />
            
            <Deleteconfirmation
                show={opendeleteconf}
                close={closedeleteconfirm}
                data={deleteComponentData}
            />
    </div>
  )
}

export default ViewRoles