const formatDate = (dateString) => {
  if (!dateString || typeof dateString !== 'string') {
    return 'Invalid Date'; 
  }

  const parts = dateString.split('-');
  if (parts.length !== 3) {
    return 'Invalid Date'; 
  }

  const [year, month, day] = parts;
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const monthIndex = parseInt(month, 10) - 1; 
  if (monthIndex < 0 || monthIndex > 11) {
    return 'Invalid Date'; 
  } 
  return `${day}-${monthNames[monthIndex]}-${year}`;
};

export default formatDate;
