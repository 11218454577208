import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { UpdateLeaveComp } from '../../Config/api';
// import { Store } from '../../Store';
// import { updateLeaveComponent } from '../../Slice/leaveComponentSlice';
import { getRegisterId } from '../../Config/userId';
import { getUserDetails } from '../../Services/Storage';

const Editleavecomp = ({ show, close, editData, updateData }) => {

  const user_details = getUserDetails();
  const register_id = getRegisterId();
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(false)
  
  const [addleavecomp, setleavecomp] = useState({
    leave_type: '',
    leave_name: '',
    leave_deduct: '',
    earning_deduction: '',
    user: register_id,
    edited_by: user_details.id,
  });

  useEffect(() => {
    if (editData) {
      setleavecomp({
        leave_type: editData.leave_type,
        leave_name: editData.leave_name,
        leave_deduct: editData.leave_deduct,
        earning_deduction: editData.earning_deduction,
        user: register_id,
        edited_by: user_details.id,
      });
    }
  }, [editData, register_id,user_details.id]);

  const closePopup =()=>{
    close();
    setErrors({})
  
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setleavecomp((preValue) => ({
      ...preValue,
      [name]: value,
    }))
  }
  const handleSubmit = async () => {
    const fieldData = [{
      name: "leave_type",
      type: "input",
      err: "Leave Type",
      conditions: [
        (value) => typeof value === "string",
        (value) => value.trim() !== "",
        (value) => /^[a-zA-Z\s]+$/.test(value),
      ]
    },
    {
      name: "leave_name",
      type: "input",
      err: "Leave Name",
      conditions: [
        (value) => typeof value === "string",
        (value) => value.trim() !== "",

        (value) => /^[a-zA-Z\s]+$/.test(value),]
    },
    {
      name: 'leave_deduct',
      type: 'select',
      err: 'Leave Deduction Type',
      conditions: [
        (value) => value !== '',
      ],
    },
    {
      name: 'earning_deduction',
      type: 'select',
      err: 'Salary Type',

      conditions: [
        (value) => value !== '',
      ]
    }

    ]
    const fieldOrder = [
      "leave_type",
      "leave_name",
      "leave_deduct",
      "earning_deduction",
    ]
    const newErrors = {};
    for (const fieldName of fieldOrder) {
      const fieldInfo = fieldData.find((field) => field.name === fieldName);
      if (!fieldInfo) {
        continue;
      }
      const fieldType = fieldInfo.type;
      const fieldLabel = fieldInfo.err;
      const value = addleavecomp[fieldName];
      const isFieldValid = fieldInfo.conditions.every((condition) => typeof condition === "function" ? condition(value) :
        condition
      );
      if (!isFieldValid) {
        if (value.trim() === "") {
          newErrors[fieldName] = `${fieldLabel} is required`
        } else {
          newErrors[fieldName] = `Please Enter a Valid ${fieldLabel}`;

        }
        const inputField = document.querySelector(`${fieldType}[name = "${fieldName}"]`);
        if (inputField) {
          inputField.focus();
          break;
        }
      }
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setloading(true);
        const response = await UpdateLeaveComp(editData.id, addleavecomp);
        updateData(response.updated_leave_component);
        // Store.dispatch(updateLeaveComponent(response.updated_leave_component))
        close();
        toast.success('Updated Sucessfully!', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
          className: 'custom_toast'
        })
      } catch (error) {
          if (error.response && error.response.data) {
            const { data } = error.response.data;
            const responseErrors = {};
            for (const key in data) {
              responseErrors[key] = data[key];
            }
            setErrors((prevErrors) => ({
              ...prevErrors,
              ...responseErrors,
            }));
          } else {
            toast.error("An error occurred. Please try again.", {
              position: "top-center",
              autoClose: 2000,
              closeButton: false,
              className: "custom_toast",
            });
          }
      } finally {
        setloading(false);
      }
    }
  }

  if (!show) return null;

  return (
    <div className='leavecomp-section'>
      <div className='leavecomp-container'>
          <span className='uploadexcel-close' onClick={closePopup}>&times;</span>
          <div className='leavecomp-container-sub'>
            <div className='leavecomp-component-heading'>
              <p>Edit Leave Component</p>
            </div>
            <div className='leavecomp-strightline'></div>
            <div className='leavecomp-comp-form-div'>
            <div className='leavecomp-comp-form-divsub'>

              <div className='leavecomp-comp-input-div'>
                <span className='leavecomp-comp-input-div-span-text'> Leave Type <span className='leavecomp-comp-input-important'> * </span> </span>
                <input type='text' value={addleavecomp.leave_type} onChange={handleInputChange} name='leave_type' className='leavecomp-comp-input' />
                {errors.leave_type && (<div className='error-message'>{errors.leave_type}</div>)}
              </div>
              <div className='leavecomp-comp-input-div'>
                <span className='leavecomp-comp-input-div-span-text'> Leave Name <span className='leavecomp-comp-input-important'> * </span> </span>
                <input type='text' value={addleavecomp.leave_name} onChange={handleInputChange} name='leave_name' className='leavecomp-comp-input' />
                {errors.leave_name && (<div className='error-message'>{errors.leave_name}</div>)}
              </div>
              <div className='leavecomp-comp-input-div'>
                <span className='leavecomp-comp-input-div-span-text' > Leave Deduction Type <span className='leavecomp-comp-input-important'> * </span> </span>

                <select className='leavecomp-comp-select-box' value={addleavecomp.leave_deduct} onChange={handleInputChange} name='leave_deduct'  >
                  <option value="">select Deduction Type</option>
                  <option value="Fullday">Full Day</option>
                  <option value="Halfday">Half Day</option>
                </select>
                {errors.leave_deduct && (<div className='error-message'>{errors.leave_deduct}</div>)}
              </div>
              <div className='leavecomp-comp-input-div'>
                <span className='leavecomp-comp-input-div-span-text' > Salary Type <span className='leavecomp-comp-input-important'> * </span> </span>

                <select className='leavecomp-comp-select-box' value={addleavecomp.earning_deduction} onChange={handleInputChange} name='earning_deduction' >
                  <option value="">select Salary Type</option>
                  <option value="earning">Earning</option>
                  <option value="deduction">Deduction</option>
                </select>
                {errors.earning_deduction && (<div className='error-message'>{errors.earning_deduction}</div>)}
              </div>
              {errors.response_error && (<div className='error-message'>{errors.response_error}</div>)}
              <div className='leavecomp-comp-button-div'>
                <button onClick={handleSubmit} className='leavecomp-button-cls' disabled={loading}>{loading ? <span className='spinner'></span> : "Submit"}</button>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
  );
}
export default Editleavecomp;