import React, { useState, useEffect, useCallback } from "react";
import "./Header.css";
import './UserSetting.css';
import { CiBellOn, CiSettings } from "react-icons/ci";
import logo from "../../Images/Logo/Logo";
import UserAccount from "./UserAccount";
import UserSettings from "./UserSettings";
import { getCompanyDetails, getNotificationDetails, getNotificationStatusDetails, getStaffDetails, getUserDetails, storeNotificationStatus } from "../../Services/Storage";
import api_backend, { viewNotificationCount } from "../../Config/api";
import userdemoimg from "../../Images/Pagesimg/userdemo";
import Notification from "./Notification/Notification";
import { getRegisterId } from "../../Config/userId";
import { useNavigate } from "react-router-dom";

const Header = () => {

  const navigate = useNavigate(); 
  const register_id = getRegisterId();
  const company_details = getCompanyDetails();
  const [shownotification, setnotification] = useState(false);
  const user_details = getUserDetails();
  const staff_details = getStaffDetails();
  const [userpop, viewUserpop] = useState(false);
  const [UserSettingspop, viewUserSettingsPop] = useState(false);
  const notificationCount = getNotificationDetails() || 0;
  const notificationStatus = getNotificationStatusDetails () || false;
    
  useEffect(() => {
      const FetchDt = async () => {
          try {
            const response = await viewNotificationCount(`?register_id=${register_id}&company_id=${company_details.company_id}`);

            if(response.data.notification_count !== notificationCount)
            {
              storeNotificationStatus(false);
                          console.log(notificationCount ,"dshdjshdjshdj")

            }
            
          } catch (error) {
  
          
          }
        } 
       
  
      
      FetchDt();
    }, [register_id,company_details.company_id,notificationCount])
  
  const toggler_user = () => {
    userpop ? viewUserpop(false) : viewUserpop(true);
    viewUserSettingsPop(false);
    setnotification(false)
  };

  const toggle_setting = () => {
    UserSettingspop ? viewUserSettingsPop(false) : viewUserSettingsPop(true);
    viewUserpop(false);
    setnotification(false)
  };

  const closeDropdowns = useCallback((e) => {
    if ((!e.target.closest(".setting-pop") && UserSettingspop)||
    (!e.target.closest(".account-menu") && userpop)||(!e.target.closest(".notification_main") && shownotification)
  ) {
    } else {
      viewUserSettingsPop(false);
      viewUserpop(false);
      setnotification(false);
    }
  }, [UserSettingspop,userpop,shownotification]);

  useEffect(() => {
    window.addEventListener("click", closeDropdowns);
    return () => {
      window.removeEventListener("click", closeDropdowns);
    };
  }, [closeDropdowns]);

  const closenoti = () => {
    setnotification(false)
  };
  const viewnotifi = () => {
    shownotification ? setnotification(false): setnotification(true);
    storeNotificationStatus(true);
    viewUserpop(false);
    viewUserSettingsPop(false);
  };

  const redirecttohome = () => {
    if (user_details.role === "Company") {
      navigate("/dashboard"); 
    } else if (user_details.role === "other_user") {
      navigate("/dashboardother");
    } else {
      navigate("/dashboardstaff");
    }
  };

  return (
    <div className="HedaerNewdiv1">
      <div className="HeaderNewDiv2">
        <div className="HeaderNewDivSub1 hds">
          <div className="sidebar-topview-sub">
            <div className="sidebar-topview-header">
              <span style={{cursor:"pointer"}} onClick={redirecttohome} className="sidebar-topview-header-span">
                <img src={logo.logo_colo_change}  alt="Company Logo" />
              </span>
            </div>
          </div>
        </div>
        <div className="HeaderNewDivSub2 hds">
          <div className="header-container-sub">
            <span></span>
            <span className="HeaderNewDivSpan">
              {(user_details.role === 'Company') && (
                <>
                  <span className="notification-icon" onClick={viewnotifi}>
                    <CiBellOn size={20} />
                  
 {notificationStatus ?  (
    <> </>
  ) : ( <span className="notification-dot"></span> )}
                  </span>
                 
                </>
              )}
              {user_details.role !== 'staff' ?(   <span onClick={toggle_setting}>
                    <CiSettings className="header-settings-sub" />
                  </span>):(<></>)
            
}
              {user_details.role === 'Company'
                ? (
                  <span onClick={toggler_user}>
                    {company_details.company_logo ?
                      (
                        <img
                          className="header-logo-img"
                          src={`${api_backend.BACKEND_API}${company_details.company_logo}`}
                          alt="Login"
                        />
                      ) :
                      ((<img src={userdemoimg.demo_img} className="header-logo-img"
                        alt="img" />)
                      )
                    }
                  </span>
                ) : (
                  <span onClick={toggler_user}>
                    {staff_details && staff_details.staff_details.staff_pic ?
                      (
                        <img
                          className="header-logo-img"
                          src={`${api_backend.BACKEND_API}${staff_details.staff_details.staff_pic}`}
                          alt="Login"
                        />
                      ) :
                      ((<img src={userdemoimg.demo_img} className="header-logo-img"
                        alt="img" />)
                      )
                    }
                  </span>
                )
              }
            </span>
          </div>
        </div>
      </div>

      <UserAccount
       user_status={userpop}
        onclose={toggler_user} />

      <UserSettings
       user_status={UserSettingspop}
        onclose={toggle_setting} />

      <Notification
        show={shownotification}
        close={closenoti}
      />

    </div>
  );
};
export default Header;
