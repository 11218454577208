import React from 'react'

const OtherComponetAlert = ({data,show,close,onConfirm}) => {
  if(!show) return null;
  return (
    <div className="modal-overlay">
    <div className="modal-content">
      <p>Are you sure you want to {data} this item </p>
      <div className="modal-buttons">
        <button onClick={()=>onConfirm(data)} className="confirm-button">Yes</button>
        <button onClick={close} className="cancel-button">No</button>
      </div>
    </div>
  </div>
  )
}

export default OtherComponetAlert