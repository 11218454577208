import React, { useState,useEffect,useRef } from 'react';
import './Forgetpass.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import Forgetpass_img from '../../Images/Forgetpassimages/Forgetpassimages';
import { ForgetPassword, ForgetPasswordvalidate, resendotp } from '../../Config/api';
import { decryptOTP } from '../Decript';
import AuthCode from "react-auth-code-input";

const Forgetpass = () => {

    const authCodeRef = useRef(null);
    const navigate = useNavigate();
    const [showpasswordfield, setpasswordfield] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [Errors, SetErrors] = useState({});
    const [otpSent, setOtpSent] = useState(false);
    const [enterotp, setenterotp] = useState("");
    // const [timer, setTimer] = useState(180);
    const [otpError, setOtpError] = useState('');
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [otp, setOtp] = useState('');
    const [loader, setloader] = useState(false);
    const [resdloader, resdsetloader] = useState(false);
    const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    const [FormData, SetFormData] = useState({
        email: ''
    });

    const [formData, setFormData] = useState({
        new_password: '',
        confirm_password: '',
    });
    const handleprev = () => {
        setenterotp("")
        setOtpSent(false)
        setOtpError('')

    };

      const expiryTimestamp = new Date();
      expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 180); // 3 minutes
    
      const { seconds, minutes, restart } = useTimer({
          expiryTimestamp,
          
          onExpire: () => {
            
            setIsResendDisabled(false);
            setenterotp("");
            setOtpError("OTP expired. Please request a new one.");
          }
      });
    
      useEffect(() => {
        if (otpSent) {
            restart(new Date(Date.now() + 180 * 1000)); // Restart timer on OTP send
        }
    }, [otpSent ,restart]);
    

    // useEffect(() => {
    //     if (otpSent) {
    //         const countdown = setInterval(() => {
    //             setTimer((prev) => {
    //                 if (prev === 1) {
    //                     clearInterval(countdown);
    //                     setIsResendDisabled(false);
    //                     setenterotp("");
    //                     setOtpError("OTP expired. Please request a new one.");
    //                     return 0;
    //                 }
    //                 return prev - 1;
    //             });
    //         }, 1000);

    //         return () => clearInterval(countdown);
    //     }
    // }, [otpSent]);

    // const startTimer = () => {
    //     setTimer(180);
    //     setIsResendDisabled(true);

    //     const countdown = setInterval(() => {
    //         setTimer((prev) => {
    //             if (prev === 1) {
    //                 clearInterval(countdown);
    //                 setIsResendDisabled(false);

    //                 return 0;
    //             }
    //             return prev - 1;
    //         });
    //     }, 1000);
    // }

    // const formatTime = (time) => {
    //     const minutes = Math.floor(time / 60);
    //     const seconds = time % 60;
    //     return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    // }

    const handleInputchange = (e) => {
        const { name, value } = e.target;
        SetFormData((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleinputchange = (e) => {
        setErrors({});
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }))

    };

    const handleOtpChange = (data) => {
        setOtpError("");
        setOtp(data);
        // const { value } = e.target;
        // if (/^[0-9]$/.test(value) || value === "") {
        //     const newOtp = [...otp];
        //     newOtp[index] = value;
        //     setOtp(newOtp);

        //     if (value && index < otp.length - 1) {
        //         document.getElementById(`otp-${index + 1}`).focus();
        //     } else if (value === "" && index > 0 && newOtp[index] === "") {
        //         document.getElementById(`otp-${index - 1}`).focus();
        //     }
        // }
    };

    const handleOtpSubmit = () => {

           if (!otp || otp.length !== 5) {  // Ensure OTP exists and is 5 digits
                setOtpError("Please enter a valid 5-digit OTP.");
                return;
            }
        
            const decryptedOtp = decryptOTP(enterotp);  // Ensure it's properly formatted
        
            if (otp === decryptedOtp) {
                setpasswordfield(true);
                // setOtp("");  // Clear OTP input
                // setOtpError("");
            } else {
                setOtpError("Entered OTP is incorrect.");
            }
        // if (!enterotp) {
        //     setOtpError("OTP expired. Please request a new one.");
        //     return;
        //   }
        // const enteredOtp = otp.join("");
        // const decript = decryptOTP(enterotp);
        // if (enteredOtp.length < 5) {
        //     setOtpError("Please enter a valid 5-digit OTP.");
        // } else if (enteredOtp === decript) {

        //     setpasswordfield(true);
        // } else {
        //     setOtpError("Entered OTP is incorrect.");
        // }
        
    };
    
    const handleconfirm = async () => {
        let newErrors = {};
        if (FormData.email.trim() === "") {
            newErrors.email = "Email is required";
        } else if (!/^\S+@\S+\.\S+$/.test(FormData.email)) {
            newErrors.email = 'Invalid email format';
        }
        SetErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {

            try {
                setloader(true);
                const response = await ForgetPasswordvalidate(FormData)
                if (response) {
                    setOtpSent(true);
                    restart(new Date(Date.now() + 180 * 1000))
                    // startTimer();
                    setOtpError('');
                    setIsResendDisabled(true);

                    setenterotp(response.data.otp_data.otp_code);
                }
            }
            catch (error) {
                if (error.response) {
                    const newErrors = {};
                    newErrors.response_error = error.response.data.data.errors;
                    SetErrors(newErrors);
                }
                else {
                    const newErrors = {
                        response_error: [],
                    };
                    for (const key in error.response.data.errors) {
                        if (error.response.data.errors.hasOwnProperty(key)) {
                            newErrors.response_error.push(`${error.response.data.errors[key]}`);
                        }
                    }
                    SetErrors(newErrors);
                    SetFormData((state) => ({
                        ...state,
                    }));
                }

            } finally {
                setloader(false)
            }
        }

    }


    const handleonsubmit = async () => {
        let newErrors = {};
        if (formData.new_password.trim() === "") {
            newErrors.new_password = "New Password is required";
        } else if (formData.new_password.length < 8) {
            newErrors.new_password = "Password must be at least 8 characters long";
        }
        if (formData.confirm_password.trim() === "") {
            newErrors.confirm_password = "Confirm Password is required";
        } else if (formData.new_password !== formData.confirm_password) {
            newErrors.confirm_password = "Passwords do not match";
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {

            try {
                const response = await ForgetPassword({
                    email: FormData.email,
                    new_password: formData.new_password,
                    confirm_password: formData.confirm_password
                });
                if (response) {
                    toast.success('Password changed successful', {
                        position: "top-center",
                        autoClose: 2000,
                        closeButton: false,
                        className: 'custom_toast'
                    });
                    navigate('/login');

                }
            } catch {

            }

        }
    }

    const handleresendotp = async () => {
        setOtpError('');
        setIsResendDisabled(true);
        restart(new Date(Date.now() + 180 * 1000))


        try {
            resdsetloader(true)
            const response = await resendotp({ email: FormData.email });
            if (response) {
                // startTimer();

                setOtp('');
                setenterotp(response.resend_otp_data.resend_otp_code);
            }
        } catch {

        } finally {
            resdsetloader(false)
        }
    }


    return (
        <div className='forgetpass-conatiner'>
            <div className='forgetpass-main'>
                <div className='forgetpass-mainsb'>
                    <div className='forgetpass-main-img'>
                        <div className='forgetpass-image'>
                            <img src={Forgetpass_img.Forgetpass_image} alt='img' />
                        </div>
                    </div>

                    {!otpSent ? (
                        <>
                            <div className='forgetpass-input-cont'>
                                <h3>Forgot Your Password?</h3>
                                <p>Enter Your Email and we'll help you reset your password</p>


                            </div>

                            <div className='forget-inputfd-main'>
                                <div className='forget-inputfd'>
                                    <input placeholder='Enter Your Email' name='email' onChange={handleInputchange} />
                                    {Errors.email && (
                                <div className="registeration-error-view">{Errors.email}</div>
                            )}

                            {Errors.response_error && (
                                <div className="registeration-error-view">{Errors.response_error}</div>
                            )}
                            <div className='login-signup'>
                                              <p >Remembered your password? Go back to login.
                                                <Link to="/login">
                            
                                                Sign in
                                                </Link>
                            
                                              </p>
                                            </div>
                                </div>

                            </div>
                          
                            
                            <div className='forget-button'>
                                <button onClick={handleconfirm} disabled={loader}>
                                    {loader ? <span className="spinner"></span> : 'Submit'}
                                </button>
                            </div>
                         
                        </>

                    ) : !showpasswordfield ? (
                        <>
                            <div className='forgetpass-input-cont h-margin'>
                                <h3>Enter OTP</h3>
                                <p>We've sent an OTP to your email. Please enter it below.</p>
                            </div>
                            <div className='forget-inputfd-main'>
                                <div className='auth-input-fieldview otp-input-box'>
                                    {/* {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength="1"
                                            id={`otp-${index}`}
                                            value={digit}
                                            onChange={(e) => handleOtpChange(e, index)}
                                            className="otp-box"
                                        />
                                    ))} */}
                                                 <AuthCode
                              onChange={handleOtpChange}
                              allowedCharacters="numeric"
                              length={5}  // Change as needed
                              ref={authCodeRef}
          containerClassName="otp-box"
                inputClassName="auth-input"

                              />

                                </div>
                            </div>
                            <div className='resend-otp-cont'>
                                {isResendDisabled ?
                                <p style={{ color: 'darkorange' ,fontSize:"14px"}}> Resend OTP in {minutes}:{String(seconds).padStart(2, '0')}</p>

                                    : <p style={{
                                        color: 'cornflowerblue',
                                        cursor: resdloader ? "default" : "pointer",
                                        pointerEvents: resdloader ? "none" : "auto"  // Disable click when loading
                                    }} onClick={handleresendotp}>
                                        {resdloader ? (
                                            <span className="spinner"></span> // Show spinner when loading
                                        ) : (
                                            'Resend'
                                        )}
                                    </p>}
                            </div>                            {otpError && (
                                <div className="registeration-error-view" style={{ textAlign: "center" }}>{otpError}</div>
                            )}

                            <div className='forget-button'>
                                <button onClick={handleprev}>
                                    Back
                                </button>
                                <button onClick={handleOtpSubmit}>
                                    Verify
                                </button>
                            </div>
                        </>


                    ) : (
                        <>
                        <div className='forgetpass-container-input'>
                            <div className='forgetpass-input-cont h-margin'>
                                <h3>Reset Your Password</h3>
                            </div>
                        
                        <div className='forgetpass-form-inputsize'>
                        <div className='forgetpass-form-inputsizesb'>

                            <div className='forgetpass-form-group'>
                                <label htmlFor='newPassword'>
                                    New Password<span className='registeration-mandatory'>*</span>
                                </label>
                                <div className='password-input-container'>
                                    <input
                                        type={showNewPassword ? 'text' : 'password'}
                                        id='newPassword'
                                        required
                                        name='new_password'
                                        onChange={handleinputchange}
                                        placeholder='Enter New Password'
                                    />
                                    <FontAwesomeIcon
                                        className='password-icon'
                                        icon={showNewPassword ? faEye : faEyeSlash}
                                        onClick={toggleNewPasswordVisibility}
                                    />
                                </div>
                                {errors.new_password && (
                                    <div className="registeration-error-view">{errors.new_password}</div>
                                )}
                            </div>
                            <div className='forgetpass-form-group'>
                                <label htmlFor='confirmPassword'>
                                    Confirm Password<span className='registeration-mandatory'>*</span>
                                </label>
                                <div className='password-input-container'>
                                    <input
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        id='confirmPassword'
                                        required
                                        onChange={handleinputchange}
                                        placeholder='Enter Confirm Password'
                                        name='confirm_password'
                                    />
                                    <FontAwesomeIcon
                                        className='password-icon'
                                        icon={showConfirmPassword ? faEye : faEyeSlash}
                                        onClick={toggleConfirmPasswordVisibility}
                                    />
                                </div>
                                {errors.confirm_password && (
                                    <div className="registeration-error-view">{errors.confirm_password}</div>
                                )}
                            </div>
                            </div>
                            </div>
                            <div className='forget-button forget-button-margin'>
                                <button onClick={handleonsubmit}>
                                    Submit
                                </button>
                            </div>
                            </div>
                        </>
                    )

                    }

                </div>

            </div>
        </div>
    )
}

export default Forgetpass