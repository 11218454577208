import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY || '!@#$%%^&&seasense)(*&^&^%^';

const getUserDetails = () => {
    try {
        const encryptedData = localStorage.getItem('user');
        if (!encryptedData) return null;

        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
            return JSON.parse(decryptedData);
        }
        return null;
    } catch (error) {
        return null;
    }
};

const getAccessDetails = () => {
    const userDetails = getUserDetails();
    if (!userDetails) return null;

    const authUser = userDetails || null;
    const usersRole = authUser ? authUser.role : null;
    const accessDetails = authUser?.get_access_role?.[0]?.invite_user_details?.[0] || null;
    const userAccess = accessDetails || null;

    const listAll = {
        ...(userAccess?.access_category || {}),
        dashboard: ["all"]
    };

    const actionPage = Object.keys(listAll).filter(
        key => listAll[key].length > 0
    );

    return { usersRole, authUser, listAll, actionPage };
};

export const employeeAccess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("employee") &&
            (listAll["employee"]?.includes("active_deactive") || listAll["employee"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
};
export const employeeAddAccess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("employee") &&
            (listAll["employee"]?.includes("add") || listAll["employee"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
};

export const employeeEditAccess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("employee") &&
            (listAll["employee"]?.includes("edit") || listAll["employee"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
};

export const leaveAddAccess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("leave") &&
            (listAll["leave"]?.includes("add") || listAll["leave"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}

export const leaveStatusAccess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("leave") &&
            (listAll["leave"]?.includes("leave_status") || listAll["leave"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}

export const leaveDeleteAccess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("leave") &&
            (listAll["leave"]?.includes("delete") || listAll["leave"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}

export const attendanceAddAccess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("attendance") &&
            (listAll["attendance"]?.includes("add") || listAll["attendance"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}

export const attendanceEditAccess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("attendance") &&
            (listAll["attendance"]?.includes("edit") || listAll["attendance"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}

export const attendanceDeleteAccess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("attendance") &&
            (listAll["attendance"]?.includes("delete") || listAll["attendance"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}

export const salarycompAddAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("salary_component") &&
            (listAll["salary_component"]?.includes("add") || listAll["salary_component"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}

export const salarycompEditAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("salary_component") &&
            (listAll["salary_component"]?.includes("edit") || listAll["salary_component"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const salarycompDeleteAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("salary_component") &&
            (listAll["salary_component"]?.includes("delete") || listAll["salary_component"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const leavecompAddAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("leave_component") &&
            (listAll["leave_component"]?.includes("add") || listAll["leave_component"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const leavecompEditAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("leave_component") &&
            (listAll["leave_component"]?.includes("edit") || listAll["leave_component"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const leavecompDeleteAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("leave_component") &&
            (listAll["leave_component"]?.includes("delete") || listAll["leave_component"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const depAddAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("department") &&
            (listAll["department"]?.includes("add") || listAll["department"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const depEditAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("department") &&
            (listAll["department"]?.includes("edit") || listAll["department"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const depDeleteAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("department") &&
            (listAll["department"]?.includes("delete") || listAll["department"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}

export const dephdAddAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("department_head") &&
            (listAll["department_head"]?.includes("add") || listAll["department_head"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const dephdEditAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("department_head") &&
            (listAll["department_head"]?.includes("edit") || listAll["department_head"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const dephdDeleteAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("department_head") &&
            (listAll["department_head"]?.includes("delete") || listAll["department_head"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}

export const deptlAddAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("department_tl") &&
            (listAll["department_tl"]?.includes("add") || listAll["department_tl"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const deptlEditAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("department_tl") &&
            (listAll["department_tl"]?.includes("edit") || listAll["department_tl"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const deptlDeleteAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("department_tl") &&
            (listAll["department_tl"]?.includes("delete") || listAll["department_tl"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}

export const desiAddAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("designation") &&
            (listAll["designation"]?.includes("add") || listAll["designation"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const desiEditAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("designation") &&
            (listAll["designation"]?.includes("edit") || listAll["designation"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const desiDeleteAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("designation") &&
            (listAll["designation"]?.includes("delete") || listAll["designation"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}


export const holidayAddAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("holiday") &&
            (listAll["holiday"]?.includes("add") || listAll["holiday"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const holidayEditAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("holiday") &&
            (listAll["holiday"]?.includes("edit") || listAll["holiday"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const holidayDeleteAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("holiday") &&
            (listAll["holiday"]?.includes("delete") || listAll["holiday"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}

export const payrollCalSal = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("payroll") &&
            (listAll["payroll"]?.includes("calculate_salary") || listAll["payroll"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const payrollApprovelAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("payroll") &&
            (listAll["payroll"]?.includes("approvel") || listAll["payroll"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
export const payrollDeleteAcess = () => {
    const { usersRole, authUser, listAll, actionPage } = getAccessDetails();
    if (usersRole === 'other_user') {
        if (authUser?.user_status && authUser?.access_users && actionPage.includes("payroll") &&
            (listAll["payroll"]?.includes("delete") || listAll["payroll"]?.includes("all"))) {
            return true;
        }
        return false;
    }
    return true;
}
