import React, { useState, useEffect, useCallback } from 'react';
import { CiEdit, CiSearch, CiTrash } from 'react-icons/ci';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Adddepartmenthead from './Adddepartmenthead';
import { Deletedepheadapi, FetchDepartmentHead } from '../../../Config/api';
import Editdepartmenthead from './Editdepartmenthd';
import pageloader from '../../../Loader/pageloading/Pageloader';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import ReactPaginate from "react-paginate";
import DeleteAlert from '../../popup/DeleteAlert';
import { Store } from '../../../Store';
import { deletedepartmenthd } from '../../../Slice/departmentHeadSlice';
import Deleteconfirmation from '../../Component/Deleteconfirmation';
import { getRegisterId } from '../../../Config/userId';
import { getUserDetails } from '../../../Services/Storage';
import { dephdAddAcess, dephdDeleteAcess, dephdEditAcess } from '../../../Services/AccessCondition';
import { CompanyApi } from '../../../Config/CommonApi';
import Select from "react-select";
import { toast } from 'react-toastify';

const Viewdepartmenthd = () => {

    const register_id = getRegisterId();
    const userDetails = getUserDetails();
    const accessid =  userDetails?.get_access_role?.[0]?.invite_user_details?.[0]?.register || "";
    const AddAccess = dephdAddAcess();
    const EditAccess = dephdEditAcess();
    const DeleteAccess = dephdDeleteAcess();
    const [isadddepopen, setadddepopen] = useState(false);
    const [departmenthead, setdepartmentHeadList] = useState([])
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [deleteComponentId, setDeleteComponentId] = useState(null);
    const [editData, setEditData] = useState([]);
    const [iseditopen, seteditopen] = useState(false)
    const [loading, setIsloading] = useState(false);
    const [totaldedcount, setdedcount] = useState(0)
    const per_page = 10;
    const [currentpage, setCurrentPage] = useState(1);
    const [search, setSearchword] = useState("");
    const [deleteComponentData, setDeleteComponentData] = useState([]); 
    const [opendeleteconf, setdeleteconf] = useState(false)
    const [companyData, setCompanyData] = useState([]);
    const [companyFilter, setCompanyFilter] = useState([]);
    const [company, setCompany] = useState('all');

          useEffect(() => {
            localStorage.removeItem("salaryFilters");
            const fetchCompanyData = async () => {
              const data = await CompanyApi();
              setCompanyData(data);
              setCompanyFilter(data[0]);
            };
    
            fetchCompanyData();
    
          }, [register_id]);
    
        const handleChangeCompany = (selecteOption) => {
            setCompany(selecteOption.value);
            setCompanyFilter(selecteOption);
            setCurrentPage(1);
          };
    

    const currentPageNo = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };

    const handleSearch = (event) => {
        setSearchword(event.target.value);
    };

    const editdepheadButton = (componentid) => {
        const updatedList = departmenthead.filter((component) =>
            component.row_id === componentid
        );
        if (updatedList) {
            setEditData(updatedList[0]);
            seteditopen(true);
        }
    };

    const handleUpdateData = (data) => {
        const updatedList = departmenthead.map((comp) =>
            comp.row_id === data.row_id ? data : comp
        );
        setdepartmentHeadList(updatedList);
    };

    const closeeditlevae = () => {
        seteditopen(false)
    };

    const openadddephd = () => {
        setadddepopen(true);
    };

    const closeadddephd = () => {
        setadddepopen(false);
    };

    const confirmDelete = () => {
        if (deleteComponentId) {
            deletesalaryComponent(deleteComponentId);
            closeConfirmationModal();
        }
    };

    const showConfirmationModalDelete = (delid) => {
        setDeleteComponentId(delid);
        setConfirmationModalOpen(true);
    };

    const closeConfirmationModal = () => {
        setDeleteComponentId(null);
        setConfirmationModalOpen(false);
    };

    const handleDataFromChild = (data) => {
        Fetchdepartmentapi();
    };
    const closedeleteconfirm = () => {
        setdeleteconf(false)
        setDeleteComponentData([])
    };

    const Fetchdepartmentapi = useCallback(async () => {
        try {
            setIsloading(true);

            const response = await FetchDepartmentHead(`?register_id=${register_id}&page=${currentpage}&page_count=${per_page}&search=${search}&company=${company}&access_id=${accessid}`);
            if (response) {
                setdedcount(response.data.page_context.total)
                setdepartmentHeadList(response.data.get_depart_head);

            }
        } catch (error) {

        } finally {
            setIsloading(false)

        }
    }, [currentpage, search, per_page ,accessid ,register_id,company]);
    useEffect(() => {
        Fetchdepartmentapi();
    }, [Fetchdepartmentapi]);

    const deletesalaryComponent = async (componentId) => {
        try {
            const response = Deletedepheadapi(`${componentId}/?edited_by=${userDetails.id}`);
            if (response && (await response).status === 200) {
                const updatedList = departmenthead.filter((component) => component.row_id !== componentId);
                setdepartmentHeadList(updatedList);
                
                Store.dispatch(deletedepartmenthd(componentId))
                      toast.success(' Deleted Sucessfully!', {
                                                position: "top-center",
                                                autoClose: 2000,
                                                closeButton: false,
                                                className: 'custom_toast'
                                              })
            }
        }
        catch (error) {
            if (error.response && error.response.status === 422) {
                setDeleteComponentData(error.response.data.data);
                setdeleteconf(true)
            }
        }
    };

    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='pages-subheader'>
                                <div className='pages-subheader-sub-1'>
                                    <p>Department Head</p>
                                    <span className='header-search-add-parent'>
                                        <div className='pages-serach-view'>
                                            <CiSearch className='page-search-icon' />
                                            <input className='pages-serach' onChange={handleSearch} placeholder='Search Department Head' type='search' />
                                        </div>
                                        {AddAccess
                                        ?(                                        <button className='pages-addempbutton-leave' onClick={openadddephd}>Add Department Head</button>
                                        ):
                                        (<></>)

                                        }
                                    </span>
                                </div>
                                <div className='pages-line'></div>
                            </div>
                            <div className="pages-filter-options">
                  <div className="pages-filter-sub">
                    <p>Filter:</p>
                    <Select
                      className="pages-dropdown1"
                      options={companyData}
                      onChange={handleChangeCompany}
                      value={companyFilter}
                      placeholder="Select Work Location"
                    />
                   
                  </div>
                </div>
                            <div className='pages-maincontent'>
                                {loading ? (
                                    <div className="loader-container">
                                        <img src={pageloader.page_loader} alt="Loading..." />
                                    </div>
                                )
                                    : (
                                        <div className="pages-table-container">

                                        <table className='pages-table'>
                                        {departmenthead && departmenthead.length > 0 && (
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Sl No</th>
                                                    <th>Department</th>
                                                    <th>Head Name</th>
                                                    <th>Company</th>
                                                    <th>Edit</th>
                                                </tr>
                                            </thead>
                                        )}
                                            <tbody>
                                                {departmenthead && departmenthead.length > 0 ? (
                                                    departmenthead.map((departmenthead, index) => (
                                                        <tr key={departmenthead.id}>
                                                            <td></td>
                                                            <td>{(currentpage - 1) * per_page + index + 1}</td> {/* Updated index logic */}
                                                            <td>{departmenthead.depart_name}</td>
                                                            <td>{departmenthead.depart_head_name}</td>
                                                            <td>{departmenthead.company_name}</td>
                                                            <td>{departmenthead.user !== null ? (
                                                                <span style={{ display: "flex", flexDirection: "row", gap: "20px", fontSize: "14px", alignItems: "center" }}>
                                                                   {EditAccess
                                                                   ?(                                                                    <CiEdit onClick={() => editdepheadButton(departmenthead.row_id)} style={{ color: "#2a7ac7", cursor: "pointer" }} />
                                                                )
                                                                   :(<></>)

                                                                   }
                                                                   {DeleteAccess?(                                                                    <CiTrash onClick={() => showConfirmationModalDelete(departmenthead.row_id)} style={{ color: "red", cursor: "pointer" }} />
)
                                                                   :(<></>)

                                                                   }
                                                                </span>
                                                            ) : null}</td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8} className="no-data-table">

                                                            <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    )}
                                <div style={{ float: "right" }} id="pagination_link"></div>
                                <div >
                                    {(!loading)&&(totaldedcount > per_page) ? (
                                        <>
                                            <ReactPaginate
                                                pageCount={Math.ceil(totaldedcount / per_page)}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={2}
                                                onPageChange={currentPageNo}
                                                containerClassName={"pagination"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                forcePage={currentpage - 1} // Adjust index (0-based)

                                            />
                                        </>
                                    ) : (
                                        <> </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Adddepartmenthead
                show={isadddepopen}
                close={closeadddephd}
                sendDataToParent={handleDataFromChild}

            />
            <Editdepartmenthead
                editData={editData}
                updateData={handleUpdateData}
                show={iseditopen}
                close={closeeditlevae}
            />
            <DeleteAlert
                isOpen={isConfirmationModalOpen}
                message="Really, would you like to remove this Department Head?"
                onCancel={closeConfirmationModal}
                onConfirm={confirmDelete}
            />
            <Deleteconfirmation
                show={opendeleteconf}
                close={closedeleteconfirm}
                data={deleteComponentData} // Pass data to the confirmation popup

            />
        </div>
    );
}

export default Viewdepartmenthd;
