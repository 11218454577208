import React, { useState, useEffect } from 'react';
import Header from '../../../Header/Header';
import "../../Style/Employeeform.css";
import '../../Style/Addemp.css'
import Sidebar from '../../../Sidebar/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { EmployeePaymentApi } from '../../../../Config/api';
import { toast } from 'react-toastify';
import { getRegisterId } from '../../../../Config/userId';
import { getUserDetails } from '../../../../Services/Storage';

const Payment = () => {

    const user_details = getUserDetails();
    const register_id = getRegisterId();
    const navigate = useNavigate();
    const location = useLocation();
    const [errors, setnewerror] = useState({})
    const { user_id ,companyId } = location.state || {};
    const [loading, Setloading] = useState(false);

    const [paymentInfo, setPaymentInfo] = useState({
        bank_name: "",
        account_num: "",
        pan_num: "",
        aadhar_num: "",
        UAN: "",
        company: companyId,
        register: user_id,
        user: register_id,
        edited_by: user_details.id,

    });
    
    useEffect(() => {
        if (!user_id) {

            navigate('/employee'); 
        }
    }, [user_id, navigate]);

    const handleInputChange = (event) => {
        setnewerror({});
        const { name, value } = event.target;
        setPaymentInfo((state) => ({
            ...state,
            [name]: value,
        }))
    };

    const handlekeypress = (e) => {
        const restrictedpattern = /[0-9!@#$%^&*(),.?":{}|<>+_]/;
        if ((e.target.name === 'bank_name') && restrictedpattern.test(e.key)) {
            e.preventDefault();
        }
    };
    
    const handlekeynumber = (e) => {
        const restricpattern = /[^0-9]/g;
        if ((e.target.name === 'aadhar_num' || e.target.name === 'account_num') && restricpattern.test(e.key)) {
            e.preventDefault();

        }
    };

    const handleInput = (e) => {
        const target = e.target;
        const start = target.selectionStart;
        const end = target.selectionEnd;
        const originalValue = target.value;
      
        // Update the value
        target.value = originalValue.toUpperCase().replace(/[^A-Z0-9]/g, '');
      
        // Restore the cursor position
        if (target.value !== originalValue) {
          const newLength = target.value.length;
          const newStart = Math.max(0, start - (originalValue.length - newLength));
          const newEnd = Math.max(0, end - (originalValue.length - newLength));
          target.setSelectionRange(newStart, newEnd);
        }
      };

    const handleKeynumstr = (e) => {
        const restricpattern = /[!@#$%^&*(),.?":'=;{}|<>+_]/;
        if ((e.target.name === 'pan_num' || e.target.name === 'UAN') && restricpattern.test(e.key)) {
          e.preventDefault();
        }
        // if (e.target.name === 'pan_num' && e.key >= 'a' && e.key <= 'z') {
        //   e.preventDefault();
        //   e.target.value += e.key.toUpperCase();
        // }
      };

    const handleSubmit = async () => {
        let newErrors = {};
        const panPattern = /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/;
        const twelveDigitPattern = /^\d{12}$/;
        const accountPattern = /^\d{9,18}$/;

        if (paymentInfo.bank_name.trim() === "") {
            newErrors.bank_name = "Bank Name is required";
        }
        if (paymentInfo.account_num.trim() === "") {
            newErrors.account_num = "Account Number is required";
        } else if (!accountPattern.test(paymentInfo.account_num)) {
            newErrors.account_num = "Account Number must be 9-18 digits.";
        }
        if (paymentInfo.pan_num.trim() === "") {
            newErrors.pan_num = "Pan Number is required";
        } else if (!panPattern.test(paymentInfo.pan_num)) {
            newErrors.pan_num = "Invalid PAN format. Example: ABCDE1234F";
        }
        if (paymentInfo.aadhar_num.trim() === "") {
            newErrors.aadhar_num = "Aadhar Number is required";
        } else if (!twelveDigitPattern.test(paymentInfo.aadhar_num)) {
            newErrors.aadhar_num = "Aadhar Number must be 12 digits.";
        }
        if (paymentInfo.UAN.trim() !== "" && !/^\d{12}$/.test(paymentInfo.UAN.trim())) {
            newErrors.UAN = "UAN must be 12 digits.";
          }
          
        setnewerror(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                Setloading(true);
                const response = await EmployeePaymentApi(paymentInfo);
                toast.success('Payment Details Added Sucessfully!', {
                    position: "top-center",
                    autoClose: 2000,
                    closeButton: false,
                    className: 'custom_toast',
                }

                );
                if (response) {
                    navigate('/employee');
                }
            }
            catch (error) {

                if (error.response && error.response.data) {
                    const errorData = error.response.data.data;
                    newErrors = {};
                    for (const key in errorData) {
                        if (errorData.hasOwnProperty(key)) {
                            newErrors[key] = errorData[key]; 
                        }
                    }
                    setnewerror(newErrors);

                } else {
                    toast.error(' Registration failed. Please try again.', {
                        position: "top-center",
                        autoClose: 2000,
                        closeButton: false,
                    });
                }

            } finally {
                Setloading(false)
            }
        }
    }

    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='addemp-subheader'>
                                <div className='addemp-subheader-sub'>
                                    <div className='addemp-subheader1'>
                                        <p>Add Payment</p>
                                    </div>
                                    <div className='addemp-subheader2'>
                                        <div className="addemp-stepper">
                                            <div className="addemp-step-indicator">
                                                <div className='addemp-step'>
                                                    Personal Info
                                                </div>
                                                <div className='addemp-step'>Basic Details</div>
                                                <div className='addemp-step'>Salary Details</div>
                                                <div className="addemp-step addemp-selected" >Payment</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='addemp-line'></div>
                            <div className='addemp-maincontent-sub'>
                                <div className='addemp-form form-padding'>
                                    <div className='addemp-form-sub'>
                                        <div className='addemp-form-sub-main'>
                                            <div className='emppersonalinfo-input-row'>
                                                <div className='emppersonalinfo-input-group'>
                                                    <label>Bank Name <span className='emppersonalinfo-mandatory'>*</span></label>
                                                    <input className='emppersonalinfo-input-field' onKeyPress={handlekeypress}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^[a-zA-Z\s]*$/.test(value)) {
                                                                handleInputChange(e); 
                                                            }
                                                        }}
                                                        placeholder='Enter bank name' name='bank_name' value={paymentInfo.bank_name} type='text' />
                                                    {errors.bank_name && (<div className='error-message-view'>{errors.bank_name}</div>)}
                                                </div>
                                                <div className='emppersonalinfo-input-group'>
                                                    <label> Account Number  <span className='emppersonalinfo-mandatory'>*</span></label>
                                                    <input className='emppersonalinfo-input-field' onKeyPress={handlekeynumber} placeholder='Enter account no' onChange={handleInputChange} name='account_num' value={paymentInfo.account_num} type='text' />
                                                    {errors.account_num && (<div className='error-message-view'>{errors.account_num}</div>)}
                                                </div>
                                            </div>
                                            <div className='emppersonalinfo-input-row'>
                                                <div className='emppersonalinfo-input-group'>
                                                    <label>Pan Number <span className='emppersonalinfo-mandatory'>*</span></label>
                                                    <input className='emppersonalinfo-input-field'   onInput={handleInput}  onKeyPress={handleKeynumstr} placeholder='Enter pan number' onChange={handleInputChange} name='pan_num' value={paymentInfo.pan_num} type='text' />
                                                    {errors.pan_num && (<div className='error-message-view'>{errors.pan_num}</div>)}
                                                </div>

                                            </div>
                                            <div className='emppersonalinfo-input-row'>
                                                <div className='emppersonalinfo-input-group'>
                                                    <label> Aadhar Number  <span className='emppersonalinfo-mandatory'>*</span></label>
                                                    <input className='emppersonalinfo-input-field' onKeyPress={handlekeynumber} placeholder='Enter aadhar number' onChange={handleInputChange} name='aadhar_num' value={paymentInfo.aadhar_num} type='text' />
                                                    {errors.aadhar_num && (<div className='error-message-view'>{errors.aadhar_num}</div>)}
                                                </div>
                                            </div>
                                            <div className='emppersonalinfo-input-row'>
                                                <div className='emppersonalinfo-input-group'>
                                                    <label>UAN </label>
                                                    <input className='emppersonalinfo-input-field' onKeyPress={handleKeynumstr} placeholder='Enter UAN' onChange={handleInputChange} name='UAN' value={paymentInfo.UAN} type='text' />
                                                    {errors.UAN && (<div className='error-message-view'>{errors.UAN}</div>)}
                                                </div>

                                            </div>
                                            <div className='emppersonalinfo-submitbutton'>
                                                <button onClick={handleSubmit} disabled={loading}>{loading ? <span className='spinner'></span> : "Submit"}</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payment