import React, { useState } from 'react'
import '../../Assets/popup.css'
import { AddLeaveComponet } from '../../Config/api';
import { toast } from 'react-toastify';
// import { Store } from '../../Store';
// import { addLeavecomponent } from '../../Slice/leaveComponentSlice';
import { getRegisterId } from '../../Config/userId';
import '../Leavecomponent/Leavecomponent.css'
import { getUserDetails } from '../../Services/Storage';
import { MultiSelect } from "react-multi-select-component";

const Addleavecomponent = ({ show, close, sendDataToParent }) => {

  const user_details = getUserDetails();
  const register_id = getRegisterId();
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(false);
  const [selectedDeductType,setselectedDeductType] = useState([])
  const [addleavecomp, setleavecomp] = useState({
    leave_type: '',
    leave_name: '',
    leave_deduct: [], 
    earning_deduction: '',
    user: register_id,
    edited_by: user_details.id,
  });

  const closePopup =()=>{
    close();
    setErrors({});
    setselectedDeductType([]);
    setleavecomp({
      leave_type: '',
      leave_name: '',
      leave_deduct: [], 
      earning_deduction: '',
      user: register_id,
      edited_by: user_details.id,
    })
  }
  const handleInputChange = (event) => {
    setErrors({});

    const { name, value } = event.target;
    setleavecomp((preValue) => ({
      ...preValue,
      [name]: value,
    }))
  };

  const listStatus = [
    { value: "Fullday", label: "Full Day" },
    { value: "Halfday", label: "Half Day" },
  ];

  const handleEligibleLeaveChange = (selected) => {
    const selectedValues = selected.map(option => option.value);
    setselectedDeductType(selectedValues)
    const isAllSelected = selectedValues.length === listStatus.length;
    setleavecomp((state) => ({
      ...state,
      leave_deduct: isAllSelected ? ["All"] : selectedValues,
    }));
  };

  const handleSubmit = async () => {
    // console.log(addleavecomp)
    const fieldData = [{
      name: "leave_type",
      type: "input",
      err: "Leave Type",
      conditions: [
        (value) => typeof value === "string",
        (value) => value.trim() !== "",
        (value) => /^[a-zA-Z\s]+$/.test(value),
      ]
    },
    {
      name: "leave_name",
      type: "input",
      err: "Leave Name",
      conditions: [
        (value) => typeof value === "string",
        (value) => value.trim() !== "",

        (value) => /^[a-zA-Z\s]+$/.test(value),]
    },
   {
            name: "leave_deduct",
            type: "select",
            err: "Leave Deduction Type",
            conditions: [
                (value) => Array.isArray(value) && value.length > 0, // Ensure it's an array and has values
            ],
        },
    {
      name: 'earning_deduction',
      type: 'select',
      err: 'Salary Type',

      conditions: [
        (value) => value !== '',
      ]
    }

    ]
    const fieldOrder = [
      "leave_type",
      "leave_name",
      "leave_deduct",
      "earning_deduction",
    ]
    const newErrors = {};
    let firstInvalidField = null;
  
    for (const fieldName of fieldOrder) {
      const fieldInfo = fieldData.find((field) => field.name === fieldName);
      if (!fieldInfo) continue;
  
      const fieldLabel = fieldInfo.err;
      const value = addleavecomp[fieldName];
  
      let isFieldValid = fieldInfo.conditions.every((condition) =>
        typeof condition === "function" ? condition(value) : condition
      );
  
      if (!isFieldValid) {
        newErrors[fieldName] = `${fieldLabel} is required`;
          if (!firstInvalidField) {
          firstInvalidField = document.querySelector(`[name="${fieldName}"]`);
        }
      }
    }
  
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setloading(true)
        const response = await AddLeaveComponet(JSON.stringify(addleavecomp));
        console.log(response.data.leave_components)
        sendDataToParent(response.data.leave_components);
        // Store.dispatch(addLeavecomponent(response.data.leave_component))
        setleavecomp((preValue) => ({
          ...preValue,
          leave_type: '',
          leave_name: '',
          leave_deduct: [], 
          earning_deduction: '',
          user: register_id,
          edited_by: user_details.id,
        }));
        setselectedDeductType([]);
        close();
        toast.success('Added Sucessfully!', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
          className: 'custom_toast'
        })
      } catch (error) {
        if (error.response && error.response.data) {
          const { data } = error.response.data;
          const responseErrors = {};
          for (const key in data) {
            responseErrors[key] = data[key];
          }
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...responseErrors,
          }));
        } else {
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: "custom_toast",
          });
        }
      } finally {
        setloading(false)
      }
    }
  };

  if (!show) return null;
  
  return (
    <div className='leavecomp-section'>
      <div className='leavecomp-container'>
          <span className='uploadexcel-close' onClick={closePopup}>&times;</span>
          <div className='leavecomp-container-sub'>
            <div className='leavecomp-component-heading'>
              <p>Add Leave Component</p>
            </div>
            <div className='leavecomp-strightline'></div>
            <div className='leavecomp-comp-form-div'>
            <div className='leavecomp-comp-form-divsub'>
              <div className='leavecomp-comp-input-div'>
                <span className='leavecomp-comp-input-div-span-text'> Leave Type <span className='leavecomp-comp-input-important'> * </span> </span>
                <input type='text' onChange={handleInputChange} name='leave_type' className='leavecomp-comp-input' />
                {errors.leave_type && (<div className='error-message'>{errors.leave_type}</div>)}
              </div>
              <div className='leavecomp-comp-input-div'>
                <span className='leavecomp-comp-input-div-span-text'> Leave Name <span className='leavecomp-comp-input-important'> * </span> </span>
                <input type='text' onChange={handleInputChange} name='leave_name' className='leavecomp-comp-input' />
                {errors.leave_name && (<div className='error-message'>{errors.leave_name}</div>)}
              </div>
              <div className='leavecomp-comp-input-div'>
                <span className='leavecomp-comp-input-div-span-text' > Leave Deduction Type <span className='leavecomp-comp-input-important'> * </span> </span>
{/* 
                <select className='leavecomp-comp-select-box' onChange={handleInputChange} name='leave_deduct'  >
                  <option value="">select Deduction Type</option>
                  <option value="Fullday">Full Day</option>
                  <option value="Halfday">Half Day</option>
                </select> */}
                <MultiSelect
                                options={
                                  listStatus
                                }
                         
                                value={selectedDeductType.map(value => ({
                                  label: listStatus.find(option => option.value === value)?.label || value,
                                  value: value
                                }))} 
                                onChange={handleEligibleLeaveChange}
                                overrideStrings={{
                                  "allItemsAreSelected": "All ",
                                  "selectSomeItems": "Select ",
                                }}                      
                              />
                {errors.leave_deduct && (<div className='error-message'>{errors.leave_deduct}</div>)}
              </div>
              <div className='leavecomp-comp-input-div'>
                <span className='leavecomp-comp-input-div-span-text' > Salary Type <span className='leavecomp-comp-input-important'> * </span> </span>

                <select className='leavecomp-comp-select-box' onChange={handleInputChange} name='earning_deduction' >
                  <option value="">select Salary Type</option>
                  <option value="earning">Earning</option>
                  <option value="deduction">Deduction</option>
                </select>
                {errors.earning_deduction && (<div className='error-message'>{errors.earning_deduction}</div>)}
              </div>
              {errors.response_error && (<div className='error-message'>{errors.response_error}</div>)}
              <div className='leavecomp-comp-button-div'>
                <button onClick={handleSubmit} className='leavecomp-button-cls' disabled={loading}>{loading ? <span className='spinner'></span> : "Submit"}</button>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  )
}

export default Addleavecomponent