import React, { useState, useEffect } from 'react';
import '../addingcomp.css'
import { updateSalaryComponentApi } from '../../../Config/api';
import { Store } from '../../../Store';
import { updateSalaryComponent } from '../../../Slice/salaryComponentSlice';
import { toast } from 'react-toastify';
import { getRegisterId } from '../../../Config/userId';
import SalaryComponetAlert from '../SalaryComponetAlert';
import { getUserDetails } from '../../../Services/Storage';

const Editdeduction = ({ show, close, editData, updateData }) => {

  const user_details = getUserDetails();
  const [loader, setloader] = useState(false);
  const register_id = getRegisterId();
  const [errors, setErrors] = useState({});
  const [openAlert,setOpenAlert] = useState(false);
  const [alertMessage ,setAlertMessage] =useState(false);

  const [salarycomponent, setSalaryComponent] = useState({
    component_name: '',
    component: '',
    calculation_type: '',
    amount_type: '',
    calculation_code: '',
    earning_deduction: '',
    user: register_id,
    edited_by:user_details.id,
    general_earning:'General Deduction',
    confirm:"",
    user_id: register_id,
    id:editData.id,

  });

  useEffect(() => {
    if (editData) {
      setSalaryComponent({
        component_name: editData.component_name,
        component: editData.component,
        calculation_type: editData.calculation_type,
        amount_type: editData.amount_type,
        calculation_code: editData.calculation_code,
        earning_deduction: editData.earning_deduction,
        general_earning:editData.general_earning,
        user: register_id,
        edited_by: user_details.id,
        confirm:"",
        user_id: register_id,
        id:editData.id,

      });
    }
  }, [editData,register_id,user_details.id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'amount_type') {
      const isPercentage = ['Percentage of CTC', 'Percentage of basic'].includes(salarycomponent.calculation_type);
      
      if (isPercentage) {
        if (!/^\d{1,3}(\.\d{1,2})?$/.test(value) || parseFloat(value) > 100) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            amount_type: 'Please enter a valid percentage',
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            amount_type: '',
          }));
        }
      } else if (salarycomponent.calculation_type === 'Float amount') {
        if (!/^\d+(\.\d{0,2})?$/.test(value) || parseFloat(value) > 1000000) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            amount_type: 'Please enter a valid amount up to 1,000,000',
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            amount_type: '',
          }));
        }
      }
    }
    setSalaryComponent((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const handlekeynumber = (e) => {
    const restricpattern = /[^0-9]/g;
    if (e.target.name === 'amount_type' && restricpattern.test(e.key)) {
      e.preventDefault();
    }
  };

  const handlekeydecimal = (e) => {
    const restricpattern = /[^0-9.]/g;
    if (e.target.name === 'amount_type' && restricpattern.test(e.key)) {
      e.preventDefault();
    }
  };
  const closePopup =()=>{
    close();
    setErrors({})
    setSalaryComponent({
      component_name: editData.component_name,
      component: editData.component,
      calculation_type: editData.calculation_type,
      amount_type: editData.amount_type,
      calculation_code: editData.calculation_code,
      earning_deduction: editData.earning_deduction,
      user: register_id,
      user_id: register_id,
      edited_by: user_details.id,
      confirm:"",
      id:editData.id,

    })
  }
  const openAlertPop =()=>{
    setOpenAlert(true);
  };

  const closeAlertPop =()=>{
    setOpenAlert(false);
  };

  const updated =async(data)=>{
    if(data === "yes"){

    try{
      setloader(true);
      const payload ={
        ...salarycomponent,
             confirm: "true",  

      }
      const response = await updateSalaryComponentApi(payload);
      if(response){
        updateData(response);
        toast.success('Updated Sucessfully!', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
          className: 'custom_toast'
        });
      }

    }catch{

      
    }finally{
      setloader(false);

    }
  }else{
  
    try{
      setloader(true);
      const payload ={
        ...salarycomponent,
             confirm: "false",  

      }
      const response = await updateSalaryComponentApi(payload);
      if(response){
        updateData(response);
        toast.success('Updated Sucessfully!', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
          className: 'custom_toast'
        });
      }

    }catch{

      
    }finally{
      setloader(false);

    }
  }
  }

  const handleSubmit = async () => {
    const fieldData = [
      {
        name: "component_name",
        type: "input",
        err: "Component Name",
        conditions: [
          (value) => typeof value === "string", 
          (value) => value.trim() !== "", 
          (value) => /^[a-zA-Z\s]+$/.test(value),
        ],
      },
      {
        name: "component",
        type: "input",
        err: "Component Type",
        conditions: [
          (value) => typeof value === "string", 
          (value) => value.trim() !== "", 
          (value) => /^[a-zA-Z\s]+$/.test(value), 
        ],
      },
      {
        name: "amount_type",
        type: "input",
        err: "Amount Type",
        conditions: [
          (value) => {
            if (salarycomponent.calculation_type === "Float amount") {
              return !isNaN(value) && parseFloat(value) > 0 && parseFloat(value) <= 1000000;
            }
            return /^[1-9]?[0-9]?(\.\d{1,2})?$|^100$/.test(value); // Validates 1-100%
          }
        ],
      },
    ];
    const fieldOrder = [
      "component_name",
      "component",
      "amount_type"
    ];
    const newErrors = {};
    for (const fieldName of fieldOrder) {
      const fieldInfo = fieldData.find((field) => field.name === fieldName);
      if (!fieldInfo) {
        continue;
      }
      const fieldType = fieldInfo.type;
      const fieldLabel = fieldInfo.err;
      const value = salarycomponent[fieldName];
      const isFieldValid = fieldInfo.conditions.every((condition) =>
        typeof condition === "function" ? condition(value) : condition
      );
      if (!isFieldValid) {
        if (value.trim() === "") {
          newErrors[fieldName] = `${fieldLabel} is required`;
        } else {
          newErrors[fieldName] = `Please Enter a Valid ${fieldLabel}.`;
        }
        const inputField = document.querySelector(
          `${fieldType}[name="${fieldName}"]`
        );
        if (inputField) {
          inputField.focus();
          break;
        }
      }
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setloader(true);

        const response = await updateSalaryComponentApi(salarycomponent);
        if (response && response.data && response.data.updated_salary_component
        ) {

          updateData(response.data.updated_salary_component
          );
          Store.dispatch(updateSalaryComponent(response.data.updated_salary_component));
          close();
          toast.success('Deduction Updated Sucessfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          })
        } else {
        }
      }
      catch (error) {
        if (error.response && error.response.status === 422) {
          setAlertMessage(error.response.data.alert);
          openAlertPop(true);
        }
        const errorMessage =
          error.response && error.response.data && error.response.data.data
            ? error.response.data.data
            : "Add Earnings Failed. Please try again.";

        setErrors((prevErrors) => ({
          ...prevErrors,
          response_error: errorMessage,
        }));

        // toast.error("Add Deduction Failed. Please try again.", {
        //   position: "top-center",
        //   autoClose: 2000,
        //   closeButton: false,
        // });
      } finally {
        setloader(false);

      }
    }
  }

  if (!show) return null
  return (

    <div className='addsalrcomp-section'>
      <div className='addsalrcomp-container'>
          <span className='uploadexcel-close' onClick={closePopup}>&times;</span>
          <div className='addsalrcomp-container-sub'>
            <div className='add-component-heading'>
              <p>Update Deduction:</p>
            </div>
            <div className='addleave-strightline'></div>
            <div className='sal-comp-form-div'>
            <div className='sal-comp-form-divsub'>

              <div className='sal-comp-input-div'>
                <span className='sal-comp-input-div-span-text'> Deduction Name <span className='sal-comp-input-important'> * </span> </span>
                <input type='text' onChange={handleInputChange} value={salarycomponent.component_name} name='component_name' className='sal-comp-input' />
                {
                  errors.component_name && (
                    <div className='error-message'>{errors.component_name}</div>
                  )
                }
              </div>
              <div className='sal-comp-input-div'>
                <span className='sal-comp-input-div-span-text'> Deduction Type  <span className='sal-comp-input-important'> * </span></span>
                <input type='text' onChange={handleInputChange} value={salarycomponent.component} name='component' className='sal-comp-input' />
                {
                  errors.component && (
                    <div className='error-message'>{errors.component}</div>
                  )
                }
              </div>
              <p className='pages-notes'>
                
                 Note<span className='emppersonalinfo-mandatory'>*</span> : When you enter a percentage or amount, it will be calculated for the whole year.
                 </p>
              <div className='sal-comp-input-div'>
                <span className='sal-comp-input-div-span-text' > Calculation Type <span className='sal-comp-input-important'> * </span> </span>
                <select className='sal-comp-select-box' value={salarycomponent.calculation_type} name='calculation_type' onChange={handleInputChange}>
                <option value="Float amount">Float Amount</option>

                  <option value="Percentage of CTC">Percentage of CTC</option>
                  <option value="Percentage of basic">Percentage of Basic</option>
                </select>

              </div>
              <p className='pages-notes'> Note<span className='emppersonalinfo-mandatory'>*</span> : Only add additional deduction to the salary.</p>

              <div className='sal-comp-input-div'>
                <span className='sal-comp-input-div-span-text' > Component Access <span className='sal-comp-input-important'> * </span> </span>

                <select className='sal-comp-select-box' name='general_earning' value={salarycomponent.general_earning} onChange={handleInputChange}>
                  <option value="General Deduction">General Deduction</option>
                  <option value="Additional Deduction">Additional Deduction</option>
                </select>

              </div>
              {salarycomponent.calculation_type === 'Float amount' ? (
                <div className='sal-comp-input-div'>
                  <span className='sal-comp-input-div-span-text'> Enter Amount <span className='sal-comp-input-important'> * </span> </span>
                  <input type='text' onKeyPress={handlekeynumber} placeholder='e.g., 5000' onChange={handleInputChange} value={salarycomponent.amount_type} name='amount_type' className='sal-comp-input' />
                  {
                    errors.amount_type && (
                      <div className='error-message'>{errors.amount_type}</div>
                    )
                  }
                </div>
              ) : (
                <div className='sal-comp-input-div'>
                  <span className='sal-comp-input-div-span-text'> Enter {salarycomponent.calculation_type} <span className='sal-comp-input-important'> * </span> </span>
                  <input type='text'  onKeyPress={handlekeydecimal} placeholder='e.g., 50%' onChange={handleInputChange} name='amount_type' value={salarycomponent.amount_type} className='sal-comp-input' />
                  {
                    errors.amount_type && (
                      <div className='error-message'>{errors.amount_type}</div>
                    )
                  }
                  {
                    errors.response_error && (
                      <div className='error-message-view salry-comp-err'>
                        {errors.response_error.component}
                      </div>
                    )
                  }
                </div>
              )}
              <div className='sal-comp-button-div'>
                <button onClick={handleSubmit} disabled={loader} className='popup-button-cls'>{loader ? <span className='spinner'></span> : "submit"}</button>
              </div>
            </div>
            </div>
        </div>
      </div>
      <SalaryComponetAlert
        data={alertMessage}
       show={openAlert}
       close={closeAlertPop}
       updated ={updated}
      />
    </div>
  )
}

export default Editdeduction