const notificationImg={
    
    announcement:"./Images/Notification/announcement.png",
    income:"./Images/Notification/income.png",
    information:"./Images/Notification/information.png",
    reminder:"./Images/Notification/reminder.png",
    warning   :"./Images/Notification/warning.png",
    warningsign     :"./Images/Notification/warning-sign.png",
    loadingarrow:"./Images/Notification/loading-arrow.png",
    


}
export default notificationImg;