import { getUserDetails } from "../Services/Storage";

export const getRegisterId = () => {
    const user_details = getUserDetails() || {};

    if (user_details.role === "Company") {
        return user_details.id || null;
    } else if (user_details.role === "other_user") {
        return user_details.user_id || null;
    } else {
        return user_details.id || null;

    }
};
