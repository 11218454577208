import React, { useState, useEffect, useCallback } from "react";
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import Select from "react-select";
import pageloader from "../../../Loader/pageloading/Pageloader";
import ReactPaginate from "react-paginate";
import perPageOptions from "../../../JsonData/Perpagelist";
import { DeleteLogs, ViewLogs, ViewRolesselect, Viewselectlogs } from "../../../Config/api";
import { getRegisterId } from "../../../Config/userId";
import nodatafound from "../../../Images/Noimagefound/Noimgfound";
import formatDate from "../../Component/DateConvert";
import Clearlog from "./Clearlog";
import Confirmation from "../../Component/Confirmation";
import { toast } from 'react-toastify';
import { CiViewList } from "react-icons/ci";
import ViewLogsDetails from "./ViewLogsDetails";
import DatepickerComp from "../../Component/DatepickerComp";

const Logs = () => {

  const register_id = getRegisterId();
  const [currentpage, setCurrentPage] = useState(1);
  const [isloading, setIsLoading] = useState(true);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [perpage, setperpage] = useState(10);
  const [totaldedcount, setdedcount] = useState(0)
  const [rowLoader, setRowLoader] = useState({});
  const [viewLogDetails, setViewLogDetails] = useState(false)
  const [logview, setlogview] = useState("");
  const [perpagefilter, setperpagefilter] = useState({ value: 10, label: "10" })
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [openclrlog, setclrlog] = useState(false);
  const [openalert, setopenalert] = useState(false);
  const [loader, setloader] = useState(false);
  const [datasedit, setdatasedit] = useState()
  const [from_date, setFromDate] = useState('');
  const [to_date, setToDate] = useState('');
  const [userfilteract, setuserfilteract] = useState({ value: "", label: "All" });
  const [userfiltercat, setuserfiltercat] = useState({ value: "", label: "All" });
  const [rolefilview, setrolefilview] = useState('')
  const [userfilterrol, setuserfilterrol] = useState({ value: "", label: "All" });
  const [action, setAction] = useState('');
  const [category, setcategory] = useState('')
  const [role, setrole] = useState('')

  useEffect(() => {
    const fetchRoleData = async () => {
      try {
        const data = await ViewRolesselect(`?user_id=${register_id}`);

        const roleData = [
          { value: "", label: "ALL" },
          ...data.data.get_role.map(role => ({
            value: role.id,
            label: role.role,
          })),
        ]
        setrolefilview(roleData);
      } catch (error) {
      }
    };
    fetchRoleData();
  }, [register_id]);

  const handleChangeAction = (selecteOption) => {
    setuserfilteract(selecteOption);
    setAction(selecteOption.value);
    setCurrentPage(1);
  };

  const handleChangeCategory = (selecteOption) => {
    setuserfiltercat(selecteOption);
    setcategory(selecteOption.value);
    setCurrentPage(1);
  };

  const handleChangeRole = (selecteOption) => {
    setrole(selecteOption.value);
    setuserfilterrol(selecteOption);
    setCurrentPage(1);
  };

  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handlePerPageChange = (selectedOption) => {
    setperpage(selectedOption.value);
    setperpagefilter(selectedOption)
    setCurrentPage(1);
  };

  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      setSelectedLogs([]);
    } else {
      setSelectedLogs(logview.map((log) => log.id));
    }
    setIsAllChecked(!isAllChecked);
  };

  const closeclrlog = () => {
    setclrlog(false)
  }
  const opneclrlog = () => {
    setclrlog(true)
  }

  const handleCheckboxChange = (id) => {
    setSelectedLogs((prevState) =>
      prevState.includes(id)
        ? prevState.filter((selectedId) => selectedId !== id)
        : [...prevState, id]
    );
  };

  const closealert = () => {
    setopenalert(false)
  }
  const openviewalert = () => {
    setopenalert(true)

  }

  const updateddatas = (data) => {
    if (data) {
      fetchLogList();
    }
  }

  const openviewlogdetails = () => {
    setViewLogDetails(true)
  };

  const closeviewlogdetails = () => {
    setViewLogDetails(false)
  };

  const listAction = [

    { value: "", label: "All" },
    { value: "add", label: "Add" },
    { value: "edit", label: "Edit" },
    { value: "delete", label: "Delete" },
    { value: "deactive", label: "Deactive/Active" },

];

  const listCategory = [

    { value: "", label: "All" },
    { value: "Employee", label: "Employee" },
    { value: "Attendance", label: "Attendance" },
    { value: "Leave", label: "Leave" },
    { value: "Payroll", label: "Payroll" },
    { value: "Designation", label: "Designation" },
    { value: "Department", label: "Department" },
    { value: "Department Head", label: "Department Head" },
    { value: "Department TL", label: "Department TL" },
    { value: "Salary Component", label: "Salary Component" },
    { value: "Leave Component", label: "Leave Component" },
    { value: "Staff Salary Component", label: "Staff Salary Component" },
 
  ];

  const setNumberofDays = () => {
    if (setNumberofDays) {
    }
  };

  const handleselecttime = () => {
    setCurrentPage(1)
  };

  const openviewlog = async (id) => {
    setRowLoader((prev) => ({ ...prev, [id]: true }));
    try {
      const response = await Viewselectlogs(id);
      if (response) {
        setdatasedit(response.data.single_history_details)
        openviewlogdetails();
      }
    } catch (error) {
    } finally {
      setRowLoader((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleConfirm = async () => {
    setopenalert(false);
    const payload = {
      user_id: register_id,
      id_list: selectedLogs,
    };
    if (selectedLogs) {
      try {
        setloader(true);

        const response = await DeleteLogs(payload);
        if (response) {
          fetchLogList();
          toast.success('Deleted successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
          setSelectedLogs([]);
        }
      }
      catch (error) {

      } finally {
        setloader(false);
      }
    }
  };

  const fetchLogList = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await ViewLogs(
        `?user_id=${register_id}&page=${currentpage}&page_count=${perpage}&action=${action}&category=${category}&role=${role}&from_date=${from_date}&to_date=${to_date}`
      );
      setlogview(response.data.log_details);
      setdedcount(response.data.page_context.total);
    } catch (error) {
    } finally {
      setIsLoading(false);

    }
  }, [currentpage, perpage, action, category, role, from_date, to_date, register_id]);

  useEffect(() => {
    localStorage.removeItem("salaryFilters");

    fetchLogList();
  }, [fetchLogList]);

  return (
    <div className="index-container">
      <Header />
      <div className="index-content-parent">
        <div className="index-content">
          <Sidebar />
          <div className="index-content-sub">
            <div className="index-main">
              <div className="pages-subheader">
                {selectedLogs.length === 0 ? (
                  <>
                    <div className="pages-subheader-sub-1">
                      <p>Logs</p>
                      <span className="header-search-add-parent">
                        <button
                          className="pages-addempbutton"
                          onClick={opneclrlog}
                        >
                          Clear Logs
                        </button>
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="pages-subheader-sub-2">
                      <button className='pages-action-button reject' disabled={loader} onClick={openviewalert}>
                        {loader ? <span className='loader-pages-tablefield'></span> : "Delete"}
                      </button>
                    </div>
                  </>
                )}
                <div className="pages-line"></div>
              </div>
              <div className="pages-filter-options">
                <div className="pages-filter-sub">
                  <p>Filter:</p>
                  <Select
                    className="pages-dropdown1"
                    options={listAction}
                    onChange={handleChangeAction}
                    value={userfilteract}
                    placeholder="Select Action"
                  />
                  <Select
                    className="pages-dropdown1"
                    options={listCategory}
                    onChange={handleChangeCategory}
                    value={userfiltercat}
                    placeholder="Select"
                  />
                  <Select
                    className="pages-dropdown1"
                    options={rolefilview}
                    onChange={handleChangeRole}
                    value={userfilterrol}
                    placeholder="Select"
                  />
                  <div className="filter-date-logs" onClick={handleselecttime}
                  >
                    <DatepickerComp
                      setNumberOfDays={setNumberofDays}
                      setFromDate={setFromDate}
                      setToDate={setToDate}
                    />
                  </div>
                </div>
              </div>
              <div className="pages-maincontent">
                {isloading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                ) : (
                  <div className="pages-table-container">
                    <table className="pages-table">
                      {logview.length > 0 && (
                        <thead>

                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                checked={isAllChecked}
                                onChange={handleAllCheckboxChange}
                              />
                            </th>
                            <th>Sl No</th>
                            <th>User Name</th>
                            <th>Roles</th>
                            <th>Edited</th>
                            <th>Action</th>
                            <th>Date</th>
                            <th>View</th>

                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {logview.length > 0 ? (
                          logview.map((log, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedLogs.includes(log.id)}
                                  onChange={() => handleCheckboxChange(log.id)}
                                />
                              </td>
                              <td>{(currentpage - 1) * perpage + index + 1}</td>


                              <td>{log.edited_name}</td>
                              <td>{log.role_name}</td>

                              <td>{log.category}</td>
                              <td>{log.action}</td>
                              <td>{formatDate(log.date)}</td>
                              <td>

                                {rowLoader[log.id] ? (
                                  <span className='loader-pages-tablefield'></span>

                                )
                                  : (<CiViewList style={{ fontSize: "18px", color: "#2a7ac7" }} onClick={() => openviewlog(log.id)} />)

                                }

                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="no-data-table">
                              <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                )
                }
                <div style={{ float: "right" }} id="pagination_link"></div>
                <div className="pagenation-perpage-cont">
                  <div >
                    {(!isloading) && (totaldedcount > perpage) ? (
                      <>

                        <div>
                          <ReactPaginate
                            pageCount={Math.ceil(totaldedcount / perpage)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            onPageChange={currentPageNo}
                            forcePage={currentpage - 1}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>

                      </>
                    ) : (
                      <>


                      </>
                    )}
                  </div>
                  {(!isloading) && (totaldedcount >= 11) ? (
                    <div className="select-per-page-container">

                      <Select
                        menuPlacement="top"
                        className="per-page-container"
                        options={perPageOptions}
                        value={perpagefilter}
                        onChange={handlePerPageChange}
                        placeholder="page"
                      />
                    </div>) : (
                    <></>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Clearlog
        show={openclrlog}
        close={closeclrlog}
        updated={updateddatas}
      />

      <ViewLogsDetails
        show={viewLogDetails}
        close={closeviewlogdetails}
        datas={datasedit}
      />

      <Confirmation
        isOpen={openalert}
        onClose={closealert}
        message={`Are you sure you want to delete this log ?`}
        onConfirm={handleConfirm}
      />

    </div>

  )
}

export default Logs