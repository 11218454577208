import React, { useState } from 'react';
import './Logs.css';
import { DeleteLogs } from '../../../Config/api';
import { toast } from 'react-toastify';
import { getRegisterId } from '../../../Config/userId';

const Clearlog = ({ show, close, updated }) => {

  const register_id = getRegisterId();
  const [timeRange, setTimeRange] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]); 
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [loader ,setloader]=useState(false);
  const isCategorySelected = (category) => selectedCategories.includes(category);

  const handleTimeRangeChange = (e) => {
    const value = e.target.value;
    setTimeRange(value);

    const currentDate = new Date();
    let from, to;

    switch (value) {
      case 'last_day':
        from = new Date(currentDate.setDate(currentDate.getDate() - 1));
        to = new Date();
        break;
      case 'today':
        from = new Date();
        to = new Date();
        break;
      case 'last_week':
        from = new Date(currentDate.setDate(currentDate.getDate() - 7));
        to = new Date();
        break;
      case 'last_month':
        from = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
        to = new Date();
        break;
      case 'last_year':
        from = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));
        to = new Date();
        break;
      case 'custom':
        from = '';
        to = '';
        break;
      case 'alldata': 
        setSelectedCategories(['employee', 'attendance', 'leave', 'payroll']);
        return; 
      default:
        from = '';
        to = '';
    }

    setFromDate(from ? from.toISOString().split('T')[0] : '');
    setToDate(to ? to.toISOString().split('T')[0] : '');
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedCategories((prev) => [...prev, value]);
    } else {
      setSelectedCategories((prev) => prev.filter((category) => category !== value));
    }
  };

  const validateForm = () => {
    if (!timeRange) {
      setErrorMessage('Please select a time range.');
      return false;
    }
    if (timeRange !== 'alldata' && selectedCategories.length === 0) {
      setErrorMessage('Please select at least one category to delete.');
      return false;
    }
    if (timeRange === 'custom' && new Date(fromDate) > new Date(toDate)) {
      setErrorMessage('From date cannot be later than To date.');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const closePopup=()=>{
    setTimeRange('');
    setFromDate('');
    setToDate('');
    setSelectedCategories([]);
    close();
  }

  const handleDelete = async () => {
    if (!validateForm()) return;
    const payload = {
      user_id: register_id,
    };
    if (timeRange === 'all') {
      payload.deleted_data = timeRange;
      payload.category = selectedCategories;
    } else if (timeRange === 'alldata') {
      payload.deleted_data = timeRange;
    } else {
      payload.category = selectedCategories; 
      payload.from_date = fromDate;
      payload.to_date = toDate;
    }

    try {
      setloader(true);
      const response = await DeleteLogs(payload);
      if(response){
        setTimeRange('');
        setFromDate('');
        setToDate('');
        setSelectedCategories([]);
           toast.success('Deleted successfully!', {
                position: "top-center",
                autoClose: 2000,
                closeButton: false,
              });
              updated(response);
              close();
      }
    } catch (error) {
      const newErrors = {
        response_error: [],
      };
      if (error.response && error.response.data) {
        const errorData = error.response.data.data; 
        for (const key in errorData) {
          if (errorData.hasOwnProperty(key)) {
            newErrors.response_error.push(errorData[key]);
          }
        }
      } else {
        newErrors.response_error.push('An unexpected error occurred.');
      }
    
      setErrors(newErrors);   
     }finally{
      setloader(false);

     }
  };
  
  if (!show) return null;

  return (
    <div className="popup-section">
      <div className="logs-popup-container">
        <span className="uploadexcel-close" onClick={closePopup}>
          &times;
        </span>

        <div className="logs-popup-containersub">
          <div className="logs-popup-header">Delete Data</div>
          <div className="logs-popup-strightline"></div>
          <div className="logs-popup-maincontent">
            <div className="logs-popup-maincontentsb">
              <br />
              

              <span className="clrlog-span">
                <p className="para-logsname">Time range:</p>
                <select
                  className="clrlog-select"
                  value={timeRange}
                  onChange={handleTimeRangeChange}
                >
                  <option value="">Select</option>
                  <option value="alldata">All Data</option>
                  <option value="all">All</option>
                  <option value="today">Today</option>
                  <option value="last_day">Last 2 Day</option>
                  <option value="last_week">Last 7 Days</option>
                  <option value="last_month">Last Month</option>
                  <option value="last_year">Last Year</option>
                  <option value="custom">Customized Date</option>
                </select>
              </span>
              {timeRange === 'custom' && (
                <span className="clrlog-span">
                  <p style={{fontSize:"12px"}}>From:</p>
                  <input
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    className="date-input"

                 />
                  <p style={{fontSize:"12px"}}>To:</p>
                  <input
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    className="date-input"

                 />
                </span>
              )}
              <span className="clrlog-span">
                <input
                  type="checkbox"
                  value="employee"
                  disabled={timeRange === 'alldata'} // Disable if all data is selected

                  checked={isCategorySelected('employee')}
                  onChange={handleCheckboxChange}
                />
                <p className="para-logsname">Employee</p>
              </span>
              <span className="clrlog-span">
                <input
                  type="checkbox"
                  value="attendance"
                  disabled={timeRange === 'alldata'} // Disable if all data is selected

                  checked={isCategorySelected('attendance')}
                  onChange={handleCheckboxChange}
                />
                <p className="para-logsname">Attendance</p>
              </span>
              <span className="clrlog-span">
                <input
                  type="checkbox"
                  value="leave"
                  disabled={timeRange === 'alldata'} // Disable if all data is selected

                  checked={isCategorySelected('leave')}
                  onChange={handleCheckboxChange}
                />
                <p className="para-logsname">Leave</p>
              </span>
              <span className="clrlog-span">
                <input
                  type="checkbox"
                  value="payroll"
                  disabled={timeRange === 'alldata'} // Disable if all data is selected

                  checked={isCategorySelected('payroll')}
                  onChange={handleCheckboxChange}
                />
                <p className="para-logsname">Payroll</p>
              </span>
              {errors.response_error && (<div className='error-message-view'>{errors.response_error}</div>)}
              {errorMessage && <div className="error-message-view">{errorMessage}</div>}
              <br />
              <div className="clear-button">
                <button onClick={handleDelete} disabled={loader}>{loader ? <span className='spinner'></span> : "Delete"}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clearlog;
