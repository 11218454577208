import React, { useState, useEffect, useCallback } from 'react';
import { CiSearch, CiTrash, CiEdit } from 'react-icons/ci';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Adddep from './Adddep';
import { DeletedepcompApi, DeletedepcompconfirmApi, FetchDepartmentcomp } from '../../../Config/api';
import Editdep from './Editdep';
import DeleteAlert from '../../popup/DeleteAlert';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import pageloader from '../../../Loader/pageloading/Pageloader';
import ReactPaginate from "react-paginate";
import { Store } from '../../../Store';
import { deleteDepartmentComp } from '../../../Slice/departmentSlice';
// import Confirmationwidbtn from '../../Component/Confirmationwidbtn';
import { getRegisterId } from '../../../Config/userId';
import { getUserDetails } from '../../../Services/Storage';
import { depAddAcess, depDeleteAcess, depEditAcess } from '../../../Services/AccessCondition';
import Deleteconfirmation from '../../Component/Deleteconfirmation';
import { CompanyApi } from '../../../Config/CommonApi';
import { toast } from 'react-toastify';
import Select from "react-select";

const Viewdepartment = () => {

    const register_id = getRegisterId();
    const userDetails = getUserDetails();
    const accessid =  userDetails?.get_access_role?.[0]?.invite_user_details?.[0]?.register || "";
    const AddAccess = depAddAcess();
    const EditAccess = depEditAcess();
    const DeleteAccess = depDeleteAcess();
    const [departmentList, setdepartmentcomp] = useState([])
    const [isadddepopen, setadddepopen] = useState(false);
    const [iseditopen, seteditopen] = useState(false)
    const [editData, setEditData] = useState([]);
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [deleteComponentId, setDeleteComponentId] = useState(null);
    const [loading, setIsloading] = useState(false);
    const [totaldedcount, setdedcount] = useState(0)
    const per_page = 10;
    const [currentpage, setCurrentPage] = useState(1);
    const [search, setSearchword] = useState("");
    // const [deldepartment, setdepartment] = useState(false);
    // const [deleteComponentData, setDeleteComponentData] = useState([]); 
    const [opendeleteconf, setdeleteconf] = useState(false)
    const [deleteComponentDataUser, setDeleteComponentDataUser] = useState([]); 
    const [companyData, setCompanyData] = useState([]);
    const [companyFilter, setCompanyFilter] = useState([]);
    const [company, setCompany] = useState('all');

              useEffect(() => {
                localStorage.removeItem("salaryFilters");
                const fetchCompanyData = async () => {
                  const data = await CompanyApi();
                  setCompanyData(data);
                  setCompanyFilter(data[0]);
                };
        
                fetchCompanyData();
        
              }, [register_id]);
        
            const handleChangeCompany = (selecteOption) => {
                setCompany(selecteOption.value);
                setCompanyFilter(selecteOption);
                setCurrentPage(1);
              };
        

    const currentPageNo = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };

    const handleSearch = (event) => {
        setSearchword(event.target.value);
    }
    const openadddep = () => {
        setadddepopen(true);
    }
    const closeaddleave = () => {
        setadddepopen(false);
    }
    
    const closeDeleteConfirmUser =()=>{
        setdeleteconf(false);
    }

    const openDeleteConfirmUser =()=>{
        setdeleteconf(true);
    }

    const closeeditlevae = () => {
        seteditopen(false)
    }
    const handleUpdateData = (data) => {
        const updatedList = departmentList.map((comp) =>
            comp.id === data.id ? data : comp
        );
        setdepartmentcomp(updatedList);
    }
    const editleaveButton = (componentid) => {
        const updatedList = departmentList.filter((component) =>
            component.id === componentid
        );
        if (updatedList) {
            setEditData(updatedList[0]);
            seteditopen(true);

        }
    }
    const showConfirmationModalDelete = (delid) => {
        setDeleteComponentId(delid);
        setConfirmationModalOpen(true);

    }
    const closeConfirmationModal = () => {
        setConfirmationModalOpen(false);
    };

    const confirmDelete = () => {
        if (deleteComponentId) {
            deletesalaryComponent(deleteComponentId, false);
            closeConfirmationModal();
        }
    };

    const handleDataFromChild = (data) => {
        setdepartmentcomp((prev) => [
            data,
            ...prev,
        ]);
    };

    // const confideletealt = () => {
    //     if (deleteComponentId) {
    //         closedelconfir();
    //         deletesalaryComponent(deleteComponentId, true);
    //     }
    // };

    // const closedelconfir = () => {
    //     setdepartment(false);
    //     setDeleteComponentId(null);
    // };

    const Fetchdepartmentcomp = useCallback(async () => {
        try {
            setIsloading(true);

            const response = await FetchDepartmentcomp(`?register_id=${register_id}&page=${currentpage}&page_count=${per_page}&search=${search}&access_id=${accessid}&company=${company}`);
            if (response) {
                setdepartmentcomp(response.data.get_department || [])
                setdedcount(response.data.page_context.total)

            }
        } catch (error) {

        } finally {
            setIsloading(false)
        }
    }, [currentpage, per_page, search,accessid,register_id,company]);

    useEffect(() => {
        Fetchdepartmentcomp();
    }, [Fetchdepartmentcomp])

    const deletesalaryComponent = async (componentId, deleteConf) => {
        try {
            if (deleteConf === false) {
                const response = await DeletedepcompApi(`${componentId}/?edited_by=${userDetails.id}`);
                if (response) {
                    const updatedList = departmentList.filter((component) => component.id !== componentId);
                    setdepartmentcomp(updatedList);
                    Store.dispatch(deleteDepartmentComp(componentId));
                      toast.success('Deleted Sucessfully!', {
                                position: "top-center",
                                autoClose: 2000,
                                closeButton: false,
                                className: 'custom_toast'
                              })
                }
            } else {
                const response = await DeletedepcompconfirmApi(`${componentId}/?confirm=${true}`);
                if (response) {
                    const updatedList = departmentList.filter((component) => component.id !== componentId);
                    setdepartmentcomp(updatedList);
                    Store.dispatch(deleteDepartmentComp(componentId));
                    toast.success('Deleted Sucessfully!', {
                        position: "top-center",
                        autoClose: 2000,
                        closeButton: false,
                        className: 'custom_toast'
                      });
                }
            }
        }
        catch (error) {
            // setdepartment(true)
            if (error.response && error.response.status === 422) {
                openDeleteConfirmUser();
                setDeleteComponentDataUser(error.response.data);
            }else if(error.response && error.response.status === 423){
                openDeleteConfirmUser();
                setDeleteComponentDataUser(error.response.data);
            }
        }
    };
    
    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='pages-subheader'>
                                <div className='pages-subheader-sub-1'>
                                    <p>Department</p>
                                    <span className='header-search-add-parent'>
                                        <div className='pages-serach-view'>
                                            <CiSearch className='page-search-icon' />
                                            <input className='pages-serach' onChange={handleSearch} placeholder='Search Department ' type='search' />
                                        </div>
                                        {AddAccess
                                        ?(<button className='pages-addempbutton' onClick={openadddep}>Add Department</button>
                                        ):(<></>)

                                        }
                                    </span>
                                </div>
                                <div className='pages-line'></div>
                            </div>
                            <div className="pages-filter-options">
                  <div className="pages-filter-sub">
                    <p>Filter:</p>
                    <Select
                      className="pages-dropdown1"
                      options={companyData}
                      onChange={handleChangeCompany}
                      value={companyFilter}
                      placeholder="Select Work Location"
                    />
                   
                  </div>
                </div>
                            <div className='pages-maincontent'>
                                {loading ? (
                                    <div className="loader-container">
                                        <img src={pageloader.page_loader} alt="Loading..." />
                                    </div>
                                )
                                    : (
                                        <div className="pages-table-container">

                                        <table className='pages-table'>
                                        {departmentList && departmentList.length > 0 && (
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Sl No</th>
                                                    <th>Department</th>
                                                    <th>Company</th>
                                                    <th>##</th>


                                                </tr>
                                            </thead>
                                        )}
                                            <tbody>
                                                {departmentList && departmentList.length > 0 ? (

                                                    departmentList.map((department, index) => (
                                                        <tr key={department.id}>
                                                            <td></td>
                                                            <td>{(currentpage - 1) * per_page + index + 1}</td> {/* Updated index logic */}
                                                            <td>{department.depart_name}</td>
                                                            <td>
                                                            <div className="dataview-dropdown1">
                                                                        <button className="dropbtn">
                                                                            View
                                                                            <span className="arrow-down"></span>
                                                                        </button>
                                                                        <div className="dataview-dropdown-content1">
                                                                            {department.company_details.map((company) => (
                                                                                <p key={company.id}>{company.company_name}</p>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            <td>{department.user !== null ? (
                                                                <span style={{ display: "flex", flexDirection: "row", gap: "20px", fontSize: "14px", alignItems: "center" }}>
                                                                   {EditAccess
                                                                   ?(<CiEdit onClick={() => editleaveButton(department.id)} style={{ color: "#2a7ac7", cursor: "pointer" }} />
                                                                )
                                                                   :(<></>)

                                                                   }
                                                                   {DeleteAccess ?(
                                                                    <CiTrash onClick={() => showConfirmationModalDelete(department.id)} style={{ color: "red", cursor: "pointer" }} />

                                                                   ):(<></>)

                                                                   }
                                                                </span>
                                                            ) : null}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8} className="no-data-table">

                                                            <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    )}
                                <div style={{ float: "right" }} id="pagination_link"></div>
                                <div >
                                    {(!loading)&&(totaldedcount > per_page) ? (
                                        <>
                                            <ReactPaginate
                                                pageCount={Math.ceil(totaldedcount / per_page)}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={2}
                                                onPageChange={currentPageNo}
                                                containerClassName={"pagination"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                forcePage={currentpage - 1} // Adjust index (0-based)

                                            />
                                        </>
                                    ) : (
                                        <> </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Adddep
                show={isadddepopen}
                close={closeaddleave}
                sendDataToParent={handleDataFromChild}

            />
            <Editdep
                editData={editData}
                updateData={handleUpdateData}
                show={iseditopen}
                close={closeeditlevae}

            />
            <DeleteAlert
                isOpen={isConfirmationModalOpen}
                message="Really, would you like to remove this Department?"
                onCancel={closeConfirmationModal}
                onConfirm={confirmDelete}
            />
            {/* <Confirmationwidbtn
                close={closedelconfir}
                onConfirm={confideletealt}
                open={deldepartment}
                data={deleteComponentData}
            /> */}
                <Deleteconfirmation
                show={opendeleteconf}
                close={closeDeleteConfirmUser}
                data={deleteComponentDataUser}
            />
        </div>
    );
}

export default Viewdepartment;
