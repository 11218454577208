import React from 'react'
import Action from '../../Images/Action/action';

const Deactivatedpopup = ({show,close}) => {

    if(!show) return null;

  return (
   <div className="modal-overlay">
      <div className="modal-content">
        <div className='modal-content-close' onClick={close}>
          X
        </div>
        <h3 className='modal-content-h3'>Alert</h3>
        <div className='modal-content-container'>
          <div className='modal-content-imgview'>
            <img src={Action.error_img} alt='img' />
          </div>
        </div>
        <p style={{fontSize:"16px",fontWeight:"bold"}}>
        Account Deactivated


        </p>

        <p className='modal-content-p' style={{fontSize:"12px",color:"gray"}}>This account has been deactivated. Please contact the admin for further inquiries.</p>
        <div className="modal-buttons">
        </div>
      </div>
    </div>
  )
}

export default Deactivatedpopup