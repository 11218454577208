import React, { useEffect, useState } from 'react';
import './Index.css';
import './Dashboard.css';
// import { useNavigate } from 'react-router-dom'
import Header from '../Header/Header';
import Siderbar from '../Sidebar/Sidebar';
import CalendarDashboard from './CalendarDashboard';
import DashboardImages from '../../Images/DashboardImages/DashboardImages';
import { FetchLeavecompApi, ViewCompanyDetails, Viewdashboardcount, ViewHolidayListDash, Viewnewemployee, viewSalaryComponentApi, ViewUserDetails } from '../../Config/api';
import { getUserDetails, storeCompanyDetails, storeUserDetails } from '../../Services/Storage';
import { Store } from '../../Store';
import { setLeaveComponent as setLeaveComponentRedux } from '../../Slice/leaveComponentSlice';
import { setSalaryComponent as setSalaryComponentRedux } from '../../Slice/salaryComponentSlice';
import pageloader from '../../Loader/pageloading/Pageloader';
import CountUp from 'react-countup';
import { getRegisterId } from '../../Config/userId';
import formatDate from '../Component/DateConvert';

const DashboardOtherUser = () => {

  // const navigate = useNavigate();
  const userDetails = getUserDetails();
  const accessid = userDetails?.get_access_role?.[0]?.invite_user_details?.[0]?.register || "";
  const register_id = getRegisterId();
  const currentyear = new Date().getFullYear();
  const currentmonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
  const user_details = getUserDetails();
  const existingtokn = user_details?.fcm_token;
  const [dashboardct, setdashboardct] = useState([]);
  const [dashboardholiday, setdashboardholiday] = useState([]);
  const [dashboardemplnew, setdashboardemplnew] = useState([]);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    localStorage.removeItem("salaryFilters");

    const fetchLeaveComponent = async () => {
      try {
        const response = await FetchLeavecompApi(`?register_id=${user_details.id}`);
        Store.dispatch(setLeaveComponentRedux(response.data.get_leave_component));
      } catch (error) {
      }
    };
    const fetchsalaryComponent = async () => {
      try {
        const response = await viewSalaryComponentApi(`?register_id=${user_details.id}`);
        Store.dispatch(setSalaryComponentRedux(response.data.get_salary_component_earning));
      } catch (error) {
      }
    }


    const fetchdashboardcount = async () => {
      if (user_details.role === 'other_user') {
        try {
          const response = await Viewdashboardcount(`?register_id=${register_id}&admin_id=${user_details.id}&access_id=${accessid}`)
          setdashboardct(response.data)
        } catch (error) {
        }
      }
    }


    const fetchdashboardholiday = async () => {
      if (user_details.role === "other_user") {
        try {
          const response = await ViewHolidayListDash(`?register_id=${register_id}&access_id=${accessid}&year=${currentyear}&month=${currentmonth}`)
          setdashboardholiday(response.data.get_holiday)
        } catch (error) {
        }
      }
    }
    const fetchdashboardemployee = async () => {

      if (user_details.role === 'other_user') {
        setloader(true)
        try {
          const response = await Viewnewemployee(`?register_id=${register_id}&admin_id=${user_details.id}&access_id=${accessid}`)
          setdashboardemplnew(response.data.filter_employees);
          // setdashboardct(response.data)
        } catch (error) {
        } finally {
          setloader(false)

        }
      }
    }

    const fetchcompanyhead = async () => {
      if (user_details.role === 'other_user') {

        try {
          const response = await ViewCompanyDetails(`?register_id=${register_id}`);
          storeCompanyDetails(response.data.get_company[0])
        } catch {

        }
      }
    };
    const FetchUserDetails = async () => {

      try {
        const response = await ViewUserDetails(`?register_id=${user_details.id}`);

        const updatedetails = response.data.user_details;
        delete updatedetails.fcm_token;

        const finalUserDetails = {
          ...updatedetails,
          fcm_token: existingtokn,

        }
        storeUserDetails(finalUserDetails);
      } catch {

      }

    };
    FetchUserDetails();
    fetchcompanyhead();
    fetchdashboardemployee();
    fetchdashboardholiday();
    fetchdashboardcount();
    fetchLeaveComponent();
    fetchsalaryComponent();

  }, [user_details.id, user_details.role, user_details.user_id, existingtokn, accessid, currentmonth, currentyear, register_id]);

  // const navigateedit = () => {
  //   navigate('/viewprofile')
  // }

  // const navigateleave = () => {
  //   navigate('/viewleave', { state: { pending: "0" } });

  // }
  // const navigateemploye = () => {
  //   navigate('/employee')

  // }




  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Siderbar />
          <div className='dashboard-content-sub '>
            {/* start */}
            {loader ? (
              <div className="loader-container">
                <img src={pageloader.page_loader} alt="Loading..." />
              </div>
            ) : (
              <div className='index-main '>
                <div className='dashboard-main'>

                  <div className='dashboard-main-div1-sub'>
                    <div className='dashboard-main-div1-sub-1'>
                      <div className='dashboard-name-card'>
                        <div className='dashboard-nmcard-content'>
                          <div className='dashboard-nmcard-view'>
                            <div className='dashboard-nmcard-view1'>
                              <div className='dashboard-nmandcont'>
                                <p className='dashboard-nmandcont-p1'>Welcome {user_details.name} !</p>
                                <p className='dashboard-nmandcont-p2'>You have been granted admin access to view, edit, or add permissions based on your role. Any restrictions indicate that your access is limited to assigned privileges.</p>
                                <div className='dashboard-nmandcont-button'>
                                  {/* <button onClick={navigateedit}>Click</button> */}

                                </div>
                              </div>
                            </div>
                            <div className='dashboard-nmcard-view2'>
                              <img src={DashboardImages.dashboard_main} alt='img' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='dashboard-card-sml-container'>
                        <div className='dashboard-card-sml'>
                          <div className='dashboard-card-sml-view'>
                            <div className='dashboard-card-sml-main'>
                              <div className='dashboard-card-sml-cont'>
                                <h2>
                                  <CountUp end={dashboardct.all_count || 0} duration={2} />

                                </h2>
                                <p className='dashboard-card-sml-cont-p'>All User</p>

                              </div >
                              <div className='dashboard-card-sml-imgmain'>
                                <div className='dashboard-card-sml-img'>
                                  <img src={DashboardImages.usersall} alt='img' />
                                </div>
                              </div >
                            </div>

                          </div >
                          <div className='dashboard-card-sml-view'>
                            <div className='dashboard-card-sml-main'>
                              <div className='dashboard-card-sml-cont'>
                                <h2>
                                  <CountUp end={dashboardct.leave_count || 0} duration={2} />
                                </h2>
                                <p className='dashboard-card-sml-cont-p'>Leave Request</p>

                              </div >
                              <div className='dashboard-card-sml-imgmain'>
                                <div className='dashboard-card-sml-img'>
                                  <img src={DashboardImages.leave} alt='img' />
                                </div>
                              </div >
                            </div>

                          </div >
                        </div>
                        <div className='dashboard-card-sml'>
                          <div className='dashboard-card-sml-view'>
                            <div className='dashboard-card-sml-main'>
                              <div className='dashboard-card-sml-cont'>
                                <h2>
                                  <CountUp end={dashboardct.active_count || 0} duration={2} />
                                </h2>
                                <p className='dashboard-card-sml-cont-p'>Active User</p>

                              </div >
                              <div className='dashboard-card-sml-imgmain'>
                                <div className='dashboard-card-sml-img'>
                                  <img src={DashboardImages.activeusers} alt='img' />
                                </div>
                              </div >
                            </div>

                          </div >
                          <div className='dashboard-card-sml-view'>
                            <div className='dashboard-card-sml-main'>
                              <div className='dashboard-card-sml-cont'>
                                <h2>
                                  <CountUp end={dashboardct.deactive_count || 0} duration={2} />
                                </h2>
                                <p className='dashboard-card-sml-cont-p'>Deactive User</p>

                              </div >
                              <div className='dashboard-card-sml-imgmain'>
                                <div className='dashboard-card-sml-img'>
                                  <img src={DashboardImages.deactiveusers} alt='img' />
                                </div>
                              </div >
                            </div>

                          </div >
                        </div>
                      </div>

                    </div>
                    <div className='dashboard-main-div1-sub-2'>
                      <div className='dashboard-main-div2-cont'>
                        <div>
                          <div className='calendar-section-main'>
                            <div className='calendar-section'>
                              <CalendarDashboard />
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>

                  </div>


                  <div className='dashboard-main-div2'>


                    <div className='dashboard-card-emp-reqview-1'>
                      <div className='dashboard-card-leave-reqview-sub'>
                        <div className='dashboard-card-leave-main'>
                          <div className='dashboard-card-leave-heading'>
                            <h5>Holidays</h5>
                            <div className='dashboard-card-leave-button'>
                            </div>
                          </div>
                          <div className='dashboard-card-leave-table'>
                            <table>
                              <thead>
                                <tr>
                                  <th>From</th>
                                  <th>To</th>
                                  <th>Reason</th>

                                </tr>
                              </thead>

                              <tbody>
                                {dashboardholiday.length > 0 ?
                                  (dashboardholiday.map((holiday, index) => (
                                    <tr key={index}>
                                      <td>{formatDate(holiday.holiday_from_date)}</td>

                                      <td>{formatDate(holiday.holiday_end_date)}</td>

                                      <td>{holiday.reason}</td>
                                    </tr>
                                  ))
                                  ) : (
                                    <tr>
                                      <td colSpan="3" style={{ textAlign: "center" }}>
                                        No data found !
                                      </td>
                                    </tr>
                                  )

                                }

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='dashboard-card-emp-reqview-2'>
                      <div className='dashboard-card-leave-reqview-sub'>
                        <div className='dashboard-card-leave-main'>
                          <div className='dashboard-card-leave-heading'>
                            <h5>New Recruit</h5>
                            <div className='dashboard-card-leave-button'>
                            </div>
                          </div>
                          <div className='dashboard-card-leave-table'>
                            <table>
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Department</th>
                                  <th>Company</th>
                                </tr>
                              </thead>

                              <tbody>
                                {dashboardemplnew.length > 0 ? (
                                  dashboardemplnew.slice(0, 4).map((empnew, index) => (
                                    <tr key={index}>
                                      <td>{empnew.name}</td>
                                      <td>{empnew.department}</td>
                                      <td>{empnew.company}</td>

                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="3" style={{ textAlign: "center" }}>
                                      No data found !
                                    </td>
                                  </tr>
                                )



                                }

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
};
export default DashboardOtherUser