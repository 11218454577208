import React, { useState, useEffect } from "react";
import logo from "../Images/Logo/Logo";
// import websiteImages from "./WebsiteImages";
import "./css/home.css";
import "./css/mobileview.css";
import { Link } from "react-router-dom";
import websiteImages from "../Images/Website_images/WebsiteImages";

const Home = () => {


  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div className="staffin_heade_main_div">
        <div className={`staffin_div_main_logo ${isScrolled ? 'scrolled' : ''}`} >
          <div className="staffin_add_logos">
            <img src={logo.logo_colo_change} alt="" />
          </div>
          <div className="staffin_login_btns">
            <Link to="/login" style={{ color: "#fff", textDecoration: "none" }}>

              <button>
                Login
              </button>
            </Link>

          </div>
        </div>
        <div className="staffin_header_div">
          <div className="header-overlay">
            <h1>Where Efficiency Meets Accountability STAFFIN</h1>
            <p>
              "Empower your workforce with precision attendance management."
              Explore More.
            </p>
            <div className="staffin_playstor_pic">
              <img src={websiteImages.playstore} alt="" />
            </div>
          </div>
          {/* 
          <div className="staffin_add_rockied_pic">
            <img src={websiteImages.image2} alt="" />
          </div> */}
        </div>
      </div>
      <div className="staffin_clrss_div">
       

        <div className="staffin_contact_main_div">
          <div className="staffin_div">
            <h2>Attendance Management</h2>
            <p>
              What is Attendance Management?
            </p>
          </div>
          <div className="staffin_contact_div_conts">
            <div className="staffin_contts_div_conts">

              <div className="staffin_att_conts_div">
                <p>
                  {" "}

                </p>
                <p>

                  Attendance management refers to the process of tracking and managing employee attendance within an organization. This involves monitoring when employees start and end their workdays, as well as tracking absenteeism, lateness, leave, and overtime. The goal is to ensure employees adhere to their assigned schedules while providing accurate data for payroll processing, performance analysis, and legal compliance.                </p>
                <p>Effective attendance management helps organizations maintain productivity and reduce payroll errors. It also involves managing leave requests, monitoring absenteeism patterns, and generating reports that support decision-making in HR and management, ensuring compliance with labor laws and organizational policies.</p>
              </div>
              <div className="staffin_attendance_add_pic">
                <img src={websiteImages.About_tt} alt="" />
              </div>
            </div>
          </div>
        </div>

{/*        
        <div className="staffin_webImage_card">
          <br/>
        <h1 style={{color:"white",fontStyle:"italic",transform:"skewX(-10deg)"}}>Attendance Management</h1>
        
        <h3 style={{color:"white"}}>Leave & Absence Management,Biometric & RFID Integration,Seamless Payroll Processing</h3>
        <br/>
        <hr style={{width:"100px"}}/>
        <br/>

        <div className="staffin_webImage_card_view" >
          <img className="staffin_webImage_card_img" src={websiteImages.Web_app} alt=""/>
        </div>
        <p style={{color:"white"}}>An Attendance Management System is a digital solution designed to track and manage employee attendance efficiently. It eliminates the need for traditional paper-based methods and automates the process, ensuring accuracy, reducing errors, and saving time.</p>
        <br/>

        </div> */}
        <hr className="staffin_line" />

        <h2>All Solutions In One Platform</h2>
        <p>All-in-One Platform for Attendance, Payroll, and Employee Management.</p>
        <br />
        <br />
        <div className="staffin_clrss_first">
          <div className="staffin_clrss_contain">
            <div className="staffin_first_img">
              <div className="staffin_first_img-sub">
                <img src={websiteImages.payroll} alt="img" />

              </div>
            </div>
            <div className="staffin_first_text" >
              <h3>Payroll</h3>
              Payroll refers to the process of calculating and distributing wages, salaries, bonuses, and deductions for employees. It involves tracking work hours, managing tax withholdings, processing payments, and ensuring compliance with labor laws.
            </div>
          </div>

          <div className="staffin_clrss_contain">
            <div className="staffin_first_img">
              <div className="staffin_first_img-sub">
                <img src={websiteImages.emplo} alt="img" />

              </div>
            </div>
            <div className="staffin_first_text" >
              <h3>Employee</h3>

              An employee works for a company in exchange for wages or salary. They follow company policies, receive benefits, and contribute to operations. Employees are key to achieving business goals and overall success. </div>
          </div>

          <div className="staffin_clrss_contain">
            <div className="staffin_first_img">
              <div className="staffin_first_img-sub">
                <img src={websiteImages.attend} alt="img" />

              </div>

            </div>
            <div className="staffin_first_text" >
              <h3>Attendance</h3>
              Attendance tracks employee presence, absences, and time worked. It ensures accurate records for payroll, performance, and management. Efficient tracking helps maintain productivity and compliance.</div>
          </div>
        </div>
        <hr className="staffin_line" />

        <div className="staffin_about_main_div">
          <div className="staffin_aboute_div">
            <h2>About Us</h2>
            <p>
              At Sea Sense Staffin, we believe in the power of efficient
              workforce management.
            </p>
          </div>
          <div className="staffin_about_div_conts">
            <div className="staffin_aboute_div_conts_1">
              <div  className="staffin_div_add_pic">
                <img src={websiteImages.about} alt="" />
              </div>
              <div className="staffin_div_contents_div">
                <p>
                  Our mission is to provide businesses with precise and reliable
                  attendance management solutions that drive productivity and
                  accountability. With our innovative app, available on both the
                  App Store and Google Play, we empower companies to streamline
                  their operations and enhance employee engagement. Founded on
                  the principles of cutting-edge technology and user-friendly
                  design, Sea Sense Staffin aims to transform the way businesses
                  manage their teams. Our dedicated team of experts works
                  tirelessly to ensure that our clients receive top-notch
                  service and support, making workforce management a seamless
                  experience. Join us on our journey to revolutionize workforce
                  management and discover how Sea Sense Staffin can help your
                  business thrive.
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr className="staffin_line" />
        <h2>WE DO MORE THEN YOU WISH
        </h2>

          <div>
            <div className="staffin_cardoption_content">
              <div className="staffin_cardoption_contentsb">
                <div className="staffin_cardoption_div1">
                <h3>Salary Calculations</h3>
                  <p className="staffin_cardoption_contentp">
                  Payroll salary calculation ensures employees receive accurate and timely wages by computing earnings, deductions, taxes, and benefits per company policies and labor laws. It requires knowledge of payroll components, legal compliance, and efficient payroll management systems.</p>
                  <div >
                    <img style={{width:"80%"}} src={websiteImages.websitesal} alt="img"/>
                  </div>
                  </div>
                <div className="staffin_cardoption_div2">
                  <div className="staffin_cardoption_div2_1">
                    <div className="staffin_cardoption_div2_1sb">
                      
                      <div className="staffin_cardoption_div2_1img">
                      <h3>Upload Attendance 
                  Excel Sheet</h3>
                      <img src={websiteImages.websitesatt} alt="img"/>

                      </div>
                      <div className="staffin_cardoption_div2_1img">
                      <p className="staffin_cardoption_contentp">
                  Easily upload an Excel sheet to record and manage attendance data efficiently. Ensure the file is in the correct format before uploading to avoid errors. The system will process the data and update attendance records accordingly.

                  </p>

                      </div>

                    </div>
                    
                    </div>
                  <div className="staffin_cardoption_div2_2">
                  <div className="staffin_cardoption_div2_1sb">
                      
                      
                      <div className="staffin_cardoption_div2_1img">
<p className="staffin_cardoption_contentp">
An attendance management system ensures accurate tracking of employee attendance, working hours, and leaves. Implementing role-based login enhances security and efficiency by granting different levels of access to users based on their roles.</p>
                      </div>
                      <div className="staffin_cardoption_div2_1img">
                      <h3>Role Based Access</h3>
                      <img src={websiteImages.websiteacc} alt="img"/>

                      </div>

                    </div>
                    </div>

                </div>

              </div>
              </div>
          </div>
          <hr className="staffin_line" />

        <div className="staffin_service_main_div">
          <div className="staffin_service_div">
            <h2>Pricing</h2>
            <p>
              At Sea Sense Staffin, we believe in the power of efficient
              workforce management.
            </p>
          </div>
          <div className="staffin_service_div_conts">
            <div className="staffin_div_main">
              <div className="staffin_card_main_div">
                <div className="staffin_card_div_1">
                  <span class="pricing">
                    <span>
                      i <small></small>
                    </span>
                  </span>
                  <h2>Basic Attendance Tracking</h2>
                  <p>
                  Track employee check-ins and check-outs effortlessly.
                  </p>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>{" "}
                    <h3>
                    Employee Clock-in/Clock-out
                    </h3>
                  </div>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>
                    Daily Attendance Reports <span></span>
                    </h3>
                  </div>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>Employees Covered : 50</h3>
                  </div>
                  <div className="price-container">
        <span className="old-price">₹1000</span>
        <span className="new-price">₹800</span>
    </div>
    <br/>
                  <div className="staffin_service_btns">
                    <button>
                      <Link
                        to="/login"
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        START MY FREE TRIAL
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
              <div className="staffin_card_main_div">
                <div className="staffin_card_div_1">
                  <span class="pricing">
                    <span>
                      i <small></small>
                    </span>
                  </span>
                  <h2>Advanced Attendance</h2>
                  <p>
                  Automate payroll with attendance integration.
                  </p>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>
                    Real-time Attendance Sync
                    </h3>
                  </div>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>
                    Automated Payroll Processing <span></span>
                    </h3>
                  </div>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>Salary Calculations</h3>
                  </div>
                  <div className="price-container">
        <span className="old-price">₹2000</span>
        <span className="new-price">₹1800</span>
    </div>
    <br/>
                  <div className="staffin_service_btns">
                    <button>
                      <Link
                        to="/login"
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        START MY FREE TRIAL
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
              <div className="staffin_card_main_div">
                <div className="staffin_card_div_1">
                  <span class="pricing">
                    <span>
                      i <small></small>
                    </span>
                  </span>
                  <h2>Comprehensive HR Suite</h2>
                  <p>
                  All-in-one HR management with attendance & payroll.
                  </p>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>
                    Comprehensive History View
                    </h3>
                  </div>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>
                    Role-Based Login
                    </h3>
                  </div>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3> Employees Covered : Unlimited</h3>
                  </div>
                  <div className="price-container">
        <span className="old-price">₹3000</span>
        <span className="new-price">₹2800</span>
    </div>
    <br/>
                  <div className="staffin_service_btns">
                    <button>
                      <Link
                        to="/login"
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        START MY FREE TRIAL
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="staffin_line" />
        <div className="staffin_contact_main_div">
          <div className="staffin_div">
            <h2>Contact Us</h2>
            <p>
              At Sea Sense Staffin, we believe in the power of efficient
              workforce management.
            </p>
          </div>
          <div className="staffin_contact_div_conts">
            <div className="staffin_contts_div_conts">

              <div className="staffin_conta_conts_div">
                <p>
                  {" "}
                  We'd love to hear from you! Whether you have a question about
                  our services, need assistance with our app, or want to provide
                  feedback, our team is here to help.
                </p>
                <p>
                  We're here to help! Whether you have a question about our
                  services, need assistance with a feature, or just want to
                  provide feedback, we're always ready to assist. Reach out to
                  us through any of the methods below, and we'll get back to you
                  as soon as possible. Your satisfaction is our top priority,
                  and we're committed to ensuring you have the best experience
                  possible. Additionally, you can follow us on social media for
                  updates, news, and special promotions. Join our community and
                  stay connected with the latest developments and offerings from
                  our team.
                </p>
                <p>
                  Thank you for choosing us. We look forward to hearing from
                  you!
                </p>
                <p>Email: support@seasensestaffin.com</p>
              </div>
              <div className="staffin_contact_add_pic">
                <img src={websiteImages.contact} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="staffin_footer_main_div">
        <div className="staffin_footer_div">
          <h2>CopyRight 2024-2025 SEA SENSE | All Right Reserved</h2>
        </div>
      </div>
    </>
  );
};

export default Home;
