import React from 'react'
import Action from '../../Images/Action/action';
import './Deleteconfirmation.css'
const Deleteconfirmation = ({ show, close, data }) => {

  if (!show) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className='modal-content-close' onClick={close}>
          X
        </div>
        <h3 className='modal-content-h3'>Alert</h3>
        <div className='modal-content-container'>
          <div className='modal-content-imgview'>
            <img src={Action.error_img} alt='img' />
          </div>
        </div>
        <p className='modal-content-p'>{data.alert}</p>
        <div className="modal-buttons">
        </div>
      </div>
    </div>
  )
}

export default Deleteconfirmation