import React, { useState, useEffect, useRef } from 'react'
import { AddDepartHeadApi, viewSelectedDept, viewSelectedEmpdept } from '../../../Config/api';
import { getUserDetails } from '../../../Services/Storage';
import { CiSearch } from 'react-icons/ci';
import { toast } from 'react-toastify';
import { Store } from '../../../Store';
import { addDepartmentHead } from '../../../Slice/departmentHeadSlice'
import { getRegisterId } from '../../../Config/userId';
import { CompanyApi } from '../../../Config/CommonApi';

const Adddepartmenthead = ({ show, close, sendDataToParent }) => {

  const register_id = getRegisterId();
  const userDetails = getUserDetails();
  const [searchOpen, setSearchOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const dropdownRef = useRef(null);
  const leaveTypeDropdownRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [employeename, setEmployeeName] = useState('');
  const [loader, setloader] = useState(false)
  const [search, setSearchword] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [companyId ,setcompanyId] = useState("");
  const [departmentView ,setDepartmentView] = useState([]);
  const [isLoadingDepartment, setIsLoadingDepartment] = useState(false);
  const [isLoadingEmployee, setIsLoadingEmployee] = useState(false);

  const [adddephead, setdephead] = useState({
    depart_name: '',
    depart_head: '',
    user: register_id,
    edited_by: userDetails.id,
    company: '',
  });

  const closepop =()=>{
    close();
    setErrors({});
    setdephead({
      depart_name: '',
      depart_head: '',
      user: register_id,
      edited_by: userDetails.id,
      company: '',
    });
    setEmployeeName("");
  };

  useEffect(() => {
        if(show){
        const fetchCompanyData = async () => {
          const data = await CompanyApi();
  
          const filteredOption = data.filter(data =>data.value !== "all");
          setCompanyData(filteredOption);
          // setCompanyFilter(data[0]);
        };
      
        fetchCompanyData();
  
      }
  }, [register_id,show]);
  
  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setSearchOpen(false);
      }

      if (
        leaveTypeDropdownRef.current &&
        !leaveTypeDropdownRef.current.contains(event.target)
      ) {
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearch = (event) => {
    setSearchword(event.target.value);
  };

    const fetchdepartment = async (id) => {
      setIsLoadingDepartment(true);
      try {
        const response = await viewSelectedDept(`?register_id=${register_id}&company_id=${id}`)
        if (response) {
          setDepartmentView(response.data.get_department)
          // setdephdfetch(response.data.get_depart_head)
        }
      } catch {
  
      }finally{
        setIsLoadingDepartment(false);
      }
    }

    
    const fetchDepartmentComp = async (id) => {
      setIsLoadingEmployee(true);
      try {
        const response = await viewSelectedEmpdept(`?register_id=${register_id}&company_id=${companyId}&depart_id=${id}&search=${search}`)
        if (response) {
          setEmployees(response.data.emp_name_id)
          // setdephdfetch(response.data.get_depart_head)
        }
      } catch {
  
      }finally{
        setIsLoadingEmployee(false);
      }
    };
    
  const validateForm = () => {
    let formErrors = {};
    if (!adddephead.depart_name) {
      formErrors.depart_name = 'Department is required';
    }
    if (!adddephead.depart_head) {
      formErrors.depart_head = 'Employee is required';
    }
    if (!adddephead.company) {
      formErrors.company = 'Company is required';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; 
  };
  
  const handleInputChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setdephead((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "company" && { company: value })
    }));
  };

  const handleCompanyChange =(e)=>{
    setErrors({});
    const { name, value } = e.target;
    setcompanyId(value);
    setEmployeeName('');

    fetchdepartment(value);
    setdephead((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "company" && { company: value }),
      depart_name:''

    }));
  };

  const handleDepartChange =(e)=>{
    setErrors({});
    const { name, value } = e.target;
    setEmployeeName('');
    fetchDepartmentComp(value);
    setdephead((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "company" && { company: value }),
      depart_head:''

    }));
  };

  const handleSelectEmployee = (employee) => {
    setSearchword('');
    setSearchOpen(false);
    setdephead((prev) => ({
      ...prev,
      depart_head: employee.register_id,
    }))
    setEmployeeName(employee.name);
  };

  // const fetchEmployeeList = useCallback(async () => {
  //   try {
  //     const response = await FetchEmployee(`?register_id=${register_id}&search=${search}&access_id=${accessid}`);
  //     setEmployees(response.data.emp_name_id);

  //   } catch (error) {
  //   }
  // }, [search, register_id,accessid]);

  // useEffect(() => {
  //   fetchEmployeeList();
  // }, [fetchEmployeeList]);

  const handlesubmit = async () => {
    if (validateForm()) {
      try {
        setloader(true)
        const response = await (AddDepartHeadApi(adddephead));
        if (response) {
          sendDataToParent(response.data.department_head[0]);
          Store.dispatch(addDepartmentHead(response.data.department_head[0]));
          close();
          toast.success('Department Head Added Sucessfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          })
          setdephead({
            depart_name: '',
            depart_head: '',
            user: register_id,
            edited_by: userDetails.id,

            company: '',
          });
          setEmployeeName('');
          setcompanyId('');
        }
      } catch (error) {

        const newErrors = {
          response_error: [],
        };
        for (const key in error.response.data.data) {
          if (error.response.data.data.hasOwnProperty(key)) {
            newErrors.response_error.push(`${error.response.data.data[key]}`);
          }
        }
        toast.error("Add Department Failed. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
        setErrors(newErrors);
      } finally {
        setloader(false)
      }
    }

  };

  if (!show) return null

  return (
    <div className='popup-section'>
      <div className='popup-container-adddepdes'>
        <span className='uploadexcel-close' onClick={closepop}>&times;</span>

        <div className='popup-container-sub'>
          <div className='addleave-heading'>
            <p>Add Department Head</p>

          </div>

          <div className='addleave-strightline'></div>
          <div className='popup-content-viewing-adddepdes'>
  <div className='popup-input-hdtl'>
                <label> Company<span className='registeration-mandatory'> *</span> </label>
                <select onChange={handleCompanyChange}  className="emppersonalinfo-input-field3" name='company'>
                <option value="" disabled selected>
    Select Company
  </option>
                                {companyData.map((company, index) => (
                                  <option key={index} value={company.value}>
                                    {company.label}
                                  </option>
                                ))}
                              </select>
                {errors.company && (<div className='error-message-view'>{errors.company}</div>)}        
                    </div>
            <div className='popup-input-hdtl'>
              <label>Department<span className='registeration-mandatory'> *</span> </label>
              {isLoadingDepartment ? (
                                <div style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
                                <span className='loader-pages-tablefield'></span>

                                </div>
                              ) :(
                                <select className='dephdtl-select' onChange={handleDepartChange} value={adddephead.depart_name} disabled={!companyId} name='depart_name'>
                                <option value="" disabled>Select Department</option>
                                {departmentView.length > 0 ? (
                
                                departmentView.map((depart, index) => (
                                  <option key={index} value={depart.id}>{depart.depart_name}</option>
                                ))
                              ):(
                                <option disabled>No Data Found</option>
                
                              )}
                              </select>
                              )

              }
            
              {errors.depart_name && <span className='error-message-view'>{errors.depart_name}</span>}
            </div>
            <div className='popup-input-hdtl'>
              <label>Employee<span className='registeration-mandatory'> *</span> </label>

              <div ref={dropdownRef} className={`invoice-grid-div invoice-span-div invoice-sales-div ${searchOpen ? "active" : ""}`}>
                <div onClick={handleSearchClick} className='popup-input-hdt-select'>
                  <input
                    type='text'
                    placeholder='Select Employee'
                    onChange={handleInputChange}
                    value={employeename}
                    readOnly
                    disabled={!adddephead.depart_name}  // Disable until department is selected
                  />
                </div>
                {searchOpen && (
                  <div className='invoice-sales-grid-div-dropdown'>
                    <div className='invoice-dropdown-search'>
                      <CiSearch className="las la-search" />
                      <input
                        type="text"
                        placeholder='Search Employees'
                        name="search"
                        onChange={handleSearch}
                      />
                    </div>
                    {isLoadingEmployee ?(
                                          <ul style={{textAlign:"center",display:"flex",justifyContent:"center" ,height:"30px"}}>
                                          <span className='loader-pages-tablefield'></span>

                                          </ul>
                                        ):(

<ul>
                      {employees
                        .filter(employee =>
                          employee.name && employee.name.toLowerCase().includes(search.toLowerCase())
                        )
                        .map(employee => (
                          <li
                            key={employee.id}
                            className='sal_block'
                            onClick={() => handleSelectEmployee(employee)}
                          >
                            {employee.name}
                          </li>
                        ))}
                      {employees.length === 0 && (
                        <li className='no-data'>No data found</li>
                      )}
                    </ul>

                                        )
                 
}
                  </div>
                )}
                {errors.depart_head && <span className='error-message-view'>{errors.depart_head}</span>}

              </div>
              {errors.response_error && (
                <div className="registeration-error-view">{errors.response_error}</div>
              )}
            </div>


            <div className='popup-button'>
              <button onClick={handlesubmit} disabled={loader}>{loader ? <span className='spinner'></span> : "Submit"}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Adddepartmenthead