import React, { useEffect } from 'react'
import './UserSetting.css';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { CiCircleList } from 'react-icons/ci';
import { FiAnchor, FiBookOpen, FiDatabase, FiKey, FiLock, FiUser, FiUserCheck, FiUsers } from 'react-icons/fi';
import { AiFillBuild } from 'react-icons/ai';
import { getUserDetails, storeUserDetails } from '../../Services/Storage';
import { UpdateStaffDetails, ViewUserDetails } from '../../Config/api';

const UserSettings = ({ user_status, onclose }) => {

  const user_details = getUserDetails();
  const existingtokn = user_details?.fcm_token;
  const navigate = useNavigate();

  useEffect(() => {
    const FetchDt = async () => {
      if (user_details.role === "Company") {
        try {
          const response = await ViewUserDetails(`?register_id=${user_details.id}`);

          const updatedetails = response.data.user_details;
          delete updatedetails.fcm_token;

          const finalUserDetails = {
            ...updatedetails,
            fcm_token: existingtokn,

          }
          storeUserDetails(finalUserDetails);
        } catch (error) {

          if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            localStorage.removeItem('Staff');
            localStorage.removeItem('notification');


            navigate('/login');
          }
        }
      } else if (user_details.role === "staff") {
        try {
          const response = await UpdateStaffDetails(`?register_id=${user_details.id}`);

          const updatedetails = response.data.user_details;
          delete updatedetails.fcm_token;

          const finalUserDetails = {
            ...updatedetails,
            fcm_token: existingtokn,

          }
          storeUserDetails(finalUserDetails);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            navigate('/login');

          }
        }
      }
      else {
        try {
          const response = await ViewUserDetails(`?register_id=${user_details.id}`);

          const updatedetails = response.data.user_details;
          delete updatedetails.fcm_token;

          const finalUserDetails = {
            ...updatedetails,
            fcm_token: existingtokn,

          }
          storeUserDetails(finalUserDetails);

        } catch (error) {

          if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            localStorage.removeItem('Staff');

            navigate('/login');
          }
        }
      }

    }
    FetchDt();
  }, [user_details.role, existingtokn, navigate, user_details.id])

  const authUser = user_details ? user_details : null;
  const accessDetails = authUser?.get_access_role?.[0]?.invite_user_details?.[0] || null;
  const userAccess = accessDetails ? accessDetails : null;

  const listAll = { ...userAccess?.access_category, dashboard: ["all"] };
  const actionPage = Object.keys(listAll).filter(
    key => listAll[key].length > 0
  );

  const settingsLinks = [
    {
      path: "/vieworgprofile",
      label: "Organization Profile",
      icon: <AiFillBuild />,
      condition: user_details.role === "Company" || (authUser.user_status && authUser.access_users && actionPage.includes("organization"))
    },
    {
      path: "/viewdep",
      label: "Department",
      icon: <CiCircleList />,
      condition: user_details.role === "Company" || (authUser.user_status && authUser.access_users && actionPage.includes("department"))
    },
    {
      path: "/viewdephead",
      label: "Department Head",
      icon: <FiUserCheck />,
      condition: user_details.role === "Company" || (authUser.user_status && authUser.access_users && actionPage.includes("department_head"))
    },
    {
      path: "/viewdeptl",
      label: "Department TL",
      icon: <FiUser />,
      condition: user_details.role === "Company" || (authUser.user_status && authUser.access_users && actionPage.includes("department_tl"))
    },
    {
      path: "/viewdesignation",
      label: "Designation",
      icon: <FiAnchor />,
      condition: user_details.role === "Company" || (authUser.user_status && authUser.access_users && actionPage.includes("designation"))
    },
    {
      path: "/holiday",
      label: "Holiday",
      icon: <FiBookOpen />,
      condition: user_details.role === "Company" || (authUser.user_status && authUser.access_users && actionPage.includes("holiday"))
    },
    {
      path: "/viewroles",
      label: "User and Roles",
      icon: <FiUsers />,
      condition: user_details.role === "Company" || (authUser.user_status && authUser.access_users && actionPage.includes("users"))
    },
    {
      path: "/viewlogs",
      label: "Logs",
      icon: <FiDatabase />,
      condition: user_details.role === "Company" || (authUser.user_status && authUser.access_users && actionPage.includes("logs"))
    },
    {
      path: "/changepass",
      label: "Change Password",
      icon: <FiKey />,
      condition: user_details.role === "Company" || (authUser.user_status && authUser.access_users && actionPage.includes("change_password"))
    },
    {
      path: "/privacy",
      label: "Privacy & Security",
      icon: <FiLock />,
      condition: user_details.role === "Company" || (authUser.user_status && authUser.access_users && actionPage.includes("privacy"))
    }
  ];

  if (!user_status) return null;
  return (
    <div className="setting-pop">
      <div className="setting-pop-div1">
        <span className="setting-title-span">
          <span className="setting-title-span">Settings</span>
          <span
            className="setting-close-span"
            style={{
              right: "10px",
              position: "absolute",
              cursor: "pointer",
              fontSize: "30px",
              zIndex: "888",
              color: "red",
            }}
            onClick={onclose}
          >
            &times;
          </span>
        </span>
        <div className="setting-pop-body">
          <div className="setting-body-div1">
            <ul className="setting-pop-ul">
              {settingsLinks
                .filter(link => link.condition) // Show only links that meet the condition
                .map((link, index) => (
                  <Link key={index} to={link.path}>
                    <li className="setting-pop-li">
                      <span className="span">
                        {link.icon}
                        <span>{link.label}</span>
                      </span>
                    </li>
                  </Link>
                ))}

            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserSettings