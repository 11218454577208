import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY || '!@#$%%^&&seasense)(*&^&^%^';

export const storeUserToken = (data) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();
        localStorage.setItem('token', encryptedData);
    } catch (error) {
    }
};

export const getUserToken = () => {
    try {
        const encryptedData = localStorage.getItem('token');
        if (!encryptedData) return null;

        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedData || null;
    } catch (error) {
        return null;
    }
};

export const storeUserDetails = (data) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
        localStorage.setItem('user', encryptedData);
    } catch (error) {
    }
};

export const storeNotification = (data) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
        localStorage.setItem('notification', encryptedData);
    } catch (error) {
    }
};

export const getNotificationDetails = () => {
    try {
        const encryptedData = localStorage.getItem('notification');
        if (!encryptedData) return null;

        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
            return JSON.parse(decryptedData);
        }
        return null;
    } catch (error) {
        return null;
    }
};


export const storeNotificationStatus = (data) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
        localStorage.setItem('notificationStatus', encryptedData);
    } catch (error) {
    }
};

export const getNotificationStatusDetails = () => {
    try {
        const encryptedData = localStorage.getItem('notificationStatus');
        if (!encryptedData) return null;

        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
            return JSON.parse(decryptedData);
        }
        return null;
    } catch (error) {
        return null;
    }
};


export const getUserDetails = () => {
    try {
        const encryptedData = localStorage.getItem('user');
        if (!encryptedData) return null;

        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
            return JSON.parse(decryptedData);
        }
        return null;
    } catch (error) {
        return null;
    }
};


export const storeStaffDetails = (data) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
        localStorage.setItem('Staff', encryptedData);
    } catch (error) {
    }
};

export const getStaffDetails = () => {
    try {
        const encryptedData = localStorage.getItem('Staff');
        if (!encryptedData) return null;

        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
            return JSON.parse(decryptedData);
        }
        return null;
    } catch (error) {
        return null;
    }
};

export const storeCompanyDetails = (data) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
        localStorage.setItem('company', encryptedData);
    } catch (error) {
    }
};

export const getCompanyDetails = () => {
    try {
        const encryptedData = localStorage.getItem('company');
        if (!encryptedData) return null;

        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
            return JSON.parse(decryptedData);
        }
        return null;
    } catch (error) {
        return null;
    }
};