import React, { useState, useEffect,useCallback } from 'react'
import '../../Style/Addemp.css';
import './Salarydetails.css';
import Header from '../../../Header/Header'
import "../../Style/Employeeform.css";
import { useLocation, useNavigate } from 'react-router-dom'
import Sidebar from '../../../Sidebar/Sidebar';
import { formatNumberDecimal } from '../../../Component/NumberFormatDecimal';
import { useSelector } from 'react-redux';
import { EmployeeSalaryApi, viewAllSalary } from '../../../../Config/api';
import { toast } from 'react-toastify';
import { getRegisterId } from '../../../../Config/userId';
import { getUserDetails } from '../../../../Services/Storage';
import AddEarnings from '../../../Salarycomponent/Earnings/Addearnings/AddEarnings';
import Adddeduction from '../../../Salarycomponent/Deductions/Adddeduction'

const Salarydetails = () => {

   const user_details = getUserDetails();
   const register_id = getRegisterId();
   const navigate = useNavigate();
   const location = useLocation();
   const { name, user_id,company_id } = location.state || {};
   const [errors, setErrors] = useState({});
   const [loading, setloading] = useState(false);
   const [selectedSlacomp, setselectedSlacomp] = useState([]);
   const salaryComponentRedux = useSelector((state) => state.salarycomponent);
   console.log(salaryComponentRedux,"hellooo")
   const [isopenearnings, setopenearnings] = useState(false);
   const [isopendeduction, setopendeduction] = useState(false);
   const [salaryComp , setSalaryComp] = useState([]);
   const [salaryInfo, setSalaryInfo] = useState({
      annual_ctc: 0,
      monthly_salary: 0,
      salary_list: [],
      company: company_id,
      register: user_id,
      user: register_id,
      edited_by: user_details.id,
   });


       const fetchsalaryComponent = useCallback(async () => {
         try {
             const response = await viewAllSalary(`?register_id=${register_id}`);
             if (response.data.status === 200) {
               setSalaryComp(response.data.get_all_salary_component);
               //   const selectSalaryComp = response.data.get_all_salary_component.map(comp => ({
               //       value: comp,
               //       label: comp.component
               //   }));
               //   setDatasSalaryComp(selectSalaryComp);

             }
             // Store.dispatch(setSalaryComponentRedux(response.data.get_salary_component_earning));
         } catch (error) {
         }
     },[register_id]);

     useEffect(() => {
      fetchsalaryComponent();
  }, [fetchsalaryComponent]);


   useEffect(() => {
      setSalaryInfo((state) => ({
         ...state,
         salary_list: salaryComp.map((component) => ({
            ...component,
            monthly: 0,
            yearly: 0,
         })),
      }));
   }, [salaryComp]);

   const handleInputChangectc = (event) => {
      const { value } = event.target;
      const intValue = parseInt(value, 10);
      setSalaryInfo((state) => ({
         ...state,
         annual_ctc: intValue,
         monthly_salary: formatNumberDecimal(value / 12),
      }));
   };

   useEffect(() => {
      if (!user_id) {

         navigate('/employee');
      }
   }, [user_id, navigate]);

   useEffect(() => {
      const updatedSalaryComponentsCtc = salaryInfo.salary_list.map((component) => {
         if (component.id !== null) {
            let monthlyAmount = 0;
            let yearlyAmount = 0;
            const basicComponent = salaryInfo.salary_list.find(comp => comp.component.toLowerCase() === 'basic');
            const basicAmount = basicComponent ? basicComponent.yearly : 0;

            if (component.calculation_type === 'Percentage of basic') {
               yearlyAmount = (basicAmount * component.amount_type) / 100;
            } else if (component.calculation_type === 'Float amount') {
               yearlyAmount = parseFloat(component.amount_type);
            }
            if (component.calculation_type === 'Percentage of CTC') {
               yearlyAmount = (salaryInfo.annual_ctc * component.amount_type) / 100;
            } else if (component.calculation_type === 'Float amount') {
               yearlyAmount = parseFloat(component.amount_type);
            }
            monthlyAmount = yearlyAmount / 12;
            return {
               ...component,
               monthly: formatNumberDecimal(monthlyAmount),
               yearly: formatNumberDecimal(yearlyAmount),
            };
         }
         return component;
      });

      const isDifferent = JSON.stringify(salaryInfo.salary_list) !== JSON.stringify(updatedSalaryComponentsCtc);
      if (isDifferent) {
         setSalaryInfo((state) => ({
            ...state,
            salary_list: updatedSalaryComponentsCtc,
         }));
      }

   }, [salaryInfo.annual_ctc, salaryInfo.salary_list]);

   const validateInput = (name, value) => {
      let error = '';
      if (value.trim() === '') {
         error = 'This field is required';
      } else if (isNaN(value)) {
         error = 'Please enter a valid number';
      }
      return error;
   };

   const handlekeynumber = (e) => {
      const restricpattern = /[^0-9]/g;
      if ((e.target.name === 'special_all') && restricpattern.test(e.key)) {
        e.preventDefault();
   
      }
    };

    const closeaddearnings = () => {
      setopenearnings(false);
    };
  
    const openaddearnings = () => {
      setopenearnings(true);
    };

    const opendeduction = () => {
      setopendeduction(true);
    };
    const closededuction = () => {
      setopendeduction(false);
    };

    const handleDataFromChild = (data) => {
      // setearningcomp((prevcompArray) => [
      //   data,
      //   ...prevcompArray,
      // ]);
      if(data){
         fetchsalaryComponent();
      }
    };

   const handleInputChange = (event, index) => {
      const { name, value } = event.target;

      const error = validateInput(name, value);

      setErrors((state) => ({
         ...state,
         [index]: error,
      }));

      const updatedSalaryComponents = salaryInfo.salary_list.map((component) => {
         if (component.id === index) {
            let monthlyAmount = 0;
            let yearlyAmount = 0;
            const basicComponent = salaryInfo.salary_list.find(comp => comp.component.toLowerCase() === 'basic');
            const basicAmount = basicComponent ? basicComponent.yearly : 0;

            if (component.calculation_type === 'Percentage of basic') {
               yearlyAmount = (basicAmount * component.amount_type) / 100;
            } else if (component.calculation_type === 'Float amount') {
               yearlyAmount = parseFloat(component.amount_type);
            }
            if (component.calculation_type === 'Percentage of CTC') {
               yearlyAmount = (salaryInfo.annual_ctc * component.amount_type) / 100;
            } else if (component.calculation_type === 'Float amount') {
               yearlyAmount = parseFloat(component.amount_type);
            }
            monthlyAmount = yearlyAmount / 12;
            return {
               ...component,
               amount_type: value,
               monthly: formatNumberDecimal(monthlyAmount),
               yearly: formatNumberDecimal(yearlyAmount),
            };
         }
         return component;
      });

      setSalaryInfo((state) => ({
         ...state,
         salary_list: updatedSalaryComponents,
      }));
   };

   const totalMonthlyEarnings = salaryInfo.salary_list
      .filter(component => component.earning_deduction === 'earning' && selectedSlacomp.includes(component.id))
      .reduce((acc, component) => acc + parseFloat(component.monthly || 0), 0);

   const totalYearlyEarnings = salaryInfo.salary_list
      .filter(component => component.earning_deduction === 'earning' && selectedSlacomp.includes(component.id))
      .reduce((acc, component) => acc + parseFloat(component.yearly || 0), 0);

   const totalMonthlyDeductions = salaryInfo.salary_list
      .filter(component => component.earning_deduction === 'deduction' && selectedSlacomp.includes(component.id))
      .reduce((acc, component) => acc + parseFloat(component.monthly || 0), 0);

   const totalYearlyDeductions = salaryInfo.salary_list
      .filter(component => component.earning_deduction === 'deduction' && selectedSlacomp.includes(component.id))
      .reduce((acc, component) => acc + parseFloat(component.yearly || 0), 0);

   const costToCompanymonly = salaryInfo.monthly_salary;

   const costToCompany = salaryInfo.annual_ctc;

   const handleCheckboxChange = (id) => {
      setselectedSlacomp((prevState) =>
         prevState.includes(id)
            ? prevState.filter((empId) => empId !== id)
            : [...prevState, id]
      );
   };

   const handlekeynumberan = (e) => {
      const restricpattern = /[^0-9]/g;
      if ((e.target.name === 'annual_ctc') && restricpattern.test(e.key)) {
        e.preventDefault();
  
      }
    }
    
   const handlesubmit = async () => {
      let newErrors = {};
      if (!salaryInfo.annual_ctc || isNaN(salaryInfo.annual_ctc)) {
         newErrors.annual_ctc = "Enter a valid Annual CTC";
      }
      if (selectedSlacomp.length === 0) {
         newErrors.selectedSlacomp = "Please select at least one salary component.";
      }
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
         try {
            setloading(true);
            const updatedSalaryList = salaryInfo.salary_list.map(salary => {
               if (selectedSlacomp.includes(salary.id)) {
                  return {
                     ...salary,
                  };
               } else {
                  return null;
               }
            }).filter(salary => salary !== null);

            const payload = {
               ...salaryInfo,
               eligible_salary: selectedSlacomp,
               salary_list: updatedSalaryList,
            };
            const response = await EmployeeSalaryApi(JSON.stringify(payload));
            toast.success('Salary Info Updated Sucessfully!', {
               position: "top-center",
               autoClose: 2000,
               CloseButton: false,
               className: 'custom_toast'
            })
            if (response) {
               navigate('/addpayment', { state: { name: name, user_id: user_id ,companyId:company_id } });
            }
         } catch (error) {
            if (error.response && error.response.data) {
               const errorData = error.response.data.data;
               newErrors = {};
               for (const key in errorData) {
                  if (errorData.hasOwnProperty(key)) {
                     newErrors[key] = errorData[key];
                  }
               }
               setErrors(newErrors);
            } else {
               setErrors(newErrors);
            }
         } finally {
            setloading(false);
         }
      }
   };


   return (
      <div className='index-container'>
         <Header />
         <div className='index-content-parent'>
            <div className='index-content'>
               <Sidebar />
               <div className='index-content-sub'>
                  <div className='index-main'>
                     <div className='addemp-subheader'>
                        <div className='addemp-subheader-sub'>
                           <div className='addemp-subheader1'>
                              <p> Add Salary Details </p>

                           </div>
                           <div className='addemp-subheader2'>
                              <div className="addemp-stepper">
                                 <div className="addemp-step-indicator">
                                    <div className='addemp-step'>
                                       Personal Info
                                    </div>
                                    <div className='addemp-step'>Basic Details</div>
                                    <div className="addemp-step addemp-selected">Salary Details</div>
                                    <div className='addemp-step'>Payment</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='addemp-line'></div>
                     <div className='addemp-maincontent-sub'>
                        <div className='addemp-form form-padding'>
                           <div className='addemp-form-sub'>
                              <div className='addemp-form-sub-main'>
                                 <div className='salarydetails-card'>
                                    <div className='salarydetails-input-row'>
                                       <div className='salarydetails-input-rowsub'>
                                          <div className='salarydetails-input-group'>
                                             <p>Annual CTC <span className='emppersonalinfo-mandatory'>*</span></p>
                                          </div>
                                          <div className='salarydetails-input-group'>
                                             <div class="salarydetails-input-container">
                                                <input type="text" onKeyPress={handlekeynumberan} name='annual_ctc' onChange={handleInputChangectc} placeholder="Enter amount" />
                                                {errors.annual_ctc && (<div className='error-message-view'>{errors.annual_ctc}</div>)}
                                             </div>
                                          </div>
                                          <div className='salarydetails-input-group'>
                                          </div>
                                       </div>
                                    </div>
                                    <div className='salarydetails-earning-dect'>
                                       <p className='salarydetails-earning-dect-note'><span className='emppersonalinfo-mandatory'>*</span> Note : Selected earnings and deductions will be calculated.</p>
                                       <table className='salary-table'>
                                          <tr>
                                             <th>COMPONENTS</th>
                                             <th>COMPONENT ACCESS</th>

                                             <th>CALCULATION TYPE</th>
                                             <th>MONTHLY AMOUNT</th>
                                             <th>ANNUAL AMOUNT</th>
                                          </tr>
                                          <p className='salary-table-p'>Earnings:</p>
                                          <tr>
                                             <td></td>
                                             <td></td>

                                             <td></td>
                                             <td></td>
                                             <td><button className='salary-addearn-deduct_bt' onClick={openaddearnings}>Add Earnings</button></td>
                                          </tr>
                                          {
                                             salaryInfo.salary_list
                                                .filter(sal_component => sal_component.earning_deduction === 'earning')
                                                .map((sal_component, index) => (
                                                   <tr key={index}>
                                                      <td>{sal_component.component_name}</td>
                                                      <td>{sal_component.general_earning}</td>

                                                      <td className='view-errorsal-input'>
                                                         <span>{sal_component.calculation_type}</span><br />
                                                         <div className='salry-comp-view-iput'>

                                                            <input type="text" value={sal_component.amount_type}
                                                               name='special_all'
                                                               onKeyPress={handlekeynumber} 
                                                               disabled={!selectedSlacomp.includes(sal_component.id)}
                                                               onChange={(e) => handleInputChange(e, sal_component.id)}
                                                               placeholder="Enter value" />
                                                            <input
                                                               className='salary_check_input'
                                                               type="checkbox"
                                                               checked={selectedSlacomp.includes(sal_component.id)}
                                                               onChange={() => handleCheckboxChange(sal_component.id)}
                                                            />
                                                         </div>
                                                         {errors[sal_component.id] && (
                                                            <span className="error-message-view">{errors[sal_component.id]}</span>
                                                         )}
                                                      </td>
                                                      <td>{sal_component.monthly}</td>
                                                      <td>{sal_component.yearly}</td>
                                                   </tr>
                                                ))
                                          }
                                          <tr className='salarydetails-total-row'>
                                             <td>Total Earnings:</td>
                                             <td></td>
                                             <td></td>

                                             <td style={{color:"#2a7ac7",fontSize:"14px"}}>₹{formatNumberDecimal(totalMonthlyEarnings)}</td>
                                             <td style={{color:"#2a7ac7",fontSize:"14px"}}>₹{formatNumberDecimal(totalYearlyEarnings)}</td>
                                          </tr>
                                          <tr>
                                             <td></td>
                                             <td></td>
                                             <td></td>
                                             <td>
                                             </td>
                                          </tr>

                                          <p>Deduction:</p>
  <tr>
                                             <td></td>
                                             <td></td>

                                             <td></td>
                                             <td></td>
                                             <td><button className ='salary-addearn-deduct_bt' onClick={opendeduction}>Add Deductions</button></td>
                                          </tr>
                                          {
                                             salaryInfo.salary_list
                                                .filter(sal_component => sal_component.earning_deduction === 'deduction')
                                                .map((sal_component, index) => (
                                                   <tr key={index}>
                                                      <td>{sal_component.component_name}</td>
                                                      <td>{sal_component.general_earning}</td>

                                                      <td className='view-errorsal-input'>
                                                         <span>{sal_component.calculation_type}</span><br />
                                                         <div className='salry-comp-view-iput'>

                                                            <input type="text" value={sal_component.amount_type}
                                                               name='special_all'
                                                               onKeyPress={handlekeynumber} 
                                                               disabled={!selectedSlacomp.includes(sal_component.id)}
                                                               onChange={(e) => handleInputChange(e, sal_component.id)}
                                                               placeholder="Enter value" />
                                                            <input
                                                               className='salary_check_input'
                                                               type="checkbox"
                                                               checked={selectedSlacomp.includes(sal_component.id)}
                                                               onChange={() => handleCheckboxChange(sal_component.id)}
                                                            />
                                                         </div>
                                                         {errors[sal_component.id] && (
                                                            <span className="error-message-view">{errors[sal_component.id]}</span>
                                                         )}
                                                      </td>
                                                      <td>{sal_component.monthly}</td>
                                                      <td>{sal_component.yearly}</td>
                                                   </tr>
                                                ))
                                          }
                                          <tr className='salarydetails-total-row'>
                                             <td>Total  Deductions:</td>
                                             <td></td>
                                             <td></td>

                                             <td style={{color:"#2a7ac7",fontSize:"14px"}}>₹{formatNumberDecimal(totalMonthlyDeductions)}</td>
                                             <td style={{color:"#2a7ac7",fontSize:"14px"}}>₹{formatNumberDecimal(totalYearlyDeductions)}</td>
                                          </tr>   <br />

                                          <tr className='salarydetails-last-col'>
                                             <td>Actual Salary</td>
                                             <td></td>
                                             <td></td>
                                             <td style={{color:"green",fontSize:"16px"}}>₹{formatNumberDecimal(costToCompanymonly)}</td>
                                             <td style={{color:"green",fontSize:"16px"}}>₹{formatNumberDecimal(costToCompany)}</td>
                                          </tr>
                                       </table>
                                       <div>

                                       </div>
                                       <div className="error-message-view">
                                          {errors.selectedSlacomp && <span>{errors.selectedSlacomp}</span>}
                                       </div>
                                       <div className='emppersonalinfo-submitbutton'>
                                          <button onClick={handlesubmit} disabled={loading}>{loading ? <span className='spinner'></span> : 'Submit'}</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <AddEarnings
        show={isopenearnings}
        close={closeaddearnings}
        sendDataToParent={handleDataFromChild}
      />
       <Adddeduction
        show={isopendeduction}
        close={closededuction}
        sendDataToParent={handleDataFromChild}
      />
      </div>
   )
}

export default Salarydetails