import React from 'react'

const LeaveReason = ({show,close,data}) => {
console.log(data)
if(!show) return null;
  return (

    <div className='popup-section'>
        <div className='popup-container-viewreason'>
        <span className='uploadexcel-close' onClick={close}>&times;</span>
        <div className='popup-container-sub'>
        <p >{data.name}'s Leave Reason:</p>
        <div className='addleave-strightline'></div>
        <p style={{fontSize:"12px"}}>{data.leave_reason}</p>
        </div>
        </div>
        </div>

  )
}

export default LeaveReason