import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../../Sidebar/Sidebar";
import Header from "../../../Header/Header";
import "../../Style/Addemp.css";
import "../../Style/Employeeform.css";
import { convertTo12HourFormat } from "../../../Component/TimeFormat";
import { useSelector } from "react-redux";
import api_backend, { UpdateEmployeeBasicApi, ViewDepHeadwdDepid, ViewDepTlwdDepid, viewSelectedDept, ViewSelectLeaveComp } from "../../../../Config/api";
import { Store } from "../../../../Store";
import { setUpdateEmployee as UpdateEmployeeRedux } from "../../../../Slice/employeeSlice";
import userdemoimg from "../../../../Images/Pagesimg/userdemo";
import { MultiSelect } from "react-multi-select-component";
import 'react-time-picker/dist/TimePicker.css';
import TimePicker from 'react-time-picker';
import { toast } from 'react-toastify';
import { getRegisterId } from "../../../../Config/userId";
import { getUserDetails } from "../../../../Services/Storage";

const Editbasicinfo = () => {

  const register_id = getRegisterId();
  const user_details = getUserDetails();
  const accessid = user_details?.get_access_role?.[0]?.invite_user_details?.[0]?.register || "";
  const basicInfo1 = useSelector((state) => state.employees);
  const fileinput = useRef(null);
  const [Empname, setEmpName] = useState("");
  const [basicInfo, setBasicInfo] = useState(null);
  const companies = useSelector((state) => state.companies);
  const designationRedux = useSelector((state) => state.designations);
  const [selectedimg, setselectedimg] = useState(null);
  const [dephdfetch, setdephdfetch] = useState([])
  const [deptlfetch, setdeptlfetch] = useState([])
  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState({});
  const [fetchLeavecomp, setLeavecomp] = useState([])
  const [maxDate, setMaxDate] = useState('');
  const [departmentData,setDepartmentData] = useState([]);
  const [companyId,seCompanyId] = useState('');
  const [isLoadingDepartment, setIsLoadingDepartment] = useState(false);
  const [isLoadingDepartmenthd, setIsLoadingDepartmenthd] = useState(false);
  const [isLoadingDepartmenttl, setIsLoadingDepartmenttl] = useState(false);

  const [updateBasicInfo, setUpdateBasicInfo] = useState({
    emp_name: " ",
    work_email: " ",
    father_name: " ",
    dob: " ",
    gender: " ",
    blood_group: " ",
    emergency_contact: " ",
    doj: " ",
    lat_dedu_days: "",
    lat_dedu_hrs: "",
    work_location: " ",
    designation: " ",
    department: " ",
    depart_tl: " ",
    depart_head: " ",
    emp_id: " ",
    work_details: " ",
    permission_hr: null,
    staff_pic: " ",
    eligible_leave: [],
    work_from: null,
    work_to: null,
    company: "",
    edited_by: user_details.id,
  });

  useEffect(() => {
    if (basicInfo1 && basicInfo1.employees.length > 0) {
      setBasicInfo(basicInfo1.employees[0]);
      setEmpName(basicInfo1.employees[0].register_details.name);
    }
  }, [basicInfo1]);

  useEffect(() => {
    if (basicInfo) {
      setUpdateBasicInfo({
        emp_name: basicInfo.register_details.name || '',
        work_email: basicInfo.work_details.work_email || '',
        father_name: basicInfo.work_details.father_name || '',
        dob: basicInfo.work_details.dob || '',
        gender: basicInfo.work_details.gender || '',
        blood_group: basicInfo.work_details.blood_group || '',
        emergency_contact: basicInfo.work_details.emergency_contact || '',
        doj: basicInfo.work_details.doj || '',
        lat_dedu_days: basicInfo.work_details.lat_dedu_days,
        lat_dedu_hrs: basicInfo.work_details.lat_dedu_hrs,
        work_location: basicInfo.work_details.work_location || '',
        company: basicInfo.work_details.company || '',
        designation: basicInfo.work_details.designation || '',
        department: basicInfo.work_details.department || '',
        depart_tl: basicInfo.work_details.depart_tl || '',
        depart_head: basicInfo.work_details.depart_head || '',
        emp_id: basicInfo.work_details.emp_id || '',
        staff_pic: basicInfo.work_details.staff_pic || '',
        eligible_leave: basicInfo.work_details.eligible_leave || [],
        permission_hr: basicInfo.work_details.permission_hr || '',
        work_from: basicInfo.work_details.work_from || '',
        work_to: basicInfo.work_details.work_to || '',
        edited_by: user_details.id,
      });
      // seCompanyId(basicInfo.work_details.work_location);
      const fetchdepartment = async (id) => {
        setIsLoadingDepartment(true);  // Start loader

        try {
          const response = await viewSelectedDept(`?register_id=${register_id}&company_id=${id}`)
          if (response) {
            setDepartmentData(response.data.get_department)
            // setDepartmentView(response.data.get_department)
            // setdephdfetch(response.data.get_depart_head)
          }
        } catch {
    
        }finally{
          setIsLoadingDepartment(false); // Stop loader

        }
      };
      const fetchdepartmenthead = async (compid,depid) => {
        setIsLoadingDepartmenthd(true);

        try {
          const response = await ViewDepHeadwdDepid(`?register_id=${register_id}&depart_id=${depid}&access_id=${accessid}&company_id=${compid}`)
          if (response) {
            setdephdfetch(response.data.get_depart_head)
          }
        } catch {
    
        }finally{
          setIsLoadingDepartmenthd(false);

        }
      };
    
      const fetchdepartmenttl = async (compid,depid) => {
        setIsLoadingDepartmenttl(true);

        try {
          const response = await ViewDepTlwdDepid(`?register_id=${register_id}&depart_id=${depid}&access_id=${accessid}&company_id=${compid}`)
          if (response) {
            setdeptlfetch(response.data.get_depart_tl)
            console.log(response.data.get_depart_tl)

          }
        } catch {
    
        }finally{
          setIsLoadingDepartmenttl(false);

        }
      };
      fetchdepartmenttl(basicInfo.work_details.work_location,basicInfo.work_details.department);
      fetchdepartmenthead(basicInfo.work_details.work_location,basicInfo.work_details.department);
      fetchdepartment(basicInfo.work_details.work_location);

    }
  }, [basicInfo, register_id,user_details.id,accessid]);

  useEffect(() => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    setMaxDate(today.toISOString().split('T')[0]);
  }, []);

  useEffect(() => {
    const fetchleavecomp = async () => {
      try {
        const response = await ViewSelectLeaveComp(`?register_id=${register_id}`)
        if (response) {

          setLeavecomp(response.data.get_leave_component)
        }
      } catch {

      }
    }
    fetchleavecomp();
  }, [user_details.id, accessid, register_id])

  const handleInputChange = (event) => {
    setErrors({})
    const { name, value, type } = event.target;
    if (type === 'time') {
      setUpdateBasicInfo((state) => ({
        ...state, [name]: convertTo12HourFormat(value),
      }));
    }
    else if (event.target.type === "file") {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        setUpdateBasicInfo((state) => ({
          ...state,
          [name]: file,
        }));
        setselectedimg(URL.createObjectURL(file));
      } else {
        setselectedimg(null);
      }
    }
    else {
      setUpdateBasicInfo((state) => ({
        ...state, [name]: value,
        ...(name === "work_location" && { company: value })

      }));
    }
  };

  const handleEligibleLeaveChange = (selected) => {
    setUpdateBasicInfo((state) => ({
      ...state,
      eligible_leave: selected.map((option) => option.value),
    }));
  };

  const handleKeyPress = (e) => {
    if (e.target.name === 'emp_id') {
      const specialCharPattern = /[!@#$%^&*(),.?":'=;{}|<>+_]/;
      if (specialCharPattern.test(e.key)) {
        e.preventDefault();
      }
    }
  }

  const handlephonepress = (e) => {
    if (e.target.name === 'emergency_contact') {
      const specialCharPattern = /[^0-9]/g;
      if (specialCharPattern.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  const handleDeduDays = (e) => {
    if (e.target.name === 'lat_dedu_days') {
      const currentValue = e.target.value;
      const newValue = currentValue + e.key;
      const validPattern = /^(3[01]|[12]?[0-9]|0)(\.5)?$/;
      if (e.key === "." && !currentValue.includes(".")) {
        return;
      }
      if (!validPattern.test(newValue)) {
        e.preventDefault();
      }
    }
  };

  const handleHourPress = (e) => {
    if (e.target.name === 'lat_dedu_hrs') {
      const currentValue = e.target.value;
      const newValue = currentValue + e.key;
      const validPattern = /^(2[0-4]|1?[0-9]|0)(\.5)?$/;
      if (e.key === "." && !currentValue.includes(".")) {
        return;
      }
      if (!validPattern.test(newValue)) {
        e.preventDefault();
      }
    }
  };

  const inputRefs = {
    emp_id: useRef(null),
    work_email: useRef(null),
    father_name: useRef(null),
    dob: useRef(null),
 
    gender: useRef(null),
    blood_group: useRef(null),
    emergency_contact: useRef(null),
    doj: useRef(null),
  };

  const handleInput = (e) => {
    const target = e.target;
    const start = target.selectionStart;
    const end = target.selectionEnd;
    const originalValue = target.value;
  
    // Update the value
    target.value = originalValue.toUpperCase().replace(/[^A-Z0-9]/g, '');
  
    // Restore the cursor position
    if (target.value !== originalValue) {
      const newLength = target.value.length;
      const newStart = Math.max(0, start - (originalValue.length - newLength));
      const newEnd = Math.max(0, end - (originalValue.length - newLength));
      target.setSelectionRange(newStart, newEnd);
    }
  };

  const handleuploadimg = () => {
    fileinput.current.click();
  }

  const handlechangeper = (newtime) => {
    setUpdateBasicInfo((state) => ({
      ...state,
      permission_hr: newtime ? newtime + ':00' : '', 
    })
    );
  };

  const handlefromtimechange = (newtime) => {
    setUpdateBasicInfo((state) => ({
      ...state,
      work_from: newtime + ':00',
    }))
  };

  const handletotimechange = (newtime) => {
    setUpdateBasicInfo((state) => ({
      ...state,
      work_to: newtime + ':00',
    }))
  };

  const handleInputChangeDep = (e) => {
    const { name, value } = e.target;
    setUpdateBasicInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    fetchdepartmenthead(value);
    fetchdepartmenttl(value);
    setUpdateBasicInfo((prevState) => ({
      ...prevState,
      depart_head: "",
      depart_tl: "",
    }));
  };

  const handleCompanyChange =(e)=>{
    const { name, value } = e.target;
    setUpdateBasicInfo((prevState) => ({
      ...prevState,
      [name]: value,  
      company: value,
      depart_head: "", 
      depart_tl: "",
      department: "",

    }));  
    seCompanyId(value);
    // console.log(updateBasicInfo)
    // fetchdepartmenthead(value);
    // fetchdepartmenttl(value);
    fetchdepartment(value);
    setUpdateBasicInfo((prevState) => ({
      ...prevState,
      depart_head: "",
      depart_tl: "",
    }));
  };

  const fetchdepartment = async (id) => {

          try {
            const response = await viewSelectedDept(`?register_id=${register_id}&company_id=${id}`)
            if (response) {
              setDepartmentData(response.data.get_department)
              // setDepartmentView(response.data.get_department)
              // setdephdfetch(response.data.get_depart_head)
            }
          } catch {
      
          }
        }

  const handleSubmit = async () => {
    let newErrors = {};
    if (updateBasicInfo.emp_id.trim() === "") {
      newErrors.emp_id = "Employee Id is required";
      inputRefs.emp_id.current.focus();

    }else if (!/(?=.*[a-zA-Z])(?=.*\d)^.{2,}$/.test(updateBasicInfo.emp_id.trim())) {
      newErrors.emp_id = "Employee ID must contain both letters and numbers";
      inputRefs.emp_id.current.focus();
    }
    
    if (!updateBasicInfo.work_email || updateBasicInfo.work_email.trim() === "") {
      newErrors.work_email = "Employee Email is required";
    } else if (
      !/^[a-zA-Z0-9]+([._%+-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
        updateBasicInfo.work_email.trim()
      )
    ) {
      newErrors.work_email = "Invalid email format";
      inputRefs.work_email.current.focus();

    }

    if (updateBasicInfo.father_name.trim === "") {
      newErrors.father_name = "Father name is required";
      inputRefs.father_name.current.focus();

    }
    if (updateBasicInfo.dob.trim() === "") {
      newErrors.dob = "Date of Birth is required";
      inputRefs.dob.current.focus();

    }
    if (updateBasicInfo.gender.trim() === "") {
      newErrors.gender = "Please Select Gender";
      inputRefs.gender.current.focus();

    }
    if (updateBasicInfo.blood_group.trim() === "") {
      newErrors.blood_group = "Blood Group is required";
      inputRefs.blood_group.current.focus();

    }
    const emergencyContact = String(updateBasicInfo.emergency_contact).trim();

    if (emergencyContact.trim() === "") {
      newErrors.emergency_contact = "Emergency Contact No is required";
    } else if (!/^\d{10}$/.test(updateBasicInfo.emergency_contact)) {
      newErrors.emergency_contact = "Phone Number must be exactly 10 digits";
      inputRefs.emergency_contact.current.focus();
    }
    if (updateBasicInfo.doj.trim() === "") {
      newErrors.doj = "Date of Joining is required"
      inputRefs.doj.current.focus();
    }
    if (updateBasicInfo.work_from === null) {
      newErrors.work_from = "Work From is required";
    }
    if (updateBasicInfo.work_to === null) {
      newErrors.work_to = "Work To is required"
    }
    const worklocat = String(updateBasicInfo.work_location).trim();

    if (worklocat === "") {
      newErrors.work_location = "Select Work Location"
    }
    const design = String(updateBasicInfo.designation).trim();
    if (design === "") {
      newErrors.designation = "Select Designation"
    }
    const depart = String(updateBasicInfo.department).trim();
    if (depart === "") {
      newErrors.department = "Select Department ";
    }

    if (updateBasicInfo.permission_hr === null) {
      newErrors.permission_hr = "Permission Hour is required"
    }
    if (updateBasicInfo.eligible_leave.length === 0) {
      newErrors.eligible_leave = "Select Eligible Leave"
    }
    setErrors(newErrors);
    const errorFields = Object.keys(newErrors);
    if (errorFields.length > 0) {
      const firstErrorField = errorFields[0];
      if (inputRefs[firstErrorField]?.current) {
        inputRefs[firstErrorField].current.focus();
      }
      return;
    }
    if (Object.keys(newErrors).length === 0) {
      try {
        setloading(true)
        const data = {
          ...updateBasicInfo,
          eligible_leave: JSON.stringify(updateBasicInfo.eligible_leave),
        };
        if (selectedimg) {
          data.staff_pic = updateBasicInfo.staff_pic;
        } else {
          delete data.staff_pic;
        }
        const response = await UpdateEmployeeBasicApi(basicInfo.work_details.id, data);
        if (response) {
          toast.success('Basic Details updated successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
          Store.dispatch(UpdateEmployeeRedux({ updatedData: response.data.updated_staff, leaveData: response.data.eligible_leave_details[0] }));
          setselectedimg(null);
        }
      }
      catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};
          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors[key] = errorData[key];
            }
          }
          setErrors(newErrors);
          const errorFields = Object.keys(newErrors);
          if (errorFields.length > 0) {
            const firstErrorField = errorFields[0];
            inputRefs[firstErrorField]?.current?.focus();
          }
        } else {
          toast.error('Failed.Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
          const newErrors = {
            response_error: [],
          };
          setErrors(newErrors);
          setUpdateBasicInfo((state) => ({
            ...state,
          }));
        }
      }
      finally {
        setloading(false)
      }
    }
  };


  const fetchdepartmenthead = async (depid) => {
    console.log(companyId,"djksdjksd")
    try {
      const response = await ViewDepHeadwdDepid(`?register_id=${register_id}&depart_id=${depid}&access_id=${accessid}&company_id=${companyId}`)
      if (response) {
        setdephdfetch(response.data.get_depart_head)
      }
    } catch {

    }
  }

  const fetchdepartmenttl = async (depid) => {
    try {
      const response = await ViewDepTlwdDepid(`?register_id=${register_id}&depart_id=${depid}&access_id=${accessid}&company_id=${companyId}`)
      if (response) {
        setdeptlfetch(response.data.get_depart_tl)
      }
    } catch {

    }
  }

  return (
    <div className="index-container">
      <Header />
      <div className="index-content-parent">
        <div className="index-content">
          <Sidebar />
          {basicInfo ? (
            <>
              <div className="index-content-sub">
                <div className="index-main">
                  <div className="addemp-subheader">
                    <div className="addemp-subheader-sub">
                      <div className="addemp-subheader2">
                        <div className="editbasic-stepper">
                          <p>{Empname}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="addemp-line"></div>

                  <div className="addemp-maincontent-sub">
                    <div className="addemp-form form-padding">
                      <div className="addemp-form-sub">
                        <div className='addemp-form-sub-main'>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-imgemp">
                              <div className="emppersonalinfo-input-imgemp-sub">

                                <div className="emppersonalinfo-input-imgemp-main" onClick={handleuploadimg}>
                                  {selectedimg ? (<img src={selectedimg} alt="demo" />
                                  )
                                    : updateBasicInfo.staff_pic ? (
                                      <img src={`${api_backend.BACKEND_API}${updateBasicInfo.staff_pic}`} alt="Employee" />

                                    ) :
                                      (<img src={userdemoimg.demo_img} alt="demo" />
                                      )
                                  }
                                  <input type="file" style={{ display: 'none' }} name="staff_pic" ref={fileinput} onChange={handleInputChange} />

                                </div>

                              </div>
                              <label className='label-fields'>Upload Image </label>
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Employee Name
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                placeholder="Enter employee name"
                                type="text"
                                name="emp_name"
                                onChange={handleInputChange}
                                value={updateBasicInfo.emp_name}
                                disabled
                              />
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Employee ID
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                placeholder="Enter employee id"
                                type="text"
                                onKeyPress={handleKeyPress}
                                name="emp_id"
                                onKeyDown={(e) => {
                                  if (e.key === ' ') {
                                    e.preventDefault();
                                  }
                                }}
                               onInput={handleInput}
                                ref={inputRefs.emp_id}
                                onChange={handleInputChange}
                                value={updateBasicInfo.emp_id}
                              />
                              {errors.emp_id && (<div className="error-message-view">{errors.emp_id}</div>)}
                            </div>
                          </div>

                          <div className='basicdetails-input-col'>
                            <div className='basicdetails-col-view'>
                              <label>Work Email <span className='emppersonalinfo-mandatory'>*</span></label>
                              <input className='emppersonalinfo-inputfield-col' ref={inputRefs.work_email} onKeyDown={(e) => {
                                const regex = /^[a-zA-Z0-9@._\b]+$/;
                                if (!regex.test(e.key)) {
                                  e.preventDefault();
                                }
                              }} placeholder='abc@xyz.com' type='text' value={updateBasicInfo.work_email} name="work_email" onChange={handleInputChange} />
                            </div>
                            {errors.work_email && (<div className="error-message-view">{errors.work_email}</div>)}
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Father Name
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="text"
                                ref={inputRefs.father_name}

                                name="father_name"
                                value={updateBasicInfo.father_name}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^[a-zA-Z\s]*$/.test(value)) {
                                    handleInputChange(e);
                                  }
                                }}
                              />
                              {errors.father_name && (<div className="error-message-view">{errors.father_name}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Date Of Birth
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="date"
                                name="dob"
                                ref={inputRefs.dob}
                                max={maxDate}
                                value={updateBasicInfo.dob}
                                onChange={handleInputChange}
                              />
                              {errors.dob && (<div className="error-message-view">{errors.dob}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Gender
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <select onChange={handleInputChange} ref={inputRefs.gender} className="emppersonalinfo-input-field3" name="gender">
                                <option value={updateBasicInfo.gender}>{updateBasicInfo.gender}</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </select>
                              {errors.gender && (<div className="error-message-view">{errors.gender}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Blood Group
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="text"
                                ref={inputRefs.blood_group}
                                name="blood_group"
                                value={updateBasicInfo.blood_group}
                                onChange={handleInputChange}
                              />
                              {errors.blood_group && (<div className="error-message-view">{errors.blood_group}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Emergency Contact No
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="text"
                                ref={inputRefs.emergency_contact}
                                name="emergency_contact"
                                onKeyPress={handlephonepress}
                                value={updateBasicInfo.emergency_contact}
                                onChange={handleInputChange}
                              />
                              {errors.emergencyContact && (<div className="error-message-view">{errors.emergency_contact}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Date Of Joining
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="date"
                                name="doj"
                                ref={inputRefs.doj}
                                value={updateBasicInfo.doj}
                                onChange={handleInputChange}
                              />
                              {errors.doj && (<div className="error-message-view">{errors.doj}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Permission Hour<span className="emppersonalinfo-mandatory">
                                  *
                                </span>
                              </label>
                              <TimePicker
                                onChange={handlechangeper}
                                value={updateBasicInfo.permission_hr || "00:00"}
                                className="time-picker"
                                format="HH:mm"
                                clockIcon={null}
                              />
                              {errors.permission_hr && (<div className="error-message-view">{errors.permission_hr}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group" style={{width:"180px"}}>
                              <label>
                                Leave Eligible
                                <span className="emppersonalinfo-mandatory">
                                  *
                                </span>
                              </label>
                              <MultiSelect
                                options={
                                  fetchLeavecomp.map(leavecomponent => ({
                                    label: `${leavecomponent.leave_type} (${leavecomponent.leave_deduct})`,
                                    value: leavecomponent.id
                                  }))
                                }
                                value={updateBasicInfo.eligible_leave.map(id => {
                                  const selectedLeave = fetchLeavecomp.find(leave => leave.id === id);
                                  return {
                                    label: selectedLeave ? `${selectedLeave.leave_type} (${selectedLeave.leave_deduct})` : "",
                                    value: id
                                  };
                                })}
                                onChange={handleEligibleLeaveChange}
                                overrideStrings={{
                                  "allItemsAreSelected": "All leave type selected",
                                  "selectSomeItems": "Select Leave Type",
                                }}   
                              />
                              {errors.eligible_leave && (<div className="error-message-view">{errors.eligible_leave}</div>)}
                            </div>
                          </div>
                          <p className='pages-notes'><span className='emppersonalinfo-mandatory'>*</span> Note : Excess permission hours beyond the limit will be considered late hours, with deductions automatically applied upon entry.</p>

                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                              <p className='pages-notes'><span className='emppersonalinfo-mandatory'>*</span> Note : Late deduction days can be either 0.5 (half-day) or 1 (full day). If no value is provided, no deduction will be applied.</p>

                                Late Deduction Days
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="text"
                                ref={inputRefs.lat_dedu_days}
                                name="lat_dedu_days"
                                onKeyPress={handleDeduDays}
                                value={updateBasicInfo.lat_dedu_days}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                              <p className='pages-notes'><span className='emppersonalinfo-mandatory'>*</span> Note : Late deduction hours can be either 0.5 (30 minutes) or 1 (1 hour). If no value is provided, no deduction will be applied.</p>

                                Late Deduction Hours
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="text"
                                ref={inputRefs.lat_dedu_hrs}
                                name="lat_dedu_hrs"
                                onKeyPress={handleHourPress}
                                value={updateBasicInfo.lat_dedu_hrs}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <p className='emp-work-p'>Work Timing:</p>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                From Time
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>

                              <TimePicker
                                onChange={handlefromtimechange}
                                className="time-picker"
                                value={updateBasicInfo.work_from}
                              />
                              {errors.work_from && (<div className="error-message-view">{errors.work_from}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                To Time
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>

                              <TimePicker
                                onChange={handletotimechange}
                                value={updateBasicInfo.work_to}
                                className="time-picker"
                              />
                              {errors.work_to && (<div className="error-message-view">{errors.work_to}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Work Location
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <select onChange={handleCompanyChange} className="emppersonalinfo-input-field3" name="work_location" value={updateBasicInfo.work_location}>
                                {companies.map((company, index) => (
                                  <option key={index} value={company.company_id}>
                                    {company.company_name}
                                  </option>
                                ))}
                              </select>
                              {errors.work_location && (<div className="error-message-view">{errors.work_location}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Designation
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <select onChange={handleInputChange} className="emppersonalinfo-input-field3" name="designation" value={updateBasicInfo.designation}>


                                {designationRedux.map((designation, index) => (
                                  <option key={index} value={designation.id}>
                                    {designation.designation}
                                  </option>
                                ))}
                              </select>
                              {errors.designation && (<div className="error-message-view">{errors.designation}</div>)}
                            </div>

                          </div>
                          <div className='emppersonalinfo-input-row'>
                            <div className='emppersonalinfo-input-group'>
                              <label>Department<span className='emppersonalinfo-mandatory'>*</span></label>
{isLoadingDepartment ? (
                                <div style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
                                <span className='loader-pages-tablefield'></span>

                                </div>
                              ) :(
                                <select name='department' value={updateBasicInfo.department} onChange={handleInputChangeDep} disabled={!updateBasicInfo.work_location} className='emppersonalinfo-input-field3'>
                                <option value='' disabled>Select Department</option>
                                {
                                  departmentData.length > 0 ?(
                                departmentData.map((department, index) => (
                                  <option key={index} value={department.id}>
                                    {department.depart_name}
                                  </option>
                                ))
                              ):(
<option disabled>No Data Found</option>
                              )
                            }
                              </select>
                              )

}
                             
                              {errors.department && (<div className="error-message-view">{errors.department}</div>)}
                            </div>
                            <div className='emppersonalinfo-input-group'>
                              <label>Department Head </label>
                              {isLoadingDepartmenthd ? (
                                <div style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
                                <span className='loader-pages-tablefield'></span>

                                </div>
                              ) :(
                                <select name='depart_head' value={updateBasicInfo.depart_head} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                                <option value='' disabled>Select Department Head</option>
                                {dephdfetch.length > 0?(                 
                                dephdfetch.map((department_head, index) => (
                                  <option key={index} value={department_head.row_id}>
                                    {department_head.depart_head_name}
                                  </option>
                                ))
                              ):(
                                <option disabled>No Data Found</option>
                              )
                                }
                              </select>
                              )

                              }
                             
                              {errors.depart_head && (<div className="error-message-view">{errors.depart_head}</div>)}
                            </div>
                            <div className='emppersonalinfo-input-group'>
                              <label>Department TL
                               </label>
{isLoadingDepartmenttl ? (
                                <div style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
                                <span className='loader-pages-tablefield'></span>

                                </div>
                              ) :
                              (
<select name='depart_tl' value={updateBasicInfo.depart_tl} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                                <option value='' disabled>Select Department TL</option>
                                {deptlfetch.length > 0 ?(

                            
                                deptlfetch.map((department_tl, index) => (
                                  <option key={index} value={department_tl.row_id}>
                                    {department_tl.depart_tl_name}
                                  </option>
                                ))
                              ):(
                                <option disabled>No Data Found</option>
                              )
                              }
                              </select>
                              )

}
                              
                              {errors.depart_tl && (<div className="error-message-view">{errors.depart_tl}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-submitbutton">
                            <button onClick={handleSubmit} disabled={loading}> {loading ? <span className="spinner"></span> : 'Submit'}</button>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Editbasicinfo;
