import React, { useState, useEffect, useCallback } from 'react';
import { CiSearch, CiTrash } from 'react-icons/ci';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import { CiEdit } from 'react-icons/ci';
import Adddesignation from './Adddesignation';
import { DeletedesignationcompApi, FetchDesigApi } from '../../../Config/api';
import Editdesignation from './Editdesignation';
import DeleteAlert from '../../popup/DeleteAlert';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import pageloader from '../../../Loader/pageloading/Pageloader';
import ReactPaginate from "react-paginate";
import { Store } from '../../../Store';
import { deleteDesignationComp } from '../../../Slice/designationSlice';
import Deleteconfirmation from '../../Component/Deleteconfirmation';
import { getRegisterId } from '../../../Config/userId';
import { getUserDetails } from '../../../Services/Storage';
import { desiAddAcess, desiDeleteAcess, desiEditAcess } from '../../../Services/AccessCondition';
import { toast } from 'react-toastify';

const Viewdesignation = () => {

    const register_id = getRegisterId();
    const userDetails = getUserDetails();
    const accessid = userDetails?.get_access_role?.[0]?.invite_user_details?.[0]?.register || "";
    const AddAccess = desiAddAcess();
    const EditAccess = desiEditAcess();
    const DeleteAccess = desiDeleteAcess();
    const [isadddepopen, setadddepopen] = useState(false);
    const [designation, setdesignation] = useState([]);
    const [editData, setEditData] = useState([]);
    const [iseditopen, seteditopen] = useState(false)
    const [deleteComponentId, setDeleteComponentId] = useState(null);
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [totaldedcount, setdedcount] = useState(0)
    const per_page = 10;
    const [currentpage, setCurrentPage] = useState(1);
    const [search, setSearchword] = useState("");
    const [loading, setIsloading] = useState(false);
    const [deleteComponentData, setDeleteComponentData] = useState([]); // New state to hold data
    const [opendeleteconf, setdeleteconf] = useState(false)

    const currentPageNo = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };

    const handleSearch = (event) => {
        setSearchword(event.target.value);
    };

    const openadddep = () => {
        setadddepopen(true);
    };
    const closeaddleave = () => {
        setadddepopen(false);
    };


    const handleDataFromChild = (data) => {
        setdesignation((prev) => [
            data,
            ...prev,
        ])
    }
    const editdesigButton = (componentid) => {
        const updatedList = designation.filter((component) =>
            component.id === componentid
        );
        if (updatedList) {
            setEditData(updatedList[0]);
            seteditopen(true);

        }
    }
    const handleUpdateData = (data) => {
        const updatedList = designation.map((comp) =>
            comp.id === data.id ? data : comp
        );
        setdesignation(updatedList);
    };

    const closedesign = () => {
        seteditopen(false)
    };

    const confirmDelete = () => {
        if (deleteComponentId) {
            deletesalaryComponent(deleteComponentId);
            closeConfirmationModal();
        }
    };

    const showConfirmationModalDelete = (delid) => {
        setDeleteComponentId(delid);
        setConfirmationModalOpen(true);

    }
    const closeConfirmationModal = () => {
        setDeleteComponentId(null);
        setConfirmationModalOpen(false);
    };
    const closedeleteconfirm = () => {
        setdeleteconf(false)
        setDeleteComponentData([])
    };

    const Fetchdesignation = useCallback(async () => {
        try {
            setIsloading(true);

            const response = await FetchDesigApi(`?register_id=${register_id}&page=${currentpage}&page_count=${per_page}&search=${search}&access_id=${accessid}`)
            if (response) {
                setdesignation(response.data.get_designation || [])
                setdedcount(response.data.page_context.total)

            }
        } catch (error) {

        } finally {
            setIsloading(false)
        }
    }, [currentpage, search, per_page, register_id, accessid]);

    useEffect(() => {
        localStorage.removeItem("salaryFilters");
        Fetchdesignation();
    }, [Fetchdesignation]);

    const deletesalaryComponent = async (componentId) => {
        try {
            const response = await DeletedesignationcompApi(`${componentId}/?edited_by=${userDetails.id}`);
            if (response) {
                const updatedList = designation.filter((component) => component.id !== componentId);

                setdesignation(updatedList);
                Store.dispatch(deleteDesignationComp(componentId));
                toast.success('Designation Deleted Sucessfully!', {
                                                position: "top-center",
                                                autoClose: 2000,
                                                closeButton: false,
                                                className: 'custom_toast'
                                              })

            }
        }
        catch (error) {
            if (error.response && error.response.status === 422) {
                setDeleteComponentData(error.response.data.data);

                setdeleteconf(true)

            }
        }
    }

    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='pages-subheader'>
                                <div className='pages-subheader-sub-1'>
                                    <p>Designation
                                    </p>
                                    <span className='header-search-add-parent'>
                                        <div className='pages-serach-view'>
                                            <CiSearch className='page-search-icon' />
                                            <input className='pages-serach' onChange={handleSearch} placeholder='Search Designation ' type='search' />
                                        </div>
                                        {AddAccess
                                            ? (<button className='pages-addempbutton' onClick={openadddep}>Add Designation</button>
                                            ) :
                                            (<></>)

                                        }
                                    </span>
                                </div>
                                <div className='pages-line'></div>
                            </div>
                            <div className='pages-maincontent'>
                                {loading ? (
                                    <div className="loader-container">
                                        <img src={pageloader.page_loader} alt="Loading..." />
                                    </div>
                                )
                                    : (
                                        <div className="pages-table-container">

                                            <table className='pages-table'>
                                                {designation && designation.length > 0 && (
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Sl No</th>
                                                            <th>Designation</th>
                                                            <th>##</th>
                                                        </tr>
                                                    </thead>
                                                )}
                                                <tbody>
                                                    {designation && designation.length > 0 ? (

                                                        designation.map((designation, index) => (
                                                            <tr key={designation.id}>
                                                                <td></td>
                                                                <td>{(currentpage - 1) * per_page + index + 1}</td>
                                                                <td>{designation.designation}</td>
                                                                <td>{designation.user !== null ? (
                                                                    <span style={{ display: "flex", flexDirection: "row", gap: "20px", fontSize: "14px", alignItems: "center" }}>
                                                                        {EditAccess
                                                                            ? (<CiEdit onClick={() => editdesigButton(designation.id)} style={{ color: "#2a7ac7", cursor: "pointer" }} />
                                                                            )
                                                                            : (<></>)

                                                                        }
                                                                        {DeleteAccess ? (<CiTrash onClick={() => showConfirmationModalDelete(designation.id)} style={{ color: "red", cursor: "pointer" }} />
                                                                        ) : (<></>)

                                                                        }
                                                                    </span>
                                                                ) : null}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={8} className="no-data-table">

                                                                <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                                                            </td>
                                                        </tr>
                                                    )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                <div style={{ float: "right" }} id="pagination_link"></div>
                                <div >
                                    {(!loading) && (totaldedcount > per_page) ? (
                                        <>
                                            <ReactPaginate
                                                pageCount={Math.ceil(totaldedcount / per_page)}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={2}
                                                onPageChange={currentPageNo}
                                                containerClassName={"pagination"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                forcePage={currentpage - 1}

                                            />
                                        </>
                                    ) : (
                                        <> </>
                                    )}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Adddesignation
                show={isadddepopen}
                close={closeaddleave}
                sendDataToParent={handleDataFromChild}
            />

            <Editdesignation
                editData={editData}
                updateData={handleUpdateData}
                show={iseditopen}
                close={closedesign}
            />

            <DeleteAlert
                isOpen={isConfirmationModalOpen}
                message="Really, would you like to remove this Designation?"
                onCancel={closeConfirmationModal}
                onConfirm={confirmDelete}
            />

            <Deleteconfirmation
                show={opendeleteconf}
                close={closedeleteconfirm}
                data={deleteComponentData}
            />

        </div>
    );
}

export default Viewdesignation;
